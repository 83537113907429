import { Injectable } from '@angular/core';
import { InputNumberConfig } from 'libs/common-components/src/lib/input-number';

@Injectable({ providedIn: 'root' })
export class FiscalTaxDecimalsService {

  constructor() { }

  get nfeDecimal0302a04(): Partial<InputNumberConfig> {
    return {
      maxValue: 100,
      minValue: 0.0001,
      precision: 4,
      allowNegative: false
    }
  }

  get nfeDecimal0302a04Optional(): Partial<InputNumberConfig> {
    return {
      maxValue: 100,
      minValue: 0.0001,
      precision: 4,
      allowNegative: false,
      nullable: true
    }
  }
}
