import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
export class VendorSummary implements DomainEntity {
  constructor(readonly id = SgvId.gen(), readonly name: string = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): VendorSummary {
    return SgvJson.from.simple(data, VendorSummary);
  }
}

export class Vendor implements DomainEntity {
  constructor(readonly id = SgvId.gen(), public name: string = '') {}

  readonly isActive = true;

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): Vendor {
    return SgvJson.from.simple(data, Vendor);
  }
}
