import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";
import { OperatableUnityLegacy } from "../operatables";

export class ConversionRateUnity implements DomainEntity {
    constructor(
        public readonly id = SgvId.gen(),
        public operatableUnity: OperatableUnityLegacy = null,
        public conversionRate: number = 0
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this, {
            operatableUnity: this.operatableUnity.toJson()
        });
    }

    static fromJson(json: any): ConversionRateUnity {
        return  json? SgvJson.from.simple(json, ConversionRateUnity, {
            operatableUnity: OperatableUnityLegacy.fromJson(json.operatableUnity) 
        })
        :  null;
    }
}