export const environment = {
  traceRouter: false,
  production: true,
  apiRoot: `https://api.eceos.cloud/v1/`,
  sgvRoot: 'https://eceos.cloud/',
  analyticsApiRoot: `https://analytics.eceos.cloud/v1/`,
  managerApiRoot: 'https://manager.eceos.cloud/v1/',
  reCaptchaV3Key: '6Lc7WucUAAAAAEqa4p8uUIfofli0H7hsybUcBgQF',
  gaCode: 'G-D4Q8HSW3LX'
};
