import { Directive, HostListener, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NumberMask } from '@eceos/common-utils';

/* Diretiva de máscara genérica em campo de texto.
 *
 * @author Márcio Casale de Souza <contato@kazale.com>
 * @since 0.0.4
 */

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[kzMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: KzMaskDirective,
      multi: true
    }
  ]
})
export class KzMaskDirective implements ControlValueAccessor {
  @Output() invalidated = new EventEmitter();

  @Input('kzMaskCleanOnBlur') cleanOnBlur = true;

  @Input('kzMaskCleanInputOnBlur') cleanInputOnBlur = true;

  private onTouched: (() => void) = null;
  private onChange: ((v: string) => void) = null;

  private mask: NumberMask;
  private focusOut: Boolean = false

  constructor(private element: ElementRef) { }

  get input(): HTMLInputElement {
    return this.element.nativeElement;
  }

  get kzMask(): string | string[] {
    if (this.mask.masks.length > 1) {
      return this.mask.masks.map(m => m.mask);
    } else {
      return this.mask.masks[0].mask;
    }
  }

  @Input()
  set kzMask(value: string | string[]) {
    this.mask = new NumberMask(value);
  }

  @Input()
  set cleanOnFocusOut(value: Boolean) {
    this.focusOut = value
  }

  writeValue(value: any): void {
    this.input.value = value ? `${value}` : '';
    this.refreshMask();
  }

  registerOnChange(fn: (v: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event: KeyboardEvent) {
    const input = <HTMLInputElement>$event.target;
    const mask = this.mask.getMask(input.value).mask;
    const valor = input.value.replace(/\D/g, '');
    const pad = mask.replace(/\D/g, '').replace(/9/g, '_');
    const valorMask = valor + pad.substring(0, pad.length - valor.length);

    // retorna caso pressionado backspace
    if ($event.keyCode === 8) {
      this.publishChange(valor);
      return;
    }

    if (valor.length <= pad.length) {
      this.publishChange(valor);
    }

    this.input.value = this.mask.apply(valorMask);
  }

  refreshMask() {
    this.input.value = this.mask.apply(this.input.value);
  }

  @HostListener('blur', ['$event'])
  onBlur($event: FocusEvent) {
    if (this.onTouched) {
      this.onTouched();
    }
    const input = <HTMLInputElement>$event.target;
    if (input.value.length === this.mask.getMask(input.value).mask.length && input.validity.valid) {
      return;
    }
    if (this.cleanInputOnBlur){
      this.publishChange('');
    } else {
      this.publishChange(input.value);
    }
    if (input.value && this.cleanOnBlur) {
      if (this.focusOut) {
        input.value = '';
      }
      this.invalidated.emit();
    }
  }

  publishChange(v: string) {
    if (this.onChange) {
      this.onChange(v);
    }
  }
}
