import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PaymentForm, CashPaymentForm, DeferredPaymentForm } from '@eceos/domain';

@Pipe({
  name: 'paymentSummary'
})
export class PaymentSummaryPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(paymentForm: PaymentForm, prefix: string = ''): any {
    if (paymentForm instanceof CashPaymentForm) {
      return `${prefix}${this.toCurrency(paymentForm.getTotalPaid())}`;
    } else if (paymentForm instanceof DeferredPaymentForm) {
      return `${prefix}${this.toCurrency(paymentForm.getTotalPaid())} +
      ${paymentForm.numberOfInstallments || 0} x ${this.toCurrency(paymentForm.installmentValue)}`;
    } else {
      return 'Sem pagamento';
    }
  }

  private toCurrency(value: number): string {
    return this.currencyPipe.transform(value || 0, 'BRL', 'symbol');
  }
}
