<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <mat-card class="unauthorized loading" *ngIf="loading">
    <mat-card-content>
      <mat-spinner></mat-spinner>
    </mat-card-content>
  </mat-card>

  <mat-card class="unauthorized" *ngIf="!loading">
    <mat-card-title>Acesso não autorizado</mat-card-title>
    <mat-card-content>
      <p>Desculpe, esta empresa está temporariamente inativa.</p>
      <span *ngIf="isDefaultReason">
        <p>Solicitamos que entre em contato conosco através das nossas centrais.</p>
        <p class="contacts">
          <span>
            <a mat-button href="tel:0800 878 2737" target="_blank">
              <mat-icon> phone </mat-icon>
            </a>
            <a href="tel:0800 878 2737"> 0800 878 2737 </a>
          </span>
          <span>
            <a mat-button href="mailto:contato@eceos.com.br" target="_blank">
              <mat-icon> email </mat-icon>
            </a>
            <a href="mailto:contato@eceos.com.br">contato@eceos.com.br </a>
          </span>
        </p>
      </span>

      <span *ngIf="isFreeCreditExpiredReason">
        <p>
          Seu período gratuito de avaliação expirou, para que continue utilizando o sistema, é
          necessário o preenchimento dos dados para a cobrança.
        </p>
      </span>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button [routerLink]="['/']">Voltar ao início</button>
      <button
        mat-raised-button
        color="primary"
        [routerLink]="['/manager/billings/new']"
        *ngIf="isFreeCreditExpiredReason"
      >
        Preencher dados de cobrança
      </button>
    </mat-card-actions>
  </mat-card>
</section>
