import { SgvId, SgvJson } from '@eceos/arch';
import { NFeShippingResponsible, NFeShippingWithoutResponsible } from './nfe-shipping-responsible';
import { NFeTransportMean } from './nfe-transport-mean';
import { NFeVolume } from './nfe-volume';
import { ShippingModality } from './shipping-modality';

export class NFeTransport {
  constructor(
    public readonly id: String = SgvId.gen(),
    public shippingModality: ShippingModality = ShippingModality.WITHOUT_TRANSPORT,
    public shippingResponsible: NFeShippingResponsible = new NFeShippingWithoutResponsible(),
    public transportMean: NFeTransportMean = null,
    public volumes: NFeVolume[] = []
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      shippingModality: this.shippingModality?.toJson(),
      shippingResponsible: !this.isShippingWithoutResponsible() ? this.shippingResponsible?.toJson() : null,
      transportMean: this.transportMean?.toJson(),
      volumes: this.getJsonVolumes()
    });
  }

  private isShippingWithoutResponsible(): boolean {
    return this.shippingResponsible ? this.shippingResponsible instanceof NFeShippingWithoutResponsible : false
  }

  private getJsonVolumes(): any {
    return this.volumes
      ?.filter((it: NFeVolume) => it != null)
      .map((it: NFeVolume) => it?.toJson())
  }

  static fromJson(json: any): NFeTransport {
    return json ? SgvJson.from.simple(json, NFeTransport, {
      shippingModality: ShippingModality.fromJson(json.shippingModality),
      shippingResponsible: NFeShippingResponsible.fromJson(json.shippingResponsible),
      transportMean: NFeTransportMean.fromJson(json.transportMean),
      volumes: SgvJson.from.array(json.volumes, NFeVolume.fromJson)
    }) : null;
  }
}
