import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AuthManagerService, AuthService } from '@eceos/arch';
import { CurrentUserService, User } from '@eceos/manager-domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LayoutBaseComponent } from '../base/layout-base.component';

@Component({
  selector: 'manager-layout',
  templateUrl: './layout-manager.component.html',
  styleUrls: ['./layout-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutManagerComponent implements OnInit, OnDestroy {
  @Input() fillPrimary = true;

  @Input() pageTitle: string = null;

  @Input() showSearch = false;

  @Input() searchPlaceholder = 'Pesquisar';

  @Input() showMenu = false;

  @Input() showBack = false;

  @Input() withDefaultButtons = true;

  @Output() searchChange = new EventEmitter<string>();

  @ViewChild(LayoutBaseComponent) layoutBase: LayoutBaseComponent;

  homePath = '/manager';

  user: Observable<User>;

  constructor(
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private authManagerService: AuthManagerService
  ) {
    this.user = this.currentUserService.$user.pipe(untilDestroyed(this));
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  doLogout() {
    this.authService.publishLogout();
    this.authManagerService.publishLogout();
    location.reload();
  }
}
