import { Injectable } from '@angular/core';
import {
  ApiService,
  ReadonlyRepositoryImpl,
  InterceptorConfig,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ticket, TicketCallRequest, TicketSumary } from './ticket';

@Injectable({ providedIn: 'root' })
export class TicketsRepository extends ReadonlyRepositoryImpl<TicketSumary, Ticket> {
  constructor(api: ApiService) {
    super(api.root.path('equeue').path('tickets'), TicketSumary, Ticket);
  }

  callTicket(
    id: string,
    ticketCallRequest: TicketCallRequest,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Ticket> {
    return this.api
      .path(id)
      .path('calls')
      .postJson(ticketCallRequest.toJson(), {
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(o => Ticket.fromJson(o)));
  }
}
