<eco-fab-speed-dial
  *ngIf="options.length"
  [(open)]="fabOpen"
  (openChange)="onOpenChange($event)"
  class="floatDefault"
>
  <eco-fab-speed-dial-trigger [spin]="true">
    <button mat-fab class="spin180">
      <mat-icon>{{ fabOpen ? 'close' : 'add' }}</mat-icon>
    </button>
  </eco-fab-speed-dial-trigger>
  <eco-fab-speed-dial-actions>
    <button
      *ngFor="let option of options"
      mat-mini-fab
      (click)="optionClick(option)"
      [style.background-color]="option?.backgroundColor"
      [style.color]="option?.color"
    >
      <span class="tooltiptext" [ngClass]="labelsPosition" *ngIf="showLabels && fabOpen">{{
        option.label
      }}</span>
      <mat-icon *ngIf="!option.iconSource || option.iconSource == MATERIAL">{{
        option.icon
      }}</mat-icon>
      <mat-icon *ngIf="option?.iconSource == FONT_AWESOME" [svgIcon]="option.icon"></mat-icon>
    </button>
  </eco-fab-speed-dial-actions>
</eco-fab-speed-dial>
