import { SgvId, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import {
  CofinsValueWithAliquotHolder,
  CofinsValueWithAliquotValue
} from './cofins-value-with-aliquot';
import {
  CofinsValueWithBcAliquot,
  CofinsValueWithBcAliquotHolder,
  CofinsValueWithBcAliquotPercent
} from './cofins-value-with-bc-aliquot';
import { CofinsCst } from './csts/cofins-cst';

export abstract class COFINS {
  constructor(readonly id: string = SgvId.gen(), public cst: CofinsCst = null) {}

  abstract get value(): number;

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): COFINS {
    if (json && json.type) {
      switch (json.type) {
        case 'AliquotCofins':
          return AliquotCOFINS.fromJson(json);
        case 'NonTaxableCofins':
          return NonTaxableCOFINS.fromJson(json);
        case 'OtherCofins':
          return OtherCOFINS.fromJson(json);
        case 'QuantityCofins':
          return QuantityCOFINS.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class AliquotCOFINS extends COFINS implements CofinsValueWithBcAliquotHolder {
  constructor(
    id = SgvId.gen(),
    cst: CofinsCst = null,
    public cofins: CofinsValueWithBcAliquot = new CofinsValueWithBcAliquotPercent()
  ) {
    super(id, cst);
  }

  get value(): number {
    return this.cofins.value.value;
  }

  isValid(): boolean {
    return this.cst != null && EceosValidatorsUtils.isValid(this.cofins);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'AliquotCofins',
      cst: this.cst ? this.cst.toJson() : null,
      cofins: this.cofins ? this.cofins.toJson() : null
    });
  }

  static fromJson(json: any): AliquotCOFINS {
    return json
      ? SgvJson.from.simple(json, AliquotCOFINS, {
          cst: CofinsCst.fromJson(json.cst),
          cofins: CofinsValueWithBcAliquot.fromJson(json.cofins)
        })
      : null;
  }
}

export class NonTaxableCOFINS extends COFINS {
  constructor(id = SgvId.gen(), cst: CofinsCst = null) {
    super(id, cst);
  }

  get value(): number {
    return null;
  }

  isValid(): boolean {
    return this.cst != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'NonTaxableCofins',
      cst: this.cst ? this.cst.toJson() : null
    });
  }

  static fromJson(json: any): NonTaxableCOFINS {
    return json
      ? SgvJson.from.simple(json, NonTaxableCOFINS, { cst: CofinsCst.fromJson(json.cst) })
      : null;
  }
}

export class OtherCOFINS extends COFINS implements CofinsValueWithBcAliquotHolder {
  constructor(
    id = SgvId.gen(),
    cst: CofinsCst = null,
    public cofins: CofinsValueWithBcAliquot = null
  ) {
    super(id, cst);
  }

  get value(): number {
    return this.cofins.value.value;
  }

  isValid(): boolean {
    return this.cst != null && EceosValidatorsUtils.isValid(this.cofins);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'OtherCofins',
      cst: this.cst ? this.cst.toJson() : null,
      cofins: this.cofins ? this.cofins.toJson() : null
    });
  }

  static fromJson(json: any): OtherCOFINS {
    return json
      ? SgvJson.from.simple(json, OtherCOFINS, {
          cst: CofinsCst.fromJson(json.cst),
          cofins: CofinsValueWithBcAliquot.fromJson(json.cofins)
        })
      : null;
  }
}

export class QuantityCOFINS extends COFINS implements CofinsValueWithAliquotHolder {
  constructor(
    id = SgvId.gen(),
    cst: CofinsCst = null,
    public cofins: CofinsValueWithAliquotValue = new CofinsValueWithAliquotValue()
  ) {
    super(id, cst);
  }

  get value(): number {
    return this.cofins.value.value;
  }

  isValid(): boolean {
    return this.cst != null && EceosValidatorsUtils.isValid(this.cofins);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'QuantityCofins',
      cst: this.cst ? this.cst.toJson() : null,
      cofins: this.cofins ? this.cofins.toJson() : null
    });
  }

  static fromJson(json: any): QuantityCOFINS {
    return json
      ? SgvJson.from.simple(json, QuantityCOFINS, {
          cst: CofinsCst.fromJson(json.cst),
          cofins: CofinsValueWithAliquotValue.fromJson(json.cofins)
        })
      : null;
  }
}
