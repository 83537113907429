import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiPath, ApiService, InterceptorConfig, UrlSearchParamsBuilder } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ecnpj } from '../core';

@Injectable({ providedIn: 'root' })
export class CurrentEcnpjService {
  private path: ApiPath;
  constructor(api: ApiService, private sanitizer: DomSanitizer) {
    this.path = api.root.path('enterprises', 'current', 'ecnpj');
  }

  getInfo(): Observable<Ecnpj> {
    const search = new UrlSearchParamsBuilder().summary(true).build();
    return this.path
      .getJson({ params: search, metadata: { autoCatch: InterceptorConfig.NO_INTERCEPT } })
      .pipe(map((j) => Ecnpj.fromJson(j)));
  }

  getFile(): Observable<Blob> {
    return this.path.getBlob();
  }

  uploadNew(file: File, pin: string): Observable<boolean> {
    const form = new FormData();
    form.append('file', file);
    form.append('pin', pin);
    return this.path.putMultipartForm(form).pipe(map((v) => true));
  }
}
