import { SgvJson } from '@eceos/arch';
import { EnterpriseSummary } from '../enterprises/enterprise';
import { BrazilianAddress } from '../addresses/address';
import { Documents } from '../documents/documents';

export abstract class BillingDataSource {
  get isEnterpriseBillingDataSource(): boolean {
    return this.constructor === EnterpriseBillingDataSource;
  }

  get isManualBillingDataSource(): boolean {
    return this.constructor === ManualBillingDataSource;
  }

  abstract toJson(): any;

  static fromJson(json: any): BillingDataSource {
    if (json && json.type) {
      switch (json.type) {
        case 'enterprise':
          return EnterpriseBillingDataSource.fromJson(json);
        case 'manual':
          return ManualBillingDataSource.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class EnterpriseBillingDataSource extends BillingDataSource {
  constructor(public enterprise: EnterpriseSummary = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'enterprise', enterprise: this.enterprise.toJson() });
  }

  static fromJson(json: any): EnterpriseBillingDataSource {
    return json
      ? SgvJson.from.simple(json, EnterpriseBillingDataSource, {
          enterprise: EnterpriseSummary.fromJson(json.enterprise)
        })
      : null;
  }
}

export class ManualBillingDataSource extends BillingDataSource {
  constructor(public address: BrazilianAddress = null, public documents: Documents = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'manual',
      address: this.address.toJson(),
      documents: this.documents.toJson()
    });
  }

  static fromJson(json: any): ManualBillingDataSource {
    return json
      ? SgvJson.from.simple(json, ManualBillingDataSource, {
          address: BrazilianAddress.fromJson(json.address),
          documents: Documents.fromJson(json.documents)
        })
      : null;
  }
}
