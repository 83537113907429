import { SgvJson } from '@eceos/arch';

export abstract class NFeFuelAnp {
  constructor(public productCode: string, public productDescription: string) {}

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): NFeFuelAnp {
    if (json && json.type) {
      switch (json.type) {
        case 'glp':
          return NFeGlpAnp.fromJson(json);
        case 'other':
          return NFeOtherAnp.fromJson(json);
      }
    }
    return null;
  }
}

export class NFeGlpAnp extends NFeFuelAnp {
  constructor(
    public glpPercent: number = null,
    public gnnPercent: number = null,
    public gniPercent: number = null,
    public startingValue: number = null
  ) {
    super('210203001', 'GLP');
  }

  isValid(): boolean {
    return (
      this.productCode === '210203001' &&
      this.productDescription === 'GLP' &&
      this.glpPercent != null &&
      this.gnnPercent != null &&
      this.gniPercent != null &&
      this.startingValue != null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'glp'
    });
  }

  static fromJson(json: any): NFeGlpAnp {
    return json ? SgvJson.from.simple(json, NFeGlpAnp) : null;
  }
}

export class NFeOtherAnp extends NFeFuelAnp {
  constructor(productCode: string = null, productDescription: string = '') {
    super(productCode, productDescription);
  }

  isValid(): boolean {
    return this.productCode != null && this.productDescription != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'other'
    });
  }

  static fromJson(json: any): NFeOtherAnp {
    return json ? SgvJson.from.simple(json, NFeOtherAnp) : null;
  }
}
