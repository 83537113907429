import { JsonEntity, SgvJson } from '@eceos/arch';

export const WITHOUT_FREIGHT_ID = 'WITHOUT_FREIGHT';

export class FreightModality implements JsonEntity {
  constructor(
    readonly id: string = null,
    readonly name: string = null,
    readonly number: number = null
  ) {}

  get isWithoutFreight(): boolean {
    return this.id === WITHOUT_FREIGHT_ID;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): FreightModality {
    return json ? SgvJson.from.simple(json, FreightModality) : null;
  }

  static withoutFreight(): FreightModality {
    return new FreightModality(WITHOUT_FREIGHT_ID, 'Sem Ocorrência de transporte', 9);
  }
}
