import { SgvId, DomainEntity, SgvJson } from '@eceos/arch';
import { TransmissionEnvironment } from './transmission-environment';

export abstract class FiscalSeries implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public series = '1',
    public startNumber = 0,
    public number = 0,
    public transmissionEnvironment = TransmissionEnvironment.PRODUCTION,
    public description = '',
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  get currentNumber() {
    return this.number + this.startNumber;
  }
  
  abstract toJson();

  static fromJson(json: any): FiscalSeries {
    if (!json) {
      return null;
    }
    switch (json.model) {
      case 'NFE':
        return NFeSeries.fromJson(json);
      case 'NFCE':
        return NFCeSeries.fromJson(json);
      case 'NFSE':
        return NFSeSeries.fromJson(json);
      default:
        return null;
    }
  }

}

export abstract class FiscalProductSeries extends FiscalSeries {

  get isNFeSerie(): boolean{
    return this instanceof NFeSeries;
  }

  get isNFCeSerie(): boolean{
    return this instanceof NFCeSeries;
  }

  static fromJson(json: any): FiscalProductSeries {
    if (!json) {
      return null;
    }
    switch (json.model) {
      case 'NFE':
        return NFeSeries.fromJson(json);
      case 'NFCE':
        return NFCeSeries.fromJson(json);
      default:
        return null;
    }
  }
}

export class NFeSeries extends FiscalProductSeries {
  toJson(): any {
    return SgvJson.to.simple(this, {
      transmissionEnvironment: this.transmissionEnvironment.name,
      model: 'NFE'
    });
  }

  static fromJson(json: any): NFeSeries {
    return SgvJson.from.simple(json, NFeSeries, {
      transmissionEnvironment: json.transmissionEnvironment
        ? TransmissionEnvironment.get(json.transmissionEnvironment)
        : null
    });
  }
}

export class NFCeSeries extends FiscalProductSeries {
  toJson(): any {
    return SgvJson.to.simple(this, {
      transmissionEnvironment: this.transmissionEnvironment.name,
      model: 'NFCE'
    });
  }

  static fromJson(json: any): NFCeSeries {
    return SgvJson.from.simple(json, NFCeSeries, {
      transmissionEnvironment: json.transmissionEnvironment
        ? TransmissionEnvironment.get(json.transmissionEnvironment)
        : null
    });
  }
}

export class NFSeSeries extends FiscalSeries {
  toJson(): any {
    return SgvJson.to.simple(this, {
      transmissionEnvironment: this.transmissionEnvironment.name,
      model: 'NFSE'
    });
  }

  static fromJson(json: any): NFSeSeries {
    return SgvJson.from.simple(json, NFSeSeries, {
      transmissionEnvironment: json.transmissionEnvironment
        ? TransmissionEnvironment.get(json.transmissionEnvironment)
        : null
    });
  }
}

export class FiscalSeriesSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public name = '',
    public model = '',
    public series = 1
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): FiscalSeriesSummary {
    return SgvJson.from.simple(json, FiscalSeriesSummary);
  }
}
