import { JsonEntity, SgvJson } from '@eceos/arch';
import { Country } from './country';
import { ZipCode } from './zipCode';

export abstract class Address implements JsonEntity {
  abstract get fullAddress();

  abstract toJson(): any;

  static fromJson(json: any): Address {
    if (!json) {
      return null;
    }
    switch (json.type) {
      case 'brazilian':
        return BrazilianAddress.fromJson(json);
      case 'simpleForeign':
        return SimpleForeignAddress.fromJson(json);
      default:
        return null;
    }
  }
}

export class BrazilianAddress extends Address {
  constructor(
    public zipCode: ZipCode = new ZipCode(),
    public number: string = '',
    public details: string = ''
  ) {
    super();
  }

  get fullAddress() {
    let result = '';
    if (this.zipCode) {
      if (this.zipCode.street) {
        result += this.zipCode.street;
        if (this.number !== '') {
          result += `, ${this.number}`;
        }
        if (this.details) {
          result += `, ${this.details}`;
        }
      }
      if (result !== '') {
        result += ' - ';
      }
      if (this.zipCode.district) {
        result += this.zipCode.district;
      }
      if (this.zipCode.district && this.zipCode.city) {
        result += ', ';
      }
      if (this.zipCode.city) {
        result += this.zipCode.city.nameWithUf;
      }
    }
    return result;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      zipCode: this.zipCode ? this.zipCode.toJson() : new ZipCode(),
      type: 'brazilian'
    });
  }

  static fromJson(json: any): BrazilianAddress {
    return SgvJson.from.simple(json, BrazilianAddress, {
      zipCode: ZipCode.fromJson(json.zipCode)
    });
  }
}

export class SimpleForeignAddress extends Address {
  constructor(public country: Country = null) {
    super();
  }
  get fullAddress() {
    return this.country ? this.country.name : '';
  }
  toJson() {
    return SgvJson.to.simple(this, {
      country: this.country ? this.country.toJson() : null,
      type: 'simpleForeign'
    });
  }

  static fromJson(json: any): SimpleForeignAddress {
    return SgvJson.from.simple(json, SimpleForeignAddress, {
      country: json.country ? Country.fromJson(json.country) : null
    });
  }
}
