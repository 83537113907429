import { SgvJson } from '@eceos/arch';

export class Name {
  constructor(public first = '', public last = '') {}

  get full(): string {
    return `${this.first} ${this.last}`.trim();
  }

  toJson(): string {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Name {
    return json ? SgvJson.from.simple(json, Name) : null;
  }
}
