import { SgvId, SgvJson } from '@eceos/arch';
import { Uf } from '../../../core/address';
export class NFeVehicle {
  constructor(
    public readonly id: String = SgvId.gen(),
    public licensePlate: String = null,
    public rntc: String = null,
    public uf: Uf = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      uf: this.uf ? this.uf.toJson() : null
    });
  }

  static fromJson(json: any): NFeVehicle {
    return json ? SgvJson.from.simple(json, NFeVehicle, {
      uf: Uf.fromJson(json.uf)
    }) : null;
  }
}
