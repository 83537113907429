import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  DateInterval,
  InterceptorConfig,
  RequestMetadata,
  RequestMetadataUtils,
  SgvJson,
  TemporalRepositoryImpl,
  UrlSearchParamsBuilder
} from '@eceos/arch';
import { ReportFormat } from '@eceos/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RemotePrinterSummary } from '../../ehwhub';
import { Sale, SaleSummary } from '../../sales';
import { VaccineApplication, VaccineApplicationSummary } from '../../vaccines';
import { NFeItemMetadataRequest, NFeItemMetadataResponse } from './item/nfe-item-metadata';
import { NFeItemOperationRequest, NFeItemOperationResponse } from './item/nfe-item-operation';
import { BaseNFe, BaseNFeSummary, NFe } from './nfe';
import { NFeEvent } from './nfe-event';
import { NFeExportType } from './nfe-export-type';

@Injectable({ providedIn: 'root' })
export class NfesRepository extends TemporalRepositoryImpl<BaseNFeSummary, BaseNFe> {
  constructor(api: ApiService) {
    super(api.root.path('/fiscal/nfes'), BaseNFeSummary, BaseNFe);
  }

  createFromSale(
    sale: Sale,
    model: any,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BaseNFeSummary> {
    const params = new HttpParams().set('model', model);
    return this.api
      .path('fromSale')
      .path(sale.id)
      .postJson({}, { params, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map((r) => BaseNFeSummary.fromJson(r)));
  }

  createFromVaccineApplication(
    vaccineApplication: VaccineApplication,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BaseNFeSummary> {
    return this.api
      .path('fromVaccineApplication')
      .path(vaccineApplication.id)
      .postJson({}, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map((r) => BaseNFeSummary.fromJson(r)));
  }

  transmit(
    nfe: BaseNFeSummary | BaseNFe,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BaseNFeTransmitResult> {
    return this.api
      .path(nfe.id)
      .path('transmit')
      .postJson({}, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map((r) => BaseNFeTransmitResult.fromJson(r)));
  }

  transmitCCe(
    nfe: BaseNFeSummary | BaseNFe,
    correction: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BaseNFeTransmitResult> {
    const formParams = new URLSearchParams();
    formParams.set('correction', correction);
    return this.api
      .path(nfe.id)
      .path('transmitCCe')
      .postForm(formParams, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map((r) => BaseNFeTransmitResult.fromJson(r)));
  }

  cancel(
    nfe: BaseNFeSummary | BaseNFe,
    justification: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BaseNFeTransmitResult> {
    const formParams = new URLSearchParams();
    formParams.set('justification', justification);
    return this.api
      .path(nfe.id)
      .path('cancel')
      .postForm(formParams, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map((r) => BaseNFeTransmitResult.fromJson(r)));
  }

  printTo(
    nfe: BaseNFeSummary,
    printer: RemotePrinterSummary,
    format: ReportFormat = ReportFormat.PDF,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<any> {
    const form = new URLSearchParams();
    form.set('printerId', printer.id);
    return this.api
      .path(nfe.id)
      .path('receipt.' + format.value)
      .path('printTo')
      .postForm(form, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  mailTo(
    nfe: BaseNFe | BaseNFeSummary,
    mail: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<any> {
    const form = new URLSearchParams();
    form.set('mail', mail);
    return this.api
      .path(nfe.id)
      .path('receipt.pdf')
      .path('mailTo')
      .postForm(form, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  findSummaryBySale(
    sale: Sale | SaleSummary,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<BaseNFeSummary> {
    const params = new HttpParams().set('summary', 'true');
    return this.api
      .path('fromSale')
      .path(sale.id)
      .getJson({ params, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map((r) => BaseNFeSummary.fromJson(r)));
  }

  findSummaryByVaccineApplication(
    vaccineApplication: VaccineApplication | VaccineApplicationSummary
  ): Observable<BaseNFeSummary> {
    return this.api
      .path('fromVaccineApplication')
      .path(vaccineApplication.id)
      .getJson({ params: new UrlSearchParamsBuilder().summary(true).build() })
      .pipe(map((r) => BaseNFeSummary.fromJson(r)));
  }

  findNFeItemMetadata(
    nfeItemMetadataRequest: NFeItemMetadataRequest
  ): Observable<NFeItemMetadataResponse> {
    return this.api
      .path('item')
      .path('metadata')
      .postJson(nfeItemMetadataRequest.toJson())
      .pipe(map((r) => NFeItemMetadataResponse.fromJson(r)));
  }

  findNFeItemOperation(
    nfeItemOperationRequest: NFeItemOperationRequest
  ): Observable<NFeItemOperationResponse> {
    return this.api
      .path('item')
      .path('operation')
      .postJson(nfeItemOperationRequest.toJson())
      .pipe(map((r) => NFeItemOperationResponse.fromJson(r)));
  }

  danfeAsPdf(nfe: BaseNFeSummary | BaseNFe): Observable<Blob> {
    return this.api.path(nfe.id).path('receipt.pdf').getBlob();
  }

  cceAsPdf(nfe: BaseNFeSummary | BaseNFe, event: NFeEvent): Observable<Blob> {
    return this.api.path(nfe.id).path('event').path(event.id).path('cce.pdf').getBlob();
  }

  exportInLot(interval: DateInterval, type: NFeExportType): Observable<Blob> {
    return this.api
      .path(interval.toString())
      .path('export')
      .getBlob({
        params: new HttpParams().append('type', type.label).append('_downloadMode', 'download')
      });
  }

  exportByNFe(nfe: BaseNFe, type: NFeExportType): Observable<Blob> {
    return this.api
      .path('export')
      .path(nfe.id)
      .getBlob({
        params: new HttpParams().append('type', type.label).append('_downloadMode', 'download')
      });
  }
}

export class BaseNFeTransmitResult {
  constructor(private readonly result: string = '', readonly details: string = '') { }

  get isSuccess() {
    return this.result === 'success';
  }

  get isUnauthorized() {
    return this.result === 'unauthorized';
  }

  get isDenied() {
    return this.result === 'denied';
  }

  get isDisabled() {
    return this.result === 'disabled';
  }

  get isOther() {
    return !this.isSuccess && !this.isUnauthorized && !this.isDenied;
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, BaseNFeTransmitResult);
  }
}
