import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { DeliveryPolicy, DeliveryPolicySummary } from './delivery-policy';

@Injectable({ providedIn: 'root' })
export class DeliveryPoliciesRepository extends RepositoryImpl<
  DeliveryPolicySummary,
  DeliveryPolicy
> {
  constructor(api: ApiService) {
    super(api.root.path('/deliveryPolicies'), DeliveryPolicySummary, DeliveryPolicy);
  }
}
