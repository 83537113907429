import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { LayoutBaseModule } from '../base/layout-base.module';
import { LayoutManagerComponent } from './layout-manager.component';

const COMPONENTS = [LayoutManagerComponent];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, LayoutBaseModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class LayoutManagerModule {}
