const SNAPSHOT_SUFFIX = 'SNAPSHOT';
const SUFFIX_SEPARATOR = '-';

export class Version {
  readonly parts: number[];

  readonly snapshot: boolean;

  constructor(readonly full: string) {
    if (full.indexOf(' ') >= 0) {
      throw Error('version não deve conter espaços');
    }
    const [body, suffix] = full.split(SUFFIX_SEPARATOR);
    this.snapshot = suffix === SNAPSHOT_SUFFIX;
    this.parts = body.split('.').map((v: string) => parseInt(v, 10));
  }

  get major() {
    return this.hasMajor ? this.parts[0] : 0;
  }

  get hasMajor() {
    return this.parts.length >= 1;
  }

  get minor() {
    return this.hasMinor ? this.parts[1] : 0;
  }

  get hasMinor() {
    return this.parts.length >= 1;
  }

  get patch() {
    return this.hasPatch ? this.parts[2] : 0;
  }

  get hasPatch() {
    return this.parts.length >= 1;
  }
}
