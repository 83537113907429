import { SgvJson } from '@eceos/arch';

export class NFeFuelClosing {
  constructor(
    public nozzleNumber: string = '',
    public pumpNumber: string = '',
    public tankNumber: string = '',
    public closingBeginValue: number = 0,
    public closingEndValue: number = 0
  ) {}

  isValid(): boolean {
    return (
      this.nozzleNumber != null &&
      this.tankNumber != null &&
      this.closingBeginValue != null &&
      this.closingEndValue != null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): NFeFuelClosing {
    return json ? SgvJson.from.simple(json, NFeFuelClosing) : null;
  }
}
