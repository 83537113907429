import { SgvJson } from "@eceos/arch";
import { VariationTypeSummary } from "./variation-type-summary";

export class VariationTypeValue {
    constructor(
        public variationType: VariationTypeSummary = null,
        public value: any = null
    ) { }

    toJson(): any {
        throw Error('Não é possível criar ou atualizar VariationTypeValue');
    }

    static fromJson(json: any): VariationTypeValue {
        return json ? SgvJson.from.simple(json, VariationTypeValue, {
            variationType: json.variationType ? VariationTypeSummary.fromJson(json.variationType) : null
        }) : null;
    }

}