import { SgvJson } from '@eceos/arch';
import { Fine } from './fine';
export class FixedValueFine extends Fine {

  constructor() {
    super(0.0, "fixed");
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(data: any): FixedValueFine {
    return SgvJson.from.simple(data, FixedValueFine);
  }
}
