import { JsonEntity, SgvJson } from '@eceos/arch';
import { EnterpriseWalletStatus } from '../../enterprises';

export class EnterprisesCountByWalletStatusSummary implements JsonEntity {
  constructor(public status: EnterpriseWalletStatus = null, public enterprises: number = 0) {}

  toJson(): any {
    return SgvJson.to.simple(this, { status: this.status.toJson() });
  }

  static fromJson(json: any): EnterprisesCountByWalletStatusSummary {
    return SgvJson.from.simple(json, EnterprisesCountByWalletStatusSummary, {
      status: EnterpriseWalletStatus.fromJson(json?.status)
    });
  }
}
