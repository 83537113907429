import { JsonEntity, SgvJson, SgvId } from '@eceos/arch';

export class IcmsDesonerationReason implements JsonEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    readonly value: string = '',
    readonly description: string = ''
  ) {}

  get valueWithDescription(): string {
    return `${this.value} - ${this.description}`;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsDesonerationReason {
    return SgvJson.from.simple(json, IcmsDesonerationReason);
  }
}
