import { Injectable } from '@angular/core';
import { ApiPath, ApiService, InterceptorConfig } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ibpt } from './ibpt';

@Injectable({ providedIn: 'root' })
export class IbptFiscalSettingRepository {
  private api: ApiPath;

  constructor(api: ApiService) {
    this.api = api.root.path('fiscal/settings/nfes/ibpt');
  }

  update(entity: Ibpt): Observable<string> {
    return this.api.patchJson(entity.toJson(), { metadata: { autoCatch: InterceptorConfig.AUTO } })
  }

  getActual(): Observable<Ibpt> {
    return this.api.getJson().pipe(map((r) => Ibpt.fromJson(r)));
  }
}
