import { SgvJson } from "@eceos/arch";

export interface IcmsMonoRetWithValueHolder {
  icms: IcmsMonoRetWithValue;
}

export class IcmsMonoRetWithValue {
  constructor(
    public bcValue: number = null,
    public aliquot: number = null,
    public icmsValue: number = null
  ) {
  }

  calculateIcmsValue(): number {
    return this.bcValue * this.aliquot;
  }

  isValid(): boolean {
    return this.bcValue != null && this.aliquot != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      bcValue: this.bcValue ? this.bcValue : null,
      icmsValue: this.icmsValue ? this.icmsValue : null,
      aliquot: this.aliquot ? this.aliquot : null
    });
  }

  static fromJson(json: any): IcmsMonoRetWithValue {
    return json
      ? SgvJson.from.simple(json, IcmsMonoRetWithValue, {
        aliquot: json.aliquot,
        bcValue: json.bcValue,
        icmsValue: json.icmsValue
      })
      : null;
  }
}
