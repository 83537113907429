import { SgvJson } from '@eceos/arch';

export abstract class SpecialTaxationRegime {
  abstract get description(): string;

  abstract toJson(): any;

  static fromJson(json: any): SpecialTaxationRegime {
    if (!json) return;
    switch (json.type) {
      case 'curitibaSpecialTaxation':
        return CuritibaSpecialTaxationRegime.fromJson(json);
      case 'elotechSpecialTaxation':
        return ElotechSpecialTaxationRegime.fromJson(json);
      case 'govbrSpecialTaxation':
        return GovbrSpecialTaxationRegime.fromJson(json);
      case 'govbr203SpecialTaxation':
        return Govbr203SpecialTaxationRegime.fromJson(json);
    }
  }
}

export class CuritibaSpecialTaxationRegime extends SpecialTaxationRegime {
  constructor(readonly value: string = '', readonly description: string = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'curitibaSpecialTaxation' });
  }

  static fromJson(json: any): CuritibaSpecialTaxationRegime {
    return SgvJson.from.simple(json, CuritibaSpecialTaxationRegime);
  }
}

export class ElotechSpecialTaxationRegime extends SpecialTaxationRegime {
  constructor(readonly value: string = '', readonly description: string = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'elotechSpecialTaxation' });
  }

  static fromJson(json: any): ElotechSpecialTaxationRegime {
    return SgvJson.from.simple(json, ElotechSpecialTaxationRegime);
  }
}

export class GovbrSpecialTaxationRegime extends SpecialTaxationRegime {
  constructor(readonly value: string = '', readonly description: string = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'govbrSpecialTaxation' });
  }

  static fromJson(json: any): GovbrSpecialTaxationRegime {
    return SgvJson.from.simple(json, GovbrSpecialTaxationRegime);
  }
}

export class Govbr203SpecialTaxationRegime extends SpecialTaxationRegime {
  constructor(readonly value: string = '', readonly description: string = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'govbr203SpecialTaxation' });
  }

  static fromJson(json: any): Govbr203SpecialTaxationRegime {
    return SgvJson.from.simple(json, Govbr203SpecialTaxationRegime);
  }
}
