import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { Deliverer, DelivererSummary } from './deliverer';

@Injectable({ providedIn: 'root' })
export class DeliverersRepository extends RepositoryImpl<DelivererSummary, Deliverer> {
  constructor(api: ApiService) {
    super(api.root.path('/deliverers'), DelivererSummary, Deliverer);
  }
}
