export class UserRoleStatus {
  static PENDING = new UserRoleStatus('PENDING', 'Pendente');
  static ACTIVE = new UserRoleStatus('ACTIVE', 'Ativo');
  static INACTIVE = new UserRoleStatus('INACTIVE', 'Inativo');

  private constructor(readonly name: string = '', public label: string = '') {}

  static get(name: string): UserRoleStatus {
    const status = UserRoleStatus.values().find(v => v.name === name);
    if (!status) {
      const err = new Error(`${name} UserRoleStatus is not mapped!`);
      console.error(err);
      throw err;
    }
    return status;
  }

  static getOrNull(name: string): UserRoleStatus {
    return UserRoleStatus.values().find(v => v.name === name);
  }

  static values(): UserRoleStatus[] {
    return [UserRoleStatus.PENDING, UserRoleStatus.ACTIVE, UserRoleStatus.INACTIVE];
  }
}
