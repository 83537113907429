import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { FormulaExecutor } from '../../../../core/formula-executor/formula-executor';
import { BcVariable } from '../../../tax/bc-variable';
import { CalculatedValue } from '../../calculated-value';
import { NFeItem } from '../../nfe-item';
import { NFeFiscalTax } from '../nfe-item-tax';
import { BcModalityST } from './bc-modalities-st/bc-modality-st';
import { IcmsWithBcValue, IcmsWithBcValueHolder } from './icms-with-bc-value';

export interface IcmsStWithBcValueHolder {
  icmsSt: IcmsStWithBcValue;
}

export class IcmsStWithBcValue {
  constructor(
    public bcModality: BcModalityST = null,
    public marginAddedStPercent: number = null,
    public bcFormula: string = null,
    public bcValue: CalculatedValue = new CalculatedValue(),
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue(),
    public bcReductionPercent: number = null
  ) {}

  calcValue(nfeItem: NFeItem): number {
    const icms =
      nfeItem.tax &&
      nfeItem.tax.taxes &&
      IcmsWithBcValue.isIcmsWithBcValueHolder(nfeItem.tax.taxes.icms)
        ? (nfeItem.tax.taxes.icms as IcmsWithBcValueHolder).icms
        : null;
    return this.bcModality.apply(
      this.bcValue.value,
      this.aliquot,
      icms && icms.value ? icms.value.value : 0
    );
  }

  calcBcValue(nfeItem: NFeItem): number {
    return this.bcFormula
      ? new FormulaExecutor(
          this.bcFormula,
          NFeFiscalTax.buildBcVariables(nfeItem)
            .set(
              BcVariable.BC_REDUCTION_ST_PERCENT,
              this.bcReductionPercent ? this.bcReductionPercent / 100 : 0
            )
            .set(
              BcVariable.MVA_PERCENT,
              this.marginAddedStPercent ? this.marginAddedStPercent / 100 : 0
            )
        ).execute()
      : null;
  }

  isValid(): boolean {
    return (
      this.bcModality != null && EceosValidatorsUtils.isValid(this.bcValue) && this.aliquot != null
    );
  }

  publishValues(nfeItem: NFeItem): IcmsStWithBcValue {
    this.bcValue.value = this.calcBcValue(nfeItem);
    this.value.value = this.calcValue(nfeItem);
    return this;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      bcModality: this.bcModality ? this.bcModality.toJson() : null,
      bcValue: this.bcValue ? this.bcValue.toJson() : null,
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, IcmsStWithBcValue, {
          bcModality: BcModalityST.fromJson(json.bcModality),
          bcValue: CalculatedValue.fromJson(json.bcValue),
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }

  static isIcmsStWithBcValueHolder(object: any): object is IcmsStWithBcValueHolder {
    return 'icmsSt' in object;
  }
}
