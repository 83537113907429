import { SgvJson } from '@eceos/arch';
export class NFeFuelCide {
  constructor(
    public bcQuantity: number = 0,
    public aliquotValue: number = 0,
    public value: number = 0
  ) {}

  isValid(): boolean {
    return this.bcQuantity != null && this.aliquotValue != null && this.value != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): NFeFuelCide {
    return json ? SgvJson.from.simple(json, NFeFuelCide) : null;
  }
}
