import { Injectable } from '@angular/core';
import {
  ApiPath,
  ApiService,
  InterceptorConfig,
  PageData,
  RequestMetadata,
  RequestMetadataUtils,
  UrlSearchParamsBuilder
} from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInvite } from './user-invite';
import {
  AcceptUserInviteOperation,
  RefuseUserInviteOperation,
  UserInviteOperation
} from './user-invite-operation';

@Injectable({ providedIn: 'root' })
export class UserInviteRepository {
  private api: ApiPath;

  private metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(api: ApiService) {
    this.api = api.manager.path('/users/invite');
  }

  accept(userInvite: UserInvite): Observable<UserInvite> {
    return this.sendOperation(userInvite, new AcceptUserInviteOperation());
  }

  refuse(userInvite: UserInvite): Observable<UserInvite> {
    return this.sendOperation(userInvite, new RefuseUserInviteOperation());
  }

  sendOperation(
    userInvite: UserInvite,
    userInviteOperation: UserInviteOperation
  ): Observable<UserInvite> {
    return this.api
      .path(userInvite.id)
      .postJson(userInviteOperation.toJson())
      .pipe(map(json => UserInvite.fromJson(json)));
  }

  listPendings(
    pageData?: PageData,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<UserInvite[]> {
    return this.api
      .path('pendings')
      .getJson<any[]>({
        params: new UrlSearchParamsBuilder().page(pageData).build(),
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(r => r.map((j: any) => UserInvite.fromJson(j))));
  }

  countPendings(
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<number> {
    return this.api
      .path('pendings')
      .path('size')
      .getText({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(
        map(r => r || '0'),
        map(text => Number.parseInt(text, 10))
      );
  }
}
