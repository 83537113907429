import { SgvJson } from '@eceos/arch';
import { BaseNFe } from '../../nfes/nfe';
import { NFeItem } from '../../nfes/nfe-item';
import { IcmsStPassedOn } from './../../nfes/tax/icms/icms-st-passed-on';
import { COFINSConfig } from './cofins/cofins-config';
import { IcmsUfReceiverConfig } from './icms-uf-receiver/icms-uf-receiver-config';
import { IcmsConfig } from './icms/icms-config';
import { IIConfig } from './ii/ii-config';
import { IPIConfig } from './ipi/ipi-config';
import { PISConfig } from './pis/pis-config';

export class FiscalTaxConfig {
  constructor(
    public icms: IcmsConfig = null,
    public pis: PISConfig = null,
    public cofins: COFINSConfig = null,
    public ipi: IPIConfig = null,
    public ii: IIConfig = null,
    public icmsUfReceiver: IcmsUfReceiverConfig = null
  ) {}

  update(nfe: BaseNFe, nfeItem: NFeItem, icmsStPassedOn: IcmsStPassedOn) {
    if (nfeItem && nfeItem.tax && nfeItem.tax.taxes) {
      nfeItem.tax.taxes.icms = this.icms
        ? this.icms.toICMS(nfeItem, icmsStPassedOn, nfe.endConsumer)
        : null;
      nfeItem.tax.taxes.pis = this.pis ? this.pis.toPIS(nfeItem) : null;
      nfeItem.tax.taxes.cofins = this.cofins ? this.cofins.toCOFINS(nfeItem) : null;
      nfeItem.tax.taxes.ipi = this.ipi ? this.ipi.toIPI(nfeItem) : null;
      nfeItem.tax.taxes.ii = this.ii ? this.ii.toII() : null;
      nfeItem.tax.taxes.icmsUfReceiver = this.icmsUfReceiver
        ? this.icmsUfReceiver.toIcmsUfReceiver(nfeItem)
        : null;
    }
  }

  toJson() {
    return SgvJson.to.simple(this, {
      icms: SgvJson.to.optional(this.icms),
      pis: SgvJson.to.optional(this.pis),
      cofins: SgvJson.to.optional(this.cofins),
      ipi: SgvJson.to.optional(this.ipi),
      ii: SgvJson.to.optional(this.ii),
      icmsUfReceiver: SgvJson.to.optional(this.icmsUfReceiver)
    });
  }

  static fromJson(json: any): FiscalTaxConfig {
    return json
      ? SgvJson.from.simple(json, FiscalTaxConfig, {
          icms: IcmsConfig.fromJson(json.icms),
          pis: PISConfig.fromJson(json.pis),
          cofins: COFINSConfig.fromJson(json.cofins),
          ipi: IPIConfig.fromJson(json.ipi),
          ii: IIConfig.fromJson(json.ii),
          icmsUfReceiver: IcmsUfReceiverConfig.fromJson(json.icmsUfReceiver)
        })
      : null;
  }
}
