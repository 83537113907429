import { SgvId, SgvJson } from '@eceos/arch';
import { NFeItem } from '../../../nfes/nfe-item';
import {
  AliquotCOFINS,
  COFINS,
  NonTaxableCOFINS,
  OtherCOFINS,
  QuantityCOFINS
} from '../../../nfes/tax/cofins/cofins';
import { CofinsCst } from '../../../nfes/tax/cofins/csts/cofins-cst';
import {
  CofinsWithAliquot,
  CofinsWithAliquotPercent,
  CofinsWithAliquotValue
} from './cofins-with-aliquot';

export abstract class COFINSConfig {
  constructor(readonly id: string = SgvId.gen(), public cst: CofinsCst = null) {}

  abstract toCOFINS(nfeItem: NFeItem): COFINS;

  abstract toJson(): any;

  static fromJson(json: any): COFINSConfig {
    if (json && json.type) {
      switch (json.type) {
        case 'AliquotCofinsConfig':
          return AliquotCOFINSConfig.fromJson(json);
        case 'NonTaxableCofinsConfig':
          return NonTaxableCOFINSConfig.fromJson(json);
        case 'OtherCofinsConfig':
          return OtherCOFINSConfig.fromJson(json);
        case 'QuantityCofinsConfig':
          return QuantityCOFINSConfig.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class AliquotCOFINSConfig extends COFINSConfig {
  constructor(
    id = SgvId.gen(),
    cst: CofinsCst = null,
    public aliquot: CofinsWithAliquotPercent = null
  ) {
    super(id, cst);
  }

  toCOFINS(nfeItem: NFeItem): AliquotCOFINS {
    return new AliquotCOFINS(
      SgvId.gen(),
      this.cst,
      this.aliquot ? this.aliquot.toBcAliquot(nfeItem) : null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'AliquotCofinsConfig',
      cst: this.cst ? this.cst.toJson() : null,
      aliquot: this.aliquot ? this.aliquot.toJson() : null
    });
  }

  static fromJson(json: any): AliquotCOFINSConfig {
    return json
      ? SgvJson.from.simple(json, AliquotCOFINSConfig, {
          cst: CofinsCst.fromJson(json.cst),
          aliquot: CofinsWithAliquotPercent.fromJson(json.aliquot)
        })
      : null;
  }
}

export class NonTaxableCOFINSConfig extends COFINSConfig {
  constructor(id = SgvId.gen(), cst: CofinsCst = null) {
    super(id, cst);
  }

  toCOFINS(): NonTaxableCOFINS {
    return new NonTaxableCOFINS(SgvId.gen(), this.cst);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'NonTaxableCofinsConfig',
      cst: this.cst ? this.cst.toJson() : null
    });
  }

  static fromJson(json: any): NonTaxableCOFINSConfig {
    return json
      ? SgvJson.from.simple(json, NonTaxableCOFINSConfig, { cst: CofinsCst.fromJson(json.cst) })
      : null;
  }
}

export class OtherCOFINSConfig extends COFINSConfig {
  constructor(id = SgvId.gen(), cst: CofinsCst = null, public aliquot: CofinsWithAliquot = null) {
    super(id, cst);
  }

  toCOFINS(nfeItem: NFeItem): OtherCOFINS {
    return new OtherCOFINS(SgvId.gen(), this.cst, this.aliquot ? this.aliquot.toBcAliquot(nfeItem) : null);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'OtherCofinsConfig',
      cst: this.cst ? this.cst.toJson() : null,
      aliquot: this.aliquot ? this.aliquot.toJson() : null
    });
  }

  static fromJson(json: any): OtherCOFINSConfig {
    return json
      ? SgvJson.from.simple(json, OtherCOFINSConfig, {
          cst: CofinsCst.fromJson(json.cst),
          aliquot: CofinsWithAliquot.fromJson(json.aliquot)
        })
      : null;
  }
}

export class QuantityCOFINSConfig extends COFINSConfig {
  constructor(
    id = SgvId.gen(),
    cst: CofinsCst = null,
    public aliquot: CofinsWithAliquotValue = null
  ) {
    super(id, cst);
  }

  toCOFINS(): QuantityCOFINS {
    return new QuantityCOFINS(
      SgvId.gen(),
      this.cst,
      this.aliquot ? this.aliquot.toAliquot() : null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'QuantityCofinsConfig',
      cst: this.cst ? this.cst.toJson() : null,
      aliquot: this.aliquot ? this.aliquot.toJson() : null
    });
  }

  static fromJson(json: any): QuantityCOFINSConfig {
    return json
      ? SgvJson.from.simple(json, QuantityCOFINSConfig, {
          cst: CofinsCst.fromJson(json.cst),
          aliquot: CofinsWithAliquotValue.fromJson(json.aliquot)
        })
      : null;
  }
}
