import { Injectable } from "@angular/core";
import { TemporalRepositoryImpl, ApiService } from "@eceos/arch";
import { StockAdjustment } from ".";

@Injectable({ providedIn: 'root' })
export class StockAdjustmentsRepository extends TemporalRepositoryImpl<StockAdjustment, StockAdjustment> {

  constructor(api: ApiService) {
    super(api.root.path('products').path('stock').path('adjustment'), StockAdjustment, StockAdjustment);
  }

}