<header mat-dialog-title>
  <div class="sideActions">
    <button class="backAction" mat-icon-button tabindex="-1" (click)="onBackClick()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <div class="title">
    <ng-container *ngIf="inInsertState">Inserção</ng-container>
    <ng-container *ngIf="inEditState">Edição</ng-container>
    <ng-container *ngIf="inViewState">Visualização</ng-container>
    <ng-container *ngIf="title || shortTitle">
      de {{ mobile && shortTitle ? shortTitle : title }}</ng-container
    >
  </div>
  <div class="sideActions">
    <mat-menu #appMenu="matMenu">
      <ng-container *ngFor="let action of context.aditionalActions.secondary">
        <button
          *ngIf="action.rendered == null || action.rendered"
          mat-menu-item
          [asyncAction]="action.onAction"
          [disabled]="action.disabled != null && action.disabled"
        >
          {{ action.name }}
        </button>
      </ng-container>
      <button mat-menu-item *ngIf="!onlyFullScreen" (click)="toggleMaximize()">
        {{ maximized ? 'Restaurar' : 'Maximizar' }}
      </button>
      <button #deleteBtn mat-menu-item *ngIf="showDelete" (click)="onDeleteClick()">Excluir</button>
      <button mat-menu-item>Ajuda</button>
    </mat-menu>
    <button mat-icon-button tabindex="-1" [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</header>
<mat-dialog-content>
  <ng-container *ngTemplateOutlet="content?.templateRef; context: context"></ng-container>
</mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngIf="!entityActive">
    <button #activateBtn mat-button [asyncAction]="onActivateAction">
      Ativar
    </button>
  </ng-container>
  <ng-container *ngIf="entityActive">
    <ng-container *ngFor="let action of context.aditionalActions.primary">
      <button
        *ngIf="action.rendered == null || action.rendered"
        mat-button
        [asyncAction]="action.onAction"
        [disabled]="action.disabled != null && action.disabled"
      >
        {{ action.name }}
      </button>
    </ng-container>
    <button #saveBtn mat-button *ngIf="showSave" [asyncAction]="onSaveAction" [disabled]="!valid">
      Salvar
    </button>
    <mat-menu #saveMenu="matMenu">
      <button mat-menu-item [asyncAction]="onSaveAndContinueAction" [disabled]="!valid">
        Salvar e continuar
      </button>
      <button
        mat-menu-item
        [asyncAction]="onSaveAndNewAction"
        [disabled]="!valid"
        *ngIf="showSaveAndNew"
      >
        Salvar e novo
      </button>
      <button mat-menu-item [asyncAction]="onSaveAndCloseAction" [disabled]="!valid">
        Salvar e sair
      </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="saveMenu" *ngIf="showSave" [disabled]="!valid">
      <mat-icon>more_vert</mat-icon>
    </button>
    <button #editBtn mat-button *ngIf="showEdit" (click)="onEditClick()">
      Editar
    </button>
  </ng-container>
</mat-dialog-actions>
