import { JsonEntity, SgvJson } from '@eceos/arch';
import { CarrierSummary } from '../../../carriers';

export abstract class NFCeDelivery implements JsonEntity {
  abstract toJson(): any;

  static fromJson(json: any): NFCeDelivery {
    if (json && json.type) {
      switch (json.type) {
        case 'presencialDelivery':
          return NFCePresencialDelivery.fromJson(json);
        case 'homeDeliveryBySelf':
          return NFCeHomeDeliveryBySelf.fromJson(json);
        case 'homeDeliveryByCarrier':
          return NFCeHomeDeliveryByCarrier.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class NFCePresencialDelivery extends NFCeDelivery {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'presencialDelivery'
    });
  }

  static fromJson(json: any): NFCePresencialDelivery {
    return SgvJson.from.simple(json, NFCePresencialDelivery);
  }
}

export class NFCeHomeDeliveryBySelf extends NFCeDelivery {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'homeDeliveryBySelf'
    });
  }

  static fromJson(json: any): NFCeHomeDeliveryBySelf {
    return SgvJson.from.simple(json, NFCeHomeDeliveryBySelf);
  }
}

export class NFCeHomeDeliveryByCarrier extends NFCeDelivery {
  constructor(public carrier: CarrierSummary = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'homeDeliveryByCarrier',
      carrier: this.carrier ? this.carrier.toJson() : null
    });
  }

  static fromJson(json: any): NFCeHomeDeliveryByCarrier {
    return SgvJson.from.simple(json, NFCeHomeDeliveryByCarrier, {
      carrier: CarrierSummary.fromJson(json.carrier)
    });
  }
}
