import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password.component';
import { NewPasswordConfirmationComponent } from './new-password-confirmation/new-password-confirmation.component';

export const routes: Routes = [
  {
    path: 'password-recovery',
    component: ForgotPasswordComponent,
    data: { title: 'e-Ceos - Recuperação de senha' }
  },
  {
    path: 'new-password/:token',
    component: NewPasswordConfirmationComponent,
    data: { title: 'e-Ceos - Nova senha' },
  }
];

export const ForgotPasswordRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
