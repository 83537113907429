import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { IcmsDesonerationReason } from './icms-desoneration-reason';

@Injectable({ providedIn: 'root' })
export class IcmsDesonerationReasonRepository
  extends ReadonlyRepositoryImpl<IcmsDesonerationReason, IcmsDesonerationReason>
  implements ReadonlyRepository<IcmsDesonerationReason, IcmsDesonerationReason> {
  constructor(api: ApiService) {
    super(
      api.root.path('fiscal/tax/icms/desonerationReasons'),
      IcmsDesonerationReason,
      IcmsDesonerationReason
    );
  }
}
