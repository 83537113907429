import { EceosValidators } from '@eceos/common-utils';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PageData, SgvId } from '@eceos/arch';
import {
  BrazilianAddress,
  CitiesRepository,
  StreetTypesRepository,
  ZipCode,
  ZipCodesRepository
} from '@eceos/domain';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

interface FormValue {
  zipCodeNumber: string;
  city: any;
  street: string;
  district: string;
  number: string;
  details: string;
}

@Component({
  selector: 'app-brazilian-address-form',
  templateUrl: './brazilian-address-form.component.html',
  styleUrls: ['./brazilian-address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrazilianAddressFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: BrazilianAddressFormComponent,
      multi: true
    }
  ]
})
export class BrazilianAddressFormComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('numberInput') numberInput: ElementRef;

  private _required = false;

  private _value: BrazilianAddress = null;

  private registerOnChangeSubscription: Subscription = null;

  valueChange = new EventEmitter<BrazilianAddress>();

  form: FormGroup;

  zipCodeQuery = new BehaviorSubject('');

  zipCodes$: Observable<ZipCode[]>;

  zipCodePage = new PageData(0, 10);

  lastSelected: ZipCode = null;

  constructor(
    private fb: FormBuilder,
    public zipcodeRepository: ZipCodesRepository,
    public citiesRepository: CitiesRepository,
    public streetTypesRepository: StreetTypesRepository
  ) {
    this.zipCodes$ = this.zipCodeQuery.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(q => (q.length > 3 ? zipcodeRepository.page(this.zipCodePage, q) : of([])))
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  get value() {
    return this._value;
  }

  @Input()
  set value(value: BrazilianAddress) {
    if (this._value !== value) {
      this._value = value;
      this.publishValuetoForm();
    }
  }

  get required(): boolean {
    return this._required;
  }

  @Input()
  set required(required: boolean) {
    if (this._required !== required) {
      this._required = required;
      this.buildForm();
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.form && this.form.valid ? null : { invalid: true };
  }

  writeValue(obj: BrazilianAddress): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    console.log('[BrazilianAddressForm] OnTouch event não suportado');
  }

  zipCodeSelect(event: MatAutocompleteSelectedEvent) {
    const selected = event.option.value;
    if (selected) {
      this.form.patchValue({
        zipCodeNumber: selected.zipCode,
        city: selected.city,
        district: selected.district,
        street: selected.street
      });
      this.lastSelected = selected;
      this.numberInput.nativeElement.focus();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled && this.form.enabled) {
      this.form.disable();
    } else if (!isDisabled && this.form.disabled) {
      this.form.enable();
    }
  }

  zipCodeKeyUp(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    this.zipCodeQuery.next(input.value);
  }

  zipCodeBlur(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    if (
      input.value.length === 8 &&
      (!this.lastSelected || input.value !== this.lastSelected.zipCode)
    ) {
      this.zipcodeRepository.list(input.value).subscribe(result => {
        if (result.length > 0) {
          const selected = result[0];
          this.form.patchValue({
            zipCodeNumber: selected.zipCode,
            city: selected.city,
            district: selected.district,
            street: selected.street
          });
          this.lastSelected = selected;
        }
      });
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      zipCodeNumber: ['', EceosValidators.requiredIf(() => this.required)],
      city: [null, EceosValidators.requiredIf(() => this.required)],
      street: ['', EceosValidators.requiredIf(() => this.required)],
      district: ['', EceosValidators.requiredIf(() => this.required)],
      number: ['', EceosValidators.requiredIf(() => this.required)],
      details: ['']
    });

    this.form.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(v => v as FormValue),
        map(
          v =>
            new BrazilianAddress(
              new ZipCode(SgvId.gen(), v.zipCodeNumber, v.street, '', v.district, v.city),
              v.number,
              v.details
            )
        )
      )
      .subscribe(addr => this.valueChange.emit((this._value = addr)));
  }

  private publishValuetoForm() {
    if (this.value == null) {
      this.form.reset();
    } else {
      if (!this.value.zipCode) {
        this.value.zipCode = new ZipCode();
      }
      this.form.setValue({
        zipCodeNumber: this.value.zipCode.zipCode,
        city: this.value.zipCode.city,
        street: this.value.zipCode.street,
        district: this.value.zipCode.district,
        number: this.value.number,
        details: this.value.details
      });
    }
    this.zipCodeQuery.next('');
  }
}
