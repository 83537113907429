import * as _moment from 'moment';
import { Moment } from 'moment';

export class YearMonth {
  constructor(public readonly year: number, public readonly month: number) {}

  toDefaultString(): string {
    const yearStr = `${this.year}`;
    const monthStr = `${this.month}`;

    return `${yearStr}-${monthStr.length === 1 ? monthStr.padStart(2, '0') : monthStr}`;
  }

  toStringPretty(): string {
    return this.toMoment().format('MMM/YYYY');
  }

  toMoment() {
    return _moment()
      .date(1)
      .month(this.month - 1)
      .year(this.year);
  }

  static from(string: string): YearMonth {
    if (!string || string.length < 7) {
      return null;
    }

    const year = +string.substring(0, 4);
    const month = +string.substring(5);

    return isNaN(year) || isNaN(month) ? null : new YearMonth(year, month);
  }

  static fromMoment(moment: Moment): YearMonth {
    return moment ? new YearMonth(moment.year(), moment.month() + 1) : null;
  }
}
