import { SgvId, SgvJson, DomainEntity } from '@eceos/arch';
import { SalesOrderActionEvent } from './sales-order-action-event';

export abstract class SalesOrderAction implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public event: SalesOrderActionEvent = null,
    public isActive = true
  ) {}

  abstract toJson(): any;

  static fromJson(json: any): SalesOrderAction {
    if (json && json.type) {
      switch (json.type) {
        case 'finalizeAndGenerateSaleAction':
          return SalesOrderFinalizeAndGenerateSaleAction.fromJson(json);
        default:
          return null;
      }
    }

    return null;
  }
}

export class SalesOrderFinalizeAndGenerateSaleAction extends SalesOrderAction {
  constructor(id = SgvId.gen(), event: SalesOrderActionEvent = null, isActive = true) {
    super(id, event, isActive);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'finalizeAndGenerateSaleAction' });
  }

  static fromJson(json: any): SalesOrderFinalizeAndGenerateSaleAction {
    return json ? SgvJson.from.simple(json, SalesOrderFinalizeAndGenerateSaleAction) : null;
  }
}
