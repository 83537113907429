import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { IpiCst } from './ipi-cst';

@Injectable({ providedIn: 'root' })
export class IpiCstsRepository extends ReadonlyRepositoryImpl<IpiCst, IpiCst>
  implements ReadonlyRepository<IpiCst, IpiCst> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/ipi/csts'), IpiCst, IpiCst);
  }
}
