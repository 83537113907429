import { Injectable } from '@angular/core';
import { ApiService, Repository, RepositoryImpl } from '@eceos/arch';
import { FiscalProductOperationMessage } from './fiscal-product-operation-message';
@Injectable({ providedIn: 'root' })
export class FiscalProductOperationMessagesRepository extends RepositoryImpl<FiscalProductOperationMessage, FiscalProductOperationMessage>
  implements Repository<FiscalProductOperationMessage, FiscalProductOperationMessage> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/products/operations/messages'), FiscalProductOperationMessage, FiscalProductOperationMessage);
  }
}
