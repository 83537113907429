import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { EceosValidators } from '@eceos/common-utils';
import { CashPaymentForm, FinancialDocument } from '@eceos/domain';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-cash-payment-form',
  templateUrl: './cash-payment-form.component.html',
  styleUrls: ['./cash-payment-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CashPaymentFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CashPaymentFormComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashPaymentFormComponent implements OnInit, ControlValueAccessor, Validator {
  private _entity: CashPaymentForm;

  private _totalToPay = 0;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  @Output() entityChange = new EventEmitter<CashPaymentForm>();

  @Input() financialDocuments: FinancialDocument[];

  @Input() paybackFinancialDocuments: FinancialDocument[];

  form: FormGroup;

  disabled = false;

  constructor(private fb: FormBuilder, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  get entity(): CashPaymentForm {
    return this._entity;
  }

  @Input()
  set entity(entity: CashPaymentForm) {
    if (this.entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get totalToPay(): number {
    return this._totalToPay;
  }

  @Input()
  set totalToPay(totalToPay: number) {
    if (this._totalToPay !== totalToPay) {
      this._totalToPay = totalToPay;
    }
  }

  get totalPaid(): number {
    return this.entity.getTotalPaid();
  }

  get pendingPaid(): number {
    return this.entity.getPendingPaid(this.totalToPay);
  }

  get hasPendingPaid(): boolean {
    return this.entity.hasPendingPaid(this.totalToPay);
  }

  get totalChange(): number {
    return this.entity.getTotalChange();
  }

  get pendingChange(): number {
    return this.totalChangeToPay - this.totalChange;
  }

  get hasPendingChange(): boolean {
    return this.pendingChange > 0;
  }

  get totalChangeToPay(): number {
    return this.totalPaid - this.totalToPay;
  }

  get hasTotalChangeToPay(): boolean {
    return this.totalChangeToPay > 0;
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.entity && !this.entity.isFullyPaid(this.totalToPay) ? { invalid: true } : null;
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.form) {
      this.publishDisable();
    }
  }

  clearChanges() {
    if (!this.disabled) {
      this.form.get('change').setValue([]);
    }
  }

  private createFormFromEntity() {
    this.form = null;
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
    if (this.entity) {
      this.form = this.fb.group({
        paid: [this.entity.paid, Validators.required],
        change: [
          this.entity.change,
          EceosValidators.requiredIf(() => this.entity.hasPendingChange(this.totalToPay))
        ]
      });
      this.addFormValueChange(v => new CashPaymentForm(v.paid, v.change));
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (f: any) => CashPaymentForm) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this.entityChange.emit((this._entity = value));
        this.changeDetector.markForCheck();
      });
  }

  private publishDisable() {
    if (this.disabled && this.form.enabled) {
      this.form.disable();
    } else if (!this.disabled && this.form.disabled) {
      this.form.enable();
    }
  }
}
