import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { PriceTable } from './price-table';

@Injectable({providedIn:'root'})
export class PriceTablesRepository extends RepositoryImpl<PriceTable, PriceTable> {

  constructor(api: ApiService) {
    super(api.root.path('priceTables'), PriceTable, PriceTable);
  }
}
