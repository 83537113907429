import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fine'
})
export class FinePipe implements PipeTransform {

  transform(type: { name: string }): string {
    switch (type.name) {
      case 'fixed':
        return 'Valor fixo';
      case 'percentage':
        return 'Porcentagem';
      default:
        return 'Não informado';
    }
  }
}
