export class SortOrder {
  constructor(public readonly field: string, public readonly sortMode: SortMode) {}

  get sortModeStr(): string {
    return this.sortMode === 0 ? 'ASC' : 'DSC';
  }
}

export enum SortMode {
  ASC,
  DSC
}
