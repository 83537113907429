import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class Dimensions implements DomainEntity{
    constructor(
        readonly id = SgvId.gen(),
        public height: number = 0,
        public width: number = 0,
        public depth: number = 0,
        public weight: number = 0
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): Dimensions {
        return json ? SgvJson.from.simple(json, Dimensions) : null;
    }
}