<div
  *ngIf="searchVisible || (isSearchOpened$ | async); else searchButton"
  [@searchAnimation]
  [class.search-box-default-open-on-desktop]="isSearchOpened$ | async"
  class="search-box-open"
>
  <div class="search">
    <mat-icon *ngIf="isSearchOpened$ | async" class="search-icon-defaultOpen">{{
      searchIcon
    }}</mat-icon>
    <input
      #searchInput
      [class.search-input-default-open-on-desktop]="!(isSearchOpened$ | async)"
      autofocus
      class="search-input"
      [placeholder]="placeholder"
      (keyup)="onSearchValueChange()"
    />
  </div>

  <button
    mat-icon-button
    class="icon"
    *ngIf="(!(isMobile$ | async) && !defaultOpenOnDesktop) || (isMobile$ | async)"
    (click)="closeSearch()"
  >
    <mat-icon>{{ backIcon }}</mat-icon>
  </button>
</div>

<ng-template #searchButton>
  <div [@searchAnimation] class="search-box-closed">
    <button mat-icon-button class="icon" (click)="openSearch()">
      <mat-icon>{{ searchIcon }}</mat-icon>
    </button>
  </div>
</ng-template>
