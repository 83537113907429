import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthGuard, AuthManagerService, AuthService } from '@eceos/arch';

const DEFAULT_MANAGER_ROUTE = '/manager';

@Injectable({
  providedIn: 'root'
})
export class EceosGuard implements CanActivate, CanActivateChild {
  private managerPath: string;

  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private authManagerService: AuthManagerService
  ) {
    this.managerPath = DEFAULT_MANAGER_ROUTE;
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.authGuard.canActivate(next, state)) {
      if (this.authService.hasToken) {
        return true;
      } else if (this.authManagerService.hasToken) {
        return this.router.createUrlTree([this.managerPath]);
      }
    }
    return false;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.canActivate(next, state);
  }
}
