import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserConfirmationRepository, CurrentUserService } from '@eceos/manager-domain';
import { delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AuthManagerService } from '@eceos/arch';
enum ConfirmationState {
  PENDING,
  VALIDATING,
  VALID,
  INVALID
}
@Component({
  selector: 'app-mail-confirmation',
  templateUrl: './mail-confirmation.component.html',
  styleUrls: ['./mail-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailConfirmationComponent implements OnInit {
  state: ConfirmationState = ConfirmationState.PENDING;

  constructor(
    private service: UserConfirmationRepository,
    private authManagerService: AuthManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private currentUserService: CurrentUserService,
    private changeDetector: ChangeDetectorRef
  ) {}

  get isValidating() {
    return this.state === ConfirmationState.VALIDATING;
  }
  get isValid() {
    return this.state === ConfirmationState.VALID;
  }
  get isInvalid() {
    return this.state === ConfirmationState.INVALID;
  }
  ngOnInit() {
    this.route.params
      .pipe(
        map(p => p['token']),
        distinctUntilChanged(),
        tap(_ => (this.state = ConfirmationState.VALIDATING)),
        switchMap(token => this.service.confirmWithToken(token)),
        tap(_ => (this.state = ConfirmationState.VALID)),
        tap(r => this.authManagerService.publishLogin(r.token)),
        tap(_=> this.currentUserService.loadUser()),
        tap(_ => this.changeDetector.markForCheck()),
        delay(2000)
      )
      .subscribe({
        next: _ => this.router.navigateByUrl('/'),
        error: e => {
          console.error(e);
          this.state = ConfirmationState.INVALID;
          this.changeDetector.markForCheck();
        }
      });
  }
}
