import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class BaseNFeIE implements JsonEntity {
  abstract toJson(): any;

  static fromJson(json: any): BaseNFeIE {
    if (json && json.type) {
      switch (json.type) {
        case 'custom':
          return BaseNFeCustomIE.fromJson(json);
        case 'byReceiver':
          return BaseNFeIEByReceiver.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class BaseNFeCustomIE extends BaseNFeIE {
  constructor(public custom: string = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'custom'
    });
  }

  static fromJson(json: any): BaseNFeCustomIE {
    return SgvJson.from.simple(json, BaseNFeCustomIE);
  }
}

export class BaseNFeIEByReceiver extends BaseNFeIE {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'byReceiver'
    });
  }

  static fromJson(json: any): BaseNFeIEByReceiver {
    return SgvJson.from.simple(json, BaseNFeIEByReceiver);
  }
}
