import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserPasswordRecoveryRepository } from '@eceos/manager-domain';
import { UserPassword } from 'libs/manager-domain/src/lib/users/password/user-new-password';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs';
enum ConfirmationState {
  PENDING,
  VALIDATING,
  VALID,
  INVALID
}
@Component({
  selector: 'app-new-password-confirmation',
  templateUrl: './new-password-confirmation.component.html',
  styleUrls: ['./new-password-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewPasswordConfirmationComponent implements OnInit {
  state: ConfirmationState = ConfirmationState.PENDING;

  form: FormGroup
  private _entity: UserPassword;
  private token: string;

  constructor(private fb: FormBuilder, 
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private service: UserPasswordRecoveryRepository,
    private changeDetector: ChangeDetectorRef) { this.entity = new UserPassword() }

    get isValidating() {
      return this.state === ConfirmationState.VALIDATING;
    }
    get isValid() {
      return this.state === ConfirmationState.VALID;
    }
    get isInvalid() {
      return this.state === ConfirmationState.INVALID;
    }

  get passwordGroup(): FormGroup {
    return this.form.get('password') as FormGroup;
  }

  get entity(): UserPassword {
    return this._entity;
  }

  set entity(entity: UserPassword) {
    if (this._entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  public onPasswordChangeClick(){
    this.service.newPassword(this.token, this.entity).subscribe();
    this.showMessage('Sua senha foi redefinida com sucesso.');
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 2000)
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        map(p => p['token']),
        distinctUntilChanged(),
        tap(token => this.token = token),
        tap(_ => (this.state = ConfirmationState.VALIDATING)),
        switchMap(token => this.service.isValidToken(token)),
        tap(_ => (this.state = ConfirmationState.VALID)),
        tap(_ => this.changeDetector.markForCheck()),
      )
      .subscribe({
        error: e => {
          console.error(e);
          this.state = ConfirmationState.INVALID;
          this.changeDetector.markForCheck();
        }
      })
  }

  private createFormFromEntity() {
    this.form = null;
    if (this.entity) {
      this.form = this.fb.group(
        {
          password: [this.entity.password, [Validators.required, Validators.minLength(6)]],
        });
      this.addFormValueChange(
        (value) =>
          new UserPassword(
            this.entity.id,
            value.password
          )
      );
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (f: any) => UserPassword) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe((value) => {
        this._entity = value;
      });
  }

  private showMessage(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

}