import { Injectable } from '@angular/core';
import { RepositoryImpl, ApiService } from '@eceos/arch';
import { Carrier, CarrierSummary } from './carrier';

@Injectable({ providedIn: 'root' })
export class CarriersRepository extends RepositoryImpl<CarrierSummary, Carrier> {
  constructor(api: ApiService) {
    super(api.root.path('carriers'), CarrierSummary, Carrier);
  }
}
