import { JsonEntity, SgvJson } from "@eceos/arch";

export class FinancialDataMovementsCache implements JsonEntity {

  constructor(
    public movements: Map<string, number> = new Map()
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): FinancialDataMovementsCache {
    return SgvJson.from.simple(data, FinancialDataMovementsCache);
  }
}
