import { SgvId, SgvJson, DomainEntity } from '@eceos/arch';

export class Country implements DomainEntity {
  constructor(public id: string = SgvId.gen(), public name: string = '', public number: string = '') {}

  toJson() {
    return {
      name: this.id,
      description: this.name,
      code: this.number
    };
  }

  static fromJson(json: any): Country {
    return new Country(json.name, json.description, json.code);
  }
}
