import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CNP } from '@eceos/domain';

@Component({
  selector: 'app-cnps-form',
  templateUrl: './cnps-form.component.html',
  styleUrls: ['./cnps-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CnpsFormComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CnpsFormComponent implements OnInit, ControlValueAccessor {
  private _value: CNP[] = [];

  private onChangeListener: any = null;

  private onTouchedListener: any = null;

  disabled = false;

  ngOnInit() { }

  constructor(private chageDetector: ChangeDetectorRef) { }

  get value() {
    return this._value.filter(cnp => cnp.value && cnp.value !== '');
  }

  set value(value: CNP[]) {
    if (value) {
      this._value = value;
    } else {
      this._value = []
    }
 
  }

  get valueWithNew() {
    return this._value
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.assertEmptyValue();
    this.chageDetector.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeListener = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.assertEmptyValue();
    this.chageDetector.detectChanges();
  }

  onChange(value: string, cnp: CNP) {
    if (value) {
      cnp.value = value;
    }
    
    if (this.allCnpsIsValid()) {
      this._value.push(new CNP(''));
      this.removeDuplicatedFields();
    }

    if (this.onChangeListener) {
      this.onChangeListener(this.value);
    }
  }

  private allCnpsIsValid(){
    let actualLength = this._value.length
    return (this._value.filter((cnp) => cnp.isValid).length == actualLength)
  }

  private removeDuplicatedFields() {
    this.value = this._value
      .filter(((element, index, self) => index === self
        .findIndex((t => t.value == element.value))));
  }

  remove(cnp: CNP) {
    this._value = this._value.filter(c => c !== cnp);
    this.assertEmptyValue();
    if (this.onChangeListener) {
      this.onChangeListener(this.value);
    }
  }

  private assertEmptyValue() {
    let invalidNumber = this._value.filter(cnp => !(cnp && cnp.value && cnp.isValid)).length

    if (invalidNumber > 1 || this.value) {
      this._value = this.value;
      invalidNumber = 0;
    }
    if (invalidNumber === 0 && !this.disabled) {
      this._value.push(new CNP(''));
    }
  }
}
