import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  InterceptorConfig,
  RepositoryImpl,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enterprise, EnterpriseSummary } from './enterprise';
import { EnterpriseAssociation } from './enterprise-operation';

@Injectable({ providedIn: 'root' })
export class EnterprisesRepository extends RepositoryImpl<EnterpriseSummary, Enterprise> {
  constructor(api: ApiService) {
    super(api.manager.path('enterprises'), EnterpriseSummary, Enterprise);
  }

  getToken(enterprise: EnterpriseSummary): Observable<string> {
    return this.api
      .path(enterprise.id)
      .path('token')
      .getText({ metadata: this.metadata });
  }

  verifyIdentifier(
    identifier: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
  ): Observable<HttpResponse<void>> {
    return this.api
      .path('identifier')
      .path(identifier)
      .getResponse({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  associate(
    enterpriseAssociation: EnterpriseAssociation,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<void> {
    return this.api.path('associate').postJson(enterpriseAssociation.toJson(), {
      metadata: RequestMetadataUtils.merge(metadata, this.metadata)
    });
  }

  getFile(
    id: string, 
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
    ): Observable<Blob> {
    return this.api.path(id).path('logotype').getBlob({ metadata: metadata });
  }

  uploadNew(file: File, id: string): Observable<boolean> {
    const form = new FormData();
    form.append('file', file);
    return this.api.path(id).path('logotype').putMultipartForm(form).pipe(map((v) => true));
  }
}
