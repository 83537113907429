import { JsonEntity, SgvJson } from '@eceos/arch';
import { IcmsCst } from '../csts/icms-cst';
import { IcmsWithBcValue } from '../icms-with-bc-value';
import { CalculatedValue } from './../../../calculated-value';
import { IcmsDesonerationCalc } from './icms-desoneration-calc';
import { IcmsDesonerationReason } from './icms-desoneration-reason';
import { IcmsWithDesonerationValue } from './icms-with-desoneration-value';

export interface IcmsWithDesonerationHolder {
  desoneration: IcmsWithDesoneration;
}

export class IcmsWithDesoneration implements JsonEntity {
  constructor(
    public reason: IcmsDesonerationReason = null,
    public calc: IcmsDesonerationCalc = null
  ) {}

  toValue(cst: IcmsCst, icmsBcValue: IcmsWithBcValue): IcmsWithDesonerationValue {
    const value = icmsBcValue
      ? this.calc.apply(
          cst,
          icmsBcValue.bcValue ? icmsBcValue.bcValue.value : null,
          icmsBcValue.aliquot,
          icmsBcValue.bcReductionPercent
        )
      : null;
    return new IcmsWithDesonerationValue(
      this.reason,
      this.calc,
      value ? new CalculatedValue(value) : new CalculatedValue()
    );
  }

  isValid(): boolean {
    return this.reason != null && this.calc != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      reason: this.reason ? this.reason.toJson() : null,
      calc: this.calc ? this.calc.toJson() : null
    });
  }

  static fromJson(json: any): IcmsWithDesoneration {
    return json
      ? SgvJson.from.simple(json, IcmsWithDesoneration, {
          reason: IcmsDesonerationReason.fromJson(json.reason),
          calc: IcmsDesonerationCalc.fromJson(json.calc)
        })
      : null;
  }
}
