import { SgvId, SgvJson } from '@eceos/arch';
import { CalculatedValue } from '../../../nfes/calculated-value';
import { InterstateIcmsUfAliquot } from '../../../nfes/tax/icms-uf-receiver/aliquot/interstate-icms-uf-aliquot';
import { IcmsUfReceiverValueWithBcAliquot } from '../../../nfes/tax/icms-uf-receiver/icms-uf-receiver-value-with-bc-aliquot';
import { IcmsUfReceiver } from './../../../nfes/tax/icms-uf-receiver/icms-uf-receiver';
import { IcmsUfReceiverWithFcpValue } from './../../../nfes/tax/icms-uf-receiver/icms-uf-receiver-with-fcp-value';
import { FcpInterstateAliquot } from './fcp-interstate-aliquot';
import { IcmsSharingTableSummary } from './icms-sharing-table/icms-sharing-table-summary';
import { InterstateAliquot } from './interstate-aliquot';
import { NFeItem } from '../../../nfes/nfe-item';

export class IcmsUfReceiverConfig {
  constructor(
    readonly id = SgvId.gen(),
    public sharePercentual: number = null,
    public icmsSharingTable: IcmsSharingTableSummary = null,
    public icmsInterstateAliquot: InterstateAliquot = null,
    public overwritingCstIcmsAliquot: boolean = null,
    public fcpInterestateAliquot: FcpInterstateAliquot = null,
    public bcFormula: string = null,
    public bcFcpFormula: string = null,
    public interstateIcmsUfAliquot: InterstateIcmsUfAliquot = null
  ) {}

  toIcmsUfReceiver(nfeItem: NFeItem): IcmsUfReceiver {
    return new IcmsUfReceiver(
      SgvId.gen(),
      this.overwritingCstIcmsAliquot,
      this.toIcmsUfReceiverValueWithBcAliquot(nfeItem),
      this.toIcmsUfReceiverWithFcpValue(nfeItem)
    );
  }

  toIcmsUfReceiverValueWithBcAliquot(nfeItem: NFeItem): IcmsUfReceiverValueWithBcAliquot {
    return new IcmsUfReceiverValueWithBcAliquot(
      this.bcFormula,
      new CalculatedValue(),
      this.icmsInterstateAliquot.internalAliquot,
      this.interstateIcmsUfAliquot
    ).publishValues(nfeItem);
  }

  toIcmsUfReceiverWithFcpValue(nfeItem: NFeItem): IcmsUfReceiverWithFcpValue {
    return new IcmsUfReceiverWithFcpValue(
      this.bcFcpFormula,
      new CalculatedValue(),
      this.fcpInterestateAliquot.aliquot.interstateAliquot
    ).publishValues(nfeItem);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      icmsSharingTable: this.icmsSharingTable.toJson(),
      fcpInterestateAliquot: this.fcpInterestateAliquot.toJson(),
      icmsInterstateAliquot: this.icmsInterstateAliquot.toJson(),
      interstateIcmsUfAliquot: this.interstateIcmsUfAliquot.toJson()
    });
  }

  static fromJson(json: any): IcmsUfReceiverConfig {
    return json
      ? SgvJson.from.simple(json, IcmsUfReceiverConfig, {
          icmsSharingTable: IcmsSharingTableSummary.fromJson(json.icmsSharingTable),
          fcpInterestateAliquot: FcpInterstateAliquot.fromJson(json.fcpInterestateAliquot),
          icmsInterstateAliquot: InterstateAliquot.fromJson(json.icmsInterstateAliquot),
          interstateIcmsUfAliquot: InterstateIcmsUfAliquot.fromJson(json.interstateIcmsUfAliquot)
        })
      : null;
  }
}
