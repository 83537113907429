import { BankSlipBillingPaymentForm } from '@eceos/manager-domain';
import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { BillingDataSource } from './billing-data-source';
import { BillingPaymentForm } from './billing-payment-form';

export class Billing implements DomainEntity {
  constructor(
    public readonly id: string = SgvId.gen(),
    public dueDay: number = null,
    public dataSource: BillingDataSource = null,
    public paymentForm: BillingPaymentForm = new BankSlipBillingPaymentForm(),
    public isActive = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      dataSource: this.dataSource.toJson(),
      paymentForm: this.paymentForm.toJson()
    });
  }

  static fromJson(json: any): Billing {
    return json
      ? SgvJson.from.simple(json, Billing, {
          dataSource: BillingDataSource.fromJson(json.dataSource),
          paymentForm: BillingPaymentForm.fromJson(json.paymentForm)
        })
      : null;
  }
}
