import { JsonEntity, SgvJson } from '@eceos/arch';
import { CarrierSummary } from './../../../carriers/carrier';

export abstract class NFCeDeliveryType implements JsonEntity {
  abstract toJson(): any;

  static fromJson(json: any): NFCeDeliveryType {
    if (json && json.type) {
      switch (json.type) {
        case 'homeDeliveryByCarrier':
        case 'homeDeliveryBySelf':
          return NFCeHomeDeliveryType.fromJson(json);
        case 'presencialDelivery':
          return NFCePresencialDeliveryType.fromJson(json);
        case 'autoDelivery':
          return AutoNFCeDeliveryType.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class NFCePresencialDeliveryType extends NFCeDeliveryType {
  toJson(): any {
    return { type: 'presencialDelivery' };
  }

  static fromJson(json: any): NFCePresencialDeliveryType {
    return new NFCePresencialDeliveryType();
  }
}

export class AutoNFCeDeliveryType extends NFCeDeliveryType {
  toJson(): any {
    return { type: 'autoDelivery' };
  }

  static fromJson(json: any): AutoNFCeDeliveryType {
    return new AutoNFCeDeliveryType();
  }
}

export abstract class NFCeHomeDeliveryType extends NFCeDeliveryType {
  static fromJson(json: any): NFCeHomeDeliveryType {
    if (json && json.type) {
      switch (json.type) {
        case 'homeDeliveryByCarrier':
          return NFCeHomeDeliveryByCarrierType.fromJson(json);
        case 'homeDeliveryBySelf':
          return NFCeHomeDeliveryBySelfType.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class NFCeHomeDeliveryByCarrierType extends NFCeDeliveryType {
  constructor(public carrier: CarrierSummary = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'homeDeliveryByCarrier',
      carrier: this.carrier.toJson()
    });
  }

  static fromJson(json: any): NFCeHomeDeliveryByCarrierType {
    return json
      ? SgvJson.from.simple(json, NFCeHomeDeliveryByCarrierType, {
          carrier: CarrierSummary.fromJson(json.carrier)
        })
      : null;
  }
}

export class NFCeHomeDeliveryBySelfType extends NFCeDeliveryType {
  toJson(): any {
    return { type: 'homeDeliveryBySelf' };
  }

  static fromJson(json: any): NFCeHomeDeliveryBySelfType {
    return new NFCeHomeDeliveryBySelfType();
  }
}
