import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class IcmsSharingTableSummary implements DomainEntity {
  constructor(readonly id = SgvId.gen(), public name: string = null) {}

  toJson(): any {
    throw SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsSharingTableSummary {
    return SgvJson.from.simple(json, IcmsSharingTableSummary);
  }
}
