import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { FormulaExecutor } from '../../../../core/formula-executor/formula-executor';
import { CalculatedValue } from '../../calculated-value';
import { NFeItem } from '../../nfe-item';
import { NFeFiscalTax } from '../nfe-item-tax';
import { BcVariable } from './../../../tax/bc-variable';
import { BcModality } from './bc-modalities/bc-modality';

export interface IcmsWithBcValueHolder {
  icms: IcmsWithBcValue;
}

export class IcmsWithBcValue {
  constructor(
    public bcModality: BcModality = null,
    public bcFormula: string = null,
    public bcValue: CalculatedValue = new CalculatedValue(),
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue(),
    public bcReductionPercent: number = null
  ) {}

  calcValue(): number {
    const value = NFeFiscalTax.calcValueBy(this.bcValue.value, this.aliquot);
    return value ? value : 0;
  }

  calcBcValue(nfeItem: NFeItem): number {
    return this.bcFormula
      ? new FormulaExecutor(
          this.bcFormula,
          NFeFiscalTax.buildBcVariables(nfeItem).set(
            BcVariable.BC_REDUCTION_PERCENT,
            this.bcReductionPercent ? this.bcReductionPercent / 100 : 0
          )
        ).execute()
      : null;
  }

  isValid(): boolean {
    return (
      this.bcModality != null && EceosValidatorsUtils.isValid(this.bcValue) && this.aliquot != null
    );
  }

  publishValues(nfeItem: NFeItem): IcmsWithBcValue {
    this.bcValue.value = this.calcBcValue(nfeItem);
    this.value.value = this.calcValue();
    return this;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      bcModality: this.bcModality ? this.bcModality.toJson() : null,
      bcValue: this.bcValue ? this.bcValue.toJson() : null,
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, IcmsWithBcValue, {
          bcModality: BcModality.fromJson(json.bcModality),
          bcValue: CalculatedValue.fromJson(json.bcValue),
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }

  static isIcmsWithBcValueHolder(object: any): object is IcmsWithBcValueHolder {
    return 'icms' in object;
  }
}
