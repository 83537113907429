<mat-form-field>
  <mat-label><ng-content></ng-content></mat-label>
  <mat-select
    class="noDownArrow"
    [(ngModel)]="selectedOption"
    [disabled]="disabled"
    [required]="required"
  >
    <mat-select-trigger>
      {{ selectedDate ? (selectedDate | date: config.format) : selectedOption.label }}
    </mat-select-trigger>
    <mat-option *ngFor="let option of options" [value]="option">{{ option.label }}</mat-option>
  </mat-select>

  <input
    matInput
    class="hidden"
    [matDatetimepicker]="datetimePicker"
    autocomplete="false"
    [(ngModel)]="selectedDate"
    [min]="config.min"
    [max]="config.max"
    [disabled]="disabled"
    [required]="required"
  />
  <mat-datetimepicker
    #datetimePicker
    [type]="config.type"
    [openOnFocus]="config.openOnFocus"
    [mode]="config.mode"
    [touchUi]="config.touchUi"
    [startView]="config.startView"
    [disabled]="disabled"
  ></mat-datetimepicker>
  <mat-datetimepicker-toggle
    [for]="datetimePicker"
    matSuffix
    *ngIf="isCustomOptionSelected"
  ></mat-datetimepicker-toggle>
</mat-form-field>
