import { Pipe, PipeTransform } from '@angular/core';
import { TimeInterval } from '@eceos/domain';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(type: { name, interval: TimeInterval }): string {
    switch (type.name) {
      case 'fixed':
        return 'Valor fixo' + this.transformInterval(type.interval);
      case 'simple':
        return 'Comercial' + this.transformInterval(type.interval);
      default:
        return 'Não informado';
    }
  }

  private transformInterval(interval: TimeInterval): string {
    return interval === TimeInterval.PER_DAY ? ' (Por dia)' : ' (Por mês)';
  }

}
