import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountantInviteAcceptComponent } from './accountant-invite-accept/accountant-invite-accept.component';
import { OwnerInviteAcceptComponent } from './owner-invite-accept/owner-invite-accept.component';

export const routes: Routes = [
  { path: 'mail/accountant/invite/:token', component: AccountantInviteAcceptComponent },
  { path: 'mail/owner/invite/:token', component: OwnerInviteAcceptComponent }
];

export const MailInviteAcceptRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
