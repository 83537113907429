import { Injectable } from "@angular/core";
import { RepositoryImpl, ApiService } from "@eceos/arch";
import { ProductCategory, ProductCategorySummary } from ".";

@Injectable({ providedIn: 'root' })
export class ProductsCategoriesRepository extends RepositoryImpl<ProductCategorySummary, ProductCategory> {

  constructor(api: ApiService) {
    super(api.root.path('productsCategories'), ProductCategorySummary, ProductCategory);
  }

}