import { JsonEntity, SgvJson } from '@eceos/arch';
import { EnterpriseWalletStatus, InactiveEnterpriseWalletStatus } from './enterprise-wallet-status';

export class EnterpriseWallet implements JsonEntity {
  constructor(
    public expiration: Date = null,
    public credits = 0,
    public status: EnterpriseWalletStatus = new InactiveEnterpriseWalletStatus()
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      status: SgvJson.to.optional(this.status)
    });
  }

  static fromJson(json: any): EnterpriseWallet {
    return SgvJson.from.simple(json, EnterpriseWallet, {
      status: EnterpriseWalletStatus.fromJson(json?.status)
    });
  }
}
