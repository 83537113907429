import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class Image implements DomainEntity {
  constructor(
    public readonly id: string = SgvId.gen(),
    public readonly type: string = ''
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Image {
    return json ? SgvJson.from.simple(json, Image) : null;
  }
}