import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { ChargingFlowSummary } from './charging-flow';

@Injectable({ providedIn: 'root' })
export class ChargingFlowsRepository extends RepositoryImpl<
  ChargingFlowSummary,
  ChargingFlowSummary
> {
  constructor(api: ApiService) {
    super(api.root.path('financial/charging/flows'), ChargingFlowSummary, ChargingFlowSummary);
  }
}
