import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthManagerService } from '@eceos/arch';
import { UserInvitePublicRepository, CurrentUserService } from '@eceos/manager-domain';
import { delay, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

enum InviteState {
  NONE,
  ACCEPTING,
  ACCEPTED,
  FAILED
}

@Component({
  selector: 'app-owner-invite-accept',
  templateUrl: './owner-invite-accept.component.html',
  styleUrls: ['./owner-invite-accept.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnerInviteAcceptComponent implements OnInit {
  state: InviteState = InviteState.NONE;

  constructor(
    private service: UserInvitePublicRepository,
    private route: ActivatedRoute,
    private router: Router,
    private authManagerService: AuthManagerService,
    private currentUserService: CurrentUserService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        map(p => p['token']),
        distinctUntilChanged(),
        tap(_ => (this.state = InviteState.ACCEPTING)),
        switchMap(token => this.service.acceptWithToken(token)),
        tap(_ => (this.state = InviteState.ACCEPTED)),
        tap(userToken => this.authManagerService.publishLogin(userToken)),
        tap(_ => this.currentUserService.loadUser()),
        tap(_ => this.changeDetector.markForCheck()),
        delay(2000)
      )
      .subscribe({
        next: _ => this.router.navigateByUrl('/'),
        error: e => {
          console.error(e);
          this.state = InviteState.FAILED;
          this.changeDetector.markForCheck();
        }
      });
  }

  get isAccepted() {
    return this.state === InviteState.ACCEPTED;
  }
  get isAccepting() {
    return this.state === InviteState.ACCEPTING;
  }
  get isFailed() {
    return this.state === InviteState.FAILED;
  }
}
