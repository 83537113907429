import { Injectable } from '@angular/core';
import { lastValueFrom, shareReplay, Subject } from 'rxjs';
import { User } from './user';
import { UsersRepository } from './users.repository';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  private $userSubject = new Subject<User>();

  // tslint:disable-next-line: member-ordering
  public $user = this.$userSubject.asObservable().pipe(shareReplay(1));

  constructor(private usersRepository: UsersRepository) {
    this.loadUser();
  }

  async loadUser() {
    try {
      const value = await lastValueFrom(this.usersRepository.getCurrent());
      this.$userSubject.next(value);
    } catch (error) {
      console.error({ message: 'Falha ao carregar o usuario atual', e: error });
    }
  }
}
