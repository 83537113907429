import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";
import { OperatableUnityLegacy } from "../operatables";
import { StockSummary, WithStockSummary } from "./stock/stock-summary";

export abstract class ProductSummary implements DomainEntity {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly defaultSellValue: number = 0,
        public readonly defaultUnity: OperatableUnityLegacy = null,
        public readonly description: string = '',
        public readonly stock: StockSummary = null,
        public readonly isActive: boolean = true
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): ProductSummary {
        if (!(json || json.type)) return null;

        switch (json.type) {
            case 'simpleProduct':
                return SimpleProductSummary.fromJson(json);
            case 'variableProduct':
                return VariableProductSummary.fromJson(json);
            case 'productByLots':
                return ProductByLotsSummary.fromJson(json);
            default:
                throw new Error('Product summary type not mapped');
        }
    }
}

export class SimpleProductSummary extends ProductSummary {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly defaultSellValue: number = 0,
        public readonly defaultUnity: OperatableUnityLegacy = null,
        public readonly description: string = '',
        public readonly stock: StockSummary = null,
        public readonly isActive: boolean = true,
        public readonly barcode: string = ''
    ) {
        super(id, name, defaultSellValue, defaultUnity, description, stock, isActive);
    }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): SimpleProductSummary {
        return json ? SgvJson.from.simple(json, SimpleProductSummary, {
            defaultUnity: json.defaultUnity ? OperatableUnityLegacy.fromJson(json.defaultUnity) : null,
            stock: json.stock ? StockSummary.fromJson(json.stock) : null
        }) : null;
    }

}

export class ProductByLotsSummary extends ProductSummary {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly defaultSellValue: number = 0,
        public readonly defaultUnity: OperatableUnityLegacy = null,
        public readonly description: string = '',
        public readonly stock: StockSummary = null,
        public readonly isActive: boolean = true,
        public readonly barcode: string = ''
    ) {
        super(id, name, defaultSellValue, defaultUnity, description, stock, isActive);
    }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): ProductByLotsSummary {
        return json ? SgvJson.from.simple(json, ProductByLotsSummary, {
            defaultUnity: json.defaultUnity ? OperatableUnityLegacy.fromJson(json.defaultUnity) : null,
            stock: json.stock ? StockSummary.fromJson(json.stock) : null
        }) : null;
    }

}

export class VariableProductSummary extends ProductSummary {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly defaultSellValue: number = 0,
        public readonly defaultUnity: OperatableUnityLegacy = null,
        public readonly description: string = '',
        public readonly stock: StockSummary = null,
        public readonly isActive: boolean = true,
        public readonly barcode: string = ''
    ) {
        super(id, name, defaultSellValue, defaultUnity, description, stock, isActive);
    }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): VariableProductSummary {
        return json ? SgvJson.from.simple(json, VariableProductSummary, {
            defaultUnity: json.defaultUnity ? OperatableUnityLegacy.fromJson(json.defaultUnity) : null,
            stock: json.stock ? StockSummary.fromJson(json.stock) : null
        }) : null;
    }

}