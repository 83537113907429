import { JsonEntity, SgvJson } from '@eceos/arch';
import { Carrier } from '../../carriers/carrier';
import { FreightModality, WITHOUT_FREIGHT_ID } from './freight-modality';

export abstract class Freight implements JsonEntity {
  constructor(public modality: FreightModality = null) {}

  abstract toJson(): any;

  static fromJson(json: any): Freight {
    if (json && json.modality) {
      switch (json.modality.id) {
        case WITHOUT_FREIGHT_ID:
          return WithoutFreight.fromJson(json);
        default:
          return FreightWithValue.fromJson(json);
      }
    }
    return null;
  }
}

export class WithoutFreight extends Freight {
  constructor() {
    super(FreightModality.withoutFreight());
  }

  toJson(): any {
    return SgvJson.to.simple(this, { modality: this.modality.toJson() });
  }

  static fromJson(json: any): WithoutFreight {
    return json
      ? SgvJson.from.simple(json, WithoutFreight, {
          modality: FreightModality.fromJson(json.modality)
        })
      : null;
  }
}

export class FreightWithValue extends Freight {
  constructor(
    public modality: FreightModality = null,
    public carrier: Carrier = null,
    public value: number = 0
  ) {
    super(modality);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      modality: this.modality.toJson(),
      carrier: SgvJson.to.optional(this.carrier)
    });
  }

  static fromJson(json: any): FreightWithValue {
    return json
      ? SgvJson.from.simple(json, FreightWithValue, {
          modality: FreightModality.fromJson(json.modality),
          carrier: Carrier.fromJson(json.carrier)
        })
      : null;
  }
}
