import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { Plan, PlanSummary } from './plan';

@Injectable({ providedIn: 'root' })
export class PlansRepository extends RepositoryImpl<PlanSummary, Plan> {
  constructor(api: ApiService) {
    super(api.manager.path('plans'), PlanSummary, Plan);
  }
}
