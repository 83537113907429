export class FormulaExecutor {
  private ALLOWED_CARACTERS = new RegExp('^[0-9\\+\\-\\/\\*\\(\\)\\[\\]\\^\\s\\.]*$');

  constructor(
    private formula: string,
    private variables: Map<FormulaVariable, number> = new Map()
  ) {}

  withVariable(variable: FormulaVariable, value: number): FormulaExecutor {
    this.variables.set(variable, value);
    return this;
  }

  withVariables(variables: Map<FormulaVariable, number>): FormulaExecutor {
    for (const [variable, value] of variables) {
      this.variables.set(variable, value);
    }
    return this;
  }

  execute(): number {
    let parsedFormula = this.formula;
    for (const [variable, value] of this.variables) {
      if (parsedFormula.includes(variable.param)) {
        parsedFormula = parsedFormula.replace(
          new RegExp('\\' + variable.param, 'g'),
          value ? value.toString() : '0'
        );
      }
    }

    try {
      return this.ALLOWED_CARACTERS.test(parsedFormula) ? eval(parsedFormula) : null;
    } catch (e) {
      return null;
    }
  }
}

export interface FormulaVariable {
  readonly param: string;
}
