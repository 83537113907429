import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { RatePolicy } from './rate-policy';

@Injectable({ providedIn: 'root' })
export class RatePoliciesRepository extends RepositoryImpl<RatePolicy, RatePolicy> {
  constructor(api: ApiService) {
    super(api.root.path('financial/ratepolicies'), RatePolicy, RatePolicy);
  }
}
