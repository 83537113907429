import { SgvJson } from '@eceos/arch';
import { CarrierSummary } from '@eceos/domain';

export abstract class NFeShippingResponsible {
  abstract toJson(): any;

  static fromJson(json: any) {
    if (json && json.type) {
      switch (json.type) {
        case 'byCarrier':
          return NFeShippingResponsibleByCarrier.fromJson(json);
        case 'bySelf':
          return NFeShippingResponsibleBySelf.fromJson(json);
        default:
          return null;
      }
    }
    return new NFeShippingWithoutResponsible();
  }
}

export class NFeShippingResponsibleByCarrier extends NFeShippingResponsible {
  constructor(public carrier: CarrierSummary = null) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      carrier: this.carrier.toJson(),
      type: 'byCarrier'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, NFeShippingResponsibleByCarrier, {
      carrier: CarrierSummary.fromJson(json.carrier)
    });
  }
}

export class NFeShippingResponsibleBySelf extends NFeShippingResponsible {
  toJson() {
    return SgvJson.to.simple(this, {
      type: 'bySelf'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, NFeShippingResponsibleBySelf);
  }
}

export class NFeShippingWithoutResponsible extends NFeShippingResponsible {
  toJson() {
    return SgvJson.to.simple(this, {
      type: 'without'
    });
  }
}
