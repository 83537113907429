import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateInterval } from '@eceos/arch';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { DateRange } from './date-range';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent implements OnInit {
  @Input() intervalOptions = DateRange.values();

  @Output() intervalChange = new EventEmitter<DateInterval>();

  @Input() withTime = false;

  @Input() custom = false;

  @Input() withNextPrev = true;

  form: FormGroup = null;

  private _interval = DateInterval.ofToday();

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      begin: fb.control(this.interval.begin, [Validators.required]),
      end: fb.control(this.interval.end, [Validators.required])
    });
    this.form.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter((v) => v.begin && v.end)
      )
      .subscribe({
        next: (v) => {
          this._interval = DateInterval.of(v.begin, v.end);
          this.intervalChange.emit(this.interval);
        }
      });
  }

  get interval() {
    return this._interval;
  }

  @Input()
  set interval(value: DateInterval) {
    if (this._interval === value) return;
    this._interval = value;
    this.form.patchValue({
      begin: this._interval.begin,
      end: this._interval.end
    });
  }

  ngOnInit() {}

  goNext() {
    this.interval = this.interval.next();
  }

  goPrevious() {
    this.interval = this.interval.previous();
  }

  selectOption(o: DateRange) {
    this.interval = o.interval;
  }

  selectCustom() {
    this.custom = true;
  }

  trackOptionBy(index: number, o: DateRange) {
    return o.name;
  }
}
