import { JsonEntity, SgvJson } from '@eceos/arch';
import {
  CarrierResponsibleData,
  DelivererResponsibleData,
  DeliveryOwnResponsibleData,
  DeliveryResponsibleData,
  WithoutDeliveryResponsibleData
} from '../delivery-responsible-data';

export abstract class DeliveryResponsible implements JsonEntity {
  abstract toResponsibleData(): DeliveryResponsibleData;

  abstract toJson(): any;

  static fromJson(json: any): DeliveryResponsible {
    if (json && json.type) {
      switch (json.type) {
        case 'without':
          return DeliveryWithoutResponsible.fromJson(json);
        case 'carrier':
          return DeliveryCarrierResponsible.fromJson(json);
        case 'deliverer':
          return DeliveryDelivererResponsible.fromJson(json);
        case 'own':
          return DeliveryOwnResponsible.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class DeliveryWithoutResponsible extends DeliveryResponsible {
  constructor() {
    super();
  }

  toResponsibleData(): WithoutDeliveryResponsibleData {
    return new WithoutDeliveryResponsibleData();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'without' });
  }

  static fromJson(json: any): DeliveryWithoutResponsible {
    return SgvJson.from.simple(json, DeliveryWithoutResponsible);
  }
}

export class DeliveryCarrierResponsible extends DeliveryResponsible {
  constructor() {
    super();
  }

  toResponsibleData(): CarrierResponsibleData {
    return new CarrierResponsibleData();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'carrier' });
  }

  static fromJson(json: any): DeliveryCarrierResponsible {
    return SgvJson.from.simple(json, DeliveryCarrierResponsible);
  }
}

export class DeliveryDelivererResponsible extends DeliveryResponsible {
  constructor() {
    super();
  }

  toResponsibleData(): DelivererResponsibleData {
    return new DelivererResponsibleData();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'deliverer' });
  }

  static fromJson(json: any): DeliveryDelivererResponsible {
    return SgvJson.from.simple(json, DeliveryDelivererResponsible);
  }
}

export class DeliveryOwnResponsible extends DeliveryResponsible {
  constructor() {
    super();
  }

  toResponsibleData(): DeliveryOwnResponsibleData {
    return new DeliveryOwnResponsibleData();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'own' });
  }

  static fromJson(json: any): DeliveryOwnResponsible {
    return SgvJson.from.simple(json, DeliveryOwnResponsible);
  }
}
