import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiService,
  Filter,
  FilterOp,
  InterceptorConfig,
  RepositoryImpl,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { retryExceptOnClientError } from '@eceos/common-utils';
import { Observable } from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import { FiscalProductSeries, FiscalSeriesSummary } from './fiscal-series';
import { FiscalDocumentNumberChangeHistory } from './history/fiscal-document-number-change-history';

@Injectable({ providedIn: 'root' })
export class FiscalProductSeriesRepository extends RepositoryImpl<
  FiscalSeriesSummary,
  FiscalProductSeries
> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/series'), FiscalSeriesSummary, FiscalProductSeries, [
      new Filter('for', FilterOp.EQ, 'product')
    ]);
  }

  findNFeDefault(
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
  ): Observable<FiscalProductSeries> {
    return this.findDefault('NFE', metadata);
  }

  findNFCeDefault(
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
  ): Observable<FiscalProductSeries> {
    return this.findDefault('NFCE', metadata);
  }

  updateFiscalDocumentNumber(
    history: FiscalDocumentNumberChangeHistory
  ): Observable<void> {
    return this.api
      .path(history.entityId)
      .path('currentNumber')
      .postJson(history);
  }

  private findDefault(
    type: 'NFE' | 'NFCE',
    metadata: RequestMetadata
  ): Observable<FiscalProductSeries> {
    return this.api
      .path('default')
      .getJson({
        params: new HttpParams().append('model', type),
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(
        map(r => FiscalProductSeries.fromJson(r)),
        retryWhen(retryExceptOnClientError()),
      );
  }
}