import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RemotePrinterSummary } from '../ehwhub/remote-printer';
import { ReportFormat } from '../report/report-format';
import { SalesOrder, SalesOrderSummary } from './sales-order';
import { ApproveSalesOrderRequest } from './sales-order-action-request';

@Injectable({ providedIn: 'root' })
export class SalesOrderRepository extends RepositoryImpl<SalesOrderSummary, SalesOrder> {
  constructor(api: ApiService) {
    super(api.root.path('salesOrders'), SalesOrderSummary, SalesOrder);
  }

  approve(salesOrder: SalesOrder): Observable<SalesOrder> {
    return this.api
      .path(salesOrder.id)
      .patchJson(new ApproveSalesOrderRequest().toJson())
      .pipe(map(json => SalesOrder.fromJson(json)));
  }

  generateReport(
    salesOrder: SalesOrder | SalesOrderSummary,
    format: ReportFormat = ReportFormat.PDF
  ): Observable<Blob> {
    return this.api
      .path(salesOrder.id)
      .path(`report.${format.value}`)
      .getBlob();
  }

  printReport(
    salesOrder: SalesOrder | SalesOrderSummary,
    printer: RemotePrinterSummary,
    format: ReportFormat = ReportFormat.PDF
  ): Observable<Response> {
    const form = new URLSearchParams();
    form.set('printerId', printer.id);
    return this.api
      .path(salesOrder.id)
      .path(`report.${format.value}`)
      .path('printTo')
      .postForm(form);
  }
}
