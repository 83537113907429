import { DomainEntity, SgvId } from "@eceos/arch";

export abstract class Fine implements DomainEntity {

  readonly id = SgvId.gen();

  constructor(
    public value = 0.0,
    public type = ''
  ) { }

  abstract toJson(): any;
}
