import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { FormulaExecutor } from '../../../../core/formula-executor/formula-executor';
import { CalculatedValue } from '../../calculated-value';
import { NFeItem } from '../../nfe-item';
import { NFeFiscalTax } from '../nfe-item-tax';
import { InterstateIcmsUfAliquot } from './aliquot/interstate-icms-uf-aliquot';

export class IcmsUfReceiverValueWithBcAliquot {
  constructor(
    public bcFormula = '',
    public bcValue: CalculatedValue = new CalculatedValue(),
    public aliquot: number = null,
    public interestateAliquot: InterstateIcmsUfAliquot = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  calcDifal(): number {
    return this.interestateAliquot && this.interestateAliquot.value != null && this.aliquot != null
      ? this.aliquot - this.interestateAliquot.value
      : null;
  }

  calcBcValue(nfeItem: NFeItem): number {
    return this.bcFormula
      ? new FormulaExecutor(this.bcFormula, NFeFiscalTax.buildBcVariables(nfeItem)).execute()
      : null;
  }

  calcValue(): number {
    const difal = this.calcDifal();
    return difal != null && this.bcValue && this.bcValue.value != null
      ? (difal / 100) * this.bcValue.value
      : null;
  }

  isValid(): boolean {
    return (
      this.aliquot != null &&
      EceosValidatorsUtils.isValid(this.bcValue) &&
      EceosValidatorsUtils.isValid(this.value) &&
      this.interestateAliquot != null
    );
  }

  publishValues(nfeItem: NFeItem): IcmsUfReceiverValueWithBcAliquot {
    this.bcValue.value = this.calcBcValue(nfeItem);
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      bcValue: this.bcValue ? this.bcValue.toJson() : null,
      value: this.value ? this.value.toJson() : null,
      interestateAliquot: this.interestateAliquot.toJson()
    });
  }

  static fromJson(json: any): IcmsUfReceiverValueWithBcAliquot {
    return json
      ? SgvJson.from.simple(json, IcmsUfReceiverValueWithBcAliquot, {
          bcValue: CalculatedValue.fromJson(json.bcValue),
          value: CalculatedValue.fromJson(json.value),
          interestateAliquot: InterstateIcmsUfAliquot.fromJson(json.interestateAliquot)
        })
      : null;
  }
}
