import { JsonEntity, SgvJson } from '@eceos/arch';
import { NFeItem } from '../../../nfe-item';
import { IcmsCst } from './../csts/icms-cst';
import { IcmsDesonerationCalc } from './icms-desoneration-calc';
import { IcmsDesonerationReason } from './icms-desoneration-reason';
import { IcmsWithDesonerationWithoutTaxValue } from './icms-with-desoneration-without-tax-value';

export interface IcmsWithDesonerationWithoutTaxHolder {
  desoneration: IcmsWithDesonerationWithoutTax;
}

export class IcmsWithDesonerationWithoutTax implements JsonEntity {
  constructor(
    public reason: IcmsDesonerationReason = null,
    public calc: IcmsDesonerationCalc = null,
    public icmsBcFormula: string = '',
    public icmsAliquot: number = null
  ) {}

  toValue(nfeItem: NFeItem, icmsCst: IcmsCst): IcmsWithDesonerationWithoutTaxValue {
    return new IcmsWithDesonerationWithoutTaxValue(
      this.reason,
      this.calc,
      this.icmsAliquot,
      this.icmsBcFormula
    ).publishValues(nfeItem, icmsCst);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      reason: this.reason ? this.reason.toJson() : null,
      calc: this.calc ? this.calc.toJson() : null
    });
  }
  static fromJson(json: any): IcmsWithDesonerationWithoutTax {
    return json
      ? SgvJson.from.simple(json, IcmsWithDesonerationWithoutTax, {
          reason: IcmsDesonerationReason.fromJson(json.reason),
          calc: IcmsDesonerationCalc.fromJson(json.calc)
        })
      : null;
  }
}
