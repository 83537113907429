import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import {
  AddsValue,
  FiscalDocumentType,
  NFeCharging,
  NFeFinality,
  NFeItem,
  NFePayment,
  NFeTotal
} from '.';
import { PersonSummary } from '../../core/person';
import { FiscalDocumentState } from '../document';
import { FiscalSeries, FiscalSeriesSummary } from '../series/fiscal-series';
import { NFeCancelAuthorizedEvent, NFeConsultReceiptAsyncAuthorizedEvent, NFeConsultReceiptAsyncPendingEvent, NFeEvent, NFeSubmitAsyncAuthorizedEvent, NFeSubmitAuthorizedEvent } from './nfe-event';
import { BaseNFeIE, BaseNFeIEByReceiver } from './nfe-ie-type';
import { BaseNFeInformationMessage } from './nfe-information-message';
import { NFeCFOPOperationType, NFeOperationType } from './nfe-operation-type';
import { DocumentReference } from './reference/document-reference';
import { NFCeDelivery, NFCePresencialDelivery } from './delivery/nfce-delivery';
import { NFeTransport } from './transport';

export abstract class BaseNFeSummary implements DomainEntity {
  constructor(
    readonly id: string,
    readonly receiver: PersonSummary,
    readonly serie: FiscalSeries,
    readonly number: number,
    readonly movement: Date,
    readonly operationType: string,
    readonly status: FiscalDocumentState,
    readonly total: number
  ) {}

  get authorized(): boolean {
    return this.status === FiscalDocumentState.AUTHORIZED;
  }

  abstract toJson(): any;

  static fromJson(json: any) {
    if (!json) {
      return null;
    }
    switch (json.model) {
      case 'nfe':
        return NFeSummary.fromJson(json);
      case 'nfce':
        return NFCeSummary.fromJson(json);
      default:
        return null;
    }
  }
}

export class NFeSummary extends BaseNFeSummary {
  constructor(
    id = SgvId.gen(),
    receiver: PersonSummary = null,
    serie: FiscalSeries = null,
    number = 0,
    movement: Date = null,
    operationType = '',
    readonly finality = NFeFinality.NORMAL,
    readonly fiscalDocumentType = FiscalDocumentType.OUT,
    status = FiscalDocumentState.PENDING,
    total = 0
  ) {
    super(id, receiver, serie, number, movement, operationType, status, total);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      receiver: this.receiver.toJson(),
      serie: this.serie.toJson(),
      fiscalDocumentType: this.fiscalDocumentType.name,
      finality: this.finality.name,
      status: this.status.name
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, NFeSummary, {
      receiver: PersonSummary.fromJson(json.receiver),
      serie: FiscalSeries.fromJson(json.serie),
      fiscalDocumentType: json.fiscalDocumentType
        ? FiscalDocumentType.get(json.fiscalDocumentType)
        : null,
      finality: json.finality ? NFeFinality.get(json.finality) : null,
      status: FiscalDocumentState.getOrNull(json.status)
    });
  }
}

export class NFCeSummary extends BaseNFeSummary {
  constructor(
    id = SgvId.gen(),
    receiver: PersonSummary = null,
    readonly cnp = '',
    serie: FiscalSeries = null,
    number = 0,
    movement: Date = null,
    operationType = '',
    status: FiscalDocumentState = FiscalDocumentState.PENDING,
    total = 0
  ) {
    super(id, receiver, serie, number, movement, operationType, status, total);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      receiver: this.receiver.toJson(),
      serie: this.serie.toJson(),
      status: this.status.name
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, NFCeSummary, {
      receiver: PersonSummary.fromJson(json.receiver),
      serie: FiscalSeries.fromJson(json.serie),
      status: FiscalDocumentState.getOrNull(json.status)
    });
  }
}

export abstract class BaseNFe implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public serie: FiscalSeriesSummary = null,
    public readonly number: string = null,
    public movement: Date = null,
    public emission: Date = null,
    public additionalInformations: BaseNFeInformationMessage[] = [],
    public items: NFeItem[] = [],
    public payments: NFePayment[] = [],
    public endConsumer: boolean = false,
    public ie: BaseNFeIE = new BaseNFeIEByReceiver(),
    public ieSt: string = '',
    public operationType: NFeOperationType = new NFeCFOPOperationType(),
    public events: NFeEvent[] = [],
    public total: NFeTotal = new NFeTotal(new AddsValue(), () => this.items),
    public charging: NFeCharging = null,
    public nfeCnpAccessXml: string[] = [],
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  abstract get receiver(): PersonSummary;

  abstract get fiscalDocumentType(): NFeFinality;

  get status(): FiscalDocumentState {
    if (this.isCancelled) {
      return FiscalDocumentState.CANCELED;
    } else if (this.isAuthorized) {
      return FiscalDocumentState.AUTHORIZED;
    } else if(this.isPending){
      return FiscalDocumentState.PROCESSING;
    } else {
      return FiscalDocumentState.PENDING;
    }
  }

  get isAllowedToTransmit() {
    return this.status === FiscalDocumentState.PENDING;
  }

  get isNFe(): boolean {
    return this instanceof NFe;
  }

  get isNFCe(): boolean {
    return this instanceof NFCe;
  }

  get isAuthorized(): boolean {
    return this.events.some((event) => event instanceof NFeSubmitAuthorizedEvent || event instanceof NFeConsultReceiptAsyncAuthorizedEvent);
  }

  get isCancelled(): boolean {
    return this.events.some((event) => event instanceof NFeCancelAuthorizedEvent);
  }

  get isPending(): boolean {
    return this.lastEvent instanceof NFeConsultReceiptAsyncPendingEvent;
  }

  get lastEvent(): NFeEvent {
    return this.events && this.events.length > 0 ? this.events[this.events.length-1] : null;
  }

  get hasItems(): boolean {
    return this.items?.length > 0;
  }

  get hasCharging(): boolean {
    return Boolean(this.charging);
  }

  abstract toJson(): any;

  addItem(item: NFeItem): void {
    this.items.push(item);
  }

  static fromJson(json: any) {
    if (!json) {
      return null;
    }
    switch (json.model) {
      case 'nfe':
        return NFe.fromJson(json);
      case 'nfce':
        return NFCe.fromJson(json);
      default:
        return null;
    }
  }
}

export class NFe extends BaseNFe {
  constructor(
    id = SgvId.gen(),
    serie: FiscalSeriesSummary = null,
    number: string = null,
    movement = new Date(),
    emission: Date = null,
    additionalInformations: BaseNFeInformationMessage[] = [],
    items: NFeItem[] = [],
    payments: NFePayment[] = [],
    endConsumer: boolean = false,
    ie: BaseNFeIE = new BaseNFeIEByReceiver(),
    ieSt: string = '',
    operationType: NFeOperationType = new NFeCFOPOperationType(),
    events: NFeEvent[] = [],
    total: NFeTotal = new NFeTotal(new AddsValue(), () => this.items),
    charging: NFeCharging = null,
    nfeCnpAccessXml: string[] = [],
    version = 0,
    isActive = true,
    public receiver: PersonSummary = null,
    public entranceOrExit: Date = null,
    public finality: NFeFinality = NFeFinality.NORMAL,
    public fiscalDocumentType: FiscalDocumentType = FiscalDocumentType.OUT,
    public documentReferences: DocumentReference[] = [],
    public transport: NFeTransport = new NFeTransport()
  ) {
    super(
      id,
      serie,
      number,
      movement,
      emission,
      additionalInformations,
      items,
      payments,
      endConsumer,
      ie,
      ieSt,
      operationType,
      events,
      total,
      charging,
      nfeCnpAccessXml,
      version,
      isActive
    );
  }

  get isAllowedToGenerateCCe(): boolean {
    return this.isAuthorized && !this.isCancelled;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      receiver: this.receiver.toJson(),
      serie: this.serie.toJson(),
      fiscalDocumentType: this.fiscalDocumentType.name,
      finality: this.finality.name,
      additionalInformations: SgvJson.to.array(this.additionalInformations),
      items: SgvJson.to.array(this.items),
      payments: SgvJson.to.array(this.payments),
      events: [],
      documentReferences: SgvJson.to.array(this.documentReferences),
      charging: this.charging ? this.charging.toJson() : null,
      ie: this.ie.toJson(),
      operationType: this.operationType.toJson(),
      total: this.total.toJson(),
      transport: this.transport ? this.transport.toJson() : null,
      model: 'nfe'
    });
  }

  static fromJson(json: any) {
    const items = SgvJson.from.array(json.items, NFeItem.fromJson);
    return SgvJson.from.simple(json, NFe, {
      receiver: PersonSummary.fromJson(json.receiver),
      serie: FiscalSeriesSummary.fromJson(json.serie),
      fiscalDocumentType: json.fiscalDocumentType
        ? FiscalDocumentType.get(json.fiscalDocumentType)
        : null,
      finality: json.finality ? NFeFinality.get(json.finality) : null,
      additionalInformations: SgvJson.from.array(
        json.additionalInformations,
        BaseNFeInformationMessage.fromJson
      ),
      items: items,
      payments: SgvJson.from.array(json.payments, NFePayment.fromJson),
      events: SgvJson.from.array(json.events, NFeEvent.fromJson),
      documentReferences: SgvJson.from.array(json.documentReferences, DocumentReference.fromJson),
      charging: NFeCharging.fromJson(json.charging),
      ie: BaseNFeIE.fromJson(json.ie),
      operationType: NFeOperationType.fromJson(json.operationType),
      total: NFeTotal.fromJson(json.total, () => items),
      transport: NFeTransport.fromJson(json.transport)
    });
  }
}

export class NFCe extends BaseNFe {
  constructor(
    id = SgvId.gen(),
    serie: FiscalSeriesSummary = null,
    number: string = null,
    movement = new Date(),
    emission: Date = null,
    additionalInformations: BaseNFeInformationMessage[] = [],
    items: NFeItem[] = [],
    payments: NFePayment[] = [],
    ie: BaseNFeIE = new BaseNFeIEByReceiver(),
    ieSt: string = '',
    operationType: NFeOperationType = new NFeCFOPOperationType(),
    events: NFeEvent[] = [],
    total: NFeTotal = new NFeTotal(new AddsValue(), () => this.items),
    charging: NFeCharging = null,
    nfeCnpAccessXml: string[] = [],
    version = 0,
    isActive = true,
    public receiver: PersonSummary = null,
    public cnp: String = '',
    public delivery: NFCeDelivery = new NFCePresencialDelivery()
  ) {
    super(
      id,
      serie,
      number,
      movement,
      emission,
      additionalInformations,
      items,
      payments,
      true,
      ie,
      ieSt,
      operationType,
      events,
      total,
      charging,
      nfeCnpAccessXml,
      version,
      isActive
    );
  }

  get fiscalDocumentType(): FiscalDocumentType {
    return FiscalDocumentType.OUT;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      receiver: this.receiver ? this.receiver.toJson() : null,
      delivery: this.delivery ? this.delivery.toJson() : null,
      serie: this.serie.toJson(),
      additionalInformations: SgvJson.to.array(this.additionalInformations),
      items: SgvJson.to.array(this.items),
      payments: SgvJson.to.array(this.payments),
      events: [],
      charging: this.charging ? this.charging.toJson() : null,
      ie: this.ie.toJson(),
      operationType: this.operationType.toJson(),
      total: this.total.toJson(),
      model: 'nfce'
    });
  }

  static fromJson(json: any) {
    const items = SgvJson.from.array(json.items, NFeItem.fromJson);
    return SgvJson.from.simple(json, NFCe, {
      receiver: PersonSummary.fromJson(json.receiver),
      delivery: NFCeDelivery.fromJson(json.delivery),
      serie: FiscalSeriesSummary.fromJson(json.serie),
      cnp: json.cnp ? json.cnp.replace(/\D/g, '') : null,
      additionalInformations: SgvJson.from.array(
        json.additionalInformations,
        BaseNFeInformationMessage.fromJson
      ),
      items: items,
      payments: SgvJson.from.array(json.payments, NFePayment.fromJson),
      events: SgvJson.from.array(json.events, NFeEvent.fromJson),
      charging: NFeCharging.fromJson(json.charging),
      ie: BaseNFeIE.fromJson(json.ie),
      operationType: NFeOperationType.fromJson(json.operationType),
      total: NFeTotal.fromJson(json.total, () => items)
    });
  }
}
