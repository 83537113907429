import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Address, BrazilianAddress, SimpleForeignAddress } from '../../../core/address/address';
import {
  Documents,
  ForeignPersonDocuments,
  LegalEntityDocuments,
  NaturalPersonDocuments
} from '../../../core/documents';
import { MailContact } from '../../../core/mail-contact';
import { PhoneContact } from '../../../core/phone-contact';

export class Deliverer implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public documents: Documents = new NaturalPersonDocuments(),
    public registrationDate: Date = new Date(),
    public address: Address = new BrazilianAddress(),
    public emails: MailContact[] = [],
    public phones: PhoneContact[] = [],
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  get isNaturalPerson(): boolean {
    return this.documents && this.documents instanceof NaturalPersonDocuments;
  }

  get isLegalEntity(): boolean {
    return this.documents && this.documents instanceof LegalEntityDocuments;
  }

  get isForeignPerson(): boolean {
    return this.documents && this.documents instanceof ForeignPersonDocuments;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      address: this.address ? this.address.toJson() : null,
      documents: this.documents ? this.documents.toJson() : null,
      contacts: [
        ...SgvJson.to.array(this.emails.filter(c => c && c.value && c.value !== '')),
        ...SgvJson.to.array(this.phones.filter(c => c && c.value && c.value !== ''))
      ]
    });
  }

  static naturalPerson(): Deliverer {
    const entity = new Deliverer();
    entity.documents = new NaturalPersonDocuments();
    return entity;
  }

  static legalEntity(): Deliverer {
    const entity = new Deliverer();
    entity.documents = new LegalEntityDocuments();
    return entity;
  }

  static foreignPerson(): Deliverer {
    const entity = new Deliverer();
    entity.documents = new ForeignPersonDocuments();
    entity.address = new SimpleForeignAddress();
    return entity;
  }

  static fromJson(json: any): Deliverer {
    return SgvJson.from.simple(json, Deliverer, {
      address: json.address ? Address.fromJson(json.address) : null,
      documents: json.documents ? Documents.fromJson(json.documents) : null,
      registrationDate: json.registrationDate ? new Date(json.registrationDate) : null,
      emails: json.contacts
        ? json.contacts
            .filter(
              (c: any) =>
                c &&
                c.type &&
                c.detail &&
                c.detail !== '' &&
                (c.type === 'EMAIL' || c.type === 'EMAIL_NFE')
            )
            .map((c: any) => MailContact.fromJson(c))
        : [],
      phones: json.contacts
        ? json.contacts
            .filter(
              (c: any) =>
                c &&
                c.type &&
                c.detail &&
                c.detail !== '' &&
                (c.type === 'TELEFONE' || c.type === 'CELULAR')
            )
            .map((c: any) => PhoneContact.fromJson(c))
        : []
    });
  }
}

export class DelivererSummary implements DomainEntity {
  constructor(public readonly id = SgvId.gen(), public name = '', public cnp = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): DelivererSummary {
    return SgvJson.from.simple(json, DelivererSummary);
  }
}
