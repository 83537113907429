import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedValue } from '../../calculated-value';
import { NFeItem } from '../../nfe-item';
import { NFeFiscalTax } from '../nfe-item-tax';
import { FormulaExecutor } from './../../../../core/formula-executor/formula-executor';

export interface CofinsValueWithBcAliquotHolder {
  cofins: CofinsValueWithBcAliquot;
}

export abstract class CofinsValueWithBcAliquot {
  constructor(
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  abstract calcValue(): number;

  abstract isValid(): boolean;

  abstract publishValues(nfeItem: NFeItem): CofinsValueWithBcAliquot;

  abstract toJson(): any;

  static fromJson(json: any): CofinsValueWithBcAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'percent':
          return CofinsValueWithBcAliquotPercent.fromJson(json);
        case 'value':
          return CofinsValueWithBcAliquotValue.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class CofinsValueWithBcAliquotPercent extends CofinsValueWithBcAliquot {
  constructor(
    aliquot: number = null,
    value = new CalculatedValue(),
    public bcValue = new CalculatedValue(),
    public bcFormula = ''
  ) {
    super(aliquot, value);
  }

  calcBcValue(nfeItem: NFeItem): number {
    const value = this.bcFormula
      ? new FormulaExecutor(this.bcFormula, NFeFiscalTax.buildBcVariables(nfeItem)).execute()
      : null;
    return value ? value : 0;
  }

  calcValue(): number {
    const value = NFeFiscalTax.calcValueBy(this.bcValue.value, this.aliquot);
    return value ? value : 0;
  }

  isValid(): boolean {
    return (
      this.aliquot != null &&
      EceosValidatorsUtils.isValid(this.value) &&
      EceosValidatorsUtils.isValid(this.bcValue)
    );
  }

  publishValues(nfeItem: NFeItem): CofinsValueWithBcAliquotPercent {
    this.bcValue.value = this.calcBcValue(nfeItem);
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'percent',
      bcValue: this.bcValue ? this.bcValue.toJson() : null,
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): CofinsValueWithBcAliquotPercent {
    return json
      ? SgvJson.from.simple(json, CofinsValueWithBcAliquotPercent, {
          bcValue: CalculatedValue.fromJson(json.bcValue),
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}

export class CofinsValueWithBcAliquotValue extends CofinsValueWithBcAliquot {
  constructor(
    aliquot: number = null,
    value = new CalculatedValue(),
    public productQuantity: number = null
  ) {
    super(aliquot, value);
  }

  calcValue(): number {
    const value = this.productQuantity * this.aliquot;
    return isNaN(value) ? 0 : value;
  }

  isValid(): boolean {
    return (
      this.aliquot != null &&
      EceosValidatorsUtils.isValid(this.value) &&
      this.productQuantity != null
    );
  }

  publishValues(): CofinsValueWithBcAliquotValue {
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'value',
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): CofinsValueWithBcAliquotValue {
    return json
      ? SgvJson.from.simple(json, CofinsValueWithBcAliquotValue, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}
