import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class EnterpriseWalletStatus implements JsonEntity {
  abstract toJson(): any;

  get isActive(): boolean {
    return this instanceof ActiveEnterpriseWalletStatus;
  }

  get isInactive(): boolean {
    return this instanceof InactiveEnterpriseWalletStatus;
  }

  get isExpired(): boolean {
    return this instanceof ExpiredEnterpriseWalletStatus;
  }

  static fromJson(json: any): EnterpriseWalletStatus {
    if (json?.type) {
      switch (json.type) {
        case InactiveEnterpriseWalletStatus.TYPE:
          return InactiveEnterpriseWalletStatus.fromJson(json);
        case ActiveEnterpriseWalletStatus.TYPE:
          return ActiveEnterpriseWalletStatus.fromJson(json);
        case ExpiredEnterpriseWalletStatus.TYPE:
          return ExpiredEnterpriseWalletStatus.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class InactiveEnterpriseWalletStatus extends EnterpriseWalletStatus {
  static TYPE = 'inactive';

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: InactiveEnterpriseWalletStatus.TYPE
    });
  }

  static fromJson(json: any): InactiveEnterpriseWalletStatus {
    return SgvJson.from.simple(json, InactiveEnterpriseWalletStatus);
  }
}

export class ActiveEnterpriseWalletStatus extends EnterpriseWalletStatus {
  static TYPE = 'active';

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: ActiveEnterpriseWalletStatus.TYPE
    });
  }

  static fromJson(json: any): ActiveEnterpriseWalletStatus {
    return SgvJson.from.simple(json, ActiveEnterpriseWalletStatus);
  }
}

export class ExpiredEnterpriseWalletStatus extends EnterpriseWalletStatus {
  static TYPE = 'expired';

  constructor(public reason: WalletExpiredReason = new OtherExpiredReason()) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: ExpiredEnterpriseWalletStatus.TYPE,
      reason: this.reason.toJson()
    });
  }

  static fromJson(json: any): ExpiredEnterpriseWalletStatus {
    return SgvJson.from.simple(json, ExpiredEnterpriseWalletStatus, {
      reason: WalletExpiredReason.fromJson(json?.reason)
    });
  }
}

export abstract class WalletExpiredReason implements JsonEntity {
  get isExpiredReason(): boolean {
    return this instanceof FreeCreditExpiredReason;
  }

  get isOtherReason(): boolean {
    return this instanceof OtherExpiredReason;
  }

  abstract toJson(): any;

  static fromJson(json: any): OtherExpiredReason {
    if (json?.type) {
      switch (json.type) {
        case OtherExpiredReason.TYPE:
          return OtherExpiredReason.fromJson(json);
        case FreeCreditExpiredReason.TYPE:
          return FreeCreditExpiredReason.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class OtherExpiredReason extends WalletExpiredReason {
  static TYPE = 'other';

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: OtherExpiredReason.TYPE
    });
  }

  static fromJson(json: any): OtherExpiredReason {
    return SgvJson.from.simple(json, OtherExpiredReason);
  }
}

export class FreeCreditExpiredReason extends WalletExpiredReason {
  static TYPE = 'freeCreditExpired';

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: FreeCreditExpiredReason.TYPE
    });
  }

  static fromJson(json: any): FreeCreditExpiredReason {
    return SgvJson.from.simple(json, FreeCreditExpiredReason);
  }
}
