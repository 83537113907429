import { SgvJson } from '@eceos/arch';
import { CalculatedValue } from '../../../nfes/calculated-value';
import {
  PisValueWithAliquot,
  PisValueWithAliquotPercent,
  PisValueWithAliquotValue
} from '../../../nfes/tax/pis/pis-value-with-aliquot';
import {
  PisValueWithBcAliquot,
  PisValueWithBcAliquotPercent,
  PisValueWithBcAliquotValue
} from '../../../nfes/tax/pis/pis-value-with-bc-aliquot';
import { NFeItem } from '../../../nfes/nfe-item';

export abstract class PisWithAliquot {
  constructor(public aliquot: number = null) {}

  abstract toAliquot(): PisValueWithAliquot;

  abstract toBcAliquot(nfeItem: NFeItem): PisValueWithBcAliquot;

  abstract toJson(): any;

  static fromJson(json: any): PisWithAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'percent':
          return PisWithAliquotPercent.fromJson(json);
        case 'value':
          return PisWithAliquotValue.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class PisWithAliquotPercent extends PisWithAliquot {
  constructor(aliquot: number = null, public bcFormula = '') {
    super(aliquot);
  }

  toAliquot(): PisValueWithAliquotPercent {
    return new PisValueWithAliquotPercent(this.aliquot, new CalculatedValue());
  }

  toBcAliquot(nfeItem: NFeItem): PisValueWithBcAliquotPercent {
    return new PisValueWithBcAliquotPercent(
      this.aliquot,
      new CalculatedValue(),
      new CalculatedValue(),
      this.bcFormula
      ).publishValues(nfeItem);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'percent' });
  }

  static fromJson(json: any): PisWithAliquotPercent {
    return json ? SgvJson.from.simple(json, PisWithAliquotPercent) : null;
  }
}

export class PisWithAliquotValue extends PisWithAliquot {
  constructor(aliquot: number = null) {
    super(aliquot);
  }

  toAliquot(): PisValueWithAliquotValue {
    return new PisValueWithAliquotValue(this.aliquot).publishValues();
  }

  toBcAliquot(): PisValueWithBcAliquotValue {
    return new PisValueWithBcAliquotValue(this.aliquot).publishValues();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'value' });
  }

  static fromJson(json: any): PisWithAliquotValue {
    return json ? SgvJson.from.simple(json, PisWithAliquotValue) : null;
  }
}
