import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { ZipCode } from './zipCode';

@Injectable({ providedIn: 'root' })
export class ZipCodesRepository extends ReadonlyRepositoryImpl<ZipCode, ZipCode> {
  constructor(api: ApiService) {
    super(api.manager.path('zipCodes'), ZipCode, ZipCode);
  }
}
