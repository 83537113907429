<mat-form-field class="auto-width">
  <mat-label>Recorre a cada</mat-label>
  <input
    matInput
    type="number"
    min="1"
    [(ngModel)]="entity.recurrences"
    (ngModelChange)="onRecurrencesChange()"
    [disabled]="disabled"
    [required]="required"
  />
</mat-form-field>
<mat-form-field class="auto-width">
  <mat-label>Periodicidade</mat-label>
  <mat-select
    [(ngModel)]="type"
    (ngModelChange)="onTypeChange()"
    [disabled]="disabled"
    [required]="required"
  >
    <mat-option *ngFor="let type of types" [value]="type">{{ type.label }}</mat-option>
  </mat-select>
</mat-form-field>
