<fieldset>
  <section>
    <mat-form-field class="discountType">
      <mat-label>Tipo de Desconto</mat-label>
      <mat-select
        [(ngModel)]="selectedDiscountFormType"
        (ngModelChange)="onSelectedDiscountFormTypeChange($event)"
        [disabled]="disabled"
      >
        <mat-option
          *ngFor="let discountFormType of discountFormTypes; trackBy: trackByValueName"
          [value]="discountFormType"
        >
          {{ discountFormType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="form">
      <ng-container *ngIf="isValueDiscount">
        <mat-form-field>
          <mat-label>Valor do Desconto</mat-label>
          <input matInput currencyMask [formControl]="form" />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="isPercentDiscount">
        <app-percent-input [formControl]="form" [options]="{ nullable: false }">
          Percentual de Desconto
        </app-percent-input>
        <mat-form-field>
          <mat-label>Valor de Desconto</mat-label>
          <input matInput currencyMask readonly disabled [ngModel]="value" />
        </mat-form-field>
      </ng-container>
    </ng-container>
  </section>
</fieldset>
