import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthManagerService, LOGIN_ROUTE, ManagerToken } from '@eceos/arch';

@Component({
  selector: 'app-sign-up-confirm-email',
  templateUrl: './sign-up-confirm-email.component.html',
  styleUrls: ['./sign-up-confirm-email.component.scss']
})
export class SignUpConfirmEmailComponent implements OnInit {
  email = '';

  constructor(
    private router: Router,
    private authManagerService: AuthManagerService,
  ) {}

  onLogin() {
    const queryParams = this.email ? { username: this.email } : {};
    this.router.navigate(["/login"], { queryParams: queryParams });
  }

  ngOnInit() {
    
    this.email = ManagerToken.decode(this.authManagerService.currentToken).email;
  }
}
