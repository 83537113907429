<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <ng-container *ngIf="completed; then confirmEmail; else signUpForm"></ng-container>

  <ng-template #confirmEmail>
    <app-confirm-email-card [email]="leadEmail" [onLogin]="toLoginFn"></app-confirm-email-card>
  </ng-template>

  <ng-template #signUpForm>
    <mat-card class="signUpForm">
      <mat-card-title> Cadastre-se </mat-card-title>
      <mat-card-subtitle>
        <small> Já possui cadastro? <a routerLink="/login">Entrar</a> </small>
      </mat-card-subtitle>
      <mat-card-content>
        <mat-horizontal-stepper linear="true" #stepper [formGroup]="form" *ngIf="form" thin>
          <mat-step [stepControl]="emailGroup" editable="false">
            <ng-template matStepLabel>E-mail</ng-template>
            <form [formGroup]="emailGroup" (ngSubmit)="emailNextBtn.onClick()">
              <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput type="email" formControlName="email" />
                <mat-error *ngIf="emailGroup.get('email').hasError('required')">
                  Por favor informe seu e-mail.
                </mat-error>
                <mat-error *ngIf="emailGroup.get('email').hasError('email')">
                  E-mail informado não é válido.
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Confirmar e-mail</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="confirmEmail"
                  [errorStateMatcher]="errorStateMatcher"
                />
                <mat-error *ngIf="emailGroup.get('confirmEmail').hasError('required')">
                  Por favor confirme seu e-mail.
                </mat-error>
                <mat-error *ngIf="emailGroup.get('confirmEmail').hasError('email')">
                  E-mail informado não é válido.
                </mat-error>
                <mat-error *ngIf="emailGroup.hasError('unmatching')">
                  Os e-mails não coincidem.
                </mat-error>
              </mat-form-field>
              <button
                #emailNextBtn="asyncAction"
                mat-raised-button
                color="accent"
                [disabled]="emailGroup.invalid || emailNextBtn.running || emailGroup.disabled"
                [asyncAction]="emailFormCompleteAction.onAction"
              >
                {{
                  emailNextBtn.running
                    ? emailFormCompleteAction.runningName
                    : emailFormCompleteAction.name
                }}
              </button>
            </form>
          </mat-step>
          <mat-step [stepControl]="generalDataGroup">
            <ng-template matStepLabel>Dados Gerais</ng-template>
            <form [formGroup]="generalDataGroup">
              <div formGroupName="name" class="name">
                <mat-form-field class="firstName">
                  <mat-label>Nome</mat-label>
                  <input matInput formControlName="first" />
                  <mat-error *ngIf="nameGroup.get('first').hasError('required')">
                    Por favor informe seu nome.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="lastName">
                  <mat-label>Sobrenome</mat-label>
                  <input matInput formControlName="last" />
                  <mat-error *ngIf="nameGroup.get('last').hasError('required')">
                    Por favor informe seu Sobrenome.
                  </mat-error>
                </mat-form-field>
              </div>

              <mat-form-field>
                <mat-label>Telefone</mat-label>
                <input
                  matInput
                  type="tel"
                  formControlName="phone"
                  [kzMask]="['(99) 9999-9999', '(99) 99999-9999']"
                />
                <mat-error *ngIf="generalDataGroup.get('phone').hasError('required')">
                  Por favor informe seu telefone.
                </mat-error>
              </mat-form-field>
              <button
                mat-raised-button
                color="accent"
                matStepperNext
                [disabled]="generalDataGroup.invalid"
                (click)="onGeneralDataNextClick()"
              >
                Próximo
              </button>
            </form>
          </mat-step>
          <mat-step [stepControl]="passwordGroup">
            <ng-template matStepLabel>Senha</ng-template>
            <form [formGroup]="passwordGroup">
              <mat-form-field>
                <mat-label>Senha</mat-label>
                <input matInput [type]="passwordType.type" formControlName="password" />
                <password-visibility-toggle matSuffix #passwordType></password-visibility-toggle>
                <mat-error *ngIf="passwordGroup.get('password').hasError('required')">
                  Por favor informe sua senha.
                </mat-error>
                <mat-error *ngIf="passwordGroup.get('password').hasError('minlength')">
                  Sua senha deve conter ao menos 6 digitos.
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Confirmar Senha</mat-label>
                <input
                  matInput
                  [type]="confirmPasswordType.type"
                  formControlName="confirmPassword"
                  [errorStateMatcher]="errorStateMatcher"
                />
                <password-visibility-toggle
                  matSuffix
                  #confirmPasswordType
                ></password-visibility-toggle>
                <mat-error *ngIf="passwordGroup.get('confirmPassword').hasError('required')">
                  Por favor confirme sua senha.
                </mat-error>
                <mat-error *ngIf="passwordGroup.hasError('unmatching')">
                  As senhas não coincidem.
                </mat-error>
              </mat-form-field>
            </form>
            <mat-checkbox class="termsOfUse" formControlName="acceptTermsAndPolicy">
              <small>
                Li e estou de acordo com os <a [href]="termsOfUse">Termos de Uso</a> e
                <a [href]="privacyPolicy">Políticas de Privacidade</a>.
              </small>
            </mat-checkbox>
            <button
              #asyncActionButton="asyncAction"
              mat-raised-button
              class="ok-btn"
              matStepperNext
              [disabled]="form.invalid || asyncActionButton.running || form.disabled"
              [asyncAction]="completeAction.onAction"
            >
              {{ asyncActionButton.running ? completeAction.runningName : completeAction.name }}
            </button>
          </mat-step>
        </mat-horizontal-stepper>
      </mat-card-content>
    </mat-card>
  </ng-template>
</section>
