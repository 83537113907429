import { SgvJson } from '@eceos/arch';

export class IbptProduct {
  constructor(
    public key: string = null,
    public code: string = null,
    public uf: string = null,
    public ex: number = null,
    public description: string = null,
    public nationalTax: number = 0,
    public stateTax: number = 0,
    public importTax: number = 0,
    public source: string = null,
    public cityTax: number = 0,
  ) { }
  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, IbptProduct);
  }
}