<ng-container *ngFor="let contact of valueWithNew">
  <mat-form-field>
    <a *ngIf="disabled" matPrefix mat-button href="mailto:{{contact.value}}" target="_blank"><mat-icon >email</mat-icon></a>
    <input
      matInput
      type="email"
      placeholder="Adicionar e-mail"
      [ngModel]="contact.value"
      (ngModelChange)="onChange($event, contact)"
      [disabled]="disabled"
    />
    <button *ngIf="!disabled && contact.value" matSuffix mat-button type="button" (click)="remove(contact)" tabindex="-1"><mat-icon>close</mat-icon></button>
  </mat-form-field>
</ng-container>
<span *ngIf="valueWithNew.length === 0" class="emptyMessage"> Nenhum e-mail registrado </span>
