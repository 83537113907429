import { JsonEntity, SgvJson } from '@eceos/arch';
import { RateDiscount } from './rate-discount';
import { RateFine } from './rate-fine';
import { RateInterestArrears } from './rate-interest-arrears';

export abstract class Rate implements JsonEntity {

  abstract toJson(): any;

  static fromJson(data: any): Rate {
    switch (data.type) {
      case 'interestArrears':
        return SgvJson.from.simple(data, RateInterestArrears, {
          interestArrears: RateInterestArrears.fromJson(data.interestArrears)
        });
      case 'fine':
        return SgvJson.from.simple(data, RateFine, {
          fine: RateFine.fromJson(data.fine)
        });
      case 'discount':
        return SgvJson.from.simple(data, RateDiscount, {
          discount: RateDiscount.fromJson(data.discount)
        });
    }
  }
}
