import { SgvJson } from '@eceos/arch';
import { TimeInterval } from '../time-interval';
import { Discount } from './discount';

export class FixedValueDiscount extends Discount {

  constructor(timeInterval?: TimeInterval) {
    super(0.0, timeInterval, "fixed");
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): FixedValueDiscount {
    return SgvJson.from.simple(data, FixedValueDiscount, {
      timeInterval: Discount.parseInterval(data.timeInterval)
    });
  }
}
