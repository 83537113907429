import { SgvJson } from '@eceos/arch';

export abstract class BillingPaymentForm {
  abstract toJson(): any;

  static fromJson(json: any): BillingPaymentForm {
    if (json && json.type) {
      switch (json.type) {
        case 'bankSlip':
          return BankSlipBillingPaymentForm.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class BankSlipBillingPaymentForm extends BillingPaymentForm {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'bankSlip' });
  }

  static fromJson(json: any): BankSlipBillingPaymentForm {
    return json ? SgvJson.from.simple(json, BankSlipBillingPaymentForm) : null;
  }
}
