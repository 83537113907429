import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'dicionary'
})
export class DicionaryActionPipe implements PipeTransform {
  private actions = new Map();

  constructor() {
    this.actions
      .set('INSERT', 'Inserir')
      .set('UPDATE', 'Editar')
      .set('DELETE_TEMPORARY', 'Deletar temporariamente')
      .set('DELETE', 'Deletar');
  }

  transform(inputValue: string) {
    const keys = Array.from(this.actions.keys());
    const values = Array.from(this.actions.values());

    const limit = keys.length;
    for (let i = 0; i < limit; i++) {
      if (keys[i] === inputValue) return values[i];
    }
  }
}
