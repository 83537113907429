import { SgvId, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { IpiCst } from './csts/ipi-cst';
import { IpiValueWithBcAliquot, IpiValueWithBcAliquotHolder } from './ipi-value-with-bc-aliquot';

export abstract class IPI {
  constructor(
    readonly id: string = SgvId.gen(),
    public cst: IpiCst = null,
    public legalFramework: string = null,
    public ipiSealCode: string = null,
    public producerCNPJ: string = null,
    public ipiFrameworkClass: string = null,
    public ipiSealQuantity: number = null
  ) {}

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): IPI {
    if (json && json.type) {
      switch (json.type) {
        case 'TaxableIpi':
          return TaxableIPI.fromJson(json);
        case 'NonTaxableIpi':
          return NonTaxableIPI.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class TaxableIPI extends IPI implements IpiValueWithBcAliquotHolder {
  constructor(
    id = SgvId.gen(),
    cst: IpiCst = null,
    legalFramework: string = null,
    ipiSealCode: string = null,
    producerCNPJ: string = null,
    ipiFrameworkClass: string = null,
    ipiSealQuantity: number = null,
    public ipi: IpiValueWithBcAliquot = null
  ) {
    super(id, cst, legalFramework, ipiSealCode, producerCNPJ, ipiFrameworkClass, ipiSealQuantity);
  }

  isValid(): boolean {
    return (
      this.cst != null && this.legalFramework != null && EceosValidatorsUtils.isValid(this.ipi)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'TaxableIpi',
      cst: this.cst ? this.cst.toJson() : null,
      ipi: this.ipi.toJson()
    });
  }

  static fromJson(json: any): TaxableIPI {
    return json
      ? SgvJson.from.simple(json, TaxableIPI, {
          cst: IpiCst.fromJson(json.cst),
          ipi: IpiValueWithBcAliquot.fromJson(json.ipi)
        })
      : null;
  }
}

export class NonTaxableIPI extends IPI {
  constructor(
    id = SgvId.gen(),
    cst: IpiCst = null,
    legalFramework: string = null,
    ipiSealCode: string = null,
    producerCNPJ: string = null,
    ipiFrameworkClass: string = null,
    ipiSealQuantity: number = null
  ) {
    super(id, cst, legalFramework, ipiSealCode, producerCNPJ, ipiFrameworkClass, ipiSealQuantity);
  }

  isValid(): boolean {
    return this.cst != null && this.legalFramework != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'NonTaxableIpi',
      cst: this.cst ? this.cst.toJson() : null
    });
  }

  static fromJson(json: any): NonTaxableIPI {
    return json
      ? SgvJson.from.simple(json, NonTaxableIPI, {
          cst: IpiCst.fromJson(json.cst)
        })
      : null;
  }
}
