import { SgvJson } from '@eceos/arch';

export interface IcmsWithFcpStRetainedValueHolder {
  fcpStRetained: IcmsWithFcpStRetainedValue;
}

export class IcmsWithFcpStRetainedValue {
  constructor(
    public bcValue: number = null,
    public aliquot: number = null,
    public value: number = null
  ) {}

  isValid(): boolean {
    return this.bcValue != null && this.aliquot != null && this.value != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsWithFcpStRetainedValue {
    return json ? SgvJson.from.simple(json, IcmsWithFcpStRetainedValue) : null;
  }
}
