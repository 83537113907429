import { EceosValidatorsUtils } from '@eceos/common-utils';
import { SgvJson } from '@eceos/arch';
import { Uf } from '../../../core';
import { NFeFuelAnp } from './nfe-fuel-anp';
import { NFeFuelCide } from './nfe-fuel-cide';
import { NFeFuelClosing } from './nfe-fuel-closing';
import { NFeFuelOrigin } from "./nfe-fuel-origin";

export class NFeFuel {
  constructor(
    public anp: NFeFuelAnp = null,
    public codif: string = null,
    public temperature: number = null,
    public consumerUf: Uf = null,
    public cide: NFeFuelCide = null,
    public closing: NFeFuelClosing = null,
    public origins: NFeFuelOrigin[] = []
  ) {
  }

  isValid(): boolean {
    return (
      this.consumerUf != null &&
      EceosValidatorsUtils.isValid(this.anp) &&
      EceosValidatorsUtils.isValidOptional(this.cide) &&
      EceosValidatorsUtils.isValidOptional(this.closing) &&
      EceosValidatorsUtils.listIsValidOptional(this.origins, NFeFuelOrigin.fromJson)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      anp: this.anp.toJson(),
      consumerUf: this.consumerUf.toJson(),
      cide: this.cide ? this.cide.toJson() : null,
      closing: this.closing ? this.closing.toJson() : null,
      origins: SgvJson.to.array(
        this.origins.map(it => NFeFuelOrigin.fromJson(it))
      )
    });
  }

  static fromJson(json: any): NFeFuel {
    return json
      ? SgvJson.from.simple(json, NFeFuel, {
        anp: NFeFuelAnp.fromJson(json.anp),
        consumerUf: Uf.fromJson(json.consumerUf),
        cide: NFeFuelCide.fromJson(json.cide),
        closing: NFeFuelClosing.fromJson(json.closing),
        origins: SgvJson.from.array(json.origins, NFeFuelOrigin.fromJson)
      })
      : null;
  }
}
