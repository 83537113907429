import { debounceTime } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnterprisesRepository, ExpiredEnterpriseWalletStatus } from '@eceos/manager-domain';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html',
  styleUrls: ['./inactive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InactiveComponent implements OnInit {
  loading = false;

  inactiveReason = InactiveReason.DEFAULT;

  private tenantId?: string;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private enterprisesRepository: EnterprisesRepository
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.tenantId = params['tenant'];
    });
  }

  get isDefaultReason(): boolean {
    return this.inactiveReason == InactiveReason.DEFAULT;
  }

  get isFreeCreditExpiredReason(): boolean {
    return this.inactiveReason == InactiveReason.FREE_CREDIT_EXPIRED;
  }

  async ngOnInit() {
    if (this.tenantId) {
      this.loading = true;
      this.changeDetector.markForCheck();
      try {
        const enterprise = await lastValueFrom(this.enterprisesRepository.find(this.tenantId));
        const status = enterprise.wallet.status as ExpiredEnterpriseWalletStatus;
        if (status?.reason?.isExpiredReason) {
          this.inactiveReason = InactiveReason.FREE_CREDIT_EXPIRED;
        } else {
          this.inactiveReason = InactiveReason.DEFAULT;
        }
      } finally {
        this.loading = false;
        this.changeDetector.markForCheck();
      }
    }
  }
}

class InactiveReason {
  static DEFAULT = new InactiveReason('DEFAULT');
  static FREE_CREDIT_EXPIRED = new InactiveReason('FREE_CREDIT_EXPIRED');
  private constructor(readonly id: string) {}
}
