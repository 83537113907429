import { SgvId, SgvJson } from '@eceos/arch';
import { PisCst } from '../../../nfes/tax/pis/csts/pis-cst';
import { AliquotPIS, NonTaxablePIS, OtherPIS, PIS, QuantityPIS } from '../../../nfes/tax/pis/pis';
import { PisWithAliquot, PisWithAliquotPercent, PisWithAliquotValue } from './pis-with-aliquot';
import { NFeItem } from '../../../nfes/nfe-item';

export abstract class PISConfig {
  constructor(readonly id: string = SgvId.gen(), public cst: PisCst = null) {}

  abstract toPIS(nfeItem: NFeItem): PIS;

  abstract toJson(): any;

  static fromJson(json: any): PISConfig {
    if (json && json.type) {
      switch (json.type) {
        case 'AliquotPisConfig':
          return AliquotPISConfig.fromJson(json);
        case 'NonTaxablePisConfig':
          return NonTaxablePISConfig.fromJson(json);
        case 'OtherPisConfig':
          return OtherPISConfig.fromJson(json);
        case 'QuantityPisConfig':
          return QuantityPISConfig.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class AliquotPISConfig extends PISConfig {
  constructor(id = SgvId.gen(), cst: PisCst = null, public aliquot: PisWithAliquotPercent = null) {
    super(id, cst);
  }

  toPIS(nfeItem: NFeItem): AliquotPIS {
    return new AliquotPIS(
      SgvId.gen(),
      this.cst,
      this.aliquot ? this.aliquot.toBcAliquot(nfeItem) : null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'AliquotPisConfig',
      cst: this.cst ? this.cst.toJson() : null,
      aliquot: this.aliquot ? this.aliquot.toJson() : null
    });
  }

  static fromJson(json: any): AliquotPISConfig {
    return json
      ? SgvJson.from.simple(json, AliquotPISConfig, {
          cst: PisCst.fromJson(json.cst),
          aliquot: PisWithAliquotPercent.fromJson(json.aliquot)
        })
      : null;
  }
}

export class NonTaxablePISConfig extends PISConfig {
  constructor(id = SgvId.gen(), cst: PisCst = null) {
    super(id, cst);
  }

  toPIS(): NonTaxablePIS {
    return new NonTaxablePIS(SgvId.gen(), this.cst);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'NonTaxablePisConfig',
      cst: this.cst ? this.cst.toJson() : null
    });
  }

  static fromJson(json: any): NonTaxablePISConfig {
    return json
      ? SgvJson.from.simple(json, NonTaxablePISConfig, { cst: PisCst.fromJson(json.cst) })
      : null;
  }
}

export class OtherPISConfig extends PISConfig {
  constructor(id = SgvId.gen(), cst: PisCst = null, public aliquot: PisWithAliquot = null) {
    super(id, cst);
  }

  toPIS(nfeItem: NFeItem): OtherPIS {
    return new OtherPIS(
      SgvId.gen(),
      this.cst,
      this.aliquot ? this.aliquot.toBcAliquot(nfeItem) : null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'OtherPisConfig',
      cst: this.cst ? this.cst.toJson() : null,
      aliquot: this.aliquot ? this.aliquot.toJson() : null
    });
  }

  static fromJson(json: any): OtherPISConfig {
    return json
      ? SgvJson.from.simple(json, OtherPISConfig, {
          cst: PisCst.fromJson(json.cst),
          aliquot: PisWithAliquot.fromJson(json.aliquot)
        })
      : null;
  }
}

export class QuantityPISConfig extends PISConfig {
  constructor(id = SgvId.gen(), cst: PisCst = null, public aliquot: PisWithAliquotValue = null) {
    super(id, cst);
  }

  toPIS(): QuantityPIS {
    return new QuantityPIS(SgvId.gen(), this.cst, this.aliquot ? this.aliquot.toAliquot() : null);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'QuantityPisConfig',
      cst: this.cst ? this.cst.toJson() : null,
      aliquot: this.aliquot ? this.aliquot.toJson() : null
    });
  }

  static fromJson(json: any): QuantityPISConfig {
    return json
      ? SgvJson.from.simple(json, QuantityPISConfig, {
          cst: PisCst.fromJson(json.cst),
          aliquot: PisWithAliquotValue.fromJson(json.aliquot)
        })
      : null;
  }
}
