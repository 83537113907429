import { Pipe, PipeTransform } from '@angular/core';
import { Action } from '@eceos/arch';
import { Permission, AvailableFeature } from '@eceos/domain';

interface FeatureAndAction {
  name: string;
  canAccess: boolean;
  actions: Action[];
}

enum Description {
  ACCESS = 'Acesso Total',
  PARTIALACCESS = 'Acesso Parcial',
  ONLYREAD = 'Apenas Leitura',
  NOACCESS = 'Sem Permisão'
}

@Pipe({
  name: 'profileDescription',
  pure: false
})
export class ProfileDescriptionPipe implements PipeTransform {
  constructor() {}

  transform(value: Permission, enterprisePermission: AvailableFeature) {
    if (value && enterprisePermission && value.feature === enterprisePermission.feature) {
      if (!value.canAccess) {
        return Description.NOACCESS;
      }
      if (value.allowedActions.length === 0) {
        return Description.ONLYREAD;
      }
      if (value.canDo(...enterprisePermission.allowedActions)) {
        return Description.ACCESS;
      }
      if (enterprisePermission.allowedActions.some(a => value.canDo(a))) {
        return Description.PARTIALACCESS;
      }
    }
    return '';
  }
}
