import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@eceos/common-pages';
import { environment } from '../environments/environment';
import { ManagerGuard } from './core/guards/manager/manager.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'manager',
    loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
    canActivate: [ManagerGuard],
    canActivateChild: [ManagerGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'e-Ceos - Página não encontrada' }
  }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy',
    enableTracing: environment.traceRouter
});
