import { SgvJson } from '@eceos/arch';

export class CalculatedPercent {
  constructor(public value: number = 0, public calculated: boolean = true) {}

  isValid(): boolean {
    return this.value != null && this.value >= 0;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): CalculatedPercent {
    return json ? SgvJson.from.simple(json, CalculatedPercent) : null;
  }
}
