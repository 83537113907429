import { DomainEntity, SgvId } from '@eceos/arch';
import { TimeInterval } from '../time-interval';

export abstract class Discount implements DomainEntity {

  readonly id = SgvId.gen();

  constructor(
    public value = 0.0,
    public timeInterval = TimeInterval.PER_DAY,
    public type = ''
  ) { }

  abstract toJson(): any;

  protected static parseInterval(ti: string): TimeInterval {
    switch (ti) {
      case 'PER_DAY':
        return TimeInterval.PER_DAY;
      case 'PER_MONTH':
        return TimeInterval.PER_MONTH;
      default: return TimeInterval.PER_DAY;
    }
  }
}
