<app-layout-base
  [fillPrimary]="fillPrimary"
  [pageTitle]="pageTitle"
  [showSearch]="showSearch"
  [searchPlaceholder]="searchPlaceholder"
  [showMenu]="showMenu"
  [showBack]="showBack"
  [homePath]="homePath"
  (searchChange)="searchChange.emit($event)"
>
  <ng-container menu>
    <ng-content select="[menu]"></ng-content>
  </ng-container>

  <ng-container pageTitle>
    <ng-content select="[pageTitle]"></ng-content>
  </ng-container>

  <ng-container actions>
    <ng-content select="[actions]"></ng-content>
    <div class="defaultActions" *ngIf="withDefaultButtons">
      <button
        mat-icon-button
        [routerLink]="['/manager', 'user', 'current']"
        matTooltip="Meu perfil"
        aria-label="Meu perfil"
      >
        <mat-icon svgIcon="user"></mat-icon>
      </button>
      <button mat-icon-button (click)="doLogout()" matTooltip="Sair" aria-label="Sair">
        <mat-icon svgIcon="sign-out-alt"></mat-icon>
      </button>
    </div>
  </ng-container>
  <ng-content></ng-content>
</app-layout-base>
