import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { FreightModality } from './freight-modality';

@Injectable({ providedIn: 'root' })
export class FreightModalitiesRepository extends ReadonlyRepositoryImpl<
  FreightModality,
  FreightModality
> {
  constructor(api: ApiService) {
    super(api.root.path('/sales/freight/modalities'), FreightModality, FreightModality);
  }
}
