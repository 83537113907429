<mat-drawer-container *ngIf="permission" [hasBackdrop]="false">
  <mat-drawer #drawerLeft position="start" [mode]="isMobileScreen ? 'over' : 'side'">
    <ng-content select="[drawerLeft]"></ng-content>
  </mat-drawer>
  <mat-drawer-content>
    <ng-content></ng-content>
    <button
      mat-fab
      *ngIf="showDefaultFab && userCanInsert"
      class="floatDefault"
      color="accent"
      (click)="newEntity()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-drawer-content>
  <mat-drawer #drawerRight position="end" [mode]="isMobileScreen ? 'over' : 'side'">
    <ng-content select="[drawerRight]"></ng-content>
  </mat-drawer>
</mat-drawer-container>
