<section [formGroup]="form">
  <mat-form-field class="zipCode auto-width" >
    <mat-label>CEP</mat-label>
    <input matInput [matAutocomplete]="auto" formControlName="zipCodeNumber" (keyup)="zipCodeKeyUp($event)" (blur)="zipCodeBlur($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="zipCodeSelect($event)">
      <mat-option *ngFor="let zipCode of (zipCodes$ | async)" [value]="zipCode"> {{ zipCode.zipCode }} </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <app-data-selector class="city" label="Município" [repository]="citiesRepository" display="nameWithUf" formControlName="city"> </app-data-selector>
  <mat-form-field><mat-label>Bairro</mat-label> <input matInput formControlName="district" /> </mat-form-field>
  <mat-form-field class="street"><mat-label>Rua</mat-label> <input matInput formControlName="street" /> </mat-form-field>
  <mat-form-field class="number"><mat-label>Número</mat-label> <input #numberInput matInput formControlName="number" /> </mat-form-field>
  <mat-form-field><mat-label>Complemento</mat-label> <input matInput formControlName="details" /> </mat-form-field>
</section>
