import { SgvJson } from "@eceos/arch";
import { ProductOrigin, Uf } from "@eceos/domain";

export class NFeFuelOrigin {

  constructor(
    public id: string = null,
    public importIndicator: ProductOrigin = null,
    public originUf: Uf = null,
    public percentageOriginatedFromUf: string = null
  ) {
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  isValid(): boolean {
    return this.importIndicator != null &&
      this.originUf != null &&
      this.percentageOriginatedFromUf != null;
  }

  static fromJson(json: any): NFeFuelOrigin {
    return SgvJson.from.simple(json, NFeFuelOrigin);
  }

}
