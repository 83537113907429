import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Name } from '../name/name';
import { Lead } from './leads/lead';

export class User implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public name = new Name(),
    public phone = '',
    public email = '',
    public password = '',
    public creation = new Date(),
    public isConfirmed = false,
    public isComplete = false,
    public isAdmin = false
  ) {}

  isValid(): boolean {
    return [this.name.full, this.phone, this.email]
      .map((it) => it.trim())
      .every((it) => it.length > 0);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      name: this.name.toJson()
    });
  }

  static fromJson(json: any): User {
    return json
      ? SgvJson.from.simple(json, User, {
          name: Name.fromJson(json.name)
        })
      : null;
  }

  static fromEmail(email: string) {
    return new User(SgvId.gen(), new Name(), '', email || '', '');
  }
}

export class UserFromLead extends User {
  constructor(
    readonly id = SgvId.gen(),
    name = new Name(),
    phone = '',
    email = '',
    password = '',
    creation = new Date(),
    isConfirmed = false,
    isComplete = false,
    isAdmin = false,
    public lead: Lead = null
  ) {
    super(id, name, phone, email, password, creation, isConfirmed, isComplete, isAdmin);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      name: this.name.toJson(),
      lead: this.lead.toJson()
    });
  }

  static fromJson(json: any): User {
    return json
      ? SgvJson.from.simple(json, UserFromLead, {
          name: Name.fromJson(json.name),
          lead: Lead.fromJson(json.lead)
        })
      : null;
  }
}
