import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { IcmsWithBcValueHolder } from '@eceos/domain';
import { FormulaVariable } from '../../../core/formula-executor/formula-executor';
import { CFOP } from '../../cfops/cfop';
import { BcVariable } from '../../tax/bc-variable';
import { NFeItem } from '../nfe-item';
import { COFINS } from './cofins/cofins';
import { IcmsWithBcValue } from './icms';
import { IcmsUfReceiver } from './icms-uf-receiver/icms-uf-receiver';
import { ICMS } from './icms/icms';
import { II } from './ii/ii';
import { IPI, TaxableIPI } from './ipi/ipi';
import { PIS } from './pis/pis';

export class NFeItemTax {
  constructor(
    public cfop: CFOP = null,
    public taxes: NFeFiscalTax = new NFeFiscalTax(),
    public approximateTax: ApproximateTax = new ApproximateTax()
  ) {}

  isValid(): boolean {
    return this.cfop != null && EceosValidatorsUtils.isValid(this.taxes);
  }

  toJson() {
    return SgvJson.to.simple(this, {
      cfop: this.cfop ? this.cfop.toJson() : null,
      taxes: this.taxes ? this.taxes.toJson() : null,
      approximateTax: this.approximateTax ? this.approximateTax.toJson() : null
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, NFeItemTax, {
      cfop: CFOP.fromJson(json.cfop),
      taxes: NFeFiscalTax.fromJson(json.taxes),
      approximateTax: ApproximateTax.fromJson(json.approximateTax)
    });
  }
}

export class NFeFiscalTax {
  constructor(
    public icms: ICMS = null,
    public pis: PIS = null,
    public cofins: COFINS = null,
    public ipi: IPI = null,
    public ii: II = null,
    public icmsUfReceiver: IcmsUfReceiver = null
  ) {}

  isValid(): boolean {
    return (
      EceosValidatorsUtils.isValid(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.pis) &&
      EceosValidatorsUtils.isValidOptional(this.cofins) &&
      EceosValidatorsUtils.isValidOptional(this.ipi) &&
      EceosValidatorsUtils.isValidOptional(this.ii) &&
      EceosValidatorsUtils.isValidOptional(this.icmsUfReceiver)
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      icms: this.icms ? this.icms.toJson() : null,
      pis: this.pis ? this.pis.toJson() : null,
      cofins: this.cofins ? this.cofins.toJson() : null,
      ipi: this.ipi ? this.ipi.toJson() : null,
      ii: this.ii ? this.ii.toJson() : null,
      icmsUfReceiver: this.icmsUfReceiver ? this.icmsUfReceiver.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, NFeFiscalTax, {
          icms: ICMS.fromJson(json.icms),
          pis: PIS.fromJson(json.pis),
          cofins: COFINS.fromJson(json.cofins),
          ipi: IPI.fromJson(json.ipi),
          ii: II.fromJson(json.ii),
          icmsUfReceiver: IcmsUfReceiver.fromJson(json.icmsUfReceiver)
        })
      : null;
  }

  static buildBcVariables(item: NFeItem): Map<FormulaVariable, number> {
    const pisValue =
      item.tax && item.tax.taxes && item.tax.taxes.pis ? item.tax.taxes.pis.value : null;
    const cofinsValue =
      item.tax && item.tax.taxes && item.tax.taxes.cofins ? item.tax.taxes.cofins.value : null;
    const ipiValue =
      item.tax && item.tax.taxes && item.tax.taxes.ipi && item.tax.taxes.ipi instanceof TaxableIPI
        ? (item.tax.taxes.ipi as TaxableIPI).ipi
        : null;
    const icms =
      item.tax &&
      item.tax.taxes &&
      item.tax.taxes.icms &&
      IcmsWithBcValue.isIcmsWithBcValueHolder(item.tax.taxes.icms)
        ? (item.tax.taxes.icms as IcmsWithBcValueHolder).icms
        : null;
    return new Map([
      [BcVariable.UNITARY_VALUE, item.unitaryValue],
      [BcVariable.QUANTITY, item.amount],
      [BcVariable.FREIGHT_VALUE, item.freight],
      [BcVariable.INSURANCE_VALUE, item.insurance],
      [BcVariable.OTHERS_VALUE, item.othersValue],
      [BcVariable.DISCOUNT_VALUE, item.discount],
      [BcVariable.PIS_VALUE, pisValue ? pisValue : 0],
      [BcVariable.COFINS_VALUE, cofinsValue ? cofinsValue : 0],
      [BcVariable.II_CUSTOM_CHARGE_VALUE, item.tax.taxes.ii ? item.tax.taxes.ii.customCharge : 0],
      [
        BcVariable.IPI_VALUE,
        ipiValue && ipiValue.value && ipiValue.value.value ? ipiValue.value.value : 0
      ],
      [BcVariable.ICMS_ALIQUOT, icms && icms.aliquot ? icms.aliquot / 100 : 0]
    ]);
  }

  static calcValueBy(bcValue: number, aliquot: number): number {
    return bcValue !== null && aliquot !== null ? bcValue * (aliquot / 100) : null;
  }
}

export class ApproximateTax {
  constructor(
    public nationalTax: number = 0,
    public stateTax: number = 0,
    public importTax: number = 0,
    public cityTax: number = 0
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, ApproximateTax);
  }
}
