import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedValue } from '../../calculated-value';

export interface IcmsWithFcpValueHolder {
  fcp: IcmsWithFcpValue;
}

export class IcmsWithFcpValue {
  constructor(
    public aliquot: number = null,
    public bcValue: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  isValid(): boolean {
    return this.bcValue != null && this.aliquot != null && EceosValidatorsUtils.isValid(this.value);
  }

  toJson() {
    return SgvJson.to.simple(this, {
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, IcmsWithFcpValue, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }

  static fromAliquot(aliquot: number) {
    return aliquot ? new IcmsWithFcpValue(aliquot, null, new CalculatedValue()) : null;
  }
}
