import { Injectable } from '@angular/core';
import { ApiPath, ApiService, InterceptorConfig } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransmissionEnvironment } from '../../../series/transmission-environment';
import { CSCFiscalSettings } from './csc-fiscal-settings';

@Injectable({ providedIn: 'root' })
export class CSCFiscalSettingsRepository {
  private api: ApiPath;

  constructor(api: ApiService) {
    this.api = api.root.path('fiscal/settings/nfes/csc');
  }

  update(csc: CSCFiscalSettings): Observable<CSCFiscalSettings> {
    return this.api
      .patchJson(csc.toJson(), { metadata: { autoCatch: InterceptorConfig.AUTO } })
      .pipe(map((json) => CSCFiscalSettings.fromJson(json)));
  }

  getActualByEnviromentType(
    enviromentType: TransmissionEnvironment
  ): Observable<CSCFiscalSettings> {
    return this.api
      .path(enviromentType.name)
      .getJson({ metadata: { autoCatch: InterceptorConfig.NO_INTERCEPT } })
      .pipe(map((r) => CSCFiscalSettings.fromJson(r)));
  }
}
