import { JsonEntity, SgvJson } from '@eceos/arch';
import { PlanSummary } from '../../plans/plan';

export class EnterprisesCountByPlanSummary implements JsonEntity {
  constructor(public plan: PlanSummary = null, public enterprises: number = 0) {}

  toJson(): any {
    return SgvJson.to.simple(this, { plan: this.plan.toJson() });
  }

  static fromJson(json: any): EnterprisesCountByPlanSummary {
    return SgvJson.from.simple(json, EnterprisesCountByPlanSummary, {
      plan: PlanSummary.fromJson(json?.plan)
    });
  }
}
