import { EnterpriseSummary } from './../../enterprises/enterprise';
import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { User } from '../user';
import { UserRoleStatus } from './user-role-status';

export class UserRoleSummary {
  constructor(
    public readonly id = SgvId.gen(),
    public user: User = null,
    public enterprise: EnterpriseSummary = null,
    public status: UserRoleStatus = null
  ) {}

  static fromJson(json: any): UserRoleSummary {
    return json
      ? SgvJson.from.simple(json, UserRoleSummary, {
          user: User.fromJson(json.user),
          enterprise: EnterpriseSummary.fromJson(json.enterprise),
          status: UserRoleStatus.get(json.status)
        })
      : null;
  }
}

export abstract class UserRole implements DomainEntity {
  constructor(
    public readonly id: string,
    public user: User,
    public enterprise: EnterpriseSummary,
    public status: UserRoleStatus
  ) {}

  toJson() {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): UserRole {
    if (json && json.type) {
      switch (json.type) {
        case 'accountant':
          return AccountantUserRole.fromJson(json);
        case 'member':
          return MemberUserRole.fromJson(json);
        case 'owner':
          return OwnerUserRole.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class AccountantUserRole extends UserRole {
  constructor(
    id = SgvId.gen(),
    user: User = null,
    enterprise: EnterpriseSummary = null,
    status: UserRoleStatus = null,
    owner: User = null
  ) {
    super(id, user, enterprise, status);
  }

  static fromJson(json: any): AccountantUserRole {
    return json
      ? SgvJson.from.simple(json, AccountantUserRole, {
          user: User.fromJson(json.user),
          enterprise: EnterpriseSummary.fromJson(json.enterprise),
          status: UserRoleStatus.get(json.status),
          owner: User.fromJson(json.owner)
        })
      : null;
  }
}

export class MemberUserRole extends UserRole {
  constructor(
    id = SgvId.gen(),
    user: User = null,
    enterprise: EnterpriseSummary = null,
    status: UserRoleStatus = null
  ) {
    super(id, user, enterprise, status);
  }

  static fromJson(json: any): MemberUserRole {
    return json
      ? SgvJson.from.simple(json, MemberUserRole, {
          user: User.fromJson(json.user),
          enterprise: EnterpriseSummary.fromJson(json.enterprise),
          status: UserRoleStatus.get(json.status)
        })
      : null;
  }
}

export class OwnerUserRole extends UserRole {
  constructor(
    id = SgvId.gen(),
    user: User = null,
    enterprise: EnterpriseSummary = null,
    status: UserRoleStatus = null,
    accountant: User = null
  ) {
    super(id, user, enterprise, status);
  }

  static fromJson(json: any): OwnerUserRole {
    return json
      ? SgvJson.from.simple(json, OwnerUserRole, {
          user: User.fromJson(json.user),
          enterprise: EnterpriseSummary.fromJson(json.enterprise),
          status: UserRoleStatus.get(json.status),
          accountant: User.fromJson(json.accountant)
        })
      : null;
  }
}
