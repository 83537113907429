import { NFeItem } from '../nfe-item';
import { PIS } from '../tax/pis/pis';
import { PisValueWithAliquot } from '../tax/pis/pis-value-with-aliquot';
import { PisValueWithBcAliquot } from '../tax/pis/pis-value-with-bc-aliquot';
import { AddsValue } from './adds-value';

export class PISTotal {
  constructor(private items: () => NFeItem[], private addsValue: () => AddsValue) {}

  get total(): number {
    return this.addsValue().totalPis ? this.value : 0;
  }

  get value(): number {
    return this.aliquotValue + this.bcAliquotValue;
  }

  get aliquotValue(): number {
    return this.pisValueWithAliquot()
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get bcAliquotValue(): number {
    return this.pisValueWithBcAliquot()
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  private pis(): PIS[] {
    return this.items().map(it => it.tax.taxes.pis).filter(it => it != null);
  }

  private pisValueWithAliquot(): PisValueWithAliquot[] {
    return (this.pis() as any[])
      .filter(it => it.pis !== undefined && it.pis instanceof PisValueWithAliquot)
      .map(it => it.pis as PisValueWithAliquot);
  }

  private pisValueWithBcAliquot(): PisValueWithBcAliquot[] {
    return (this.pis() as any[])
      .filter(it => it.pis !== undefined && it.pis instanceof PisValueWithBcAliquot)
      .map(it => it.pis as PisValueWithBcAliquot);
  }
}
