import { SgvJson } from '@eceos/arch';
import { CalculatedValue } from '../../../nfes/calculated-value';
import { NFeItem } from '../../../nfes/nfe-item';
import {
  IpiValueWithBcAliquot,
  IpiValueWithBcAliquotPercent,
  IpiValueWithBcAliquotValue
} from '../../../nfes/tax/ipi/ipi-value-with-bc-aliquot';

export abstract class IpiWithBcAliquot {
  constructor(public aliquot: number = null) {}

  abstract toBcAliquot(nfeItem?: NFeItem): IpiValueWithBcAliquot;

  abstract toJson(): any;

  static fromJson(json: any): IpiWithBcAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'percent':
          return IpiWithBcAliquotPercent.fromJson(json);
        case 'value':
          return IpiWithBcAliquotValue.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class IpiWithBcAliquotPercent extends IpiWithBcAliquot {
  constructor(aliquot: number = null, public bcFormula = '') {
    super(aliquot);
  }

  toBcAliquot(nfeItem: NFeItem): IpiValueWithBcAliquotPercent {
    return new IpiValueWithBcAliquotPercent(
      this.aliquot,
      new CalculatedValue(),
      new CalculatedValue(),
      this.bcFormula
    ).publishValues(nfeItem);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'percent' });
  }

  static fromJson(json: any): IpiWithBcAliquotPercent {
    return json ? SgvJson.from.simple(json, IpiWithBcAliquotPercent) : null;
  }
}

export class IpiWithBcAliquotValue extends IpiWithBcAliquot {
  constructor(aliquot: number = null) {
    super(aliquot);
  }

  toBcAliquot(): IpiValueWithBcAliquotValue {
    return new IpiValueWithBcAliquotValue(this.aliquot, new CalculatedValue(), null).publishValues();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'value' });
  }

  static fromJson(json: any): IpiWithBcAliquotValue {
    return json ? SgvJson.from.simple(json, IpiWithBcAliquotValue) : null;
  }
}
