import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SubMenu } from '../../../features/features-catalog';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubMenuComponent implements OnInit {
  @Input() menu: SubMenu;

  @Output() submenuClick = new EventEmitter<SubMenu>();

  ngOnInit() {}

  onSubmenuClick(submenu: SubMenu) {
    this.submenuClick.emit(submenu);
  }
}
