import { SgvJson } from '@eceos/arch';
import { NFeFuel } from '../fuel/nfe-fuel';
import { BaseNFe } from '../nfe';
import { NFeItem, NFeItemSummary } from '../nfe-item';
import { IcmsStPassedOn } from '../tax/icms/icms-st-passed-on';
import { NFeItemOperationRequest, NFeItemOperationResponse } from './nfe-item-operation';
export class NFeItemMetadataResponse {
  constructor(
    public fuel: boolean = false,
    public pharmaco: boolean = false,
    public importDeclaration: boolean = false,
    public operation: NFeItemOperationResponse = null,
    public icmsStPassedOn: IcmsStPassedOn = null,
    public interstateOperation: boolean = false
  ) {}

  toJson(): string {
    return SgvJson.to.simple(this, {
      operation: SgvJson.to.optional(this.operation),
      icmsStPassedOn: SgvJson.to.optional(this.icmsStPassedOn)
    });
  }

  update(nfe: BaseNFe, item: NFeItem) {
    if (item) {
      item.fuel = this.fuel ? new NFeFuel() : null;
      if (this.operation && this.operation.fiscalProductOperation) {
        this.operation.fiscalProductOperation.update(nfe, item, this.icmsStPassedOn);
      }
    }
  }

  static fromJson(json: any): NFeItemMetadataResponse {
    return json
      ? SgvJson.from.simple(json, NFeItemMetadataResponse, {
          operation: NFeItemOperationResponse.fromJson(json.operation),
          icmsStPassedOn: IcmsStPassedOn.fromJson(json.icmsStPassedOn)
        })
      : null;
  }
}

export class NFeItemMetadataRequest {
  constructor(
    public item: NFeItemSummary = null,
    public operation: NFeItemOperationRequest = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      operation: SgvJson.to.optional(this.operation),
      item: SgvJson.to.optional(this.item)
    });
  }
}
