import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { IcmsDesonerationCalc } from './icms-desoneration-calc';

@Injectable({ providedIn: 'root' })
export class IcmsDesonerationCalcRepository
  extends ReadonlyRepositoryImpl<IcmsDesonerationCalc, IcmsDesonerationCalc>
  implements ReadonlyRepository<IcmsDesonerationCalc, IcmsDesonerationCalc> {
  constructor(api: ApiService) {
    super(
      api.root.path('fiscal/tax/icms/desonerationCalcs'),
      IcmsDesonerationCalc,
      IcmsDesonerationCalc
    );
  }
}
