import { SgvJson } from '@eceos/arch';
import { Name } from '../../name/name';

export abstract class LeadOperation {
  abstract toJson(): string;

  static fromJson(json: any): LeadOperation {
    if (json && json.action) {
      switch (json.action) {
        case 'leadToUser':
          return LeadToUserOperation.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class LeadToUserOperation extends LeadOperation {
  constructor(
    public name: Name = null,
    public phone = '',
    public password = '',
    public recaptchaToken = ''
  ) {
    super();
  }

  toJson(): string {
    return SgvJson.to.simple(this, {
      action: 'leadToUser',
      name: this.name.toJson()
    });
  }

  static fromJson(json: any): LeadToUserOperation {
    return json
      ? SgvJson.from.simple(json, LeadToUserOperation, {
          name: Name.fromJson(json.name)
        })
      : null;
  }
}
