import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';

export const FeaturesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  {
    path: 'financial',
    loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule)
  },
  {
    path: 'fiscal',
    loadChildren: () => import('./fiscal/fiscal.module').then(m => m.FiscalModule)
  },
  {
    path: 'equeue',
    loadChildren: () => import('./equeue/equeue.module').then(m => m.EqueueModule)
  },
  {
    path: 'operation',
    loadChildren: () => import('./operations/operations.module').then(m => m.OperationsModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./generals/generals.module').then(m => m.GeneralsModule)
  },
  {
    path: 'person',
    loadChildren: () => import('./persons/persons.module').then(m => m.PersonsModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./registers/registers.module').then(m => m.RegistersModule)
  }
]);
