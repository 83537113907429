import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryValueCalc, DeliveryValueWithoutCalc } from '@eceos/domain';

@Pipe({
  name: 'deliveryValueCalc'
})
export class DeliveryValueCalcPipe implements PipeTransform {
  private readonly labels = new Map<any, string>();

  constructor() {
    this.labels.set(DeliveryValueWithoutCalc, 'Sem cálculo do valor');
  }

  transform(value: DeliveryValueCalc): string {
    if (!value) return '';
    if (this.labels.has(value.constructor)) {
      return this.labels.get(value.constructor);
    } else {
      return value.constructor.name;
    }
  }
}
