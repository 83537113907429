import { SgvJson } from '@eceos/arch';
import { ZipCode } from './zipCode';

export class BrazilianAddress {
  constructor(public zipCode: ZipCode = null, public number: string = '') {}

  get fullAddress() {
    let result = '';
    if (this.zipCode) {
      if (this.zipCode.street) {
        result += this.zipCode.street;
        if (this.number !== '') {
          result += `, ${this.number}`;
        }
      }
      if (result !== '') {
        result += ' - ';
      }
      if (this.zipCode.district) {
        result += this.zipCode.district;
      }
      if (this.zipCode.district && this.zipCode.city) {
        result += ', ';
      }
      if (this.zipCode.city) {
        result += this.zipCode.city.nameWithUf;
      }
    }
    return result;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'brazilian',
      zipCode: this.zipCode.toJson()
    });
  }

  static fromJson(json: any): BrazilianAddress {
    return json
      ? SgvJson.from.simple(json, BrazilianAddress, {
          zipCode: ZipCode.fromJson(json.zipCode)
        })
      : null;
  }
}
