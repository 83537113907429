import { JsonEntity, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { FormulaExecutor, NFeFiscalTax, NFeItem } from '@eceos/domain';
import { CalculatedValue } from '../../../calculated-value';
import { IcmsCst } from './../csts/icms-cst';
import { IcmsDesonerationCalc } from './icms-desoneration-calc';
import { IcmsDesonerationReason } from './icms-desoneration-reason';

export interface IcmsWithDesonerationWithoutTaxValueHolder {
  desoneration: IcmsWithDesonerationWithoutTaxValue;
}

export class IcmsWithDesonerationWithoutTaxValue implements JsonEntity {
  constructor(
    public reason: IcmsDesonerationReason = null,
    public calc: IcmsDesonerationCalc = null,
    public icmsAliquot: number = null,
    public icmsBcFormula: string = '',
    public icmsBcValue: CalculatedValue = new CalculatedValue(),
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  calcValue(cst: IcmsCst): number {
    return this.calc.apply(cst, this.icmsBcValue ? this.icmsBcValue.value : null, this.icmsAliquot);
  }

  calcIcmsBcValue(nfeItem: NFeItem): number {
    return this.icmsBcFormula
      ? new FormulaExecutor(this.icmsBcFormula, NFeFiscalTax.buildBcVariables(nfeItem)).execute()
      : null;
  }

  publishValues(nfeItem: NFeItem, cst: IcmsCst): IcmsWithDesonerationWithoutTaxValue {
    this.icmsBcValue.value = this.calcIcmsBcValue(nfeItem);
    this.value.value = this.calcValue(cst);
    return this;
  }

  isValid(): boolean {
    return (
      this.reason != null &&
      this.calc != null &&
      EceosValidatorsUtils.isValidOptional(this.icmsBcValue) &&
      EceosValidatorsUtils.isValidOptional(this.value)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      reason: this.reason ? this.reason.toJson() : null,
      calc: this.calc ? this.calc.toJson() : null,
      icmsBcValue: this.icmsBcValue ? this.icmsBcValue.toJson() : null,
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): IcmsWithDesonerationWithoutTaxValue {
    return json
      ? SgvJson.from.simple(json, IcmsWithDesonerationWithoutTaxValue, {
          reason: IcmsDesonerationReason.fromJson(json.reason),
          calc: IcmsDesonerationCalc.fromJson(json.calc),
          icmsBcValue: CalculatedValue.fromJson(json.icmsBcValue),
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}
