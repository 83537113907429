import { NFeItem } from '../nfe-item';
import { COFINS } from '../tax/cofins/cofins';
import { CofinsValueWithAliquot } from '../tax/cofins/cofins-value-with-aliquot';
import { CofinsValueWithBcAliquot } from '../tax/cofins/cofins-value-with-bc-aliquot';
import { AddsValue } from './adds-value';

export class COFINSTotal {
  constructor(private items: () => NFeItem[], private addsValue: () => AddsValue) {}

  get total(): number {
    return this.addsValue().totalCofins ? this.value : 0;
  }

  get value(): number {
    return this.aliquotValue + this.bcAliquotValue;
  }

  get aliquotValue(): number {
    return this.cofinsValueWithAliquot()
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get bcAliquotValue(): number {
    return this.cofinsValueWithBcAliquot()
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  private cofins(): COFINS[] {
    return this.items().map(it => it.tax.taxes.cofins).filter(it => it != null);
  }

  private cofinsValueWithAliquot(): CofinsValueWithAliquot[] {
    return (this.cofins() as any[])
      .filter(it => it.cofins !== undefined && it.cofins instanceof CofinsValueWithAliquot)
      .map(it => it.cofins as CofinsValueWithAliquot);
  }

  private cofinsValueWithBcAliquot(): CofinsValueWithBcAliquot[] {
    return (this.cofins() as any[])
      .filter(it => it.cofins !== undefined && it.cofins instanceof CofinsValueWithBcAliquot)
      .map(it => it.cofins as CofinsValueWithBcAliquot);
  }
}
