import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiPath,
  ApiService,
  InterceptorConfig,
  RequestMetadata,
  RequestMetadataUtils
} from '@eceos/arch';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LeadsPublicRepository {
  protected metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  private api: ApiPath;

  constructor(api: ApiService) {
    this.api = api.manager.path('public').path('leads');
  }

  token(
    email: string,
    source: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<string> {
    return this.api.path(email).post({
      params: new HttpParams().set('source', source),
      metadata: RequestMetadataUtils.merge(metadata, this.metadata)
    });
  }
}
