import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appNewlineValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NewLineValidatorDirective,
      multi: true
    }
  ]
})
export class NewLineValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    const newlinePattern: RegExp = /^.*\n.*$/;

    if (newlinePattern.test(control?.value)) {
      return { hasNewline: true };
    }

    return null;
  }

}
