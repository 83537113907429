import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class TicketSumary implements DomainEntity {
  public readonly id = SgvId.gen();

  public readonly creation = new Date();

  public readonly code = '';

  public readonly called = false;

  public readonly lastCall: TicketCallHistory = new TicketCallHistory();

  static fromJson(json: any): TicketSumary {
    return SgvJson.from.simple(json, TicketSumary, {
      lastCall: TicketCallHistory.fromJson(json.lastCall)
    });
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      lastCall: this.lastCall.toJson()
    });
  }
}

export class Ticket implements DomainEntity {
  public readonly id = SgvId.gen();

  public readonly number: Number;

  public readonly creation: Date;

  static fromJson(json: any): Ticket {
    return SgvJson.from.simple(json, Ticket);
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
}

export class TicketCallRequest {
  public readonly balcony: string = '';

  constructor(balcony: string) {
    this.balcony = balcony;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
}

class TicketCallHistory implements DomainEntity {
  public readonly id = SgvId.gen();

  public readonly instant = new Date();

  public readonly balcony = '';

  static fromJson(json: any): TicketCallHistory {
    return SgvJson.from.simple(json, TicketCallHistory);
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
}
