import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class VariationTypeSummary implements DomainEntity {
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
    ) { }

    toJson(): any {
        throw Error('Não é possível criar ou atualizar VariationTypeSummary');
    }

    static fromJson(json: any): VariationTypeSummary {
        return json ? SgvJson.from.simple(json, VariationTypeSummary) : null;
    }
}