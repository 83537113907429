<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <mat-card>
    <mat-card-title>Olá contador(a), seja bem-vindo(a)!</mat-card-title>
    <mat-card-subtitle>
      <small *ngIf="isAccepting"> Estamos efetivando o seu convite, por favor aguarde... </small>
      <small *ngIf="isAccepted"> Seu convite foi efetivado com sucesso!</small>
      <small *ngIf="isFailed"> Não foi possível efetivar seu convite </small>
    </mat-card-subtitle>
    <mat-card-content>
      <mat-spinner *ngIf="isAccepting"></mat-spinner>
      <span *ngIf="isAccepted">
        Você será redirecionado em breve para o <a href="https://eceos.app/">e-Ceos</a>
      </span>
      <span *ngIf="isFailed">
        Tente novamente em alguns instantes ou entre em contato com nosso suporte pelos nossos
        canais:
        <ul>
          <li>Telefone: <a href="tel://08008782737">0800 878 2737</a></li>
          <li>e-mail: <a href="mailto://contato@eceos.com.br">contato@eceos.com.br</a></li>
        </ul>
      </span>
    </mat-card-content>
  </mat-card>
</section>
