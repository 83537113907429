import { SgvJson } from '@eceos/arch';
import { REGEX_EXP_VALID_MAIL } from '../contacts/contact';

export abstract class AssociationType {
  abstract toJson(): any;
  abstract isValid(): boolean;

  static fromJson(json: any): AssociationType {
    if (json && json.action) {
      switch (json.action) {
        case 'self':
          return SelfAssociationType.fromJson(json);
        case 'other':
          return OtherAssociationType.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class SelfAssociationType extends AssociationType {
  isValid(): boolean {
    return true;
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'self' });
  }

  static fromJson(json: any): SelfAssociationType {
    return json ? SgvJson.from.simple(json, SelfAssociationType) : null;
  }
}

export class OtherAssociationType extends AssociationType {
  constructor(public email = '') {
    super();
  }

  isValid(): boolean {
    return this.email && this.email.trim() !== '' && REGEX_EXP_VALID_MAIL.test(this.email);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'other' });
  }

  static fromJson(json: any): OtherAssociationType {
    return json ? SgvJson.from.simple(json, OtherAssociationType) : null;
  }
}
