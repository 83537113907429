import { SgvJson } from '@eceos/arch';
import { BaseNFe, CFOP, NFeItem, NFeItemSummary, PersonSummary } from '@eceos/domain';
import { FiscalProductOperation } from '../../tax-categories';
import { FiscalDocumentType } from '../fiscal-document-type';
import { IbptProduct } from './nfe-item-ibpt-product';

export class NFeItemOperationRequest {
  constructor(
    public fiscalDocumentType: FiscalDocumentType = null,
    public item: NFeItemSummary = null,
    public cfop: CFOP = null,
    public fiscalDocumentReceiver: PersonSummary = null,
    public ibptProductRequest: NFeIbptProductRequest = null
  ) {}

  toJson() {
    return SgvJson.to.simple(this, {
      fiscalDocumentType: this.fiscalDocumentType ? this.fiscalDocumentType.name : null,
      item: SgvJson.to.optional(this.item),
      cfop: SgvJson.to.optional(this.cfop),
      fiscalDocumentReceiver: SgvJson.to.optional(this.fiscalDocumentReceiver),
      ibptProductRequest: SgvJson.to.optional(this.ibptProductRequest)
    });
  }

  static from(item: NFeItem, nfe: BaseNFe, cfop: CFOP = null): NFeItemOperationRequest {
    return new NFeItemOperationRequest(
      nfe.fiscalDocumentType,
      NFeItemSummary.from(item),
      cfop,
      nfe.receiver,
      new NFeIbptProductRequest(item.unitaryValue)
    );
  }
}

export class NFeIbptProductRequest {
  constructor(
    public productUnitaryValue: number = null,
    public productDescription: string = null,
    public productUnit: string = null,
    public productGtin: string = null,
    public productCode: string = null,
    public ex: number = null
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, NFeIbptProductRequest);
  }
}

export class NFeItemOperationResponse {
  constructor(
    public fiscalProductOperation: FiscalProductOperation = null,
    public ibptProduct: IbptProduct = null
  ) {}

  toJson() {
    return SgvJson.to.simple(this, {
      fiscalProductOperation: SgvJson.to.optional(this.fiscalProductOperation),
      ibptProduct: SgvJson.to.optional(this.ibptProduct)
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, NFeItemOperationResponse, {
          fiscalProductOperation: FiscalProductOperation.fromJson(json.fiscalProductOperation),
          ibptProduct: IbptProduct.fromJson(json.ibptProduct)
        })
      : null;
  }
}
