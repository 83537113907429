<div class="header">
  <span *ngIf="!searchBox.searchVisible">
    <span class="mat-title">{{ title }}</span>
  </span>
  <app-search-box
    #searchBox
    backIcon="arrow_back"
    class="searchBox"
    placeholder="Buscar"
    (searchDebounce)="search($event)"
    flex
  >
  </app-search-box>
  <button mat-icon-button (click)="invalidate()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<table #dataTable mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let col of columns" [matColumnDef]="col.name">
    <th mat-header-cell *matHeaderCellDef>{{ col.label }}</th>
    <td mat-cell *matCellDef="let element">{{ element[col.name] }}</td>
  </ng-container>

  <!-- <ng-content></ng-content> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    class="table-row"
    (click)="onRowClick(row)"
    *matRowDef="let row; columns: displayedColumns"
  ></tr>
</table>
<div class="md-padding" *ngIf="!dataSource.hasData" layout="row" layout-align="center center">
  <h3>Nenhum resultado para exibir.</h3>
</div>
<mat-paginator
  #pagingBar
  [pageSizeOptions]="[10, 20, 50, 100]"
  showFirstLastButtons
  [length]="dataSource.size"
  (page)="paginate($event)"
></mat-paginator>
