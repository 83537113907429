import { Injectable } from '@angular/core';
import { ApiPath, ApiService, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { Observable } from 'rxjs';
import { Crt } from '../crts/crt';
import { map } from 'rxjs/operators';
import { CrtFiscalRepository } from '../crts/crt-fiscal.repository';

@Injectable({ providedIn: 'root' })
export class CrtFiscalSettingRepository {
  private api: ApiPath;

  private crtFiscalRepository: CrtFiscalRepository;

  metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(api: ApiService) {
    this.api = api.root.path('fiscal/settings/crts');
  }

  update(crt: Crt): Observable<Crt> {
    return this.api
      .putJson(crt.toJson(), { metadata: this.metadata })
      .pipe(map((json) => Crt.fromJson(json)));
  }

  getActual(): Observable<Crt> {
    try {
      return this.api.getJson().pipe(map((r) => Crt.fromJson(r)));
    } catch (e) {
      return this.crtFiscalRepository.getFirst();
    }
  }
}
