import { Functionality } from '@eceos/arch';
import {
  ACCOUNTS,
  CARRIERS,
  CLIENTS,
  DELIVERERS,
  DELIVERY_POLICIES,
  FISCAL_DOCUMENT_SERIES,
  FISCAL_PRODUCTS,
  FISCAL_SERVICES,
  OPERATABLE_UNITIES,
  PRODUCTS,
  PROFILES,
  SALES_ACTIONS,
  SALES_ORDER,
  SALES_ORDER_ACTIONS,
  TAX_CATEGORIES,
  USERS
} from '@eceos/domain';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faBoxes,
  faClipboardList,
  faCogs,
  faCreditCard,
  faDolly,
  faFileInvoiceDollar,
  faFill,
  faListAlt,
  faMotorcycle,
  faRulerHorizontal,
  faShoppingCart,
  faSlidersH,
  faStickyNote,
  faTable,
  faTags,
  faTh,
  faThLarge,
  faTruck,
  faUser,
  faUsers,
  faWrench
} from '@fortawesome/free-solid-svg-icons';
export class MenuItem {
  readonly icon = this.iconRef.iconName;

  constructor(
    readonly feature: Functionality = null,
    readonly name: string,
    readonly iconRef: IconDefinition,
    readonly path: string,
    readonly details: string = null,
    readonly title: string = name
  ) {}

  get icons(): IconDefinition[] {
    return [this.iconRef];
  }
}
export class SubMenu {
  readonly icon = this.iconRef.iconName;

  constructor(
    readonly name: string,
    readonly iconRef: IconDefinition,
    readonly details: string = null,
    readonly title: string = name,
    readonly childs: (MenuGroup | MenuItem)[]
  ) {}

  get icons(): IconDefinition[] {
    return [
      this.iconRef,
      ...this.childs.map((c) => c.icons).reduce((v, acc) => [...v, ...acc], [])
    ];
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const child of this.childs) {
      if (child instanceof MenuItem) {
        if (matcher(child)) return child;
      } else {
        const result = child.getItem(matcher);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
}
export class MenuGroup {
  constructor(readonly name: string, readonly childs: (MenuItem | SubMenu)[]) {}

  get icons(): IconDefinition[] {
    return this.childs
      .map((c) => c.icons)
      .reduce((v, acc) => [...(Array.isArray(v) ? v : [v]), ...acc], []);
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const child of this.childs) {
      if (child instanceof SubMenu) {
        const result = child.getItem(matcher);
        if (result) {
          return result;
        }
      } else if (child instanceof MenuItem) {
        if (matcher(child)) {
          return child;
        }
      }
    }
    return null;
  }

  getSubMenu(matcher: (item: SubMenu) => boolean): SubMenu {
    for (const child of this.childs) {
      if (child instanceof SubMenu) {
        if (matcher(child)) {
          return child;
        }
      }
    }
    return null;
  }
}
export class Menu {
  constructor(readonly childs: MenuGroup[]) {}

  get icons(): IconDefinition[] {
    return this.childs.map((c) => c.icons).reduce((v, acc) => [...v, ...acc], []);
  }

  getByFunctionality(feature: Functionality): MenuItem {
    return this.getItem((i) => i.feature === feature);
  }

  getByPath(path: string): MenuItem {
    return this.getItem((i) => i.path === path);
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const group of this.childs) {
      const result = group.getItem(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }

  getSubMenu(matcher: (item: SubMenu) => boolean): SubMenu {
    for (const group of this.childs) {
      const result = group.getSubMenu(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }
}

export const MAIN_MENU = new Menu([
  new MenuGroup('Operações', [
    new MenuItem(
      SALES_ORDER,
      'Pedidos',
      faClipboardList,
      '/operation/salesOrders',
      'Crie e gerencie os pedidos dos seus clientes'
    ),
    new SubMenu(
      'Configurações',
      faSlidersH,
      'Configurações gerais das operações',
      'Configurações Gerais das Operações',
      [
        new MenuGroup('Operações', [
          new MenuItem(
            DELIVERY_POLICIES,
            'Políticas de Entrega',
            faDolly,
            '/operation/settings/deliveryPolicies',
            'Defina e gerencie suas politicas de entregas'
          ),
          new MenuItem(
            OPERATABLE_UNITIES,
            'Unidades de Medida',
            faRulerHorizontal,
            '/register/operatableUnities',
            'Defina e gerencie suas unidades de medida'
          )
        ]),
        new MenuGroup('Pedidos', [
          new MenuItem(
            SALES_ORDER_ACTIONS,
            'Configurações de Pedidos',
            faClipboardList,
            '/operation/settings/salesOrders',
            'Configurações e automações dos pedidos'
          )
        ]),
        new MenuGroup('Vendas', [
          new MenuItem(
            SALES_ACTIONS,
            'Configurações de Vendas',
            faShoppingCart,
            '/operation/settings/sales',
            'Configurações e automações das vendas'
          )
        ])
      ]
    )
  ]),
  new MenuGroup('Cadastros', [
    new MenuItem(
      PRODUCTS,
      'Produtos',
      faBoxes,
      '/register/products',
      'Gerencie os cadastros dos seus produtos'
    )
  ]),
  new MenuGroup('Pessoas', [
    new MenuItem(
      CLIENTS,
      'Clientes',
      faBook,
      '/person/clients',
      'Gerencie os cadastros dos seus clientes'
    ),
    new MenuItem(
      CARRIERS,
      'Transportadoras',
      faTruck,
      '/person/carriers',
      'Registre as transportadoras que você trabalha'
    ),
    new MenuItem(
      DELIVERERS,
      'Entregadores',
      faMotorcycle,
      '/person/deliverers',
      'Gerencie os cadastros dos seus entregadores'
    ),
    new MenuItem(
      USERS,
      'Usuários',
      faUser,
      '/person/users',
      'Defina quem pode acessar o que no sistema'
    ),
    new SubMenu('Configurações', faSlidersH, 'Configurações gerais', 'Configurações Gerais', [
      new MenuGroup('Segurança', [
        new MenuItem(
          PROFILES,
          'Perfis de Acesso',
          faUsers,
          '/general/settings/profiles/',
          'Controle o que os usuários podem fazer'
        )
      ])
    ])
  ]),
  new MenuGroup('Financeiro', [
    new MenuItem(
      ACCOUNTS,
      'Contas',
      faCreditCard,
      '/financial/accounts',
      'Seu caixa e contas bancárias'
    ),
    new SubMenu(
      'Configurações',
      faSlidersH,
      'Definições e parametros do financeiro',
      'Configurações Financeiras',
      []
    )
  ]),
  new MenuGroup('Fiscal', [
    new MenuItem(
      FISCAL_PRODUCTS,
      'Nota Fiscal Eletronica',
      faFileInvoiceDollar,
      '/fiscal/nfes/',
      'Emita e consulte NFes e NFCes',
      'Nota Fiscal Eletronica'
    ),
    new MenuItem(
      FISCAL_PRODUCTS,
      'Enquadramento Tributário - Produtos',
      faTh,
      '/fiscal/products/',
      'Defina como seus produtos serão tributados',
      'Fiscal - Produtos'
    ),
    new MenuItem(
      FISCAL_SERVICES,
      'Enquadramento Tributário - Serviços',
      faListAlt,
      '/fiscal/services/',
      'Defina como seus serviços serão tributados',
      'Fiscal - Serviços'
    ),
    new SubMenu(
      'Configurações',
      faSlidersH,
      'Definições e parametros fiscais',
      'Configurações Fiscais',
      [
        new MenuItem(null, 'Definições Gerais', faCogs, '/fiscal/settings/general'),
        new MenuGroup('Produtos', [
          new MenuItem(
            FISCAL_DOCUMENT_SERIES,
            'Série',
            faWrench,
            '/fiscal/settings/productSeries/',
            'Série fiscal utilizada em NFes e NFCes',
            'Série de Produtos'
          ),
          new MenuItem(
            TAX_CATEGORIES,
            'Categoria Tributária',
            faTags,
            '/fiscal/settings/taxCategories/',
            'Configure detalhes de sua tributação'
          ),
          new MenuItem(
            null,
            'Aliquotas de ICMS interestadual',
            faTable,
            '/fiscal/settings/icmsAliquotTable/'
          ),
          new MenuItem(null, 'Aliquotas de FCP', faTable, '/fiscal/settings/fcpAliquotTable/'),
          new MenuItem(
            null,
            'Mensagens adicionais',
            faStickyNote,
            '/fiscal/settings/productAditionalMessage/'
          )
        ]),
        new MenuGroup('Serviços', [
          new MenuItem(
            FISCAL_DOCUMENT_SERIES,
            'Série',
            faWrench,
            '/fiscal/settings/serviceSeries/',
            'Série fiscal utilizada em NFSes',
            'Série de Serviços'
          ),
          new MenuItem(null, 'Categoria Tributária', faTags, '/fiscal/settings/serviceCategories/'),
          new MenuItem(null, 'Lote RPS', faThLarge, '/fiscal/settings/rpsBatch/'),
          new MenuItem(
            null,
            'Mensagens adicionais',
            faStickyNote,
            '/fiscal/settings/serviceAditionalMessage/'
          )
        ])
      ]
    )
  ])
]);
