import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { BcModality } from './bc-modality';
@Injectable({ providedIn: 'root' })
export class BcModalitiesRepository extends ReadonlyRepositoryImpl<BcModality, BcModality>
  implements ReadonlyRepository<BcModality, BcModality> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/icms/bcModalities'), BcModality, BcModality);
  }
}
