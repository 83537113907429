import { Injectable } from '@angular/core';
import {
  ApiService,
  RepositoryImpl,
  InterceptorConfig,
  PageData,
  Filter,
  FilterOp
} from '@eceos/arch';
import { SalesOrderAction } from './sales-order-action';
import { SalesOrderActionEvent } from './sales-order-action-event';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SalesOrderActionRepository extends RepositoryImpl<SalesOrderAction, SalesOrderAction> {
  constructor(api: ApiService) {
    super(api.root.path('/salesOrderActions'), SalesOrderAction, SalesOrderAction);
  }

  get(event: SalesOrderActionEvent): Observable<SalesOrderAction> {
    const filters = [new Filter('event', FilterOp.EQ, event)];
    return this.page(new PageData(0, 1), '', filters).pipe(map(value => (value ? value[0] : null)));
  }

  async save(action: SalesOrderAction): Promise<SalesOrderAction> {
    if (action) {
      let entity = null;
      try {
        entity = await lastValueFrom(this.findSummary(action.id, {
          autoCatch: InterceptorConfig.NO_INTERCEPT
        }));
      } catch (e) {
        entity = null;
      }

      if (entity) {
        return lastValueFrom(this.update(action));
      } else {
        return lastValueFrom(this.insert(action));
      }
    }
  }
}
