import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { PaymentDiscount, PaymentPercentDiscount, PaymentValueDiscount } from '@eceos/domain';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-discount-form',
  templateUrl: './discount-form.component.html',
  styleUrls: ['./discount-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DiscountFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DiscountFormComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountFormComponent implements OnInit, ControlValueAccessor, Validator {
  private _entity: PaymentDiscount;

  private _totalToPay = 0;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  @Output() entityChange = new EventEmitter<PaymentDiscount>();

  form: FormControl;

  disabled = false;

  readonly discountFormTypes: DiscountFormType[] = [
    { value: PaymentDiscount, label: 'Sem desconto' },
    { value: PaymentValueDiscount, label: 'Desconto em valor' },
    { value: PaymentPercentDiscount, label: 'Desconto percentual' }
  ];

  selectedDiscountFormType: DiscountFormType = this.discountFormTypes[0];

  constructor(private fb: FormBuilder, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  get entity(): PaymentDiscount {
    return this._entity;
  }

  @Input()
  set entity(entity: PaymentDiscount) {
    if (this.entity !== entity) {
      this._entity = entity;
      if (entity) {
        this.selectedDiscountFormType = this.discountFormTypes.find(
          type => entity.constructor === type.value
        );
      } else {
        this.selectedDiscountFormType = this.discountFormTypes[0];
      }
      this.createFormFromEntity();
    }
  }

  get totalToPay(): number {
    return this._totalToPay;
  }

  @Input()
  set totalToPay(totalToPay: number) {
    if (this._totalToPay !== totalToPay) {
      this._totalToPay = totalToPay;
      this.clearDiscount();
    }
  }

  get isPercentDiscount(): boolean {
    return this.entity && this.entity instanceof PaymentPercentDiscount;
  }

  get isValueDiscount(): boolean {
    return this.entity && this.entity instanceof PaymentValueDiscount;
  }

  get value(): number {
    return this.entity.getValue(this.totalToPay) || 0;
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.form && this.form.invalid ? { invalid: true } : null;
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.form) {
      this.publishDisable();
    }
  }

  onSelectedDiscountFormTypeChange(discountFormType: DiscountFormType) {
    this.entity = discountFormType.value ? new discountFormType.value() : null;
  }

  trackByValueName(i, entity: DiscountFormType): any {
    return entity.value.name;
  }

  private createFormFromEntity() {
    this.form = null;
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
    if (this.entity) {
      if (this.entity instanceof PaymentPercentDiscount) {
        this.form = this.fb.control(this.entity.value, [Validators.min(0), Validators.max(100)]);
        this.addFormValueChange(value => new PaymentPercentDiscount(value));
      } else if (this.entity instanceof PaymentValueDiscount) {
        this.form = this.fb.control(this.entity.value, [
          Validators.min(0),
          Validators.max(this.totalToPay)
        ]);
        this.addFormValueChange(value => new PaymentValueDiscount(value));
      }
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (value: any) => PaymentDiscount) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this.entityChange.emit((this._entity = value));
        this.changeDetector.markForCheck();
      });
  }

  private publishDisable() {
    if (this.disabled && this.form.enabled) {
      this.form.disable();
    } else if (!this.disabled && this.form.disabled) {
      this.form.enable();
    }
  }

  private clearDiscount() {
    if (this.entity) {
      this.entityChange.emit((this.entity = new this.discountFormTypes[0].value()));
    }
  }
}

interface DiscountFormType {
  readonly value: new () => PaymentDiscount;
  readonly label: string;
}
