import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountantInviteAcceptComponent } from './accountant-invite-accept/accountant-invite-accept.component';
import { MailInviteAcceptRoutes } from './mail-invite-accept.routes';
import { OwnerInviteAcceptComponent } from './owner-invite-accept/owner-invite-accept.component';

@NgModule({
  declarations: [AccountantInviteAcceptComponent, OwnerInviteAcceptComponent],
  imports: [SharedModule, CommonModule, MailInviteAcceptRoutes]
})
export class MailInviteAcceptModule {}
