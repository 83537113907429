import { SgvJson } from "@eceos/arch";


export abstract class StockSummary {

    static fromJson(json: any): StockSummary {
        if (!json || !json.type) {
            return null;
        }
        switch (json.type) {
            case 'withoutStockSummary':
                return WithoutStockSummary.fromJson(json);
            case 'withStockSummary':
                return WithStockSummary.fromJson(json);
            default:
                throw new Error('StockSummary type not mapped')
        }
    }
}

export class WithoutStockSummary extends StockSummary {

    static fromJson(json: any): WithoutStockSummary {
        return json
            ? SgvJson.from.simple(json, WithoutStockSummary)
            : null
    }
}

export class WithStockSummary extends StockSummary {
    constructor(
        public initial?: number,
        public minimum: number = 0,
        public reserved: number = 0,
        public total: number = 0,
        public available: number = 0
    ) {
        super();
    }

    static fromJson(json: any): WithoutStockSummary {
        return json
            ? SgvJson.from.simple(json, WithoutStockSummary)
            : null
    }
}