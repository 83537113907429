import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailConfirmationComponent } from './mail-confirmation.component';
import { MailConfirmationRoutes } from './mail-confirmation.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [MailConfirmationComponent],
  imports: [SharedModule, CommonModule, MailConfirmationRoutes]
})
export class MailConfirmationModule {}
