import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QueryFilterOp, QueryValueOp } from '@eceos/arch';
import { NFePaymentMethod, NFePaymentMethodsRepository, NfePaymentsCube } from '@eceos/domain';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { Label } from 'ng2-charts';
import { lastValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'app-nfes-payment-widget',
  templateUrl: './nfes-payment-widget.component.html',
  styleUrls: ['./nfes-payment-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NfesPaymentWidgetComponent implements OnInit {
  data: ChartDataSets[] = [];

  labels: Label[] = [];

  options: ChartOptions = { responsive: true };

  paymentMethods$: Observable<NFePaymentMethod[]>;

  constructor(
    private cube: NfePaymentsCube,
    private changeDetector: ChangeDetectorRef,
    private nfePaymentMethodsRepository: NFePaymentMethodsRepository
  ) {
    this.paymentMethods$ = this.nfePaymentMethodsRepository.list();
  }

  ngOnInit() {
    this.fetchData();
  }

  async refresh() {
    this.fetchData();
  }

  async fetchData() {
    const result = await lastValueFrom(
      this.cube.fetch({
        groups: [NfePaymentsCube.DIMENSIONS.PAYMENT_METHOD],
        filters: [
          NfePaymentsCube.DIMENSIONS.MOVEMENT.YEAR_MONTH_DAY.asFilter(
            QueryFilterOp.GTE,
            moment().subtract(7, 'days').format('YYYY-MM-DD')
          )
        ],
        values: [NfePaymentsCube.MEASURES.PAYMENT_VALUE.asValue(QueryValueOp.SUM)],
        sorts: ['paymentValue.total:DSC']
      })
    );

    const dataset = [];
    const labels = [];
    const paymentMethods = await lastValueFrom(this.paymentMethods$);
    for (const { groups, values } of result) {
      labels.push(paymentMethods.find((method) => method.value === groups.paymentMethod).description);
      dataset.push(values.paymentValue);
    }
    this.labels = labels;
    this.data = [{ data: dataset, label: '' }];
    this.changeDetector.markForCheck();
  }
}
