import { SgvJson } from '@eceos/arch';
import { StockLocationData } from './stock-location-data';

export abstract class Stock {

  abstract get isStockByLocation(): boolean;

  abstract get isWithoutStockData(): boolean;
  
  abstract toJson(): any;

  static fromJson(json: any): Stock {
    if (!(json || json.type)) {
      return null;
    }
    switch (json.type) {
      case 'withoutStock':
        return WithoutStockData.fromJson(json);
      case 'stockByLocation':
        return StockByLocation.fromJson(json);
      default:
        throw new Error('Stock type not mapped');
    }
  }

}

export class WithoutStockData extends Stock {
  constructor() {
    super();
  }

  get isStockByLocation(): boolean{
    return false;
  }

  get isWithoutStockData(): boolean{
    return true;
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'withoutStock' });
  }

  static fromJson(json: any): WithoutStockData {
    return json ? SgvJson.from.simple(json, WithoutStockData) : null;
  }

}

export class StockByLocation extends Stock {
  constructor(
    public stockByLocations: StockLocationData[] = []
  ) {
    super();
  }

  get isStockByLocation(): boolean{
    return true;
  }

  get isWithoutStockData(): boolean{
    return false;
  }

  toJson(): any {
    return SgvJson.to.simple(this, { 
      type: 'stockByLocation', 
      stockByLocations: this.stockByLocations ? SgvJson.to.array(this.stockByLocations) : null
    });
  }

  static fromJson(json: any): StockByLocation {
    return json 
      ? SgvJson.from.simple(json, StockByLocation, {
      stockByLocations: json.stockByLocations ? SgvJson.from.array(json.stockByLocations, StockLocationData.fromJson) : null
    }) : null;
  }

}
