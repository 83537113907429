import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthGuard, AuthManagerService, AuthService, ManagerToken } from '@eceos/arch';

const SIGNUP_CONFIRM_EMAIL_PATH = 'signup/confirm-email';

@Injectable({
  providedIn: 'root'
})
export class ManagerGuard implements CanActivate, CanActivateChild {
  constructor(
    private authGuard: AuthGuard,
    private router: Router,
    private authService: AuthService,
    private authManagerService: AuthManagerService
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    if (this.authGuard.canActivate(next, state)) {
      if (this.authManagerService.hasUserToken) {
        if (ManagerToken.decode(this.authManagerService.currentToken).confirmed) {
          return true;
        } else {
          return this.router.createUrlTree([SIGNUP_CONFIRM_EMAIL_PATH]);
        }
      } else if (this.authService.hasToken) {
        return this.router.createUrlTree(['/']);
      }
    }
    return false;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.canActivate(next, state);
  }
}
