import { SgvJson } from '@eceos/arch';

export interface IcmsStWithDestinationUfValueHolder {
  icmsStDestinationUf: IcmsStWithDestinationUfValue;
}

export class IcmsStWithDestinationUfValue {
  constructor(public bcValue: number = null, public value: number = null) {}

  isValid(): boolean {
    return this.bcValue != null && this.value != null;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsStWithDestinationUfValue {
    return json ? SgvJson.from.simple(json, IcmsStWithDestinationUfValue) : null;
  }
}
