import { JsonEntity, SgvJson } from '@eceos/arch';
import { NFCeDeliveryType, NFCePresencialDeliveryType } from './nfce-delivery-type';
import { NFeDeliveryType, WithoutCarrierNFeDeliveryType } from './nfe-delivery-type';

export class DeliveryFiscalData implements JsonEntity {
  constructor(
    public nfeDeliveryType: NFeDeliveryType = null,
    public nfceDeliveryType: NFCeDeliveryType = null
  ) {}

  get isWithNFe(): boolean {
    return Boolean(this.nfeDeliveryType);
  }

  get isWithNFCe(): boolean {
    return Boolean(this.nfceDeliveryType);
  }

  withNFe(withNFe: boolean) {
    this.nfeDeliveryType = withNFe && !this.isWithNFe ? new WithoutCarrierNFeDeliveryType() : null;
  }

  withNFCe(withNFCe: boolean) {
    this.nfceDeliveryType = withNFCe && !this.isWithNFCe ? new NFCePresencialDeliveryType() : null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      nfeDeliveryType: SgvJson.to.optional(this.nfeDeliveryType),
      nfceDeliveryType: SgvJson.to.optional(this.nfceDeliveryType)
    });
  }

  static fromJson(json: any): DeliveryFiscalData {
    return json
      ? SgvJson.from.simple(json, DeliveryFiscalData, {
          nfeDeliveryType: NFeDeliveryType.fromJson(json.nfeDeliveryType),
          nfceDeliveryType: NFCeDeliveryType.fromJson(json.nfceDeliveryType)
        })
      : null;
  }
}
