import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EnterprisesService, MovideskService } from '@eceos/domain';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-suspension-notification',
  templateUrl: './suspension-notification.component.html',
  styleUrls: ['./suspension-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuspensionNotificationComponent implements OnInit {

  showing = false;

  inactivationDate: Date;

  constructor(private movideskService: MovideskService, private enterprisesService: EnterprisesService, private ref: ChangeDetectorRef) {
  }

  async ngOnInit() {
    this.inactivationDate = await lastValueFrom(this.enterprisesService.getInactive());
    this.showing = this.inactivationDate != null;
    this.ref.markForCheck();
  }

  close(): void {
    this.showing = false;
  }

  get remainingDays(): any {
    var oneDay = 1000 * 60 * 60 * 24;
    var now = new Date();
    var dif = this.inactivationDate.getTime() - now.getTime();
    return Math.round(dif / oneDay);
  }

  openHelp() {
    this.movideskService.openChat();
  }

}
