import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('searchAnimation', [
      transition(':enter', [
        style({ 'max-width': '0%' }),
        animate('150ms ease-in', style({ 'max-width': '100%' }))
      ]),
      transition(':leave', [animate('150ms ease-in', style({ 'max-width': '0%' }))])
    ])
  ]
})
export class SearchBoxComponent implements OnInit {
  private _searchVisible = new BehaviorSubject<boolean>(false);

  private searchListener = new BehaviorSubject<string>('');
  private defaultOpenOnDesktopListener = new BehaviorSubject<boolean>(true);

  public isMobile$: Observable<boolean>;
  public isSearchVisible$: Observable<boolean>;
  public isSearchOpened$: Observable<boolean>;

  @Input() backIcon = 'arrow_back';
  @Input() searchIcon = 'search';
  @Input() placeholder = 'Procurar';
  @Input() showUnderline = true;
  @Input() debounceTime = 300;
  @Output() searchDebounce = new EventEmitter<string>();
  @Output() clear = new EventEmitter<boolean>();

  @ViewChild('searchInput', { static: false }) input: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isMobile$ = this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(map((v) => v.matches));
  }

  ngOnInit(): void {
    this.searchListener
      .asObservable()
      .pipe(distinctUntilChanged())
      .subscribe((v) => {
        this.searchDebounce.emit(v);
      });

    this.isSearchOpened$ = combineLatest([this.defaultOpenOnDesktopListener, this.isMobile$]).pipe(
      map(([defaultOpenOnDesktop, isMobile]) => defaultOpenOnDesktop && !isMobile),
      tap(() => this.changeDetector.markForCheck())
    );

    this.isSearchVisible$ = this._searchVisible;

    setTimeout(() => {
      this.changeDetector.markForCheck();
    }, 100);
  }

  @Input()
  set searchVisible(value: boolean) {
    this._searchVisible.next(value);
    if (!this.searchVisible) {
      this.clearSearch();
    }
  }

  get searchVisible() {
    return this._searchVisible.value;
  }

  @Input()
  set defaultOpenOnDesktop(value: boolean) {
    this.defaultOpenOnDesktopListener.next(value);
  }

  get defaultOpenOnDesktop() {
    return this.defaultOpenOnDesktopListener.value;
  }

  toggleSearch() {
    this.searchVisible = !this.searchVisible;
  }

  openSearch() {
    this.searchVisible = true;
  }

  closeSearch() {
    this.searchVisible = false;
  }

  clearSearch() {
    this.searchDebounce.emit((this.input.nativeElement.value = ''));
  }

  onSearchValueChange() {
    this.searchListener.next(this.input.nativeElement.value);
  }

  focusOnInput() {
    this.input?.nativeElement?.focus();
  }
}
