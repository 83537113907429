<app-layout-base
  [fillPrimary]="fillPrimary"
  [pageTitle]="pageTitle"
  [showSearch]="showSearch"
  [searchPlaceholder]="searchPlaceholder"
  [showMenu]="showMenu"
  [showBack]="showBack"
  [homePath]="homePath"
  [onSideBackClick]="onSideBackClickFn"
  (searchChange)="searchChange.emit($event)"
>
  <ng-container beforeActionbar>
    <app-suspension-notification>
    </app-suspension-notification>
  </ng-container>
  <ng-container menuToolbarActions>
    <button
      *ngIf="managerUser | async"
      mat-icon-button
      class="manager-settings"
      [routerLink]="['/manager']"
    >
      <mat-icon>settings</mat-icon>
    </button>
  </ng-container>
  <ng-container menu>
    <mat-toolbar color="primary">
      <mat-toolbar-row *ngIf="user?.enterprise">
        <button mat-icon-button>
          <mat-icon>business</mat-icon>
        </button>
        <div class="user">
          <h4 class="name" [matTooltip]="user?.enterprise?.name">
            {{ user?.enterprise?.name | truncate: [30] }}
          </h4>
          <small class="identifier">
            {{ user?.enterprise?.cnpj | numberMask: cnpjMask }} (@{{
              user?.enterprise?.identifier | truncate: [15]
            }})
          </small>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="user">
        <button mat-icon-button>
          <mat-icon svgIcon="user-circle"></mat-icon>
        </button>
        <div class="user">
          <h4 class="name">{{ user?.name }}</h4>
          <small class="identifier">{{ user?.identifier }}</small>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <app-main-menu
      *ngIf="!submenu"
      [@menuAnimation]
      [menu]="mainMenu"
      (submenuClick)="onSubmenuClick($event)"
    ></app-main-menu>
    <app-sub-menu *ngIf="submenu" [@menuAnimation] [menu]="submenu"></app-sub-menu>
  </ng-container>
  <ng-container actions>
    <button
      mat-icon-button
      *ngIf="showHelp"
      (click)="openHelp()"
      matTooltip="Ajuda"
      aria-label="Ajuda"
    >
      <mat-icon>contact_support</mat-icon>
    </button>
    <ng-content select="[actions]"></ng-content>
  </ng-container>
  <ng-content></ng-content>
</app-layout-base>
