import { JsonEntity, SgvJson } from '@eceos/arch';

export class NFePresenceIndicator implements JsonEntity {
  constructor(readonly name = '', readonly value: number = null, readonly description = '') {}

  toJson(): string {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): NFePresenceIndicator {
    return SgvJson.from.simple(json, NFePresenceIndicator);
  }

  static default(): NFePresenceIndicator {
    return new NFePresenceIndicator('PRESENCIAL_OPERATION', 1, 'Operação Presencial');
  }
}
