import { SgvJson } from '@eceos/arch';
import { TimeInterval } from '../time-interval';
import { Discount } from './discount';
export class SimpleDiscount extends Discount {

  constructor(timeInterval?: TimeInterval) {
    super(0.0, timeInterval, "simple");
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): SimpleDiscount {
    return SgvJson.from.simple(data, SimpleDiscount, {
      timeInterval : Discount.parseInterval(data.timeInterval)
    });
  }
}
