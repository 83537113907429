import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CFOP } from '../../cfops/cfop';
import { BaseNFe } from '../nfe';
import { NFeItem, NFeItemSummary, OperatableNFeItem, SimpleNFeItem } from '../nfe-item';
import { NfesRepository } from '../nfes.repository';
import { NFeItemMetadataRequest, NFeItemMetadataResponse } from './nfe-item-metadata';
import { NFeItemOperationRequest } from './nfe-item-operation';

@Injectable({ providedIn: 'root' })
export class NFeItemMetadataService {
  private cache: Map<String, NFeItemMetadataResponse> = new Map();

  constructor(private repository: NfesRepository) {}

  clear() {
    this.cache.clear();
  }

  get(item: NFeItem): NFeItemMetadataResponse {
    return this.getFromCache(this.getCacheKeyFrom(item));
  }

  invalidateCache(item: NFeItem, cfop: CFOP = null) {
    this.removeFromCache(this.getCacheKeyFrom(item, cfop));
    this.removeFromCache(this.getCacheKeyFrom(item, cfop, true));
  }

  async fetchMetadataAndCache(
    item: NFeItem,
    nfe: BaseNFe,
    cfop: CFOP = null
  ): Promise<NFeItemMetadataResponse> {
    const cacheKey = this.getCacheKeyFrom(item, cfop);
    if (cacheKey) {
      const value = this.getFromCache(cacheKey);
      if (value) {
        return value;
      } else {
        const metadataResponse = await this.fetchMetadata(item, nfe, cfop);
        this.addOnCache(cacheKey, metadataResponse);
        if (
          !cfop &&
          metadataResponse &&
          metadataResponse.operation &&
          metadataResponse.operation.fiscalProductOperation &&
          metadataResponse.operation.fiscalProductOperation.cfop
        ) {
          const defaultCacheKey = this.getCacheKeyFrom(
            item,
            metadataResponse.operation.fiscalProductOperation.cfop
          );
          this.addOnCache(defaultCacheKey, metadataResponse);
        }
        return metadataResponse;
      }
    }
    return null;
  }

  async fetchMetadata(
    item: NFeItem,
    nfe: BaseNFe,
    cfop: CFOP = null
  ): Promise<NFeItemMetadataResponse> {
    const request = new NFeItemMetadataRequest(
      NFeItemSummary.from(item),
      NFeItemOperationRequest.from(item, nfe, cfop)
    );
    try {
      const metadataResponse = await lastValueFrom(this.repository.findNFeItemMetadata(request));
      return metadataResponse;
    } catch (err) {
      console.error(err);
    }
  }

  private getCacheKeyFrom(item: NFeItem, cfop: CFOP = null, withoutCFOP: boolean = false): string {
    let key = null;
    if (item instanceof SimpleNFeItem) {
      key = item.fiscalProduct.id;
    } else if (item instanceof OperatableNFeItem) {
      key = item.operatable.id;
    } else {
      return null;
    }

    if (!withoutCFOP) {
      if (cfop) {
        key += cfop.id;
      } else if (item && item.tax && item.tax.cfop) {
        key += item.tax.cfop.id;
      }
    }

    return key;
  }

  private addOnCache(cacheKey: string, value: NFeItemMetadataResponse) {
    if (value) {
      this.cache.set(cacheKey, value);
    } else {
      this.cache.delete(cacheKey);
    }
  }

  private getFromCache(cacheKey: string): NFeItemMetadataResponse {
    return this.cache.has(cacheKey) ? this.cache.get(cacheKey) : null;
  }

  private removeFromCache(cacheKey: string): void {
    if (this.cache.has(cacheKey)) {
      this.cache.delete(cacheKey);
    }
  }
}
