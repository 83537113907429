import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';
import { ProducerReferencedNFModel } from './producer-referenced-nf-model';

@Injectable({ providedIn: 'root' })
export class ProducerReferencedNFModelRepository extends ReadonlyRepositoryImpl<
  ProducerReferencedNFModel,
  ProducerReferencedNFModel
> {
  constructor(api: ApiService) {
    super(
      api.root.path('producerReferencedNFModels'),
      ProducerReferencedNFModel,
      ProducerReferencedNFModel
    );
  }
}
