<ng-container *ngFor="let contact of valueWithNew">
  <mat-form-field>
    <a *ngIf="disabled" matPrefix mat-button href="tel:{{contact.value}}" target="_blank"><mat-icon>phone</mat-icon></a>
    <input
      matInput
      type="tel"
      [kzMask]="['9999-9999', '99999-9999', '(99) 9999-9999', '(99) 99999-9999']"
      [kzMaskCleanOnBlur]="false"
      placeholder="Adicionar telefone"
      [ngModel]="contact.value"
      (ngModelChange)="onChange($event, contact)"
      [disabled]="disabled"
    />
    <button *ngIf="!disabled && contact.value" matSuffix mat-button type="button" (click)="remove(contact)" tabindex="-1"><mat-icon>close</mat-icon></button>
  </mat-form-field>
</ng-container>
<span *ngIf="valueWithNew.length === 0" class="emptyMessage"> Nenhum telefone registrado </span>
