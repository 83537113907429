import { JsonEntity, SgvJson } from '@eceos/arch';
import { TransmissionEnvironment } from '../../../series';

export class CSCFiscalSettings implements JsonEntity {
  constructor(
    public identifier: string = '',
    public value: string = '',
    public transmissionEnvironment = TransmissionEnvironment.PRODUCTION
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      transmissionEnvironment: this.transmissionEnvironment.name
    });
  }

  static fromJson(json: any): CSCFiscalSettings {
    if (!json) return;
    return SgvJson.from.simple(json, CSCFiscalSettings, {
      transmissionEnvironment: TransmissionEnvironment.get(json.transmissionEnvironment)
    });
  }
}
