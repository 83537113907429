import { SgvId, SgvJson } from '@eceos/arch';

export class InterstateIcmsUfAliquot {
  constructor(readonly id = SgvId.gen(), readonly description = '', readonly number = '') {}

  get value(): number {
    const value = parseFloat(this.number);
    return isNaN(value) ? null : value;
  }

  get numberWithdescription(): string {
    return this.number + ' - ' + this.description;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): InterstateIcmsUfAliquot {
    return json ? SgvJson.from.simple(json, InterstateIcmsUfAliquot) : null;
  }
}
