import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  email: string
}

@Component({
  selector: 'app-forgot-password-success-dialog',
  templateUrl: './forgot-password-success-dialog.component.html',
  styleUrls: ['./forgot-password-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordSuccessDialogComponent implements OnInit {

  email = ''

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private dialogRef: MatDialogRef<ForgotPasswordSuccessDialogComponent>
  ) { this.email = data.email; }

  ngOnInit(): void {}

}
