<section *ngIf="canAccess" class="content">
    <div *ngIf="almostExpired" class="expiring">
        Seu certificado digital vence em {{ ecnpj?.expirationRemainingDays }} dias.
        <button mat-button color="primary" (click)="openUploadDialog()">
            Atualize aqui
        </button>
    </div>
    <div *ngIf="isExpired" class="expiring">
        Seu certificado digital está vencido.
        <button mat-button color="primary" (click)="openUploadDialog()">
            Atualize aqui
        </button>
    </div>
</section>