import { SgvJson } from '@eceos/arch';
import { MathUtils } from '@eceos/common-utils';

export class EnterprisesLastMonthGrowthSummary {
  constructor(public currentMonth = 0, public lastMonth = 0) {}

  get difference(): number {
    return this.currentMonth - this.lastMonth;
  }

  get percentage(): number {
    if (this.lastMonth > 0) {
      return MathUtils.round((this.currentMonth * 100) / this.lastMonth - 100, 0);
    } else if (this.currentMonth > 0) {
      return 100;
    } else {
      return 0;
    }
  }

  static fromJson(json: any): EnterprisesLastMonthGrowthSummary {
    return SgvJson.from.simple(json, EnterprisesLastMonthGrowthSummary);
  }
}
