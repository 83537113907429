<section class="bg fillPrimary">

  <div class="loading" *ngIf="isValidating">
    <mat-spinner></mat-spinner>
  </div>

    <ng-container class="newPassword" *ngIf="isValid">
      <img class="logo" src="/assets/images/logo_eceos_white.svg" />
      <mat-card class="newPasswordCard">
        <mat-card-title>
          Alterar sua senha
        </mat-card-title>
        <form [formGroup]="form" class="fields">
          <mat-form-field thin>
            <mat-label>Nova Senha</mat-label>
            <input matInput [type]="passwordType.type" #password formControlName="password" placeholder="Senha" />
            <mat-hint class="hint">Dica: Use uma combinação forte com letras e números!</mat-hint>
            <mat-error class="error" *ngIf="passwordGroup.hasError('minlength')">
              Sua senha deve conter ao menos 6 digitos.
            </mat-error>
            <password-visibility-toggle matSuffix #passwordType></password-visibility-toggle>
          </mat-form-field>
          <mat-password-strength class="validator" #passwordValidator [password]="password.value">
          </mat-password-strength>
        </form>
        <div class="sugestion">
          <app-password-strength-info [passwordValidator]="passwordValidator"></app-password-strength-info>
        </div>
        <mat-card-actions>
          <button #sendPasswordBtn mat-raised-button class="ok-btn" [disabled]="form.invalid"
            (click)="onPasswordChangeClick()">
            Mudar Senha
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-container>

  <ng-container *ngIf="isInvalid">
    <app-error-page class="expired">
      <div class="title">
        401 - Acesso a página não autorizado!
      </div>
      <div class="content">
        Essa solicitação de troca de senha está expirada ou é inválida. Tente novamente.
      </div>
      <mat-card-actions class="actions">
        <button mat-button [routerLink]="['/']">Voltar para o início</button>
      </mat-card-actions>
    </app-error-page>
  </ng-container>

</section>