import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { HomeComponent } from './home.component';
import { HomeRoutes } from './home.routes';
import { CertificateExpirationComponent } from './certificate-expiration/certificate-expiration.component';

@NgModule({
  imports: [CommonModule, SharedModule, HomeRoutes],
  declarations: [HomeComponent, CertificateExpirationComponent]
})
export class HomeModule {}
