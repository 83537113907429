import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class NFeOperationType implements JsonEntity {
  abstract toJson(): any;

  static fromJson(json: any): NFeOperationType {
    if (json && json.type) {
      switch (json.type) {
        case 'custom':
          return NFeCustomOperationType.fromJson(json);
        case 'cfop':
          return NFeCFOPOperationType.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class NFeCustomOperationType extends NFeOperationType {
  constructor(public custom: string = '') {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'custom'
    });
  }

  static fromJson(json: any): NFeCustomOperationType {
    return SgvJson.from.simple(json, NFeCustomOperationType);
  }
}

export class NFeCFOPOperationType extends NFeOperationType {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'cfop'
    });
  }

  static fromJson(json: any): NFeCFOPOperationType {
    return SgvJson.from.simple(json, NFeCFOPOperationType);
  }
}
