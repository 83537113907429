import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { BrazilianAddress } from '../addresses';
import { EmailContact, PhoneContact } from '../contacts';
import { LegalEntityDocuments } from '../documents';
import { PlanSummary } from '../plans';
import { User } from '../users';
import { Periodicity } from '../periodicities';
import { Billing } from '../billings';
import { EnterpriseWallet } from './enterprise-wallet';
import { EnterpriseInterface, enterpriseInterfaceFrom } from "./enterprise-interface";

export class Enterprise implements DomainEntity {
  constructor(
    public readonly id: string = SgvId.gen(),
    public name: string = '',
    public identifier: string = '',
    public documents: LegalEntityDocuments = new LegalEntityDocuments(),
    public address: BrazilianAddress = null,
    public phones: PhoneContact[] = [],
    public emails: EmailContact[] = [],
    public plan: PlanSummary = null,
    public registrationDate: Date = new Date(),
    public owner: User = null,
    public accountant: User = null,
    public periodicity: Periodicity = Periodicity.MONTHLY,
    public billing: Billing = null,
    public wallet: EnterpriseWallet = new EnterpriseWallet(),
    public isActive: boolean = true,
    public defaultInterface: EnterpriseInterface = EnterpriseInterface.CLOUD
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      documents: this.documents.toJson(),
      address: SgvJson.to.optional(this.address),
      phones: SgvJson.to.array(this.phones),
      emails: SgvJson.to.array(this.emails),
      plan: SgvJson.to.optional(this.plan),
      owner: SgvJson.to.optional(this.owner),
      accountant: SgvJson.to.optional(this.accountant),
      periodicity: SgvJson.to.optional(this.periodicity),
      billing: SgvJson.to.optional(this.billing),
      wallet: SgvJson.to.optional(this.wallet),
      defaultInterface: this.defaultInterface
    });
  }

  static fromJson(json: any): Enterprise {
    return json
      ? SgvJson.from.simple(json, Enterprise, {
        documents: LegalEntityDocuments.fromJson(json.documents),
        address: BrazilianAddress.fromJson(json.address),
        phones: SgvJson.from.array(json.phones, PhoneContact.fromJson),
        emails: SgvJson.from.array(json.emails, EmailContact.fromJson),
        plan: PlanSummary.fromJson(json.plan),
        owner: User.fromJson(json.owner),
        accountant: User.fromJson(json.accountant),
        periodicity: Periodicity.fromJson(json.periodicity),
        billing: Billing.fromJson(json.billing),
        wallet: EnterpriseWallet.fromJson(json.wallet),
        defaultInterface: enterpriseInterfaceFrom(json?.defaultInterface)
      })
      : null;
  }
}

export class EnterpriseSummary implements DomainEntity {
  constructor(
    public readonly id: string = SgvId.gen(),
    public name = '',
    public corporateName = '',
    public identifier = '',
    public cnpj = '',
    public isActive = true,
    public defaultInterface: EnterpriseInterface = EnterpriseInterface.CLOUD
  ) {}

  get maskedCnpj(): string {
    return LegalEntityDocuments.MASK.apply(this.cnpj);
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): EnterpriseSummary {
    return json ? SgvJson.from.simple(json, EnterpriseSummary, {
      defaultInterface: enterpriseInterfaceFrom(json?.defaultInterface)
    }) : null;
  }
}
