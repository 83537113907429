import { JsonEntity, SgvJson } from "@eceos/arch";
import { CompositionIncomeType } from "./composition-income-type";

export class CompositionIncome implements JsonEntity {

  constructor(
    public type: CompositionIncomeType = CompositionIncomeType.INCOME_PER_JOB,
    public description: string = '',
    public value: number = 0
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: this.type.name
    });
  }

  static fromJson(data: any): CompositionIncome {
    return SgvJson.from.simple(data, CompositionIncome, {
      type: data.type ? CompositionIncomeType.get(data.type) : null
    });
  }
}
