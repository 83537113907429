import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { City } from './city';

@Injectable({ providedIn: 'root' })
export class CitiesRepository extends ReadonlyRepositoryImpl<City, City> {
  constructor(api: ApiService) {
    super(api.manager.path('cities'), City, City);
  }
}
