import { SgvId, SgvJson } from '@eceos/arch';

export abstract class NFeEvent {
  constructor(
    readonly id = SgvId.gen(),
    readonly protocol = '',
    readonly details = '',
    readonly date = new Date()
  ) {}

  static fromJson(json: any): NFeEvent {
    if (json && json.type) {
      if (json.type.startsWith('cancel')) return NFeCancelEvent.fromJson(json);
      if (json.type.startsWith('cce')) return NFeCCeEvent.fromJson(json);
      if (json.type.startsWith('submit')) return NFeSubmitEvent.fromJson(json);
      if (json.type.startsWith('consult')) return NFeConsultReceiptEvent.fromJson(json);
      if (json.type.startsWith('email')) return NFeEmailEvent.fromJson(json);
    }
    return null;
  }
}

/* Cancel Events */
export abstract class NFeCancelEvent extends NFeEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCancelEvent {
    if (json && json.type) {
      switch (json.type) {
        case 'cancelAuthorized':
          return NFeCancelAuthorizedEvent.fromJson(json);
        case 'cancelUnauthorized':
          return NFeCancelUnauthorizedEvent.fromJson(json);
        case 'cancelDisabled':
          return NFeCancelDisabledEvent.fromJson(json);
      }
    }
    return null;
  }
}

export class NFeCancelAuthorizedEvent extends NFeCancelEvent {
  constructor(
    id = SgvId.gen(),
    protocol = '',
    details = '',
    date = new Date(),
    readonly justification = ''
  ) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCancelAuthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeCancelAuthorizedEvent);
  }
}

export class NFeCancelUnauthorizedEvent extends NFeCancelEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCancelUnauthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeCancelUnauthorizedEvent);
  }
}
export class NFeCancelDisabledEvent extends NFeCancelEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCancelDisabledEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeCancelDisabledEvent);
  }
}

/* CCe Events */
export abstract class NFeCCeEvent extends NFeEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCCeEvent {
    if (json && json.type) {
      switch (json.type) {
        case 'cceAuthorized':
          return NFeCCeAuthorizedEvent.fromJson(json);
        case 'cceUnauthorized':
          return NFeCCeUnauthorizedEvent.fromJson(json);
        case 'cceDisabled':
          return NFeCCeDisabledEvent.fromJson(json);
      }
    }
    return null;
  }
}
export class NFeCCeAuthorizedEvent extends NFeCCeEvent {
  constructor(
    id = SgvId.gen(),
    protocol = '',
    details = '',
    date = new Date(),
    readonly correction = ''
  ) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCCeAuthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeCCeAuthorizedEvent);
  }
}
export class NFeCCeUnauthorizedEvent extends NFeCCeEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCCeUnauthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeCCeUnauthorizedEvent);
  }
}
export class NFeCCeDisabledEvent extends NFeCCeEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeCCeDisabledEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeCCeDisabledEvent);
  }
}

/* Submit Events */
export abstract class NFeSubmitEvent extends NFeEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any) {
    if (json && json.type) {
      switch (json.type) {
        case 'submitAuthorized':
          return NFeSubmitAuthorizedEvent.fromJson(json);
        case 'submitUnauthorized':
          return NFeSubmitUnauthorizedEvent.fromJson(json);
        case 'submitAsyncAuthorized':
            return NFeSubmitAsyncAuthorizedEvent.fromJson(json);
        case 'submitDenied':
          return NFeSubmitDeniedEvent.fromJson(json);
        case 'submitDisabled':
          return NFeSubmitDisabledEvent.fromJson(json);
      }
    }
    return null;
  }
}
export class NFeSubmitAuthorizedEvent extends NFeSubmitEvent {
  constructor(
    id = SgvId.gen(),
    protocol = '',
    details = '',
    date = new Date(),
    readonly accessKey = '',
    readonly number = 0
  ) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeSubmitAuthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeSubmitAuthorizedEvent);
  }
}
export class NFeSubmitUnauthorizedEvent extends NFeSubmitEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeSubmitUnauthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeSubmitUnauthorizedEvent);
  }
}
export class NFeSubmitAsyncAuthorizedEvent extends NFeSubmitEvent {
  constructor(
    id = SgvId.gen(),
    protocol = '',
    details = '',
    date = new Date(),
    readonly receiptNumber = '',
    readonly nfeNumber = 0
  ) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeSubmitAsyncAuthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeSubmitAsyncAuthorizedEvent);
  }
}
/* Consult Events */
export abstract class NFeConsultReceiptEvent extends NFeEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeConsultReceiptEvent {
    if (json && json.type) {
      switch (json.type) {
        case 'consultAsyncAuthorized':
          return NFeConsultReceiptAsyncAuthorizedEvent.fromJson(json);
        case 'consultAsyncPending':
          return NFeConsultReceiptAsyncPendingEvent.fromJson(json);
        case 'consultAsyncUnauthorized':
          return NFeConsultReceiptAsyncUnauthorizedEvent.fromJson(json);
      }
    }
    return null;
  }
}
export class NFeConsultReceiptAsyncAuthorizedEvent extends NFeConsultReceiptEvent {
  constructor(
    id = SgvId.gen(),
    protocol = '',
    details = '',
    date = new Date(),
    readonly accessKey = '',
    readonly number = 0
  ) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeConsultReceiptAsyncAuthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeConsultReceiptAsyncAuthorizedEvent);
  }
}
export class NFeConsultReceiptAsyncPendingEvent extends NFeConsultReceiptEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeConsultReceiptAsyncPendingEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeConsultReceiptAsyncPendingEvent);
  }
}
export class NFeConsultReceiptAsyncUnauthorizedEvent extends NFeConsultReceiptEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeConsultReceiptAsyncUnauthorizedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeConsultReceiptAsyncUnauthorizedEvent);
  }
}
export class NFeSubmitDeniedEvent extends NFeSubmitEvent {
  constructor(
    id = SgvId.gen(),
    protocol = '',
    details = '',
    date = new Date(),
    readonly code = '',
    readonly description = ''
  ) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeSubmitDeniedEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeSubmitDeniedEvent);
  }
}
export class NFeSubmitDisabledEvent extends NFeSubmitEvent {
  constructor(id = SgvId.gen(), protocol = '', details = '', date = new Date()) {
    super(id, protocol, details, date);
  }
  static fromJson(json: any): NFeSubmitDisabledEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeSubmitDisabledEvent);
  }
}

/* Email Events */
export abstract class NFeEmailEvent extends NFeEvent {
  constructor(id = SgvId.gen(), details = '', date = new Date(), readonly email = '') {
    super(id, '', details, date);
  }
  static fromJson(json: any): NFeEmailEvent {
    if (json && json.type) {
      switch (json.type) {
        case 'emailSucess':
          return NFeEmailSucessEvent.fromJson(json);
        case 'emailError':
          return NFeEmailErrorEvent.fromJson(json);
      }
    }
    return null;
  }
}
export class NFeEmailSucessEvent extends NFeEmailEvent {
  constructor(id = SgvId.gen(), details = '', date = new Date(), email = '') {
    super(id, details, date, email);
  }
  static fromJson(json: any): NFeEmailSucessEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeEmailSucessEvent);
  }
}
export class NFeEmailErrorEvent extends NFeEmailEvent {
  constructor(id = SgvId.gen(), details = '', date = new Date(), email = '', readonly error = '') {
    super(id, details, date, email);
  }
  static fromJson(json: any): NFeEmailErrorEvent {
    if (!json) return null;
    return SgvJson.from.simple(json, NFeEmailErrorEvent);
  }
}
