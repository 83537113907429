import { JsonEntity, SgvJson } from '@eceos/arch';
import { AssociationType } from './association-type';
import { Enterprise } from './enterprise';

export abstract class EnterpriseOperation implements JsonEntity {
  abstract toJson(): any;

  static fromJson(json: any): EnterpriseOperation {
    if (json && json.action) {
      switch (json.action) {
        case 'enterpriseAssociation':
          return EnterpriseAssociation.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class EnterpriseAssociation extends EnterpriseOperation {
  constructor(
    public enterprise: Enterprise = new Enterprise(),
    public accountant: AssociationType = null,
    public owner: AssociationType = null
  ) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      action: 'enterpriseAssociation',
      enterprise: this.enterprise.toJson(),
      accountant: this.accountant.toJson(),
      owner: this.owner.toJson()
    });
  }

  static fromJson(json: any): EnterpriseAssociation {
    return json
      ? SgvJson.from.simple(json, EnterpriseAssociation, {
          enterprise: Enterprise.fromJson(json.enterprise),
          accountant: AssociationType.fromJson(json.accountant),
          owner: AssociationType.fromJson(json.owner)
        })
      : null;
  }
}
