import { Pipe, PipeTransform } from '@angular/core';
import { SalesOrder } from '@eceos/domain';

@Pipe({
  name: 'salesOrderKey'
})
export class SalesOrderKeyPipe implements PipeTransform {

  transform(entity: SalesOrder): string {
    return entity.hasKey ? entity.key : 'Não Gerado';
  }

}
