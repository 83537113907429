import { ICMSUFReceiverTotal } from './icms-uf-receiver-total';
import { NFeItem } from '../nfe-item';
import { AddsValue } from './adds-value';
import { COFINSTotal } from './cofins-total';
import { FederalTaxRetention } from './federal-tax-retention';
import { ICMSTotal } from './icms-total';
import { IITotal } from './ii-total';
import { IPITotal } from './ipi-total';
import { PISTotal } from './pis-total';
import { ApproximateTaxTotal } from './approximate-tax-total';
import { ICMSSN101, ICMSSN201, ICMSSN900 } from '../tax/icms/icms';

export class NFeTotal {
  readonly icmsTotal: ICMSTotal;
  readonly pisTotal: PISTotal;
  readonly cofinsTotal: COFINSTotal;
  readonly ipiTotal: IPITotal;
  readonly iiTotal: IITotal;
  readonly federalTaxRetention: FederalTaxRetention;
  readonly icmsUfReceiverTotal: ICMSUFReceiverTotal;
  readonly approximateTaxTotal: ApproximateTaxTotal;

  constructor(
    public addsValue: AddsValue = new AddsValue(),
    private items: () => NFeItem[] = () => []
  ) {
    this.icmsTotal = new ICMSTotal(items, () => addsValue);
    this.pisTotal = new PISTotal(items, () => addsValue);
    this.cofinsTotal = new COFINSTotal(items, () => addsValue);
    this.ipiTotal = new IPITotal(items);
    this.iiTotal = new IITotal(items);
    this.federalTaxRetention = new FederalTaxRetention(items);
    this.icmsUfReceiverTotal = new ICMSUFReceiverTotal(items);
    this.approximateTaxTotal = new ApproximateTaxTotal(items);
  }

  get total(): number {
    const total = [
      this.itemsValue,
      this.shippingValue,
      this.insuranceValue,
      this.otherIncidentalCostsValue,
      this.taxesValue
    ].reduce((vAnt, vAt) => vAnt + vAt, 0.0);
    return total - this.discountValue;
  }

  get itemsValue() {
    return this.items()
      .map((i) => i.valueWithouDiscount)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get shippingValue(): number {
    return this.items()
      .map((it) => it.freight)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get insuranceValue(): number {
    return this.items()
      .map((it) => it.insurance)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get discountValue(): number {
    return this.items()
      .map((it) => it.discount)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get otherIncidentalCostsValue(): number {
    return this.items()
      .map((it) => it.othersValue)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get taxesValue(): number {
    return [
      this.icmsTotal.total,
      this.pisTotal.total,
      this.cofinsTotal.total,
      this.iiTotal.total,
      this.ipiTotal.total
    ].reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get totalValueOfCreditIcmsSn(): number {
    return [
      this.totalValueOfCreditIcmsSn101,
      this.totalValueOfCreditIcmsSn201,
      this.totalValueOfCreditIcmsSn900
    ].reduce((v1, v2) => v1 + v2, 0.0);
  }

  get totalValueOfCreditIcmsSn101(): number {
    return this.items()
      .map((i) => i.tax.taxes.icms)
      .filter((i) => i instanceof ICMSSN101)
      .map((i) => i as ICMSSN101)
      .map((i) => i?.creditSn.value.value)
      .reduce((v1, v2) => v1 + v2, 0.0);
  }

  get totalValueOfCreditIcmsSn201(): number {
    return this.items()
      .map((i) => i.tax.taxes.icms)
      .filter((i) => i instanceof ICMSSN201)
      .map((i) => i as ICMSSN201)
      .map((i) => i?.creditSn.value.value)
      .reduce((v1, v2) => v1 + v2, 0.0);
  }
  get totalValueOfCreditIcmsSn900(): number {
    return this.items()
      .map((i) => i.tax.taxes.icms)
      .filter((i) => i instanceof ICMSSN900)
      .map((i) => i as ICMSSN900)
      .map((i) => i?.creditSn?.value?.value)
      .reduce((v1, v2) => v1 + v2, 0.0);
  }

  toJson(): any {
    return {
      addsValue: this.addsValue.toJson()
    };
  }

  static fromJson(json: any, items: () => NFeItem[]): NFeTotal {
    return json ? new NFeTotal(AddsValue.fromJson(json.addsValue), items) : null;
  }
}
