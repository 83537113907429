import { RepositoryImpl, ApiService, Repository } from '@eceos/arch';
import { TaxCategorySummary, TaxCategory } from './tax-category';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class TaxCategoriesRepository extends RepositoryImpl<TaxCategorySummary, TaxCategory>
  implements Repository<TaxCategorySummary, TaxCategory> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/products/taxCategories'), TaxCategorySummary, TaxCategory);
  }
}
