import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appWhitespaceOnlyValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: WhitespaceOnlyValidatorDirective,
      multi: true
    }
  ]
})
export class WhitespaceOnlyValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    const value: string = control?.value;

    if (!value) {
      return null;
    }

    if (value?.split(' ').join('')?.length === 0) {
      return { whitespaceOnly: true };
    }

    return null;
  }

}
