import { JsonEntity, SgvJson } from '@eceos/arch';
import { Periodicity } from '@eceos/manager-domain';

export class PlanVariation implements JsonEntity {
  constructor(public serviceIdRef = '', public value = 0, public periodicity: Periodicity = null) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      periodicity: SgvJson.to.optional(this.periodicity)
    });
  }

  static fromJson(json: any): PlanVariation {
    return json
      ? SgvJson.from.simple(json, PlanVariation, {
          periodicity: Periodicity.fromJson(json.periodicity)
        })
      : null;
  }
}
