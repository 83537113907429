import { Injectable } from '@angular/core';
import { ApiPath, ApiService } from '@eceos/arch';
import { Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserInvitePublicRepository {
  private publicInvitePath: ApiPath;

  constructor(api: ApiService) {
    this.publicInvitePath = api.manager.path('/public/users/invite/mail');
  }

  acceptWithToken(token: string): Observable<string> {
    if (!token || token.length === 0) return throwError(Error('Token Inválido ou não informado'));
    return this.publicInvitePath.path(token).post({ supressDefaultAuth: true });
  }
}
