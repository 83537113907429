import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class DeliveryValueCalc implements JsonEntity {
  constructor() {}

  abstract toJson(): any;

  static fromJson(json: any): DeliveryValueCalc {
    if (json && json.type) {
      switch (json.type) {
        case 'withoutCalc':
          return DeliveryValueWithoutCalc.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class DeliveryValueWithoutCalc extends DeliveryValueCalc {
  constructor() {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'withoutCalc' });
  }

  static fromJson(json: any): DeliveryValueWithoutCalc {
    return SgvJson.from.simple(json, DeliveryValueWithoutCalc);
  }
}
