import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { FiscalProductSummary } from '../../../fiscal-products';

export abstract class AdditionFiscalConfig implements DomainEntity {
  constructor(readonly id: string = SgvId.gen(), public isActive = true) {}

  abstract toJson(): any;

  static fromJson(json: any): AdditionFiscalConfig {
    if (json && json.type) {
      switch (json.type) {
        case 'fiscalProductAddition':
          return FiscalProductAdditionFiscalConfig.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class FiscalProductAdditionFiscalConfig extends AdditionFiscalConfig {
  constructor(
    readonly id: string = SgvId.gen(),
    public fiscalProduct: FiscalProductSummary = null,
    isActive = true
  ) {
    super(id, isActive);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'fiscalProductAddition',
      fiscalProduct: this.fiscalProduct.toJson()
    });
  }

  static fromJson(json: any): FiscalProductAdditionFiscalConfig {
    return json
      ? SgvJson.from.simple(json, FiscalProductAdditionFiscalConfig, {
          fiscalProduct: FiscalProductSummary.fromJson(json.fiscalProduct)
        })
      : null;
  }
}
