import { Injectable } from '@angular/core';
import { ApiService, PageData, ReadonlyRepositoryImpl } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Govbr203SpecialTaxationRegime } from '../special-taxation-regime';

@Injectable({ providedIn: 'root' })
export class Govbr203SpecialTaxationRegimeRepository extends ReadonlyRepositoryImpl<
  Govbr203SpecialTaxationRegime,
  Govbr203SpecialTaxationRegime
> {
  constructor(api: ApiService) {
    super(
      api.root.path('fiscal/settings/nfes/govbr203Taxations'),
      Govbr203SpecialTaxationRegime,
      Govbr203SpecialTaxationRegime
    );
  }

  getFirst(): Observable<Govbr203SpecialTaxationRegime> {
    return this.page(new PageData(0, 1)).pipe(map((value) => (value ? value[0] : null)));
  }
}
