import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum EntityDeleteOption {
  NONE,
  TEMPORARY,
  PERMANENT
}

export interface EntityDeleteDialogData {
  allowPermanent?: boolean
  allowTemporary?: boolean
}

@Component({
  selector: 'app-entity-delete-dialog',
  templateUrl: './entity-delete-dialog.component.html',
  styleUrls: ['./entity-delete-dialog.component.scss']
})
export class EntityDeleteDialogComponent implements OnInit {
  tempResult = EntityDeleteOption.TEMPORARY;
  permResult = EntityDeleteOption.PERMANENT;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EntityDeleteDialogData) {}

  ngOnInit() {}
}
