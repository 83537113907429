import { SgvJson, JsonEntity, SgvId } from '@eceos/arch';

export class Uf implements JsonEntity {
  constructor(public readonly id: string = SgvId.gen(), public readonly name: string = '') {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Uf {
    if (typeof json === 'string') {
      return new Uf(json, json);
    } else {
      return SgvJson.from.simple(json, Uf);
    }
  }
}
