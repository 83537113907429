import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { OperatableUnity } from '@eceos/domain';
import { OperatableUnityLegacy } from './operatable-unity-legacy';
import { OperatableUnitySummary } from './operatable-unity-summary';

@Injectable({ providedIn: 'root' })
export class OperatableUnitiesRepository extends RepositoryImpl<OperatableUnitySummary, OperatableUnity> {
  
    constructor(api: ApiService) {
      super(api.root.path('operatableUnities'), OperatableUnitySummary, OperatableUnity);
    }
  
}
