import { JsonEntity, SgvJson, SgvId } from '@eceos/arch';
import { FiscalProductOperationMessage } from '@eceos/domain';

export abstract class BaseNFeInformationMessage implements JsonEntity {
  constructor(readonly id: string = SgvId.gen()) {}

  abstract toJson(): any;

  abstract get message() : string;

  static fromJson(json: any): BaseNFeInformationMessage {
    if (json && json.type) {
      switch (json.type) {
        case 'manualMessage':
          return ManualInformationMessage.fromJson(json);
        case 'operationMessage':
          return OperationInformationMessage.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class ManualInformationMessage extends BaseNFeInformationMessage {
  constructor(id = SgvId.gen(), public message: string = '') {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'manualMessage'
    });
  }

  static fromJson(json: any): ManualInformationMessage {
    return SgvJson.from.simple(json, ManualInformationMessage);
  }
}

export class OperationInformationMessage extends BaseNFeInformationMessage {
  
  constructor(id = SgvId.gen(), public operationMessage: FiscalProductOperationMessage = null) {
    super(id);
  }

  get message(): string {
    return this.operationMessage != null ? this.operationMessage.message : ""
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'operationMessage',
      operationMessage: this.operationMessage ? this.operationMessage.toJson() : null,
    });
  }

  static fromJson(json: any): OperationInformationMessage {
    return SgvJson.from.simple(json, OperationInformationMessage, {
      operationMessage: FiscalProductOperationMessage.fromJson(json.operationMessage)
    });
  }
}
