import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { FiscalProduct, FiscalProductSummary } from './fiscal-product';

@Injectable({providedIn:'root'})
export class FiscalProductRepository extends RepositoryImpl<FiscalProductSummary, FiscalProduct> {
  constructor(api: ApiService) {
    super(api.root.path('/fiscal/fiscalProducts'), FiscalProductSummary, FiscalProduct);
  }
}
