import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class FiscalProductOperationMessage implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public message = '',
    public readonly version = 0,
    public readonly isActive = true 
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): FiscalProductOperationMessage {
    return SgvJson.from.simple(json, FiscalProductOperationMessage);
  }
}