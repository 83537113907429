<ng-container [formGroup]="form" *ngIf="form">
  <fieldset>
    <legend>
      Entrada
      <small> (Total: {{ totalPaid | currency: 'BRL' }}) </small>
    </legend>
    <fieldset>
      <app-payment-table
        [financialDocuments]="financialDocuments"
        formControlName="paid"
      ></app-payment-table>
    </fieldset>
  </fieldset>
  <fieldset>
    <legend>
      Parcelamento
      <small> (Total: {{ pendingPaid | currency: 'BRL' }}) </small>
    </legend>
    <fieldset class="deferred">
      <section>
        <mat-form-field>
          <mat-label>Política de Parcelamento</mat-label>
          <mat-select formControlName="installmentPolicy" [compareWith]="compareById">
            <mat-option
              *ngFor="let installmentPolicy of installmentPolicies$ | async; trackBy: trackById"
              [value]="installmentPolicy"
            >
              {{ installmentPolicy.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Nº de Parcelas</mat-label>
          <input matInput type="number" min="1" formControlName="numberOfInstallments" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>1º Parcela</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="firstExpiration" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <app-periodicity formControlName="periodicity"></app-periodicity>
        <mat-form-field class="parcelValue">
          <mat-label>Valor da Parcela</mat-label>
          <input
            matInput
            currencyMask
            disabled
            [(ngModel)]="parcelValue"
            [ngModelOptions]="{ standalone: true }"
          />
        </mat-form-field>
      </section>
    </fieldset>
  </fieldset>
</ng-container>
