import { SgvJson } from '@eceos/arch';
import { FcpInterestateTableSummary } from './fcp-interestate-table/fcp-interestate-table-summary';
import { InterstateAliquot } from './interstate-aliquot';

export abstract class FcpInterstateAliquot {
  constructor(readonly aliquot: InterstateAliquot = null) {}

  abstract toJson(): any;

  static fromJson(json: any): FcpInterstateAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'empty':
          return FcpEmptyInterstateAliquot.fromJson(json);
        case 'table':
          return FcpInterstateTableAliquot.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class FcpEmptyInterstateAliquot extends FcpInterstateAliquot {
  constructor() {
    super(InterstateAliquot.zero());
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'empty',
      aliquot: this.aliquot.toJson()
    });
  }

  static fromJson(json: any): FcpEmptyInterstateAliquot {
    return json
      ? SgvJson.from.simple(json, FcpEmptyInterstateAliquot, {
          aliquot: InterstateAliquot.fromJson(json.aliquot)
        })
      : null;
  }
}

export class FcpInterstateTableAliquot extends FcpInterstateAliquot {
  constructor(
    aliquot: InterstateAliquot = null,
    public fcpInterestateTable: FcpInterestateTableSummary = null
  ) {
    super(aliquot);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'table',
      fcp: this.fcpInterestateTable.toJson(),
      aliquot: this.aliquot.toJson()
    });
  }

  static fromJson(json: any): FcpInterstateTableAliquot {
    return json
      ? SgvJson.from.simple(json, FcpInterstateTableAliquot, {
          fcp: FcpInterestateTableSummary.fromJson(json.fcp),
          aliquot: InterstateAliquot.fromJson(json.aliquot)
        })
      : null;
  }
}
