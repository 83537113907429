import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { NFePresenceIndicator } from './nfe-presence-indicator';

@Injectable({ providedIn: 'root' })
export class NFePresenceIndicatorsRepository extends ReadonlyRepositoryImpl<
  NFePresenceIndicator,
  NFePresenceIndicator
> {
  constructor(api: ApiService) {
    super(
      api.root.path('/fiscal/nfes/presenceIndicators'),
      NFePresenceIndicator,
      NFePresenceIndicator
    );
  }
}
