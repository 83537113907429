import { isValid } from 'gtin'

export class Barcode {
    static gen() {
        let barcode = ''
        do {
            barcode = Math.floor(1000000 + Math.random() * 9000000).toString() + Math.floor(100000 + Math.random() * 900000).toString();
        } while(isValid(barcode))
        return barcode;
    } 
}
