import { SgvId, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { IcmsCst } from './csts/icms-cst';
import {
  IcmsWithDesonerationValue,
  IcmsWithDesonerationValueHolder
} from './desoneration/icms-with-desoneration-value';
import {
  IcmsWithDesonerationWithoutTaxValue,
  IcmsWithDesonerationWithoutTaxValueHolder
} from './desoneration/icms-with-desoneration-without-tax-value';
import { IcmsSnWithCreditValue, IcmsSnWithCreditValueHolder } from './icms-sn-with-credit-value';
import { IcmsStWithBcValue, IcmsStWithBcValueHolder } from './icms-st-with-bc-value';
import {
  IcmsStWithDestinationUfValue,
  IcmsStWithDestinationUfValueHolder
} from './icms-st-with-destination-value';
import {
  IcmsStWithRetainedValue,
  IcmsStWithRetainedValueHolder
} from './icms-st-with-retained-value';
import { IcmsWithBcValue, IcmsWithBcValueHolder } from './icms-with-bc-value';
import { IcmsWithDeferralValue, IcmsWithDeferralValueHolder } from './icms-with-deferral-value';
import {
  IcmsWithFcpStRetainedValue,
  IcmsWithFcpStRetainedValueHolder
} from './icms-with-fcp-st-retained-value';
import { IcmsWithFcpStValue, IcmsWithFcpStValueHolder } from './icms-with-fcp-st-value';
import { IcmsWithFcpValue, IcmsWithFcpValueHolder } from './icms-with-fcp-value';
import { ProductOrigin } from './product-origins/product-origin';
import { IcmsMonoRetWithValue, IcmsMonoRetWithValueHolder } from "./icms-mono-ret-with-value";

export abstract class ICMS {
  constructor(
    readonly id: string = SgvId.gen(),
    public cst: IcmsCst = null,
    public productOrigin: ProductOrigin = null
  ) {}

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): ICMS {
    if (json && json.type) {
      switch (json.type) {
        case 'Icms00':
          return ICMS00.fromJson(json);
        case 'Icms10':
          return ICMS10.fromJson(json);
        case 'Icms20':
          return ICMS20.fromJson(json);
        case 'Icms30':
          return ICMS30.fromJson(json);
        case 'Icms40to50':
          return ICMS40to50.fromJson(json);
        case 'Icms51':
          return ICMS51.fromJson(json);
        case 'Icms60':
          return ICMS60.fromJson(json);
        case 'Icms61':
          return ICMS61.fromJson(json);
        case 'Icms70':
          return ICMS70.fromJson(json);
        case 'Icms90':
          return ICMS90.fromJson(json);
        case 'IcmsSt':
          return ICMSST.fromJson(json);
        case 'IcmsSn101':
          return ICMSSN101.fromJson(json);
        case 'IcmsSn102to103And300to400':
          return ICMSSN102to103and300to400.fromJson(json);
        case 'IcmsSn201':
          return ICMSSN201.fromJson(json);
        case 'IcmsSn202to203':
          return ICMSSN202to203.fromJson(json);
        case 'IcmsSn500':
          return ICMSSN500.fromJson(json);
        case 'IcmsSn900':
          return ICMSSN900.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class ICMS00 extends ICMS implements IcmsWithBcValueHolder, IcmsWithFcpValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = new IcmsWithBcValue(),
    public fcp: IcmsWithFcpValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.fcp)
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'Icms00',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      fcp: this.fcp ? this.fcp.toJson() : null
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, ICMS00, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      fcp: IcmsWithFcpValue.fromJson(json.fcp)
    });
  }
}
export class ICMS10 extends ICMS
  implements
    IcmsWithBcValueHolder,
    IcmsStWithBcValueHolder,
    IcmsWithFcpValueHolder,
    IcmsWithFcpStValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = new IcmsWithBcValue(),
    public icmsSt: IcmsStWithBcValue = new IcmsStWithBcValue(),
    public fcp: IcmsWithFcpValue = null,
    public fcpSt: IcmsWithFcpStValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icms) &&
      EceosValidatorsUtils.isValid(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcp) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt)
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'Icms10',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcp: this.fcp ? this.fcp.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, ICMS10, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcp: IcmsWithFcpValue.fromJson(json.fcp),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt)
    });
  }
}

export class ICMS20 extends ICMS
  implements IcmsWithBcValueHolder, IcmsWithFcpValueHolder, IcmsWithDesonerationValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = new IcmsWithBcValue(),
    public fcp: IcmsWithFcpValue = null,
    public desoneration: IcmsWithDesonerationValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  get hasBeneficiaryCode(): boolean {
    return Boolean(this.beneficiaryCode);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.fcp) &&
      EceosValidatorsUtils.isValidOptional(this.desoneration)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms20',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      fcp: this.fcp ? this.fcp.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): ICMS20 {
    return SgvJson.from.simple(json, ICMS20, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      fcp: IcmsWithFcpValue.fromJson(json.fcp),
      desoneration: IcmsWithDesonerationValue.fromJson(json.desoneration)
    });
  }
}

export class ICMS30 extends ICMS
  implements
    IcmsStWithBcValueHolder,
    IcmsWithFcpStValueHolder,
    IcmsWithDesonerationWithoutTaxValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsSt: IcmsStWithBcValue = new IcmsStWithBcValue(),
    public fcpSt: IcmsWithFcpStValue = null,
    public desoneration: IcmsWithDesonerationWithoutTaxValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt) &&
      EceosValidatorsUtils.isValidOptional(this.desoneration)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms30',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): ICMS30 {
    return SgvJson.from.simple(json, ICMS30, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt),
      desoneration: IcmsWithDesonerationWithoutTaxValue.fromJson(json.desoneration)
    });
  }
}

export class ICMS40to50 extends ICMS implements IcmsWithDesonerationWithoutTaxValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public desoneration: IcmsWithDesonerationWithoutTaxValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValidOptional(this.desoneration)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms40to50',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): ICMS40to50 {
    return SgvJson.from.simple(json, ICMS40to50, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      desoneration: IcmsWithDesonerationWithoutTaxValue.fromJson(json.desoneration)
    });
  }
}

export class ICMS51 extends ICMS
  implements IcmsWithBcValueHolder, IcmsWithFcpValueHolder, IcmsWithDeferralValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = new IcmsWithBcValue(),
    public fcp: IcmsWithFcpValue = null,
    public deferral: IcmsWithDeferralValue = null,
    public operationValue: number = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValidOptional(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.fcp) &&
      EceosValidatorsUtils.isValidOptional(this.deferral)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms51',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      fcp: this.fcp ? this.fcp.toJson() : null,
      deferral: this.deferral ? this.deferral.toJson() : null
    });
  }

  static fromJson(json: any): ICMS51 {
    return SgvJson.from.simple(json, ICMS51, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      fcp: IcmsWithFcpValue.fromJson(json.fcp),
      deferral: IcmsWithDeferralValue.fromJson(json.deferral)
    });
  }
}

export class ICMS60 extends ICMS implements IcmsStWithRetainedValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsStRetained: IcmsStWithRetainedValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  setDefaultIcmsStRetained(isEndConsumer: boolean): void {
    this.icmsStRetained = isEndConsumer ? null : new IcmsStWithRetainedValue();
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValidOptional(this.icmsStRetained)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms60',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsStRetained: this.icmsStRetained ? this.icmsStRetained.toJson() : null
    });
  }

  static fromJson(json: any): ICMS60 {
    return SgvJson.from.simple(json, ICMS60, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsStRetained: IcmsStWithRetainedValue.fromJson(json.icmsStRetained)
    });
  }
}

export class ICMS61 extends ICMS implements IcmsMonoRetWithValueHolder {

  constructor(
    id: string = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsMonoRetWithValue = new IcmsMonoRetWithValue()
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValidOptional(this.icms)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms61',
      cst: this.cst ? this.cst.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): ICMS61 {
    return SgvJson.from.simple(json, ICMS61, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsMonoRetWithValue.fromJson(json.icms)
    });
  }
}

export class ICMS70 extends ICMS
  implements
    IcmsWithBcValueHolder,
    IcmsStWithBcValueHolder,
    IcmsWithFcpValueHolder,
    IcmsWithFcpStValueHolder,
    IcmsWithDesonerationValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = new IcmsWithBcValue(),
    public icmsSt: IcmsStWithBcValue = new IcmsStWithBcValue(),
    public fcp: IcmsWithFcpValue = null,
    public fcpSt: IcmsWithFcpStValue = null,
    public desoneration: IcmsWithDesonerationValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcp) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt) &&
      EceosValidatorsUtils.isValidOptional(this.desoneration)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms70',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcp: this.fcp ? this.fcp.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): ICMS70 {
    return SgvJson.from.simple(json, ICMS70, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcp: IcmsWithFcpValue.fromJson(json.fcp),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt),
      desoneration: IcmsWithDesonerationValue.fromJson(json.desoneration)
    });
  }
}

export class ICMS90 extends ICMS
  implements
    IcmsWithBcValueHolder,
    IcmsStWithBcValueHolder,
    IcmsWithFcpValueHolder,
    IcmsWithFcpStValueHolder,
    IcmsWithDesonerationValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = new IcmsWithBcValue(),
    public icmsSt: IcmsStWithBcValue = new IcmsStWithBcValue(),
    public fcp: IcmsWithFcpValue = null,
    public fcpSt: IcmsWithFcpStValue = null,
    public desoneration: IcmsWithDesonerationValue = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcp) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt) &&
      EceosValidatorsUtils.isValidOptional(this.desoneration)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms90',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcp: this.fcp ? this.fcp.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): ICMS90 {
    return SgvJson.from.simple(json, ICMS90, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcp: IcmsWithFcpValue.fromJson(json.fcp),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt),
      desoneration: IcmsWithDesonerationValue.fromJson(json.desoneration)
    });
  }
}

export class ICMSST extends ICMS
  implements
    IcmsStWithRetainedValueHolder,
    IcmsWithFcpStRetainedValueHolder,
    IcmsStWithDestinationUfValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsStRetained: IcmsStWithRetainedValue = new IcmsStWithRetainedValue(),
    public fcpStRetained: IcmsWithFcpStRetainedValue = null,
    public icmsStDestinationUf: IcmsStWithDestinationUfValue = new IcmsStWithDestinationUfValue()
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icmsStRetained) &&
      EceosValidatorsUtils.isValidOptional(this.fcpStRetained) &&
      EceosValidatorsUtils.isValid(this.icmsStDestinationUf)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSt',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsStRetained: this.icmsStRetained ? this.icmsStRetained.toJson() : null,
      fcpStRetained: this.fcpStRetained ? this.fcpStRetained.toJson() : null,
      icmsStDestinationUf: this.icmsStDestinationUf ? this.icmsStDestinationUf.toJson() : null
    });
  }

  static fromJson(json: any): ICMSST {
    return SgvJson.from.simple(json, ICMSST, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsStRetained: IcmsStWithRetainedValue.fromJson(json.icmsStRetained),
      fcpStRetained: IcmsWithFcpStRetainedValue.fromJson(json.fcpStRetained),
      icmsStDestinationUf: IcmsStWithDestinationUfValue.fromJson(json.icmsStDestinationUf)
    });
  }
}

export class ICMSSN101 extends ICMS implements IcmsSnWithCreditValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public creditSn: IcmsSnWithCreditValue = new IcmsSnWithCreditValue()
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null && this.productOrigin != null && EceosValidatorsUtils.isValid(this.creditSn)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn101',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      creditSn: this.creditSn ? this.creditSn.toJson() : null
    });
  }

  static fromJson(json: any): ICMSSN101 {
    return SgvJson.from.simple(json, ICMSSN101, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      creditSn: IcmsSnWithCreditValue.fromJson(json.creditSn)
    });
  }
}

export class ICMSSN102to103and300to400 extends ICMS {
  constructor(id = SgvId.gen(), cst: IcmsCst = null, productOrigin: ProductOrigin = null) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return this.cst != null && this.productOrigin != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn102to103And300to400',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): ICMSSN102to103and300to400 {
    return SgvJson.from.simple(json, ICMSSN102to103and300to400, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin)
    });
  }
}

export class ICMSSN201 extends ICMS
  implements IcmsStWithBcValueHolder, IcmsWithFcpStValueHolder, IcmsSnWithCreditValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsSt: IcmsStWithBcValue = new IcmsStWithBcValue(),
    public fcpSt: IcmsWithFcpStValue = null,
    public creditSn: IcmsSnWithCreditValue = new IcmsSnWithCreditValue()
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt) &&
      EceosValidatorsUtils.isValid(this.creditSn)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn201',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null,
      creditSn: this.creditSn ? this.creditSn.toJson() : null
    });
  }

  static fromJson(json: any): ICMSSN201 {
    return SgvJson.from.simple(json, ICMSSN201, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt),
      creditSn: IcmsSnWithCreditValue.fromJson(json.creditSn)
    });
  }
}

export class ICMSSN202to203 extends ICMS
  implements IcmsStWithBcValueHolder, IcmsWithFcpStValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsSt: IcmsStWithBcValue = new IcmsStWithBcValue(),
    public fcpSt: IcmsWithFcpStValue = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValid(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn202to203',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null
    });
  }

  static fromJson(json: any): ICMSSN202to203 {
    return SgvJson.from.simple(json, ICMSSN202to203, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt)
    });
  }
}

export class ICMSSN500 extends ICMS implements IcmsStWithRetainedValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsStRetained: IcmsStWithRetainedValue = null
  ) {
    super(id, cst, productOrigin);
  }

  setDefaultIcmsStRetained(isEndConsumer: boolean) {
    this.icmsStRetained = isEndConsumer ? null : new IcmsStWithRetainedValue();
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValidOptional(this.icmsStRetained)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn500',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsStRetained: this.icmsStRetained ? this.icmsStRetained.toJson() : null
    });
  }

  static fromJson(json: any): ICMSSN500 {
    return SgvJson.from.simple(json, ICMSSN500, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsStRetained: IcmsStWithRetainedValue.fromJson(json.icmsStRetained)
    });
  }
}

export class ICMSSN900 extends ICMS
  implements
    IcmsWithBcValueHolder,
    IcmsStWithBcValueHolder,
    IcmsWithFcpStValueHolder,
    IcmsSnWithCreditValueHolder {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBcValue = null,
    public icmsSt: IcmsStWithBcValue = null,
    public fcpSt: IcmsWithFcpStValue = null,
    public creditSn: IcmsSnWithCreditValue = null
  ) {
    super(id, cst, productOrigin);
  }

  isValid(): boolean {
    return (
      this.cst != null &&
      this.productOrigin != null &&
      EceosValidatorsUtils.isValidOptional(this.icms) &&
      EceosValidatorsUtils.isValidOptional(this.icmsSt) &&
      EceosValidatorsUtils.isValidOptional(this.fcpSt) &&
      EceosValidatorsUtils.isValidOptional(this.creditSn)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn900',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      fcpSt: this.fcpSt ? this.fcpSt.toJson() : null,
      creditSn: this.creditSn ? this.creditSn.toJson() : null
    });
  }

  static fromJson(json: any): ICMSSN900 {
    return SgvJson.from.simple(json, ICMSSN900, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBcValue.fromJson(json.icms),
      icmsSt: IcmsStWithBcValue.fromJson(json.icmsSt),
      fcpSt: IcmsWithFcpStValue.fromJson(json.fcpSt),
      creditSn: IcmsSnWithCreditValue.fromJson(json.creditSn)
    });
  }
}
