import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AcronymPipe } from './acronym/acronym.pipe';
import { AddressPipe } from './address/address.pipe';
import { DeliveryResponsiblePipe } from './delivery-responsible/delivery-responsible.pipe';
import { DeliveryValueCalcPipe } from './delivery-value-calc/delivery-value-calc.pipe';
import { DicionaryActionPipe } from './dicionary-action/dicionary-action.pipe';
import { DiscountSummaryPipe } from './discount-summary/discount-summary.pipe';
import { PaymentSummaryPipe } from './payment-summary/payment-summary.pipe';
import { ProfileDescriptionPipe } from './profile-description/profile-description.pipe';
import { DiscountPipe } from './rates/discount.pipe';
import { FinePipe } from './rates/fine.pipe';
import { InterestArrearsPipe } from './rates/interest-arrears.pipe';
import { SalesOrderKeyPipe } from './sales-order-key/sales-order-key.pipe';

const PIPES = [
  AddressPipe,
  DiscountPipe,
  FinePipe,
  InterestArrearsPipe,
  DicionaryActionPipe,
  ProfileDescriptionPipe,
  AcronymPipe,
  DiscountSummaryPipe,
  PaymentSummaryPipe,
  DeliveryResponsiblePipe,
  DeliveryValueCalcPipe,
  SalesOrderKeyPipe
];

@NgModule({
  imports: [CommonModule],
  providers: [CurrencyPipe],
  declarations: PIPES,
  exports: PIPES
})
export class PipesModule {}
