import { Injectable } from "@angular/core";
import { ApiService, InterceptorConfig, RepositoryImpl, RequestMetadata } from "@eceos/arch";
import { Observable } from "rxjs";
import { UserPassword } from "./user-new-password";

@Injectable({ providedIn: 'root' })
export class UserPasswordRecoveryRepository extends RepositoryImpl<UserPassword, UserPassword> {
    constructor(api: ApiService) {
        super(api.manager.path("public/users/password/"), UserPassword, UserPassword);
    }

    sendEmailToPasswordRecovery(
        email: string,
        metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
    ): Observable<string>{
        return this.api.path(`recovery/${email}`).post({ metadata: metadata });
    }

    isValidToken(
        token: string,
        metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }
    ): Observable<string>{
        return this.api.path(token).getText({ metadata: metadata });
    }

    newPassword(
        token: string,
        userPassword: UserPassword,
        metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
    ): Observable<void>{
        return this.api.path(`${token}/newPassword`).patchJson(userPassword.toJson(), { metadata: metadata });
    }
}

