import { SgvJson } from '@eceos/arch';
import { SgvId, DomainEntity } from '@eceos/arch';

export class PersonSummary implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly name = '',
    readonly cnp = '',
    readonly type = ''
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any) {
    if (!json) {
      return null;
    }
    return SgvJson.from.simple(json, PersonSummary, {
      cnp: json.cnp ? json.cnp.replace(/\D/g, '') : null
    });
  }
}
