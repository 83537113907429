import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Actives, Filter, FilterOp, ReadonlyRepository } from "@eceos/arch";
import { map, Observable, of } from "rxjs";
import { CNP } from "../core/person/cnp";

@Injectable({
    providedIn: 'root'
})
export class EceosPersonValidators {
    private constructor(
    ) { }

    static cnpExists(repository: ReadonlyRepository<any, any>, fields: CnpInformations): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            const isValid = new CNP(control?.value).isValid
            if (isValid) {
                fields.updateCnpType(control.value)
                return repository.list(
                    '',
                    [new Filter(`documents.${fields.currentCnpType}`, FilterOp.EQ, control.value)],
                    [],
                    Actives.ALL
                )
                    .pipe(
                        map(people => {
                            if (people.length > 0 && people[0]?.cnp !== fields.initialCnpNumber) {
                                return { personExists: control.value }
                            } else {
                                return null;
                            }
                        })
                    )
            }
            return of(null);
        }
    }
}

export class CnpInformations {
    constructor(
        public initialCnpNumber: string = '',
        public currentCnpType: string = '',
    ) {
        this.updateCnpType(this.initialCnpNumber);
    }

    updateCnpType(cnpValue: string) {
        const currentCnpType = new CNP(cnpValue);
        if (cnpValue && currentCnpType.isCpf) {
            this.currentCnpType = 'cpf';
        } else if (cnpValue && currentCnpType.isCnpj) {
            this.currentCnpType = 'cnpj';
        }
    }

}