import { SgvId, SgvJson } from '@eceos/arch';

export class Ecnpj {
  constructor(
    public id: string = SgvId.gen(),
    public filename: string = '',
    public contentType: string = '',
    public expiration: Date = new Date(),
    public isExpired: boolean = false,
    public isAlmostExpired: boolean = false,
    public expirationRemainingDays: number = 0
  ) {}

  static fromJson(json: any) {
    return SgvJson.from.simple(json, Ecnpj);
  }
}
