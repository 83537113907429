import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CookieService } from '@eceos/common-components';
import { CurrentVersionService, Version } from '@eceos/common-utils';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
    <ngx-loading-bar [includeSpinner]="false" height="3px"></ngx-loading-bar>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  readonly version: Version;
  private readonly defaultTitle = 'e-Ceos';

  constructor(
    private router: Router,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    currentVersion: CurrentVersionService
  ) {
    this.version = currentVersion.current;
    console.table({
      message: 'Inicializando e-Ceos',
      version: this.version.full
    });
  }

  ngOnInit(): void {
    this.cookieService.checkCookieConsent();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          const titleParam = child.snapshot.data['title'];
          if (titleParam) {
            return titleParam;
          }
          return this.defaultTitle;
        })
      )
      .subscribe((ttl: string) => this.title.setTitle(ttl));
  }
}
