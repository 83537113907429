import { Injectable } from '@angular/core';
import { RepositoryImpl, ApiService } from '@eceos/arch';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class UsersRepository extends RepositoryImpl<User, User> {
  constructor(api: ApiService) {
    super(api.root.path('users'), User, User);
  }
}
