import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiService,
  AuthManagerService,
  AuthService,
  InterceptorConfig,
  RequestMetadata
} from '@eceos/arch';
import { LoginResult, LoginValidator } from '@eceos/common-pages';
import { lastValueFrom, Observable, of } from 'rxjs';
import { CurrentUserService } from '@eceos/manager-domain';

@Injectable({ providedIn: 'root' })
export class LoginService implements LoginValidator {
  protected metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT };
  constructor(
    private api: ApiService,
    private authService: AuthService,
    private authManagerService: AuthManagerService,
    private router: Router,
    private currentUserManagerService: CurrentUserService
  ) {}

  async validateLogin(username: string, password: string): Promise<LoginResult> {
    if (!username || !password) {
      return LoginResult.fail('Credenciais inválidas');
    } else {
      try {
        const token = await lastValueFrom( this.getEceosToken(username, password));
        return this.handleToken(token, this.authService);
      } catch (err) {
        try {
          const token = await lastValueFrom(this.getManagerToken(username, password));
          const result = this.handleToken(token, this.authManagerService);
          if(result.ok) {
            this.currentUserManagerService.loadUser();
          }
          return result;
        } catch (err) {
          console.error(err);
          return LoginResult.fail();
        }
      }
    }
  }

  private getEceosToken(username: string, password: string): Observable<string> {
    const split = username.split('@');
    if (split.length !== 2) {
      return of(null);
    } else {
      const user = split[0];
      const enterprise = split[1];
      return this.api.root.path(`login`).getText({
        headers: new HttpHeaders().set(
          'Authorization',
          `Basic ${btoa(`${user}@${enterprise}:${password}`)}`
        ),
        metadata: this.metadata
      });
    }
  }

  private getManagerToken(username: string, password: string): Observable<string> {
    return this.api.manager.path(`login`).getText({
      headers: new HttpHeaders().set('Authorization', `Basic ${btoa(`${username}:${password}`)}`),
      metadata: this.metadata
    });
  }

  private handleToken(token: string, authService: AuthService): LoginResult {
    if (token) {
      authService.publishLogin(token);
      this.router.navigate(['/']);
      return LoginResult.success();
    } else {
      return LoginResult.fail();
    }
  }
}
