import { SgvJson } from '@eceos/arch';
import { CalculatedValue } from '../../../nfes/calculated-value';
import { NFeItem } from '../../../nfes/nfe-item';
import { BcModalityST } from '../../../nfes/tax/icms/bc-modalities-st/bc-modality-st';
import { IcmsStWithBcValue } from '../../../nfes/tax/icms/icms-st-with-bc-value';

export class IcmsStWithBc {
  constructor(
    public bcModality: BcModalityST = null,
    public marginAddedStPercent: number = null,
    public bcFormula: string = null,
    public aliquot: number = null,
    public bcReductionPercent: number = null
  ) {}

  toValue(nfeItem: NFeItem): IcmsStWithBcValue {
    return new IcmsStWithBcValue(
      this.bcModality,
      this.marginAddedStPercent,
      this.bcFormula,
      new CalculatedValue(),
      this.aliquot,
      new CalculatedValue(),
      this.bcReductionPercent
    ).publishValues(nfeItem);
  }

  toJson() {
    return SgvJson.to.simple(this, {
      bcModality: this.bcModality ? this.bcModality.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, IcmsStWithBc, {
          bcModality: BcModalityST.fromJson(json.bcModality)
        })
      : null;
  }
}
