import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {
  CarriersRepository,
  Freight,
  FreightModalitiesRepository,
  FreightModality,
  FreightWithValue,
  WithoutFreight
} from '@eceos/domain';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-freight-form',
  templateUrl: './freight-form.component.html',
  styleUrls: ['./freight-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FreightFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FreightFormComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreightFormComponent implements OnInit, ControlValueAccessor, Validator {
  private _entity: Freight;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  @Output() entityChange = new EventEmitter<Freight>();

  form: FormGroup;

  disabled = false;

  modalities$: Observable<FreightModality[]>;

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private freightModalitiesRepository: FreightModalitiesRepository,
    public carriersRepository: CarriersRepository
  ) {
    this.modalities$ = freightModalitiesRepository.list();
  }

  ngOnInit() {}

  get entity(): Freight {
    return this._entity;
  }

  set entity(entity: Freight) {
    if (this.entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get isWithoutFreight(): boolean {
    return this.entity && this.entity instanceof WithoutFreight;
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.form && this.form.invalid ? { invalid: true } : null;
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.form) {
      this.publishDisable();
    }
  }

  compareById(obj, anotherObj): boolean {
    return obj && anotherObj && obj.id === anotherObj.id;
  }

  trackById(i, entity): string {
    return entity.id;
  }

  private createFormFromEntity() {
    this.form = null;

    if (this.entity) {
      if (this.entity instanceof WithoutFreight) {
        this.form = this.fb.group({
          modality: [this.entity.modality, Validators.required]
        });
      } else if (this.entity instanceof FreightWithValue) {
        this.form = this.fb.group({
          modality: [this.entity.modality, Validators.required],
          carrier: [this.entity.carrier],
          value: [this.entity.value, Validators.required]
        });
      }
      this.publishDisable();
      this.addFormValueChange(value => {
        if (value && value.modality) {
          return value.modality.isWithoutFreight
            ? new WithoutFreight()
            : new FreightWithValue(value.modality, value.carrier, value.value);
        }
        return null;
      });
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (f: any) => Freight) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this.entityChange.emit((this._entity = value));
        this.createFormFromEntity();
      });
  }

  private publishDisable() {
    if (this.disabled && this.form.enabled) {
      this.form.disable();
    } else if (!this.disabled && this.form.disabled) {
      this.form.enable();
    }
  }
}
