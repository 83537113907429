import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { CofinsCst } from './cofins-cst';

@Injectable({ providedIn: 'root' })
export class CofinsCstsRepository extends ReadonlyRepositoryImpl<CofinsCst, CofinsCst>
  implements ReadonlyRepository<CofinsCst, CofinsCst> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/cofins/csts'), CofinsCst, CofinsCst);
  }
}
