
import { isValidCnpj, isValidCpf } from '@brazilian-utils/validators';

export class CNP {
    private _value: string
    private unmaskedValue: string
  
    isCpf: boolean = false
    isCnpj: boolean = false
  
    isValid: boolean = false
  
    constructor(value: string) {
      this.value = value
    }
  
    get value() {
      return this._value
    }
  
    set value(value: string) {
      if(!value) {
        this._value = ""
        this.unmaskedValue = ""
        this.isCpf = false
        this.isCnpj = false
        this.isValid = false
      } else if (this._value != value) {
        this._value = value
        this.unmaskedValue = value.replace(/\D/g, '')
        this.isCpf = this.unmaskedValue.length === 11
        this.isCnpj = this.unmaskedValue.length === 14
        this.isValid = (this.isCpf && isValidCpf(this.unmaskedValue)) || (this.isCnpj && isValidCnpj(this.unmaskedValue))
      }
    }
  }

