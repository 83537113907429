<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <app-data-selector
    [repository]="data.repository"
    [(ngModel)]="entity"
    [label]="data.content"
    [required]="data.required"
    #input="ngModel"
  ></app-data-selector>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    type="button"
    color="accent"
    [mat-dialog-close]="entity"
    [disabled]="input.invalid"
  >
    Confirmar
  </button>
  <button mat-button type="button" color="warn" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>