<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <mat-card>
    <mat-card-title> Confirmação de conta </mat-card-title>
    <mat-card-subtitle>
      <small *ngIf="isValidating"> Estamos validando sua conta, aguarde... </small>
      <small *ngIf="isValid"> Sua conta foi validada com sucesso </small>
      <small *ngIf="isInvalid"> Não foi possível validar sua conta </small>
    </mat-card-subtitle>
    <mat-card-content>
      <mat-spinner *ngIf="isValidating"></mat-spinner>
      <span *ngIf="isValid">
        Você será redirecionado em breve para o <a href="https://eceos.app/">e-Ceos</a>
      </span>
      <span *ngIf="isInvalid">
        Tente novamente em alguns instantes ou entre em contato com nosso suporte pelos nossos
        canais:
        <ul>
          <li>Telefone: <a href="tel://08008782737">0800 878 2737</a></li>
          <li>e-mail: <a href="mailto://contato@eceos.com.br">contato@eceos.com.br</a></li>
        </ul>
      </span>
    </mat-card-content>
  </mat-card>
</section>
