import { JsonEntity, SgvJson } from '@eceos/arch';
import { CarrierSummary } from './../../carriers/carrier';
import { DelivererSummary } from './deliverers/deliverer';

export abstract class DeliveryResponsibleData implements JsonEntity {
  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): DeliveryResponsibleData {
    if (json && json.type) {
      switch (json.type) {
        case 'withoutData':
          return WithoutDeliveryResponsibleData.fromJson(json);
        case 'carrierData':
          return CarrierResponsibleData.fromJson(json);
        case 'delivererData':
          return DelivererResponsibleData.fromJson(json);
        case 'ownData':
          return DeliveryOwnResponsibleData.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class WithoutDeliveryResponsibleData extends DeliveryResponsibleData {
  constructor() {
    super();
  }

  isValid(): boolean {
    return true;
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'withoutData' });
  }

  static fromJson(json: any): WithoutDeliveryResponsibleData {
    return SgvJson.from.simple(json, WithoutDeliveryResponsibleData);
  }
}

export class CarrierResponsibleData extends DeliveryResponsibleData {
  constructor(public carrier: CarrierSummary = null) {
    super();
  }

  isValid(): boolean {
    return this.carrier !== null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'carrierData',
      carrier: this.carrier.toJson()
    });
  }

  static fromJson(json: any): CarrierResponsibleData {
    return json
      ? SgvJson.from.simple(json, CarrierResponsibleData, {
          carrier: CarrierSummary.fromJson(json.carrier)
        })
      : null;
  }
}

export class DelivererResponsibleData extends DeliveryResponsibleData {
  constructor(public deliverer: DelivererSummary = null) {
    super();
  }

  isValid(): boolean {
    return this.deliverer !== null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'delivererData',
      deliverer: this.deliverer.toJson()
    });
  }

  static fromJson(json: any): DelivererResponsibleData {
    return json
      ? SgvJson.from.simple(json, DelivererResponsibleData, {
          deliverer: DelivererSummary.fromJson(json.deliverer)
        })
      : null;
  }
}

export class DeliveryOwnResponsibleData extends DeliveryResponsibleData {
  constructor() {
    super();
  }

  isValid(): boolean {
    return true;
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'ownData' });
  }

  static fromJson(json: any): DeliveryOwnResponsibleData {
    return SgvJson.from.simple(json, DeliveryOwnResponsibleData);
  }
}
