import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Queue } from './queue';

export class Category implements DomainEntity {
  public readonly id = SgvId.gen();

  public name = '';

  public shortName = '';

  public description = '';

  public queue: Queue;

  static fromJson(json: any): Category {
    return json ? SgvJson.from.simple(json, Category) : null;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
}
