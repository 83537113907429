import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CurrentEcnpjService } from "@eceos/domain";
import { lastValueFrom } from "rxjs";

@Component({
  selector: 'app-ecnpj-upload-dialog',
  templateUrl: './ecnpj-upload-dialog.component.html',
  styleUrls: ['./ecnpj-upload-dialog.component.scss']
})
export class EcnpjUploadDialogComponent implements OnInit {
  selectedFile: File = null;
  pin = '';

  constructor(
    private service: CurrentEcnpjService,
    private dialogRef: MatDialogRef<EcnpjUploadDialogComponent>
  ) {}

  get isValid() {
    return this.selectedFile && this.pin && this.pin.length >= 3;
  }

  ngOnInit(): void {}

  onFileSelected(file: File) {
    this.selectedFile = file;
  }

  clear() {
    this.selectedFile = null;
    this.pin = '';
  }

  async submit() {
    if (await lastValueFrom(this.service.uploadNew(this.selectedFile, this.pin))) {
      this.dialogRef.close(true);
    }
  }
}
