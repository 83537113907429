import { JsonEntity, SgvJson } from '@eceos/arch';

export class Crt implements JsonEntity {
  constructor(
    readonly value: string = '',
    readonly description: string = ''
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Crt {
    return SgvJson.from.simple(json, Crt);
  }
}
