import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpComponent } from './sign-up.component';
import { PublicGuard } from '../shared/guards/public.guard';
import { SignUpConfirmEmailComponent } from './sign-up-confirm-email/sign-up-confirm-email.component';

export const routes: Routes = [
  {
    path: 'signup',
    component: SignUpComponent,
    data: { title: 'e-Ceos - Registre-se' },
    canActivate: [PublicGuard]
  },
  {
    path: 'signup/confirm-email',
    component: SignUpConfirmEmailComponent,
    data: { title: 'e-Ceos - Confirmação de email pendente' },
    canActivate: [PublicGuard]
  }
];

export const SignUpRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
