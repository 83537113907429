import { JsonEntity, SgvJson } from '@eceos/arch';
import { CarrierSummary } from '../../../carriers/carrier';
import { NFePresenceIndicator } from '../../../fiscal/nfes/presence-indicator/nfe-presence-indicator';
import { FreightModality } from '../../freight/freight-modality';

export abstract class NFeDeliveryType implements JsonEntity {
  constructor(
    public freightModality: FreightModality = null,
    public presenceIndicator: NFePresenceIndicator = null
  ) {}

  abstract toJson(): any;

  static fromJson(json: any): NFeDeliveryType {
    if (json && json.type) {
      switch (json.type) {
        case 'withoutCarrier':
          return WithoutCarrierNFeDeliveryType.fromJson(json);
        case 'fixedCarrier':
          return FixedCarrierNFeDeliveryType.fromJson(json);
        case 'ownCarrier':
          return OwnCarrierNFeDeliveryType.fromJson(json);
        case 'autoCarrier':
          return AutoCarrierNFeDeliveryType.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class WithoutCarrierNFeDeliveryType extends NFeDeliveryType {
  constructor(
    freightModality: FreightModality = null,
    presenceIndicator: NFePresenceIndicator = NFePresenceIndicator.default()
  ) {
    super(freightModality, presenceIndicator);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'withoutCarrier',
      freightModality: this.freightModality.toJson(),
      presenceIndicator: this.presenceIndicator.toJson()
    });
  }

  static fromJson(json: any): WithoutCarrierNFeDeliveryType {
    return json
      ? SgvJson.from.simple(json, WithoutCarrierNFeDeliveryType, {
          freightModality: FreightModality.fromJson(json.freightModality),
          presenceIndicator: NFePresenceIndicator.fromJson(json.presenceIndicator)
        })
      : null;
  }
}

export class FixedCarrierNFeDeliveryType extends NFeDeliveryType {
  constructor(
    public carrier: CarrierSummary = null,
    freightModality: FreightModality = null,
    presenceIndicator: NFePresenceIndicator = NFePresenceIndicator.default()
  ) {
    super(freightModality, presenceIndicator);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'fixedCarrier',
      freightModality: this.freightModality.toJson(),
      presenceIndicator: this.presenceIndicator.toJson(),
      carrier: this.carrier.toJson()
    });
  }

  static fromJson(json: any): FixedCarrierNFeDeliveryType {
    return json
      ? SgvJson.from.simple(json, FixedCarrierNFeDeliveryType, {
          freightModality: FreightModality.fromJson(json.freightModality),
          presenceIndicator: NFePresenceIndicator.fromJson(json.presenceIndicator),
          carrier: CarrierSummary.fromJson(json.carrier)
        })
      : null;
  }
}

export class OwnCarrierNFeDeliveryType extends NFeDeliveryType {
  constructor(
    freightModality: FreightModality = null,
    presenceIndicator: NFePresenceIndicator = NFePresenceIndicator.default()
  ) {
    super(freightModality, presenceIndicator);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'ownCarrier',
      freightModality: this.freightModality.toJson(),
      presenceIndicator: this.presenceIndicator.toJson()
    });
  }

  static fromJson(json: any): OwnCarrierNFeDeliveryType {
    return json
      ? SgvJson.from.simple(json, OwnCarrierNFeDeliveryType, {
          freightModality: FreightModality.fromJson(json.freightModality),
          presenceIndicator: NFePresenceIndicator.fromJson(json.presenceIndicator)
        })
      : null;
  }
}

export class AutoCarrierNFeDeliveryType extends NFeDeliveryType {
  constructor(
    freightModality: FreightModality = null,
    presenceIndicator: NFePresenceIndicator = NFePresenceIndicator.default()
  ) {
    super(freightModality, presenceIndicator);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'autoCarrier',
      freightModality: this.freightModality.toJson(),
      presenceIndicator: this.presenceIndicator.toJson()
    });
  }

  static fromJson(json: any): AutoCarrierNFeDeliveryType {
    return json
      ? SgvJson.from.simple(json, AutoCarrierNFeDeliveryType, {
          freightModality: FreightModality.fromJson(json.freightModality),
          presenceIndicator: NFePresenceIndicator.fromJson(json.presenceIndicator)
        })
      : null;
  }
}
