import { SgvId, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils, MathUtils } from '@eceos/common-utils';
import { FiscalProduct, FiscalProductSummary } from '../../fiscal-products';
import { Operatable, OperatableUnityLegacy } from '../../operatables';
import { FiscalUnity } from '../unities/fiscal-unity';
import { NFeFuel } from './fuel/nfe-fuel';
import { NFeItemTax } from './tax/nfe-item-tax';

export const PURCHASE_ORDER_NUMBER_PATTERN = '[0-9]{1,6}';
export const FCI_NUMBER_PATTERN = '[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}';

export abstract class NFeItem {
  constructor(
    readonly id: string = SgvId.gen(),
    public unitaryValue: number = null,
    public amount: number = 1,
    public name: string = null,
    public discount: number = 0,
    public freight: number = 0,
    public insurance: number = 0,
    public othersValue: number = 0,
    public purchaseOrderDescription: string = null,
    public purchaseOrderNumber: string = null,
    public fciNumber: string = null,
    public tax: NFeItemTax = new NFeItemTax(),
    public additionalInfo: string = null,
    public fuel: NFeFuel = null
  ) {}

  abstract get unityAcronym(): string;

  get total() {
    const _total =
      this.amount != null && this.unitaryValue != null
        ? MathUtils.round(this.amount * this.unitaryValue) - this.discount
        : 0;
    return _total > 0 ? _total : 0;
  }

  get valueWithouDiscount() {
    const _total =
      this.amount != null && this.unitaryValue != null
        ? MathUtils.round(this.amount * this.unitaryValue)
        : 0;
    return _total > 0 ? _total : 0;
  }

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any) {
    if (json && json.type) {
      switch (json.type) {
        case 'simple':
          return SimpleNFeItem.fromJson(json);
        case 'operatable':
          return OperatableNFeItem.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class SimpleNFeItem extends NFeItem {
  constructor(
    id = SgvId.gen(),
    public fiscalProduct: FiscalProductSummary = null,
    unitaryValue: number = 0,
    amount: number = 1,
    public unity: FiscalUnity = null,
    name: string = null,
    discount: number = 0,
    freight: number = 0,
    insurance: number = 0,
    othersValue: number = 0,
    purchaseOrderDescription: string = null,
    purchaseOrderNumber: string = null,
    fciNumber: string = null,
    tax: NFeItemTax = new NFeItemTax(),
    additionalInfo: string = null,
    fuel: NFeFuel = null
  ) {
    super(
      id,
      unitaryValue,
      amount,
      name,
      discount,
      freight,
      insurance,
      othersValue,
      purchaseOrderDescription,
      purchaseOrderNumber,
      fciNumber,
      tax,
      additionalInfo,
      fuel
    );
  }

  get unityAcronym() {
    return this.unity ? this.unity.acronym : 'UNID';
  }

  isValid(): boolean {
    return (
      this.fiscalProduct != null &&
      this.unitaryValue != null &&
      this.amount != null &&
      this.amount > 0 &&
      EceosValidatorsUtils.isValid(this.tax) &&
      EceosValidatorsUtils.isValidOptional(this.fuel)
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      fiscalProduct: this.fiscalProduct.toJson(),
      unity: this.unity ? this.unity.toJson() : null,
      tax: this.tax ? this.tax.toJson() : null,
      fuel: this.fuel ? this.fuel.toJson() : null,
      type: 'simple'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, SimpleNFeItem, {
      fiscalProduct: FiscalProduct.fromJson(json.fiscalProduct),
      unity: FiscalUnity.fromJson(json.unity),
      tax: NFeItemTax.fromJson(json.tax),
      fuel: NFeFuel.fromJson(json.fuel)
    });
  }
}

export class OperatableNFeItem extends NFeItem {
  constructor(
    id = SgvId.gen(),
    public operatable: Operatable = null,
    unitaryValue: number = 0,
    amount: number = 1,
    public unity: OperatableUnityLegacy = null,
    name: string = null,
    discount: number = 0,
    freight: number = 0,
    insurance: number = 0,
    othersValue: number = 0,
    purchaseOrderDescription: string = null,
    purchaseOrderNumber: string = null,
    fciNumber: string = null,
    tax: NFeItemTax = new NFeItemTax(),
    additionalInfo: string = null,
    fuel: NFeFuel = null
  ) {
    super(
      id,
      unitaryValue,
      amount,
      name,
      discount,
      freight,
      insurance,
      othersValue,
      purchaseOrderDescription,
      purchaseOrderNumber,
      fciNumber,
      tax,
      additionalInfo,
      fuel
    );
  }

  get unityAcronym() {
    return this.unity ? this.unity.acronym : 'UNID';
  }

  isValid(): boolean {
    return (
      this.operatable != null &&
      this.unitaryValue != null &&
      this.amount != null &&
      this.amount > 0 &&
      EceosValidatorsUtils.isValid(this.tax) &&
      EceosValidatorsUtils.isValidOptional(this.fuel)
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      operatable: this.operatable.toJson(),
      unity: this.unity ? this.unity.toJson() : null,
      tax: this.tax ? this.tax.toJson() : null,
      fuel: this.fuel ? this.fuel.toJson() : null,
      type: 'operatable'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, OperatableNFeItem, {
      operatable: Operatable.fromJson(json.operatable),
      unity: FiscalUnity.fromJson(json.unity),
      tax: NFeItemTax.fromJson(json.tax),
      fuel: NFeFuel.fromJson(json.fuel)
    });
  }
}

export abstract class NFeItemSummary {
  abstract toJson(): any;

  static from(entity: NFeItem): NFeItemSummary {
    if (entity instanceof SimpleNFeItem) {
      return SimpleNFeItemSummary.from(entity);
    } else if (entity instanceof OperatableNFeItem) {
      return OperatableNFeItemSummary.from(entity);
    }
    return null;
  }

  static fromJson(json: any): NFeItemSummary {
    if (json && json.type) {
      switch (json.type) {
        case 'simple':
          return SimpleNFeItemSummary.fromJson(json);
        case 'operatable':
          return OperatableNFeItemSummary.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class SimpleNFeItemSummary extends NFeItemSummary {
  constructor(public fiscalProduct: FiscalProductSummary = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'simple',
      fiscalProduct: this.fiscalProduct.toJson()
    });
  }

  static from(entity: SimpleNFeItem): SimpleNFeItemSummary {
    return entity ? new SimpleNFeItemSummary(entity.fiscalProduct) : null;
  }

  static fromJson(json: any): SimpleNFeItemSummary {
    return json
      ? SgvJson.from.simple(json, SimpleNFeItemSummary, {
          fiscalProduct: FiscalProduct.fromJson(json.fiscalProduct)
        })
      : null;
  }
}

export class OperatableNFeItemSummary extends NFeItemSummary {
  constructor(public operatable: Operatable = null) {
    super();
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'operatable',
      operatable: this.operatable.toJson()
    });
  }

  static from(entity: OperatableNFeItem): OperatableNFeItemSummary {
    return entity ? new OperatableNFeItemSummary(entity.operatable) : null;
  }

  static fromJson(json: any): OperatableNFeItemSummary {
    return json
      ? SgvJson.from.simple(json, OperatableNFeItemSummary, {
          operatable: Operatable.fromJson(json.operatable)
        })
      : null;
  }
}
