import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthManagerService,
  HttpStatusCode,
  InterceptorConfig,
  LOGIN_ROUTE,
  ManagerToken
} from '@eceos/arch';
import { CustomErrorStateMatcher, EceosValidators } from '@eceos/common-utils';
import {
  LeadsPublicRepository,
  LeadsRepository,
  LeadToUserOperation,
  Name
} from '@eceos/manager-domain';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const LEAD_SOURCE = 'eceos-signup';

interface AsyncAction {
  name: string;
  runningName: string;
  onAction(): Promise<void>;
}

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpComponent implements OnInit, OnDestroy {
  private _entity: LeadToUserOperation;

  private leadToken: ManagerToken;

  readonly privacyPolicy =
    'https://docs.google.com/document/d/e/2PACX-1vTxtAfe-jSf0qisY6U9X2E2XOlDS2sdMOM7lx0vuMUvLu0ThIorexwU2el4nCQNTozUmUMCBDIkFbUi/pub';
  readonly termsOfUse =
    'https://docs.google.com/document/d/e/2PACX-1vTQ0oZYY674EQUvW5xiZE3Jj5NCWiaYu9le_ZU42301n8BJiqN8-oT6dZkl9vdPlNUA9OZQu5hYPUEH/pub';

  readonly errorStateMatcher = new CustomErrorStateMatcher();

  form: FormGroup;

  completed = false;

  @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;

  readonly toLoginFn = () => this.toLogin();

  // tslint:disable-next-line: member-ordering
  readonly completeAction: AsyncAction = {
    name: 'Finalizar Cadastro',
    runningName: 'Finalizando Cadastro..',
    onAction: () => this.onCompleteClick()
  };

  // tslint:disable-next-line: member-ordering
  readonly emailFormCompleteAction: AsyncAction = {
    name: 'Próximo',
    runningName: 'Carregando..',
    onAction: () => this.onEmailNextClick()
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private reCaptchaService: ReCaptchaV3Service,
    private leadsRepository: LeadsRepository,
    private leadsPublicRepository: LeadsPublicRepository,
    private authManagerService: AuthManagerService,
    private snackbar: MatSnackBar,
    @Inject(LOGIN_ROUTE) private loginRoute: string
  ) {
    this.route.queryParams.subscribe(params => {
      this.publishLeadToken(params['lead']);
      this.entity = new LeadToUserOperation();
      if (this.hasLeadToken) {
        setTimeout(() => {
          this.onEmailNextClick();
          this.stepToGeneralData();
        }, 1);
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.authManagerService.publishLogout();
  }

  get entity(): LeadToUserOperation {
    return this._entity;
  }

  set entity(entity: LeadToUserOperation) {
    if (this._entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get emailGroup(): FormGroup {
    return this.form.get('email') as FormGroup;
  }

  get confirmEmailControlValue(): string {
    return this.emailGroup.get('confirmEmail').value;
  }

  get generalDataGroup(): FormGroup {
    return this.form.get('generalData') as FormGroup;
  }

  get nameGroup(): FormGroup {
    return this.generalDataGroup.get('name') as FormGroup;
  }

  get passwordGroup(): FormGroup {
    return this.form.get('password') as FormGroup;
  }

  get acceptTermsAndPolicyControl(): AbstractControl {
    return this.form.get('acceptTermsAndPolicy');
  }

  get hasLeadToken(): boolean {
    return this.authManagerService.hasToken && Boolean(this.leadToken);
  }

  get leadEmail(): string {
    return this.leadToken ? this.leadToken.email : null;
  }

  async onEmailNextClick() {
    if (this.emailGroup.invalid) {
      throw new Error('Email group is not valid!');
    }
    if (!this.hasLeadToken) {
      let leadToken = null;
      this.emailGroup.disable();
      try {
        leadToken = await lastValueFrom(this.leadsPublicRepository
          .token(this.confirmEmailControlValue, LEAD_SOURCE, {
            autoCatch: InterceptorConfig.NO_INTERCEPT
          }));
        this.stepToGeneralData();
      } catch (e) {
        if (e instanceof HttpErrorResponse && e.status === HttpStatusCode.CONFLICT) {
          this.showMessage('Já existe usuário cadastrado com este e-mail!');
        }
      } finally {
        this.publishLeadToken(leadToken);
        this.emailGroup.enable();
      }
    }
    this.executeAction(RecaptchaAction.EMAIL_FORM);
  }

  onGeneralDataNextClick() {
    this.executeAction(RecaptchaAction.GENERAL_DATA_FORM);
  }

  toLogin() {
    const queryParams = this.leadEmail ? { username: this.leadEmail } : {};
    this.router.navigate([this.loginRoute], { queryParams: queryParams });
  }

  async onCompleteClick() {
    if (this.form.invalid) {
      throw new Error('Can not complete sign up, form is not valid!');
    }
    this.publishDisable(true);
    await this.executeAction(RecaptchaAction.LEAD_TO_USER);
    try {
      await lastValueFrom(this.leadsRepository.sendOperation(this.entity));
      this.completed = true;
    } catch (err) {
      this.showMessage('Falha ao completar cadastro!');
    } finally {
      this.publishDisable(false);
    }
  }

  private createFormFromEntity() {
    this.form = null;
    if (this.entity) {
      this.form = this.formBuilder.group({
        email: this.formBuilder.group(
          {
            email: [this.leadEmail, [Validators.required, Validators.email]],
            confirmEmail: [this.leadEmail, [Validators.required, Validators.email]]
          },
          { validator: EceosValidators.matches('email', 'confirmEmail') }
        ),
        generalData: this.formBuilder.group({
          name: this.formBuilder.group({
            first: ['', Validators.required],
            last: ['', Validators.required]
          }),
          phone: ['', Validators.required]
        }),
        password: this.formBuilder.group(
          {
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required]
          },
          { validator: EceosValidators.matches('password', 'confirmPassword') }
        ),
        acceptTermsAndPolicy: [false, Validators.requiredTrue]
      });

      this.addFormValueChange(
        value =>
          new LeadToUserOperation(
            new Name(value.generalData.name.first, value.generalData.name.last),
            value.generalData.phone,
            value.password.confirmPassword,
            this.entity.recaptchaToken
          )
      );
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (f: any) => LeadToUserOperation) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this._entity = value;
      });
    this.acceptTermsAndPolicyControl.valueChanges.pipe(distinctUntilChanged()).subscribe(_ => {
      this.executeAction(RecaptchaAction.ACCEPT_TERMS_AND_POLICY);
    });
  }

  private async executeAction(action: RecaptchaAction) {
    try {
      const recaptchaToken = await lastValueFrom(this.reCaptchaService.execute(action.key));
      this.entity.recaptchaToken = recaptchaToken;
    } catch (err) {
      console.error(err);
      this.entity.recaptchaToken = null;
    }
  }

  private publishLeadToken(token: string) {
    try {
      this.leadToken = ManagerToken.decode(token);
      this.authManagerService.publishLogin(token);
    } catch (error) {
      console.warn('Invalid lead token -> ', token);
      this.leadToken = null;
      this.authManagerService.publishLogout();
    }
  }

  private stepToGeneralData() {
    if (this.stepper) {
      this.stepper.selectedIndex = 1;
    }
  }

  private publishDisable(disabled: boolean) {
    if (disabled && this.form.enabled) {
      this.form.disable();
    } else if (!disabled && this.form.disabled) {
      this.form.enable();
    }
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }
}

class RecaptchaAction {
  static EMAIL_FORM = new RecaptchaAction('emailForm');
  static GENERAL_DATA_FORM = new RecaptchaAction('generalDataForm');
  static ACCEPT_TERMS_AND_POLICY = new RecaptchaAction('acceptTermsAndPolicy');
  static LEAD_TO_USER = new RecaptchaAction('leadToUser');
  private constructor(readonly key: string) {}
}
