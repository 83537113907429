import { Injectable } from '@angular/core';
import {
  ApiService,
  Filter,
  FilterOp,
  InterceptorConfig,
  PageData,
  RepositoryImpl
} from '@eceos/arch';
import { lastValueFrom } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { SaleAction } from './sale-action';
import { SaleActionEvent } from './sale-action-event';

@Injectable({ providedIn: 'root' })
export class SaleActionRepository extends RepositoryImpl<SaleAction, SaleAction> {
  constructor(api: ApiService) {
    super(api.root.path('/saleActions'), SaleAction, SaleAction);
  }

  get(event: SaleActionEvent): Observable<SaleAction> {
    const filters = [new Filter('event', FilterOp.EQ, event)];
    return this.page(new PageData(0, 1), '', filters).pipe(map(value => (value ? value[0] : null)));
  }

  async save(action: SaleAction): Promise<SaleAction> {
    if (action) {
      let entity = null;
      try {
        entity = await lastValueFrom(this.findSummary(action.id, {
          autoCatch: InterceptorConfig.NO_INTERCEPT
        }));
      } catch (e) {
        entity = null;
      }

      if (entity) {
        return lastValueFrom(this.update(action));
      } else {
        return lastValueFrom(this.insert(action));
      }
    }
  }
}
