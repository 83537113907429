import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class Balance implements DomainEntity {
    constructor(
        readonly id = SgvId.gen(),
        readonly name = '',
        readonly company = '',
        readonly model = '',
        readonly fileName = '',
    ) { }

    toJson() {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): Balance {
        return SgvJson.from.simple(json, Balance)
    }
}