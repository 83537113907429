import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";
import { ProductCategorySummary } from ".";

export class ProductCategory implements DomainEntity{
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly details: string = '',
        public readonly parent: ProductCategorySummary = new ProductCategorySummary(),
        public readonly isActive: boolean = true
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this, {
            parent: this.parent ? this.parent.toJson() : null
        });
    }

    static fromJson(json: any): ProductCategory {
        return json 
            ? SgvJson.from.simple(json, ProductCategory, {
                parent: json.parent ? ProductCategorySummary.fromJson(json.parent) : null
        }) : null;
    }
}