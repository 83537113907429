import { SgvId, SgvJson } from '@eceos/arch';
import {
  ICMS,
  ICMS00,
  ICMS10,
  ICMS20,
  ICMS30,
  ICMS40to50,
  ICMS51,
  ICMS60,
  ICMS61,
  ICMS70,
  ICMS90,
  ICMSST,
  ICMSSN101,
  ICMSSN102to103and300to400,
  ICMSSN201,
  ICMSSN202to203,
  ICMSSN500,
  ICMSSN900,
  IcmsCst,
  IcmsMonoRetWithValue,
  IcmsSnWithCreditValue,
  IcmsStPassedOn,
  IcmsWithDeferralValue,
  IcmsWithDesoneration,
  IcmsWithDesonerationWithoutTax,
  IcmsWithFcpStValue,
  IcmsWithFcpValue,
  NFeItem,
  ProductOrigin
} from '../../../nfes';
import { IcmsStWithBc } from './icms-st-with-bc';
import { IcmsWithBc } from './icms-with-bc';

export abstract class IcmsConfig {
  constructor(
    readonly id: string = SgvId.gen(),
    public cst: IcmsCst = null,
    public productOrigin: ProductOrigin = null
  ) {}

  abstract toICMS(nfeItem?: NFeItem, icmsPassedOn?: IcmsStPassedOn, isEndConsumer?: boolean): ICMS;

  abstract toJson(): any;

  static fromJson(json: any): IcmsConfig {
    if (json && json.type) {
      switch (json.type) {
        case 'Icms00Config':
          return Icms00Config.fromJson(json);
        case 'Icms10Config':
          return Icms10Config.fromJson(json);
        case 'Icms20Config':
          return Icms20Config.fromJson(json);
        case 'Icms30Config':
          return Icms30Config.fromJson(json);
        case 'Icms40to50Config':
          return Icms40to50Config.fromJson(json);
        case 'Icms51Config':
          return Icms51Config.fromJson(json);
        case 'Icms60Config':
          return Icms60Config.fromJson(json);
        case 'Icms61Config':
          return Icms61Config.fromJson(json);
        case 'Icms70Config':
          return Icms70Config.fromJson(json);
        case 'Icms90Config':
          return Icms90Config.fromJson(json);
        case 'IcmsStConfig':
          return IcmsStConfig.fromJson(json);
        case 'IcmsSn101Config':
          return IcmsSn101Config.fromJson(json);
        case 'IcmsSn102to103And300to400Config':
          return IcmsSn102to103and300to400Config.fromJson(json);
        case 'IcmsSn201Config':
          return IcmsSn201Config.fromJson(json);
        case 'IcmsSn202to203Config':
          return IcmsSn202to203Config.fromJson(json);
        case 'IcmsSn500Config':
          return IcmsSn500Config.fromJson(json);
        case 'IcmsSn900Config':
          return IcmsSn900Config.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class Icms00Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = new IcmsWithBc(),
    public fcpAliquot: number = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS00 {
    return new ICMS00(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icms ? this.icms.toValue(nfeItem) : null,
      IcmsWithFcpValue.fromAliquot(this.fcpAliquot),
      this.beneficiaryCode
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'Icms00Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, Icms00Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms)
    });
  }
}
export class Icms10Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = new IcmsWithBc(),
    public icmsSt: IcmsStWithBc = new IcmsStWithBc(),
    public fcpAliquot: number = null,
    public fcpStAliquot: number = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS10 {
    return new ICMS10(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icms ? this.icms.toValue(nfeItem) : null,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpValue.fromAliquot(this.fcpAliquot),
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot),
      this.beneficiaryCode
    );
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'Icms10Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, Icms10Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt)
    });
  }
}

export class Icms20Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = new IcmsWithBc(),
    public fcpAliquot: number = null,
    public desoneration: IcmsWithDesoneration = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS20 {
    const icms = this.icms ? this.icms.toValue(nfeItem) : null;
    return new ICMS20(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      icms,
      IcmsWithFcpValue.fromAliquot(this.fcpAliquot),
      this.desoneration ? this.desoneration.toValue(this.cst, icms) : null,
      this.beneficiaryCode
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms20Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): Icms20Config {
    return SgvJson.from.simple(json, Icms20Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms),
      desoneration: IcmsWithDesoneration.fromJson(json.desoneration)
    });
  }
}

export class Icms30Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsSt: IcmsStWithBc = new IcmsStWithBc(),
    public fcpStAliquot: number = null,
    public desoneration: IcmsWithDesonerationWithoutTax = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS30 {
    return new ICMS30(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot),
      this.desoneration ? this.desoneration.toValue(nfeItem, this.cst) : null,
      this.beneficiaryCode
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms30Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): Icms30Config {
    return SgvJson.from.simple(json, Icms30Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt),
      desoneration: IcmsWithDesonerationWithoutTax.fromJson(json.desoneration)
    });
  }
}

export class Icms40to50Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public desoneration: IcmsWithDesonerationWithoutTax = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS40to50 {
    return new ICMS40to50(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.desoneration ? this.desoneration.toValue(nfeItem, this.cst) : null,
      this.beneficiaryCode
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms40to50Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): Icms40to50Config {
    return SgvJson.from.simple(json, Icms40to50Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      desoneration: IcmsWithDesonerationWithoutTax.fromJson(json.desoneration)
    });
  }
}

export class Icms51Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = new IcmsWithBc(),
    public fcpAliquot: number = null,
    public deferralPercent: number = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS51 {
    return new ICMS51(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icms ? this.icms.toValue(nfeItem) : null,
      IcmsWithFcpValue.fromAliquot(this.fcpAliquot),
      IcmsWithDeferralValue.fromPercent(this.deferralPercent),
      null,
      this.beneficiaryCode
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms51Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null
    });
  }

  static fromJson(json: any): Icms51Config {
    return SgvJson.from.simple(json, Icms51Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms)
    });
  }
}

export class Icms60Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem, icmsPassedOn: IcmsStPassedOn, isEndConsumer: boolean): ICMS60 {
    const icms = new ICMS60(SgvId.gen(), this.cst, this.productOrigin, null, this.beneficiaryCode);
    icms.setDefaultIcmsStRetained(isEndConsumer);
    if (icms.icmsStRetained) {
      icms.icmsStRetained.publishValues(nfeItem, icmsPassedOn, isEndConsumer);
    }
    return icms;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms60Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): Icms60Config {
    return SgvJson.from.simple(json, Icms60Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin)
    });
  }
}

export class Icms61Config extends IcmsConfig {
  constructor(
    id: string = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsMonoRetWithValue = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(): ICMS61 {
    return new ICMS61(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icms
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms61Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null
    });
  }

  static fromJson(json: any): Icms61Config {
    return SgvJson.from.simple(json, Icms61Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsMonoRetWithValue.fromJson(json.icms)
    });
  }
}

export class Icms70Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = new IcmsWithBc(),
    public icmsSt: IcmsStWithBc = new IcmsStWithBc(),
    public fcpAliquot: number = null,
    public fcpStAliquot: number = null,
    public desoneration: IcmsWithDesoneration = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS70 {
    const icms = this.icms ? this.icms.toValue(nfeItem) : null;
    return new ICMS70(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      icms,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpValue.fromAliquot(this.fcpAliquot),
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot),
      this.desoneration ? this.desoneration.toValue(this.cst, icms) : null,
      this.beneficiaryCode
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms70Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): Icms70Config {
    return SgvJson.from.simple(json, Icms70Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt),
      desoneration: IcmsWithDesoneration.fromJson(json.desoneration)
    });
  }
}

export class Icms90Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = new IcmsWithBc(),
    public icmsSt: IcmsStWithBc = new IcmsStWithBc(),
    public fcpAliquot: number = null,
    public fcpStAliquot: number = null,
    public desoneration: IcmsWithDesoneration = null,
    public beneficiaryCode: string = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMS90 {
    const icms = this.icms ? this.icms.toValue(nfeItem) : null;
    return new ICMS90(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      icms,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpValue.fromAliquot(this.fcpAliquot),
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot),
      this.desoneration ? this.desoneration.toValue(this.cst, icms) : null,
      this.beneficiaryCode
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'Icms90Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null,
      desoneration: this.desoneration ? this.desoneration.toJson() : null
    });
  }

  static fromJson(json: any): Icms90Config {
    return SgvJson.from.simple(json, Icms90Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt),
      desoneration: IcmsWithDesoneration.fromJson(json.desoneration)
    });
  }
}

export class IcmsStConfig extends IcmsConfig {
  constructor(id = SgvId.gen(), cst: IcmsCst = null, productOrigin: ProductOrigin = null) {
    super(id, cst, productOrigin);
  }

  toICMS(): ICMSST {
    return new ICMSST(SgvId.gen(), this.cst, this.productOrigin);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsStConfig',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): IcmsStConfig {
    return SgvJson.from.simple(json, IcmsStConfig, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin)
    });
  }
}

export class IcmsSn101Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public creditSnAliquot: number = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMSSN101 {
    return new ICMSSN101(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      IcmsSnWithCreditValue.fromAliquot(this.creditSnAliquot, nfeItem.total)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn101Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSn101Config {
    return SgvJson.from.simple(json, IcmsSn101Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin)
    });
  }
}

export class IcmsSn102to103and300to400Config extends IcmsConfig {
  constructor(id = SgvId.gen(), cst: IcmsCst = null, productOrigin: ProductOrigin = null) {
    super(id, cst, productOrigin);
  }

  toICMS(): ICMSSN102to103and300to400 {
    return new ICMSSN102to103and300to400(SgvId.gen(), this.cst, this.productOrigin);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn102to103And300to400Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSn102to103and300to400Config {
    return SgvJson.from.simple(json, IcmsSn102to103and300to400Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin)
    });
  }
}

export class IcmsSn201Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsSt: IcmsStWithBc = new IcmsStWithBc(),
    public fcpStAliquot: number = null,
    public creditSnAliquot: number = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMSSN201 {
    return new ICMSSN201(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot),
      IcmsSnWithCreditValue.fromAliquot(this.creditSnAliquot, nfeItem.total)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn201Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSn201Config {
    return SgvJson.from.simple(json, IcmsSn201Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt)
    });
  }
}

export class IcmsSn202to203Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icmsSt: IcmsStWithBc = new IcmsStWithBc(),
    public fcpStAliquot: number = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMSSN202to203 {
    return new ICMSSN202to203(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn202to203Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSn202to203Config {
    return SgvJson.from.simple(json, IcmsSn202to203Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt)
    });
  }
}

export class IcmsSn500Config extends IcmsConfig {
  constructor(id = SgvId.gen(), cst: IcmsCst = null, productOrigin: ProductOrigin = null) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem, icmsPassedOn: IcmsStPassedOn, isEndConsumer: boolean): ICMS {
    const icms = new ICMSSN500(SgvId.gen(), this.cst, this.productOrigin);
    icms.setDefaultIcmsStRetained(isEndConsumer);
    if (icms.icmsStRetained) {
      icms.icmsStRetained.publishValues(nfeItem, icmsPassedOn, isEndConsumer);
    }
    return icms;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn500Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSn500Config {
    return SgvJson.from.simple(json, IcmsSn500Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin)
    });
  }
}

export class IcmsSn900Config extends IcmsConfig {
  constructor(
    id = SgvId.gen(),
    cst: IcmsCst = null,
    productOrigin: ProductOrigin = null,
    public icms: IcmsWithBc = null,
    public icmsSt: IcmsStWithBc = null,
    public fcpStAliquot: number = null,
    public creditSnAliquot: number = null
  ) {
    super(id, cst, productOrigin);
  }

  toICMS(nfeItem: NFeItem): ICMSSN900 {
    const icms = this.icms ? this.icms.toValue(nfeItem) : null;
    return new ICMSSN900(
      SgvId.gen(),
      this.cst,
      this.productOrigin,
      icms,
      this.icmsSt ? this.icmsSt.toValue(nfeItem) : null,
      IcmsWithFcpStValue.fromAliquot(this.fcpStAliquot),
      IcmsSnWithCreditValue.fromAliquot(
        this.creditSnAliquot,
        icms && icms.bcValue ? icms.bcValue.value : null
      )
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'IcmsSn900Config',
      cst: this.cst ? this.cst.toJson() : null,
      productOrigin: this.productOrigin ? this.productOrigin.toJson() : null,
      icms: this.icms ? this.icms.toJson() : null,
      icmsSt: this.icmsSt ? this.icmsSt.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSn900Config {
    return SgvJson.from.simple(json, IcmsSn900Config, {
      cst: IcmsCst.fromJson(json.cst),
      productOrigin: ProductOrigin.fromJson(json.productOrigin),
      icms: IcmsWithBc.fromJson(json.icms),
      icmsSt: IcmsStWithBc.fromJson(json.icmsSt)
    });
  }
}
