import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { ReferencedECFModel } from './referenced-ecf-model';

@Injectable({ providedIn: 'root' })
export class ReferencedECFModelRepository extends ReadonlyRepositoryImpl<
  ReferencedECFModel,
  ReferencedECFModel
> {
  constructor(api: ApiService) {
    super(api.root.path('referencedECFModels'), ReferencedECFModel, ReferencedECFModel);
  }
}
