import { SgvId, DomainEntity, SgvJson } from '@eceos/arch';

export class NFeDuplicate implements DomainEntity {
  readonly id: string = SgvId.gen();
  constructor(public number: string = '', public due: Date = null, public value: number = 0) {}

  toJson() {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): NFeDuplicate {
    return json
      ? SgvJson.from.simple(json, NFeDuplicate, {
          due: json.due ? new Date(json.due) : null
        })
      : null;
  }
}
