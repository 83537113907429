import { Directive, HostListener, EventEmitter, Input, Output, ElementRef, Optional } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Directive({
  selector: '[swipeTab]'
})
export class SwipeTabDirective {
  constructor(@Optional() private tabGroup: MatTabGroup) {}

  @Input()
  set swipeTab(group: MatTabGroup) {
    if (group) {
      this.tabGroup = group;
    }
  }

  private get tabs() {
    return this.tabGroup._tabs.length;
  }

  private get selectedIndex() {
    return this.tabGroup.selectedIndex;
  }

  private set selectedIndex(index: number) {
    this.tabGroup.selectedIndex = index;
  }

  @HostListener('swipeleft', ['$event'])
  onSwipeleft(event) {
    if (this.tabGroup) {
      if (this.selectedIndex + 1 <= this.tabs - 1) {
        this.selectedIndex += 1;
      }
    } else {
      console.error('swipeTab sem TabGroup definido');
    }
  }

  @HostListener('swiperight', ['$event'])
  onSwiperight(event) {
    if (this.tabGroup) {
      if (this.selectedIndex - 1 >= 0) {
        this.selectedIndex -= 1;
      }
    } else {
      console.error('swipeTab sem TabGroup definido');
    }
  }
}
