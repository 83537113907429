import { DomainEntity, JsonEntity, SgvId, SgvJson } from '@eceos/arch';
import { FiscalUnity } from '../../fiscal';

export abstract class OperatableUnity implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public acronym = '',
    public name  = '',
    public fiscalData: OperatableUnityFiscalData = null
  ) { }

  abstract get isSimpleUnity(): boolean;

  abstract get isWeightableUnity(): boolean;

  abstract get isHourlyUnity(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): OperatableUnity {
    if (!json) return null;

    switch (json.type) {
      case 'simple':
        return SimpleOperatableUnity.fromJson(json);
      case 'weightable':
        return WeightableOperatableUnity.fromJson(json);
      case 'hourly':
        return HourlyOperatableUnity.fromJson(json);
      default:
        throw new Error('Operatable Unity type not mapped');
    }
  }
}

export class SimpleOperatableUnity extends OperatableUnity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public acronym: string = '',
    public fiscalData: OperatableUnityFiscalData = null
  ) {
    super(id, name, acronym, fiscalData);
  }

  get isSimpleUnity(): boolean {
    return true;
  }

  get isWeightableUnity(): boolean {
    return false;
  }

  get isHourlyUnity(): boolean {
    return false;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'simple',
      fiscalData: this.fiscalData ? SgvJson.to.simple(this.fiscalData) : null
    });
  }

  static fromJson(json: any): SimpleOperatableUnity {
    return json 
      ? SgvJson.from.simple(json, SimpleOperatableUnity, {
        fiscalData: OperatableUnityFiscalData.fromJson(json.fiscalData)
    }) : null;
  }
}

export class WeightableOperatableUnity extends OperatableUnity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public acronym: string = '',
    public fiscalData: OperatableUnityFiscalData = null
  ) {
    super(id, name, acronym, fiscalData);
  }

  get isSimpleUnity(): boolean {
    return false;
  }
  get isWeightableUnity(): boolean {
    return true;
  }
  get isHourlyUnity(): boolean {
    return false;
  }
  
  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'weightable',
      fiscalData: this.fiscalData ? SgvJson.to.simple(this.fiscalData) : null
    });
  }

  static fromJson(json: any): WeightableOperatableUnity {
    return json ? SgvJson.from.simple(json, WeightableOperatableUnity, {
      fiscalData: json.fiscalData ? OperatableUnityFiscalData.fromJson(json.fiscalData) : null
    }) : null;
  }
}

export class HourlyOperatableUnity extends OperatableUnity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public acronym: string = '',
    public fiscalData: OperatableUnityFiscalData = null
  ) {
    super(id, name, acronym, fiscalData);
  }

  get isSimpleUnity(): boolean {
    return false;
  }
  get isWeightableUnity(): boolean {
    return false;
  }
  get isHourlyUnity(): boolean {
    return true;
  }

  toJson(): any {
    console.log(this.fiscalData);
    
    return SgvJson.to.simple(this, {
      type: 'hourly',
      fiscalData: this.fiscalData ? SgvJson.to.simple(this.fiscalData) : null
    });
  }

  static fromJson(json: any): HourlyOperatableUnity {
    return json ? SgvJson.from.simple(json, HourlyOperatableUnity, {
      fiscalData: json.fiscalData ? OperatableUnityFiscalData.fromJson(json.fiscalData) : null
    }) : null;
  }
}

export class OperatableUnityFiscalData{
  constructor(
    public fiscalUnity: FiscalUnity = null,
    public conversionRate: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      fiscalUnity: this.fiscalUnity ? this.fiscalUnity.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json ? SgvJson.from.simple(json, OperatableUnityFiscalData, {
      fiscalUnity: FiscalUnity.fromJson(json.fiscalUnity)
    }) : null;
  }
}