import { Injectable } from '@angular/core';
import { ApiService, PageData, ReadonlyRepositoryImpl } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GovbrSpecialTaxationRegime } from '../special-taxation-regime';

@Injectable({ providedIn: 'root' })
export class GovbrSpecialTaxationRegimeRepository extends ReadonlyRepositoryImpl<
  GovbrSpecialTaxationRegime,
  GovbrSpecialTaxationRegime
> {
  constructor(api: ApiService) {
    super(
      api.root.path('fiscal/settings/nfes/govbrTaxations'),
      GovbrSpecialTaxationRegime,
      GovbrSpecialTaxationRegime
    );
  }

  getFirst(): Observable<GovbrSpecialTaxationRegime> {
    return this.page(new PageData(0, 1)).pipe(map((value) => (value ? value[0] : null)));
  }
}