import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { NCM } from './ncm';

@Injectable({providedIn:'root'})
export class NCMRepository extends RepositoryImpl<NCM, NCM>{

  constructor(api: ApiService) {
    super(api.root.path('/fiscal/ncms'), NCM, NCM);
  }

}

