import { Injectable } from '@angular/core';
import { Movidesk, MovideskUser } from '@eceos/arch';
import { filter, map } from 'rxjs';
import { CurrentInfo, CurrentInfoService as CurrentService } from '../current';
import { ExternalUser } from '../users';
import { AuthenticatedPdv, AuthenticatedUser } from '../core/authenticated';

@Injectable({ providedIn: 'root' })
export class MovideskService {
  private movidesk: Movidesk = null;
  private user: MovideskUser = null;

  constructor(loginMetadataService: CurrentService) {
    loginMetadataService.$value
      .pipe(
        filter((p) => Boolean(p.partner)),
        map((v) => ({
          movidesk: new Movidesk(v.partner.widgetCode),
          user: this.movideskUserFrom(v)
        }))
      )
      .subscribe((m) => {
        this.movidesk = m.movidesk;
        this.user = m.user;
      });
  }

  get isEnabled() {
    return this.movidesk !== null;
  }

  openChat() {
    if (this.isEnabled) {
      window.open(this.movidesk.buildChatLink({ user: this.user }), '_blank');
    }
  }

  private movideskUserFrom(metadata: CurrentInfo): MovideskUser {
    if (metadata.authenticated instanceof AuthenticatedUser) {
      const user = metadata.authenticated.user;
      return {
        name: `${user.name} (${user.login}@${metadata.enterprise.identifier})`,
        email: user instanceof ExternalUser ? user.email : '',
        phoneNumber: '',
        codeReference: user.id,
        organizationCodeReference: metadata.enterprise.identifier
      };
    } else if (metadata.authenticated instanceof AuthenticatedPdv) {
      const pdv = metadata.authenticated.pdv;
      return {
        name: `PDV: ${pdv.name} (${pdv.identifier}@${metadata.enterprise.identifier})`,
        email: '',
        phoneNumber: '',
        codeReference: pdv.id,
        organizationCodeReference: metadata.enterprise.identifier
      };
    } else {
      return null;
    }
  }
}
