<h2 mat-dialog-title>
  <mat-icon>warning</mat-icon>
  Excluir este registro?
</h2>
<mat-dialog-content>
  <button
    mat-stroked-button
    *ngIf="data.allowTemporary"
    color="accent"
    [mat-dialog-close]="tempResult"
  >
    Excluir temporáriamente
  </button>
  <button
    mat-stroked-button
    *ngIf="data.allowPermanent"
    color="warn"
    [mat-dialog-close]="permResult"
  >
    Excluir permanentemente
  </button>
  <button mat-button mat-dialog-close>
    Voltar
  </button>
</mat-dialog-content>
