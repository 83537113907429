import { Injectable } from '@angular/core';
import { ApiPath, ApiService, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NfseFiscalDocumentSetting } from './nfse-fiscal-document-setting';

@Injectable({ providedIn: 'root' })
export class NfseFiscalDocumentSettingRepository {
  private api: ApiPath;

  metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(api: ApiService) {
    this.api = api.root.path('fiscal/settings/nfes/fiscalDocuments');
  }

  getActual(): Observable<NfseFiscalDocumentSetting> {
    return this.api
      .getJson({ metadata: { autoCatch: InterceptorConfig.NO_INTERCEPT } })
      .pipe(map((json) => NfseFiscalDocumentSetting.fromJson(json)));
  }

  update(
    nfseFiscalDocumentSetting: NfseFiscalDocumentSetting
  ): Observable<NfseFiscalDocumentSetting> {
    return this.api
      .putJson(nfseFiscalDocumentSetting.toJson(), { metadata: this.metadata })
      .pipe(map((json) => NfseFiscalDocumentSetting.fromJson(json)));
  }

  delete(): Observable<NfseFiscalDocumentSetting> {
    return this.api.delete({ metadata: this.metadata });
  }
}
