import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Address } from '../core/address';
import {
  Documents,
  ForeignPersonDocuments,
  LegalEntityDocuments,
  NaturalPersonDocuments
} from '../core/documents';
import { MailContact } from '../core/mail-contact';
import { PhoneContact } from '../core/phone-contact';
import { BrazilianAddress, SimpleForeignAddress } from './../core/address/address';


export class CarrierSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly cnp = ''
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): CarrierSummary {
    return json ? SgvJson.from.simple(json, CarrierSummary) : null;
  }
}

export class Carrier implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public documents: Documents = new LegalEntityDocuments(),
    public address: Address = new BrazilianAddress(),
    public emails: MailContact[] = [],
    public phones: PhoneContact[] = [],
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  get isNaturalPerson() {
    return this.documents instanceof NaturalPersonDocuments;
  }

  get isLegalEntity() {
    return this.documents instanceof LegalEntityDocuments;
  }

  get isForeignPerson() {
    return this.documents instanceof ForeignPersonDocuments;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      documents: this.documents ? this.documents.toJson() : null,
      address: this.address ? this.address.toJson() : null,
      contacts: [
        ...this.emails
          .map(c => c.value)
          .filter(c => c && c !== '')
          .map(c => ({
            type: 'EMAIL',
            detail: c
          })),
        ...this.phones
          .map(c => c.value)
          .filter(c => c && c !== '')
          .map(c => ({
            type: 'TELEFONE',
            detail: c
          }))
      ]
    });
  }

  static naturalPerson(): Carrier {
    const entity = new Carrier();
    entity.documents = new NaturalPersonDocuments();
    return entity;
  }

  static legalEntity(): Carrier {
    const entity = new Carrier();
    entity.documents = new LegalEntityDocuments();
    return entity;
  }

  static foreignPerson(): Carrier {
    const entity = new Carrier();
    entity.documents = new ForeignPersonDocuments();
    entity.address = new SimpleForeignAddress();
    return entity;
  }

  static fromJson(json: any): Carrier {
    if (!json) {
      return null;
    }
    return SgvJson.from.simple(json, Carrier, {
      documents: json.documents ? Documents.fromJson(json.documents) : null,
      address: json.address ? Address.fromJson(json.address) : null,
      emails: json.contacts
        .filter((c: any) => c.type === 'EMAIL' || c.type === 'EMAIL_NFE')
        .filter(c => c.detail && c.detail !== '')
        .map((c: any) => new MailContact(c.detail)),
      phones: json.contacts
        .filter((c: any) => c.type === 'TELEFONE' || c.type === 'CELULAR')
        .filter(c => c.detail && c.detail !== '')
        .map((c: any) => new PhoneContact(c.detail))
    });
  }
}
