import { JsonEntity, SgvJson } from '@eceos/arch';
import { City } from '../addresses/city';
import { ZipCode } from '../addresses/zipCode';

export abstract class RegionCoverage implements JsonEntity {
  abstract toJson(): any;

  static fromJson(json: any): RegionCoverage {
    if (json?.type) {
      switch (json.type) {
        case 'zipCode':
          return ZipCodeCoverage.fromJson(json);
        case 'city':
          return CityCoverage.fromJson(json);
        default:
          return null;
      }
    }

    return null;
  }
}

export class ZipCodeCoverage extends RegionCoverage {
  constructor(public zipCode: ZipCode = null) {
    super();
  }

  toJson(): any {
    return {
      type: 'zipCode',
      zipCode: this.zipCode.toJson()
    };
  }

  static fromJson(json: any): ZipCodeCoverage {
    return SgvJson.from.simple(json, ZipCodeCoverage, {
      zipCode: ZipCode.fromJson(json?.zipCode)
    });
  }
}

export class CityCoverage extends RegionCoverage {
  constructor(public city: City = null) {
    super();
  }

  toJson(): any {
    return {
      type: 'city',
      city: this.city.toJson()
    };
  }

  static fromJson(json: any): CityCoverage {
    return SgvJson.from.simple(json, CityCoverage, {
      city: City.fromJson(json?.city)
    });
  }
}
