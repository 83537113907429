import { SgvJson, DomainEntity, SgvId } from '@eceos/arch';
export class NFeInvoice implements DomainEntity {
  readonly id = SgvId.gen();
  constructor(
    public number: string = '',
    public originalValue: number = 0,
    public discountValue: number = 0
  ) {}

  get netValue(): number{
    return this.originalValue - this.discountValue;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): NFeInvoice {
    return json ? SgvJson.from.simple(json, NFeInvoice) : null;
  }
}
