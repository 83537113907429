import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EceosGuard } from '../../core/guards/eceos/eceos.guard';
import { HomeComponent } from './home.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'e-Ceos - Início' },
    canActivate: [EceosGuard]
  }
];

export const HomeRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
