import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class PriceTable implements DomainEntity {

  readonly id: string = SgvId.gen();

  constructor(
    public name: string = '',
    public creationDate: Date = null
  ) { }

  readonly isActive = true;

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): PriceTable {
    return SgvJson.from.simple(data, PriceTable);
  }
}
