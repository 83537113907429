import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordRoutes } from './forgot-password.routes';
import { ForgotPasswordComponent } from './forgot-password.component';
import { SharedThirdPartyModule } from '@eceos/shared-third-party';
import { ForgotPasswordSuccessDialogComponent } from './forgot-password-success-dialog/forgot-password-success-dialog.component';
import { NewPasswordConfirmationComponent } from './new-password-confirmation/new-password-confirmation.component';


@NgModule({
  declarations: [ForgotPasswordComponent, ForgotPasswordSuccessDialogComponent, NewPasswordConfirmationComponent],
  imports: [
    SharedModule, SharedThirdPartyModule, CommonModule, ForgotPasswordRoutes
  ]
})
export class ForgotPasswordModule { }
