import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {

  private readonly _message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<LoadingOverlayComponent>
  ) {
    this.dialogRef.disableClose = true;
    this._message = data ?? 'Carregando...';
  }

  get message(): string {
    return this._message;
  }
}
