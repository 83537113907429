<div class="container">
  <input
    type="file"
    #fileInput
    [multiple]="isMulti"
    id="fileInput"
    (change)="onFileSelected(fileInput.files)"
  />
  <mat-icon [inline]="true">upload</mat-icon>
  <div class="message">Arraste e solte o arquivo aqui</div>
  <small>ou</small>
  <label for="fileInput">Selecione</label>
</div>
