<h2 mat-dialog-title>Envio de certificado digital</h2>
<mat-dialog-content>
  <app-file-upload-area 
    *ngIf="!selectedFile" 
    (fileSelected)="onFileSelected($event)"
  ></app-file-upload-area>
  <div *ngIf="selectedFile" class="form">
    <mat-form-field>
      <mat-label>Arquivo selecionado</mat-label>
      <input matInput [value]="selectedFile.name" readonly />
      <button mat-icon-button matSuffix (click)="clear()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Senha do certificado</mat-label>
      <input matInput type="password" [(ngModel)]="pin" minlength="3" required />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [disabled]="!isValid" (click)="submit()">Enviar</button>
</mat-dialog-actions>