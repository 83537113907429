import { Inject, Injectable } from "@angular/core";
import { SGV_ROOT_URL } from "@eceos/arch";

@Injectable({providedIn: 'root'})
export class SgvNavigation {

  private _path = '';
  private _query = '';

  constructor(
    @Inject(SGV_ROOT_URL) private readonly _sgvRootUrl: string
  ) {
  }

  navigate(): void {
    if (!this._sgvRootUrl || this._path == null || this._query == null) {
      alert("Não foi possível navegar para o Eceos Cloud automaticamente, por favor, acesse o Eceos Cloud manualmente.");
      return;
    }

    const builtPath: string = this.buildRedirectUrl();

    this.resetUrlComponents();

    window.location.href = builtPath;
  }

  withQuery(key: string, value: string): this {
    if (this._query.includes('?')) {
      this._query = this._query.concat('&');
    } else {
      this._query = '?';
    }

    this._query = this._query.concat(`${key}=${value}`);
    return this;
  }

  withPath(value: string): this {
    const sanitizedValue: string = value
      .replace(/^\//, '')
      .trim();

    this._path = this._path.concat(`/${sanitizedValue}`);
    return this;
  }

  withTokenQuery(value: string): this {
    this.withQuery('token', value);
    return this;
  }

  private validateAndCorrect(url: string): string {
    if (url.endsWith('/')) {
      return url.substring(0, url.length - 1);
    }

    return url;
  }

  private buildRedirectUrl(): string {
    return `${this.validateAndCorrect(this._sgvRootUrl)}${this._path}.jsf${this._query}`;
  }

  private resetUrlComponents(): void {
    this._path = '';
    this._query = '';
  }
}
