import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { UserRole } from './user-role';

@Injectable({ providedIn: 'root' })
export class UserRolesRepository extends ReadonlyRepositoryImpl<UserRole, UserRole> {
  constructor(api: ApiService) {
    super(api.manager.path('userRoles'), UserRole, UserRole);
  }

  getToken(role: UserRole) {
    return this.api
      .path(role.id)
      .path('token')
      .getText({ metadata: this.metadata });
  }
}
