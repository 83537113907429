import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';
import { Uf } from './uf';

@Injectable({providedIn:'root'})
export class UfsRepository extends ReadonlyRepositoryImpl<Uf, Uf> {
  constructor(api: ApiService) {
    super(api.root.path('ufs'), Uf, Uf);
  }
}
