import { Injectable } from "@angular/core";
import { RepositoryImpl, ApiService } from "@eceos/arch";
import { StockMovement } from ".";

@Injectable({ providedIn: 'root' })
export class StockMovementsRepository extends RepositoryImpl<StockMovement, StockMovement> {

  constructor(api: ApiService) {
    super(api.root.path('products').path('stock').path('movements'), StockMovement, StockMovement);
  }

}