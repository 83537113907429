import { SgvJson } from "@eceos/arch";
import { Fine } from './fines/fine';
import { FixedValueFine } from "./fines/fixed-value-fine";
import { PercentageFine } from './fines/percentage-fine';
import { Rate } from "./rate";

export class RateFine implements Rate {

  readonly type = 'fine';

  constructor(
    public fine: Fine = new FixedValueFine()
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      fine: this.fine.toJson()
    });
  }

  static fromJson(data: any): Fine {
    switch (data.type) {
      case 'fixed':
        return FixedValueFine.fromJson(data);
      case 'percentage':
        return PercentageFine.fromJson(data);
    }
  }
}
