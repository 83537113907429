import { SgvJson } from "@eceos/arch";

export class StockAmount {
    constructor(
        public total: number = 0,
        public initial: number = 0,
        public reserved: number = 0,
        public minimum: number = 0
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): StockAmount {
        return json ? SgvJson.from.simple(json, StockAmount) : null;
    }
}