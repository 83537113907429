import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { FormulaExecutor } from '../../../../core/formula-executor/formula-executor';
import { NFeItem } from '../../nfe-item';
import { NFeFiscalTax } from '../nfe-item-tax';
import { CalculatedValue } from './../../calculated-value';

export interface IpiValueWithBcAliquotHolder {
  ipi: IpiValueWithBcAliquot;
}

export abstract class IpiValueWithBcAliquot {
  constructor(
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  abstract isValid(): boolean;

  abstract calcValue(): number;

  abstract publishValues(nfeItem: NFeItem): IpiValueWithBcAliquot;

  abstract toJson(): any;

  static fromJson(json: any): IpiValueWithBcAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'percent':
          return IpiValueWithBcAliquotPercent.fromJson(json);
        case 'value':
          return IpiValueWithBcAliquotValue.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class IpiValueWithBcAliquotPercent extends IpiValueWithBcAliquot {
  constructor(
    aliquot: number = null,
    value = new CalculatedValue(),
    public bcValue = new CalculatedValue(),
    public bcFormula = ''
  ) {
    super(aliquot, value);
  }

  isValid(): boolean {
    return (
      this.aliquot != null &&
      EceosValidatorsUtils.isValid(this.value) &&
      EceosValidatorsUtils.isValid(this.bcValue)
    );
  }

  calcBcValue(nfeItem: NFeItem): number {
    return this.bcFormula
      ? new FormulaExecutor(this.bcFormula, NFeFiscalTax.buildBcVariables(nfeItem)).execute()
      : null;
  }

  calcValue(): number {
    return NFeFiscalTax.calcValueBy(this.bcValue.value, this.aliquot);
  }

  publishValues(nfeItem: NFeItem): IpiValueWithBcAliquotPercent {
    this.bcValue.value = this.calcBcValue(nfeItem);
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'percent',
      value: this.value ? this.value.toJson() : null,
      bcValue: this.bcValue ? this.bcValue.toJson() : null
    });
  }

  static fromJson(json: any): IpiValueWithBcAliquotPercent {
    return json
      ? SgvJson.from.simple(json, IpiValueWithBcAliquotPercent, {
          value: CalculatedValue.fromJson(json.value),
          bcValue: CalculatedValue.fromJson(json.bcValue)
        })
      : null;
  }
}

export class IpiValueWithBcAliquotValue extends IpiValueWithBcAliquot {
  constructor(
    aliquot: number = null,
    value = new CalculatedValue(),
    public unityQuantity: number = null
  ) {
    super(aliquot, value);
  }

  isValid(): boolean {
    return (
      this.aliquot != null && EceosValidatorsUtils.isValid(this.value) && this.unityQuantity != null
    );
  }

  calcValue(): number {
    const value = this.unityQuantity * this.aliquot;
    return isNaN(value) ? null : value;
  }

  publishValues(): IpiValueWithBcAliquotValue {
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'value',
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): IpiValueWithBcAliquotValue {
    return json
      ? SgvJson.from.simple(json, IpiValueWithBcAliquotValue, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}
