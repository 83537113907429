import { SgvJson } from '@eceos/arch';
import { CompoundInterestArrears } from './interest-arrears/compound-interest-arrears';
import { FixedValueInterestArrears } from './interest-arrears/fixed-value-interest-arrears';
import { InterestArrears } from './interest-arrears/interest-arrears';
import { SimpleInterestArrears } from './interest-arrears/simple-interest-arrears';
import { Rate } from './rate';
export class RateInterestArrears implements Rate {

  readonly type = 'interestArrears';

  constructor(
    public interestArrears: InterestArrears = new FixedValueInterestArrears()
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      interestArrears: this.interestArrears.toJson()
    });
  }

  static fromJson(data: any): InterestArrears {
    switch (data.type) {
      case 'fixed':
        return FixedValueInterestArrears.fromJson(data);
      case 'compound':
        return CompoundInterestArrears.fromJson(data);
      case 'simple':
        return SimpleInterestArrears.fromJson(data);
    }
  }
}
