<section class="bg fillPrimary">
  <img class="logo" src="/assets/images/logo_eceos_white.svg" />

  <mat-card class="forgotPasswordCard">
    <mat-card-title>
      <mat-icon class="goBack" (click)="onBtnGoBackClick()">arrow_back</mat-icon>
     Problemas para fazer o login?
    </mat-card-title>
    <mat-card-subtitle>Digite seu email e enviaremos um link para você redefinir sua senha.</mat-card-subtitle>
    <form class="fields" >
      <mat-form-field thin>
        <mat-label>E-mail</mat-label>
        <input 
        matInput 
        #email
        type="email" 
        placeholder="E-mail" 
        (keydown.enter)="sendLink(email.value)"
        required />
        <mat-hint class="mat-error" *ngIf="isInvalidEmail(email)">E-mail informado não é válido.</mat-hint>
      </mat-form-field>
    </form>
    <div class="signUp">
        <small>Ou </small><a [routerLink]="signUpPath"><small>Crie uma nova conta</small></a>
      </div>
    <mat-card-actions>
      <button #sendPasswordBtn mat-raised-button class="ok-btn" (click)="sendLink(email.value)">
        Enviar
      </button>
    </mat-card-actions>
  </mat-card>
</section>
