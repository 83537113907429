import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class Session implements DomainEntity {
  public readonly id = SgvId.gen();

  public readonly start: Date;

  public readonly end: Date;

  public active: boolean;

  static fromJson(json: any): Session {
    return SgvJson.from.simple(json, Session);
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
}
