<ng-container *ngIf="formArray">
  <section
    *ngFor="let control of formArrayControls; let i = index"
    [formGroup]="control"
    [class.untouched]="control.untouched || !hasTotalToPay"
    [class.invalid]="control.invalid"
    [class.valid]="control.valid || control.disabled"
  >
    <mat-form-field thin>
      <mat-label>Forma de Pagamento</mat-label>
      <mat-select formControlName="document" [compareWith]="compareById">
        <mat-option
          *ngFor="let financialDocument of financialDocuments; trackBy: trackById"
          [value]="financialDocument"
        >
          {{ financialDocument.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field thin>
      <mat-label>Valor</mat-label>
      <input matInput currencyMask formControlName="value" />
    </mat-form-field>
    <button
      mat-icon-button
      type="button"
      aria-label="Remover Pagamento"
      matTooltip="Remover Pagamento"
      (click)="onRemovePayment(i)"
      *ngIf="!disabled && formArray.controls.length > 1"
      [disabled]="control.invalid && invalidFormArrayControls.length === 1"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </section>
</ng-container>
