import { SgvId, SgvJson } from "@eceos/arch";
import { Stock } from "../stock/stock";
import { VariationTypeValue } from "./variation-type-value";

export class ProductVariation {
  constructor(
    public readonly id: string = SgvId.gen(),
    public variations: VariationTypeValue[] = [],
    public barcode: string = '',
    public stock: Stock = null
  ) { }

  toJson(): any {
    throw Error('Não é possível criar ou atualizar ProductVariation');
  }

  static fromJson(json: any): ProductVariation {
    return json 
    ? SgvJson.from.simple(json, ProductVariation, {
      variations: json.variations ? SgvJson.from.array(json.variations, VariationTypeValue.fromJson) : null,
      stock: json.stock ? Stock.fromJson(json.stock) : null,
    }) : null;
  }

}