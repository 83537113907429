import { ApproximateTax } from './../tax/nfe-item-tax';
import { NFeItem } from '../nfe-item';

export class ApproximateTaxTotal {
  constructor(private items: () => NFeItem[]) {}

  get total(): number {
    return [
      this.nationalTaxValue,
      this.stateTaxValue,
      this.importTaxValue,
      this.cityTaxValue
    ].reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get nationalTaxValue(): number {
    return this.approximateTax()
      .filter(it => it.nationalTax)
      .map(it => it.nationalTax)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get stateTaxValue(): number {
    return this.approximateTax()
      .filter(it => it.stateTax)
      .map(it => it.stateTax)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get importTaxValue(): number {
    return this.approximateTax()
      .filter(it => it.importTax)
      .map(it => it.importTax)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get cityTaxValue(): number {
    return this.approximateTax()
      .filter(it => it.cityTax)
      .map(it => it.cityTax)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  private approximateTax(): ApproximateTax[] {
    return this.items()
      .map(it => it.tax.approximateTax)
      .filter(it => it != null);
  }
}
