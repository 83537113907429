<button *ngIf="withNextPrev" mat-button class="arrow" (click)="goPrevious()">
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<div class="value">
  <button *ngIf="!custom" mat-button [matMenuTriggerFor]="intervalOptionsMenu">
    {{ interval | dateInterval: (withTime ? 'datetime' : 'date') }}
  </button>
  <mat-form-field *ngIf="custom" appearance="fill" [formGroup]="form">
    <mat-label>Intervalo personalizado</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Inicio" formControlName="begin" />
      <input matEndDate placeholder="Fim" formControlName="end" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>
<button *ngIf="withNextPrev" mat-button class="arrow" (click)="goNext()">
  <mat-icon>keyboard_arrow_right</mat-icon>
</button>
<mat-menu #intervalOptionsMenu="matMenu">
  <button
    *ngFor="let o of intervalOptions; trackBy: trackOptionBy"
    mat-menu-item
    (click)="selectOption(o)"
  >
    {{ o.label }}
  </button>
  <button mat-menu-item (click)="selectCustom()">Personalizado...</button>
</mat-menu>
