import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { PisCst } from './pis-cst';

@Injectable({ providedIn: 'root' })
export class PisCstsRepository extends ReadonlyRepositoryImpl<PisCst, PisCst>
  implements ReadonlyRepository<PisCst, PisCst> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/pis/csts'), PisCst, PisCst);
  }
}
