import { EntityCrudState } from './entity-crud-state';

export class CrudMode {
  constructor(public readonly urlId: string, public readonly entityType: string, public readonly state: EntityCrudState) {}

  get emptyUrlId(): boolean {
    return !Boolean(this.urlId);
  }
  get isNewMode(): boolean {
    return !this.emptyUrlId && this.urlId === 'new' && this.state === EntityCrudState.INSERT;
  }

  get isEditMode(): boolean {
    return !this.isNewMode && this.state === EntityCrudState.EDIT;
  }

  get isViewMode(): boolean {
    return !this.isNewMode && !this.isEditMode && (this.state === EntityCrudState.VIEW || !this.state);
  }

  static from(urlId: string, entityType?: string, state?: EntityCrudState): CrudMode {
    if (urlId) {
      if (urlId === 'new') {
        return new CrudMode(urlId, entityType, EntityCrudState.INSERT);
      } else {
        return new CrudMode(urlId, entityType, state ? state : EntityCrudState.VIEW);
      }
    } else {
      return new ListOnlyCrudMode();
    }
  }
}
export class ListOnlyCrudMode extends CrudMode {
  constructor() {
    super(null, null, EntityCrudState.LIST);
  }
}
