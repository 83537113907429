import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { PeriodicityModule } from '../periodicity/periodicity.module';
import { CashPaymentFormComponent } from './cash-payment-form/cash-payment-form.component';
import { DeferredPaymentFormComponent } from './deferred-payment-form/deferred-payment-form.component';
import { PaymentFormComponent } from './payment-form.component';
import { PaymentTableComponent } from './payment-table/payment-table.component';

const COMPONENTS = [
  PaymentFormComponent,
  CashPaymentFormComponent,
  DeferredPaymentFormComponent,
  PaymentTableComponent
];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, PeriodicityModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class PaymentFormModule {}
