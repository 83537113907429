export enum EnterpriseInterface {
  APP = "APP",
  CLOUD = "CLOUD"
}

export function enterpriseInterfaceFrom(value?: string): EnterpriseInterface {
  if (value === EnterpriseInterface.APP) {
    return EnterpriseInterface.APP;
  }

  return EnterpriseInterface.CLOUD;
}
