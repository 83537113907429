import { Injectable } from '@angular/core';
import { ApiPath, ApiService, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserConfirmResult } from './user-confirm-result';

@Injectable({ providedIn: 'root' })
export class UserConfirmationRepository {
  private api: ApiPath;
  private publicConfirmPath: ApiPath;

  protected metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(api: ApiService) {
    this.api = api.manager.path('users').path('confirmation');
    this.publicConfirmPath = api.manager.path('/public/users/confirmation/mail');
  }

  sendMail(): Observable<void> {
    return this.api.path('mail').postJson(null, { metadata: this.metadata });
  }

  confirmWithToken(token: string): Observable<UserConfirmResult> {
    if (!token || token.length === 0) return throwError(Error('Token Inválido ou não informado'));
    return this.publicConfirmPath
      .path(token)
      .postJson({ supressDefaultAuth: true })
      .pipe(map(r => UserConfirmResult.fromJson(r)));
  }
}
