import { SgvJson, JsonEntity } from '@eceos/arch';

export abstract class UserInviteOperation implements JsonEntity {
  abstract toJson(): any;
}

export class AcceptUserInviteOperation extends UserInviteOperation {
  toJson(): any {
    return SgvJson.to.simple(this, { action: 'accept' });
  }
}

export class RefuseUserInviteOperation extends UserInviteOperation {
  toJson(): any {
    return SgvJson.to.simple(this, { action: 'refuse' });
  }
}
