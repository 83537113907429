import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { EceosValidators } from '@eceos/common-utils';
import { FinancialDocument, PaymentDocumentValue } from '@eceos/domain';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentTableComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PaymentTableComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentTableComponent implements OnInit, ControlValueAccessor, Validator {
  private _entity: PaymentDocumentValue[];

  private _totalToPay = 0;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  @Input() financialDocuments: FinancialDocument[] = [];

  @Output() entityChange = new EventEmitter<PaymentDocumentValue[]>();

  @Output() totalPaidChange = new EventEmitter<number>();

  formArray: FormArray;

  disabled = false;

  constructor(private fb: FormBuilder, private changeDetector: ChangeDetectorRef) {
    this.entityChange
      .pipe(
        map(() => this.totalPaid),
        distinctUntilChanged()
      )
      .subscribe((value) => this.totalPaidChange.emit(value));
  }

  ngOnInit() {}

  get formArrayControls(): FormGroup[] {
    return this.formArray?.controls?.map((it) => it as FormGroup) ?? [];
  }

  get entity(): PaymentDocumentValue[] {
    return this._entity;
  }

  @Input()
  set entity(entity: PaymentDocumentValue[]) {
    if (this.entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get totalToPay(): number {
    return this._totalToPay;
  }

  @Input()
  set totalToPay(totalToPay: number) {
    if (this._totalToPay !== totalToPay) {
      this._totalToPay = totalToPay;
      this.clearPayments();
    }
  }

  get invalidFormArrayControls(): FormGroup[] {
    return this.formArray.controls.filter(
      (c) => c.invalid || !this.createEntityFromFormValue(c.value).isValid
    ) as FormGroup[];
  }

  get validPayments(): PaymentDocumentValue[] {
    return this.entity.filter((e) => e.isValid);
  }

  get invalidPayments(): PaymentDocumentValue[] {
    return this.entity.filter((e) => !e.isValid);
  }

  get totalPaid(): number {
    return this.validPayments.map((i) => i.value).reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get pendingToPay(): number {
    const pending = this.totalToPay - this.totalPaid;
    return pending >= 0 ? pending : 0;
  }

  get payback(): number {
    const payback = this.totalPaid - this.totalToPay;
    return payback >= 0 ? payback : 0;
  }

  get hasPendingValue(): boolean {
    return this.pendingToPay > 0;
  }

  get hasPaybackValue(): boolean {
    return this.payback > 0;
  }

  get hasTotalToPay(): boolean {
    return this.totalToPay != null && this.totalToPay > 0;
  }

  validate(control: AbstractControl): ValidationErrors {
    return (this.formArray && this.formArray.invalid && this.hasTotalToPay) ||
      this.hasPendingValue ||
      (this.formArray &&
        !this.hasTotalToPay &&
        this.invalidFormArrayControls.length > 1 &&
        this.invalidPayments.length > 1)
      ? { invalid: true }
      : null;
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.formArray) {
      this.publishDisable();
      this.removeInvalids();
    }
  }

  onRemovePayment(index: number): void {
    this.formArray.removeAt(index);
  }

  compareById(obj, anotherObj): boolean {
    return obj && anotherObj && obj.id === anotherObj.id;
  }

  trackById(i, entity) {
    return entity.id;
  }

  private createFormFromEntity() {
    this.formArray = null;
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
    if (this.entity) {
      this.formArray = this.fb.array(this.entity.map((e) => this.createFormGroupFrom(e)));
      this.addNewControlIfValid();
      this.addFormValueChange((values) =>
        values.map((value) => this.createEntityFromFormValue(value)).filter((e) => e.isValid)
      );
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (f: any) => PaymentDocumentValue[]) {
    this.formArray.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe((value) => {
        this.entityChange.emit((this._entity = value));
        this.addNewControlIfValid();
        this.removeInvalids();
        this.changeDetector.markForCheck();
      });
  }

  private createEntityFromFormValue(formValue: any): PaymentDocumentValue {
    return formValue ? new PaymentDocumentValue(formValue.document, formValue.value) : null;
  }

  private createFormGroupFrom(entity: PaymentDocumentValue): FormGroup {
    const formGroup = this.fb.group({
      document: [entity.document, Validators.required],
      value: [entity.value, [Validators.required, EceosValidators.greaterThan(0)]]
    });

    this.publishPendingValueToField(this.entityChange, () => {
      const invalidControl = this.invalidFormArrayControls.find((_) => true);
      return invalidControl && invalidControl.controls.value !== formGroup.controls.value
        ? invalidControl
        : null;
    });

    return formGroup;
  }

  private addNewControlIfValid() {
    if (
      this.formArray.controls.every((control) => control.valid) &&
      (this.hasPendingValue || !this.hasTotalToPay) &&
      !this.disabled
    ) {
      this.formArray.push(
        this.createFormGroupFrom(new PaymentDocumentValue(null, this.pendingToPay))
      );
    }
  }

  private removeInvalids(): void {
    if ((!this.hasPendingValue && this.hasTotalToPay) || this.disabled) {
      for (const invalidControl of this.invalidFormArrayControls) {
        this.formArray.removeAt(this.formArray.controls.indexOf(invalidControl));
      }
    }
  }

  private publishPendingValueToField(valueChange: Observable<any>, mapper: () => FormGroup) {
    valueChange
      .pipe(
        map(mapper),
        filter((group) => Boolean(group))
      )
      .subscribe((group: FormGroup) => {
        const documentControl = group.controls.document;
        const valueControl = group.controls.value;
        if (this.hasPendingValue && documentControl.pristine && valueControl.pristine) {
          valueControl.setValue(this.pendingToPay, { emitEvent: false });
        }
      });
  }

  private publishDisable() {
    if (this.disabled && this.formArray.enabled) {
      this.formArray.disable({ emitEvent: false });
    } else if (!this.disabled && this.formArray.disabled) {
      this.formArray.enable({ emitEvent: false });
    }
  }

  clearPayments(): void {
    if (!this.disabled && this.formArray) {
      this.entity = [];
      this.formArray.updateValueAndValidity();
    }
  }
}
