<mat-nav-list>
  <ng-content select="[before]"></ng-content>
  <ng-container *ngFor="let group of $any(entries); let first = first; trackBy: trackByFn">
    
    <ng-container *ngIf="!group.childs">
      <a mat-list-item [routerLink]="group.path">
        <mat-icon mat-list-icon [svgIcon]="group.icon"></mat-icon>
        <h4 mat-line>{{ group.name }}</h4>
        <p mat-line>{{ group.details }}</p>
      </a>
    </ng-container>
    <ng-container *ngIf="group.childs">
      <mat-divider *ngIf="!first"></mat-divider>
      <h3 mat-subheader>{{ group.name }}</h3>
      <ng-container *ngFor="let entry of $any(group.childs); trackBy: trackByFn">
        <a *ngIf="!entry.isSubmenu" mat-list-item [routerLink]="entry.path">
          <mat-icon mat-list-icon [svgIcon]="entry.icon"></mat-icon>
          <h4 mat-line>{{ entry.name }}</h4>
          <p mat-line>{{ entry.details }}</p>
        </a>
        <a *ngIf="entry.isSubmenu" mat-list-item (click)="onSubmenuClick(entry)">
          <mat-icon mat-list-icon [svgIcon]="entry.icon"></mat-icon>
          <h4 mat-line>{{ entry.name }}</h4>
          <p mat-line>{{ entry.details }}</p>
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-content select="[after]"></ng-content>
</mat-nav-list>
