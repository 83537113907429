import { SgvJson } from '@eceos/arch';
import { Fine } from "./fine";

export class PercentageFine extends Fine {

  constructor() {
    super(0.0, "percentage");
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): PercentageFine {
    return SgvJson.from.simple(data, PercentageFine);
  }
}
