import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-expansion-fieldset',
  templateUrl: './expansion-fieldset.component.html',
  styleUrls: ['./expansion-fieldset.component.scss']
})
export class ExpansionFieldsetComponent implements OnInit {

  @Input() label: string;

  @Input() visible = false;

  constructor() { }

  ngOnInit() {
  }

  changeVisible() {
    this.visible = !this.visible;
  }

}
