import { Injectable } from '@angular/core';
import { ApiService, InterceptorConfig, PageData, RepositoryImpl } from '@eceos/arch';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdditionFiscalConfig } from './addition-fiscal-config';

@Injectable({ providedIn: 'root' })
export class AdditionFiscalConfigRepository extends RepositoryImpl<
  AdditionFiscalConfig,
  AdditionFiscalConfig
> {
  constructor(api: ApiService) {
    super(api.root.path('/fiscal/additionConfig'), AdditionFiscalConfig, AdditionFiscalConfig);
  }

  get(): Observable<AdditionFiscalConfig> {
    return this.page(new PageData(0, 1)).pipe(map(value => (value ? value[0] : null)));
  }

  async save(additionFiscalConfig: AdditionFiscalConfig): Promise<AdditionFiscalConfig> {
    if (additionFiscalConfig) {
      let entity = null;
      try {
        entity = await lastValueFrom(this.findSummary(additionFiscalConfig.id, {
          autoCatch: InterceptorConfig.NO_INTERCEPT
        }));
      } catch (e) {
        entity = null;
      }

      if (entity) {
        return lastValueFrom(this.update(additionFiscalConfig));
      } else {
        return lastValueFrom(this.insert(additionFiscalConfig));
      }
    } else {
      const entity = await lastValueFrom(this.get());

      return lastValueFrom(this.delete(entity)
        .pipe(map(_ => null)));
    }
  }
}
