import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';
import { ZipCode } from './zipCode';

@Injectable({providedIn:'root'})
export class ZipCodesRepository extends ReadonlyRepositoryImpl<ZipCode, ZipCode> {
  constructor(api: ApiService) {
    super(api.root.path('zipCodes'), ZipCode, ZipCode);
  }
}
