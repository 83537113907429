import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { NFeFiscalTax } from '../../nfe-item-tax';

export class BcModalityST implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly name = '',
    readonly number = ''
  ) { }

  get nameWithNumber(): string {
    return this.number + " - " + this.name;
  }

  apply(bcValue: number, aliquot: number, icmsValue: number = 0) {
    switch(this.id) {
      case 'MARGEM_VALOR_AGREGADO': return NFeFiscalTax.calcValueBy(bcValue, aliquot) - icmsValue;
      default: return NFeFiscalTax.calcValueBy(bcValue, aliquot);
    }
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): BcModalityST {
    return SgvJson.from.simple(json, BcModalityST);
  }
}