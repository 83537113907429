import { SgvId, SgvJson } from '@eceos/arch';
import { IpiCst } from '../../../nfes/tax/ipi/csts/ipi-cst';
import { IPI, NonTaxableIPI } from '../../../nfes/tax/ipi/ipi';
import { TaxableIPI } from './../../../nfes/tax/ipi/ipi';
import { IpiWithBcAliquot } from './ipi-with-bc-aliquot';
import { NFeItem } from '../../../nfes/nfe-item';

export abstract class IPIConfig {
  constructor(
    readonly id: string = SgvId.gen(),
    public cst: IpiCst = null,
    public legalFramework: string = null,
    public ipiSealCode: string = null,
    public producerCNPJ: string = null,
    public ipiFrameworkClass: string = null
  ) {}

  abstract toIPI(nfeItem?: NFeItem): IPI;

  abstract toJson(): any;

  static fromJson(json: any): IPIConfig {
    if (json && json.type) {
      switch (json.type) {
        case 'TaxableIpiConfig':
          return TaxableIPIConfig.fromJson(json);
        case 'NonTaxableIpiConfig':
          return NonTaxableIPIConfig.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class TaxableIPIConfig extends IPIConfig {
  constructor(
    id: string = SgvId.gen(),
    cst: IpiCst = null,
    legalFramework: string = null,
    ipiSealCode: string = null,
    producerCNPJ: string = null,
    ipiFrameworkClass: string = null,
    public aliquot: IpiWithBcAliquot = null
  ) {
    super(id, cst, legalFramework, ipiSealCode, producerCNPJ, ipiFrameworkClass);
  }

  toIPI(nfeItem: NFeItem): IPI {
    return new TaxableIPI(
      SgvId.gen(),
      this.cst,
      this.legalFramework,
      this.ipiSealCode,
      this.producerCNPJ,
      this.ipiFrameworkClass,
      null,
      this.aliquot.toBcAliquot(nfeItem)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      cst: this.cst.toJson(),
      aliquot: this.aliquot.toJson()
    });
  }

  static fromJson(json: any): TaxableIPIConfig {
    return json
      ? SgvJson.from.simple(json, TaxableIPIConfig, {
          cst: IpiCst.fromJson(json.cst),
          aliquot: IpiWithBcAliquot.fromJson(json.aliquot)
        })
      : null;
  }
}

export class NonTaxableIPIConfig extends IPIConfig {
  constructor(
    id: string = SgvId.gen(),
    cst: IpiCst = null,
    legalFramework: string = null,
    ipiSealCode: string = null,
    producerCNPJ: string = null,
    ipiFrameworkClass: string = null
  ) {
    super(id, cst, legalFramework, ipiSealCode, producerCNPJ, ipiFrameworkClass);
  }

  toIPI(): IPI {
    return new NonTaxableIPI(
      SgvId.gen(),
      this.cst,
      this.legalFramework,
      this.ipiSealCode,
      this.producerCNPJ,
      this.ipiFrameworkClass,
      null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      cst: this.cst.toJson()
    });
  }

  static fromJson(json: any): NonTaxableIPIConfig {
    return json
      ? SgvJson.from.simple(json, NonTaxableIPIConfig, {
          cst: IpiCst.fromJson(json.cst)
        })
      : null;
  }
}
