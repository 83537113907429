import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SignUpComponent } from './sign-up.component';
import { SignUpRoutes } from './sign-up.routes';
import { SignUpConfirmEmailComponent } from './sign-up-confirm-email/sign-up-confirm-email.component';

@NgModule({
  imports: [CommonModule, SharedModule, SignUpRoutes],
  declarations: [SignUpComponent, SignUpConfirmEmailComponent]
})
export class SignUpModule {}
