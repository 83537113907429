import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class Pack implements DomainEntity{
    constructor(
        readonly id = SgvId.gen(),
        public name: string = '',
        public capacity: number = 0
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): Pack {
        return json ? SgvJson.from.simple(json, Pack) : null;
    }
}