import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService, PageData } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Crt } from './crt';

@Injectable({providedIn:'root'})
export class CrtFiscalRepository extends ReadonlyRepositoryImpl<Crt, Crt> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/crts'), Crt, Crt);
  }

  getFirst(): Observable<Crt> {
    return this.page(new PageData(0, 1)).pipe(map(value => (value ? value[0] : null)));
  }
}
