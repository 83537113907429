import { Injectable } from '@angular/core';
import {
  ApiPath,
  ApiService,
  InterceptorConfig,
  RequestMetadata
} from '@eceos/arch';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserPublicRepository {
  private api: ApiPath;

  constructor(api: ApiService) {
    this.api = api.manager.path('public').path('users');
  }

  findEmail(
    email: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<string> {
    return this.api.path(email).getJson({ metadata: metadata });
  }
}
