import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Agency } from './agency';

export abstract class Account implements DomainEntity {
  readonly id: string = SgvId.gen();
  readonly isActive = true;
  constructor(
    public name: string = '',
    public balance: number = 0,
    public movementWithoutBalance: boolean = true,
    public numberConsolidatedAccountingStatement?: string
  ) {}

  get isBankAccount(): boolean {
    return this instanceof BankAccount;
  }

  get isSimpleAccount(): boolean {
    return this instanceof SimpleAccount;
  }

  abstract toJson(): any;

  static fromJson(data: any): Account {
    if (data) {
      switch (data.type) {
        case BankAccount.TYPE:
          return BankAccount.fromJson(data);
        case SimpleAccount.TYPE:
          return SimpleAccount.fromJson(data);
        default:
          return null;
      }
    }
    return null;
  }
}

export class BankAccount extends Account {
  static TYPE = 'bank';

  constructor(
    public number: number = 0,
    public digit: number = 0,
    public limit: number = 0,
    public agency: Agency = null
  ) {
    super('', 0, true, '');
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: BankAccount.TYPE });
  }
  static fromJson(data: any): BankAccount {
    return SgvJson.from.simple(data, BankAccount, {
      number: data.number,
      digit: data.digit,
      limit: data.limit,
      agency: Agency.fromJson(data.agency)
    });
  }
}

export class SimpleAccount extends Account {
  static TYPE = 'simple';

  constructor() {
    super('', 0, true, '');
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: SimpleAccount.TYPE });
  }
  static fromJson(data: any): SimpleAccount {
    return SgvJson.from.simple(data, SimpleAccount);
  }
}
