import { SgvId, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { IcmsUfReceiverValueWithBcAliquot } from './icms-uf-receiver-value-with-bc-aliquot';
import { IcmsUfReceiverWithFcpValue } from './icms-uf-receiver-with-fcp-value';

export class IcmsUfReceiver {
  constructor(
    readonly id = SgvId.gen(),
    public overwritingCstIcmsAliquot = false,
    public icmsUfReceiver: IcmsUfReceiverValueWithBcAliquot = new IcmsUfReceiverValueWithBcAliquot(),
    public fcp: IcmsUfReceiverWithFcpValue = null
  ) {}

  isValid(): boolean {
    return (
      this.overwritingCstIcmsAliquot != null &&
      EceosValidatorsUtils.isValid(this.icmsUfReceiver) &&
      EceosValidatorsUtils.isValidOptional(this.fcp)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      icmsUfReceiver: this.icmsUfReceiver.toJson(),
      fcp: this.fcp ? this.fcp.toJson() : null
    });
  }

  static fromJson(json: any): IcmsUfReceiver {
    return json
      ? SgvJson.from.simple(json, IcmsUfReceiver, {
          icmsUfReceiver: IcmsUfReceiverValueWithBcAliquot.fromJson(json.icmsUfReceiver),
          fcp: IcmsUfReceiverWithFcpValue.fromJson(json.fcp)
        })
      : null;
  }
}
