import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FinancialDocument } from './financial-document';
import { ReadonlyRepositoryImpl, ApiService, Filter, FilterOp, SgvJson } from '@eceos/arch';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FinancialDocumentsRepository extends ReadonlyRepositoryImpl<
  FinancialDocument,
  FinancialDocument
> {
  constructor(api: ApiService) {
    super(api.root.path('/financial/documents'), FinancialDocument, FinancialDocument);
  }

  allowsPayback(): Observable<FinancialDocument[]> {
    return this.api
      .path('allowsPayback')
      .getJson()
      .pipe(map(r => SgvJson.from.array(r, v => this.summaryType.fromJson(v))));
  }

  listIncome(): Observable<FinancialDocument[]> {
    return this.list('', [new Filter('toIncome', FilterOp.EQ, true)]);
  }
}
