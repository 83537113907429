import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Discount } from './rates/discounts/discount';
import { FixedValueDiscount } from './rates/discounts/fixed-value-discount';
import { SimpleDiscount } from './rates/discounts/simple-discount';
import { Fine } from './rates/fines/fine';
import { FixedValueFine } from './rates/fines/fixed-value-fine';
import { PercentageFine } from './rates/fines/percentage-fine';
import { CompoundInterestArrears } from './rates/interest-arrears/compound-interest-arrears';
import { FixedValueInterestArrears } from './rates/interest-arrears/fixed-value-interest-arrears';
import { InterestArrears } from './rates/interest-arrears/interest-arrears';
import { SimpleInterestArrears } from './rates/interest-arrears/simple-interest-arrears';
import { Rate } from './rates/rate';
import { RateDiscount } from './rates/rate-discount';
import { RateFine } from './rates/rate-fine';
import { RateInterestArrears } from './rates/rate-interest-arrears';
import { TimeInterval } from './rates/time-interval';

export class RatePolicy implements DomainEntity {

  readonly id = SgvId.gen();

  constructor(
    public name = '',
    public rates: Rate[] = [new RateInterestArrears(), new RateFine(), new RateDiscount()]
  ) { }

  readonly isActive = true;

  get interestArrears(): InterestArrears {
    const rate = this.rates.find(r => r instanceof RateInterestArrears) as RateInterestArrears;
    if (!Boolean(rate)) {
      return new SimpleInterestArrears();
    }

    return rate.interestArrears;
  }

  get fine(): Fine {
    const rate = this.rates.find(r => r instanceof RateFine) as RateFine;
    if (!Boolean(rate)) {
      return new FixedValueFine();
    }

    return rate.fine;
  }

  get discount(): Discount {
    const rate = this.rates.find(r => r instanceof RateDiscount) as RateDiscount;
    if (!Boolean(rate)) {
      return new FixedValueDiscount();
    }

    return rate.discount;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  addInterestArrears(type: { name: string, interval: TimeInterval }) {
    if (Boolean(type) && Boolean(type.name)) {
      const index: number = this.rates.findIndex(r => r instanceof RateInterestArrears);
      if (index !== -1) {
        this.rates.splice(index, 1);
      }
      const rate = new RateInterestArrears();
      switch (type.name) {
        case 'fixed':
          rate.interestArrears = new FixedValueInterestArrears(type.interval);
          break;
        case 'compound':
          rate.interestArrears = new CompoundInterestArrears(type.interval);
          break;
        case 'simple':
          rate.interestArrears = new SimpleInterestArrears(type.interval);
          break;
      }
      this.rates.push(rate);
    }
  }

  addFine(type: { name: string }) {
    if (Boolean(type)) {
      const index: number = this.rates.findIndex(r => r instanceof RateFine);
      if (index !== -1) {
        this.rates.splice(index, 1);
      }
      const rate = new RateFine();
      switch (type.name) {
        case 'fixed':
          rate.fine = new FixedValueFine();
          break;
        case 'percentage':
          rate.fine = new PercentageFine();
          break;
      }
      this.rates.push(rate);
    }
  }

  addDiscount(type: { name: string, interval: TimeInterval }) {
    if (Boolean(type) && Boolean(type.name)) {
      const index: number = this.rates.findIndex(r => r instanceof RateDiscount);
      if (index !== -1) {
        this.rates.splice(index, 1);
      }
      const rate = new RateDiscount();
      switch (type.name) {
        case 'fixed':
          rate.discount = new FixedValueDiscount(type.interval);
          break;
        case 'simple':
          rate.discount = new SimpleDiscount(type.interval);
          break;
      }
      this.rates.push(rate);
    }
  }

  static fromJson(data: any): RatePolicy {
    return SgvJson.from.simple(data, RatePolicy, {
      rates: data ? SgvJson.from.array(
        data.rates,
        Rate.fromJson
      ) : null
    });
  }
}
