import { Pipe, PipeTransform } from '@angular/core';
import { PaymentDiscount, PaymentValueDiscount, PaymentPercentDiscount } from '@eceos/domain';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'discountSummary'
})
export class DiscountSummaryPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: PaymentDiscount, prefix: string = ''): any {
    if (value instanceof PaymentValueDiscount) {
      return prefix + this.currencyPipe.transform(value.value, 'BRL', 'symbol');
    } else if (value instanceof PaymentPercentDiscount) {
      return prefix + value.value + ' %';
    } else {
      return 'Sem desconto';
    }
  }
}
