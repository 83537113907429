import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { RequiredHandler } from '@eceos/common-utils';
import {
  DailyPeriodicity,
  MonthlyPeriodicity,
  Periodicity,
  WeeklyPeriodicity,
  YearlyPeriodicity
} from '@eceos/domain';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-periodicity',
  templateUrl: './periodicity.component.html',
  styleUrls: ['./periodicity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodicityComponent extends RequiredHandler
  implements OnInit, ControlValueAccessor, Validator {
  private _entity: Periodicity;

  private _type: PeriodicityType;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  @Output() entityChange = new EventEmitter<Periodicity>();

  @Input() disabled = false;

  readonly types: PeriodicityType[] = [
    { clazz: DailyPeriodicity, label: 'dias' },
    { clazz: WeeklyPeriodicity, label: 'semanas' },
    { clazz: MonthlyPeriodicity, label: 'meses' },
    { clazz: YearlyPeriodicity, label: 'anos' }
  ];

  constructor(@Optional() @Self() ngControl: NgControl) {
    super(ngControl);
    super.setControlValueAccessor(this);
  }

  ngOnInit() {}

  get entity(): Periodicity {
    return this._entity;
  }

  set entity(entity: Periodicity) {
    if (this._entity !== entity) {
      this._entity = entity;
      this.publishValuesFromEntity();
    }
  }

  get type(): PeriodicityType {
    return this._type;
  }

  set type(type: PeriodicityType) {
    if (this._type !== type) {
      this._type = type;
    }
  }

  get valid(): boolean {
    return this.entity && this.entity.recurrences && this.entity.recurrences > 0;
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.valid ? null : { invalid: true };
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onRecurrencesChange() {
    this.entityChange.emit(this.entity);
  }

  onTypeChange() {
    const entity = new this.type.clazz();
    if (this.entity) {
      entity.recurrences = this.entity.recurrences;
    }
    this.entity = entity;
    this.entityChange.emit(this.entity);
  }

  private publishValuesFromEntity() {
    if (this.entity) {
      this.type = this.types.find(t => t.clazz === this.entity.constructor);
    }
  }
}

interface PeriodicityType {
  readonly clazz: new () => Periodicity;
  readonly label: string;
}
