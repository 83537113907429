import { JsonEntity, SgvJson } from '@eceos/arch';

export class City implements JsonEntity {
  constructor(
    public id: string = '',
    public name: string = '',
    public uf: string = '',
    public ibgeCode: number = 0
  ) {}

  get nameWithUf() {
    return this.uf && this.uf !== '' ? `${this.name} - ${this.uf}` : this.name;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): City {
    return SgvJson.from.simple(json, City);
  }
}
