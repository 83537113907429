import { NFeItem } from '../nfe-item';

export class FederalTaxRetention {
  constructor(private items: () => NFeItem[]) {}

  get total(): number {
    return [
      this.pisRetainedValue,
      this.cofinsRetainedValue,
      this.csllRetainedValue,
      this.irrfRetentionBCValue,
      this.irrfRetainedValue,
      this.socialSecurityRetainedValue,
      this.socialSecurityRetentionBCValue
    ].reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get pisRetainedValue(): number {
    return 0;
  }

  get cofinsRetainedValue(): number {
    return 0;
  }

  get csllRetainedValue(): number {
    return 0;
  }

  get irrfRetentionBCValue(): number {
    return 0;
  }

  get irrfRetainedValue(): number {
    return 0;
  }

  get socialSecurityRetainedValue(): number {
    return 0;
  }

  get socialSecurityRetentionBCValue(): number {
    return 0;
  }
}
