import { SgvJson } from '@eceos/arch';

export class AddsValue {
  constructor(
    public totalIcms = false,
    public totalPis = false,
    public totalCofins = false,
    public totalIcmsDesoneration = true
  ) {}

  get isDefault(): boolean {
    return !this.totalIcms && !this.totalPis && !this.totalCofins && this.totalIcmsDesoneration;
  }

  get isNone(): boolean {
    return !this.totalIcms && !this.totalPis && !this.totalCofins && !this.totalIcmsDesoneration;
  }

  get isCustom(): boolean {
    return !this.isDefault && !this.isNone;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): AddsValue {
    return SgvJson.from.simple(json, AddsValue);
  }
}
