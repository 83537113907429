import { SgvId, SgvJson } from '@eceos/arch';
import { II } from './../../../nfes/tax/ii/ii';

export class IIConfig {
  constructor(readonly id: string = SgvId.gen()) {}

  toII(): II {
    return new II();
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IIConfig {
    return SgvJson.from.simple(json, IIConfig);
  }
}
