import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class Queue implements DomainEntity {
  public readonly id = SgvId.gen();

  public name = '';

  static fromJson(json: any): Queue {
    return SgvJson.from.simple(json, Queue);
  }
  toJson(): any {
    return SgvJson.to.simple(this);
  }
}
