<ng-container *ngFor="let cnp of valueWithNew">
    <mat-form-field>
        <input 
        matInput 
        [kzMask]="['999.999.999-99', '99.999.999/9999-99']"
        [kzMaskCleanOnBlur]="false"
        placeholder="Adicionar CPF/CNPJ"
        [ngModel]="cnp.value"
        (ngModelChange)="onChange($event, cnp)"
        [disabled]="disabled" 
    
        />
        <button
        *ngIf="!disabled && cnp.value"
         matSuffix 
         mat-button 
         color="warn" 
         type="button" 
         matTooltip="Deletar campo"
         tabindex="-1"
         (click)="remove(cnp)">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <ng-container *ngIf="!cnp.isValid">
        <mat-error *ngIf="cnp.isCpf">CPF inválido</mat-error>
        <mat-error *ngIf="cnp.isCnpj">CNPJ inválido</mat-error>
    </ng-container>
</ng-container>