<app-menu [menu]="menu" (submenuClick)="onSubmenuClick($event)">
  <section before>
    <a mat-list-item routerLink="/">
      <mat-icon mat-list-icon svgIcon="home"></mat-icon>
      <h4 mat-line>Início</h4>
    </a>
    <mat-divider></mat-divider>
  </section>

  <section after>
    <mat-divider></mat-divider>
    <a mat-list-item (click)="logout()">
      <mat-icon mat-list-icon svgIcon="sign-out-alt"></mat-icon>
      <h4 mat-line>Sair</h4>
    </a>
  </section>
</app-menu>
