import { Injectable } from '@angular/core';
import { ApiService, InterceptorConfig, RepositoryImpl, RequestMetadata } from '@eceos/arch';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Product } from '.';
import { Image } from './image';
import { ProductSummary } from './product-summary';

@Injectable({ providedIn: 'root' })
export class ProductsRepository extends RepositoryImpl<ProductSummary, Product> {

  constructor(api: ApiService) {
    super(api.root.path('products'), ProductSummary, Product);
  }

  getImage(productId: string, imageId: string, metadata: RequestMetadata = { autoCatch: InterceptorConfig.NO_INTERCEPT }): Observable<Blob> {
    return this.api.path(productId).path('images').path(imageId).getBlob({ metadata: metadata });
  }

  uploadNewImage(file: File, productId: string): Observable<Image> {
    const form = new FormData();
    form.append('file', file);
    return this.api.path(productId).path('images').putMultipartForm(form).pipe(map(img => Image.fromJson(img)));
  }

  deleteImage(productId: string, imageId: string, ): Observable<boolean> {
    return this.api.path(productId).path('images').path(imageId).delete();
  }

}