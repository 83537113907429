import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicGuard } from '../shared/guards/public.guard';
import { InactiveComponent } from './inactive.component';

export const routes: Routes = [
  { path: 'inactive', component: InactiveComponent, canActivate: [PublicGuard] }
];

export const InactiveRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
