import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { PlanVariation } from './plan-variation';

export class Plan implements DomainEntity {
  constructor(
    public readonly id: string = SgvId.gen(),
    public name = '',
    public evaluationDays = 0,
    public monthlyCreditCost = 0,
    public planIdRef = '',
    public variations: PlanVariation[] = [],
    public htmlContent = '',
    public isActive = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      variations: SgvJson.to.array(this.variations),
    });
  }

  static fromJson(json: any): Plan {
    return json ? SgvJson.from.simple(json, Plan, {
      variations: PlanVariation.fromJson(json.variations),
    }) : null;
  }
}

export class PlanSummary implements DomainEntity {
  constructor(
    public readonly id: string = SgvId.gen(),
    public name = '',
    public isActive = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): PlanSummary {
    return json ? SgvJson.from.simple(json, PlanSummary) : null;
  }
}
