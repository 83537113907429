import { Injectable } from '@angular/core';
import { Action, ApiService, Functionality, RepositoryImpl, SgvJson } from '@eceos/arch';
import { Profile } from './profile';
import { ProfileSumary } from './profile-sumary';
import { Feature } from '../core/feature';
import { map } from 'rxjs/operators';

export class AvailableFeature {
  constructor(readonly feature: Functionality = null, readonly allowedActions: Action[] = []) {}

  static fromJson(json: any) {
    const feature = Feature.fromJson(json.functionality);
    if (!feature) {
      return null;
    }
    return new AvailableFeature(
      feature,
      feature.actions.filter(a => json.allowedActions.some((aa: string) => a.id === aa))
    );
  }
}

@Injectable({ providedIn: 'root' })
export class ProfilesRepository extends RepositoryImpl<ProfileSumary, Profile> {
  constructor(api: ApiService) {
    super(api.root.path('/accessProfiles'), ProfileSumary, Profile);
  }

  fetchAvailableFeatures() {
    return this.api
      .path('availablePermissions')
      .getJson<any>()
      .pipe(map(v => SgvJson.from.array(v, AvailableFeature.fromJson)));
  }
}
