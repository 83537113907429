import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { ConfirmEmailCardComponent } from './confirm-email-card/confirm-email-card.component';
import { ContactEmailsFormComponent } from './contact-emails-form/contact-emails-form.component';
import { ContactPhonesFormComponent } from './contact-phones-form/contact-phones-form.component';
import { CnpsFormComponent } from './cnps-form/cnps-form.component';
import { DiscountFormComponent } from './discount-form/discount-form.component';
import { FreightFormComponent } from './freight-form/freight-form.component';
import { PaymentFormModule } from './payment-form/payment-form.module';
import { PeriodicityModule } from './periodicity/periodicity.module';
import { PasswordStrengthInfoComponent } from './password-strength-info/password-strength-info.component';
import { EcnpjUploadDialogComponent } from './ecnpj-upload-dialog/ecnpj-upload-dialog.component';

const COMPONENTS = [
  ContactEmailsFormComponent,
  ContactPhonesFormComponent,
  CnpsFormComponent,
  FreightFormComponent,
  DiscountFormComponent,
  ConfirmEmailCardComponent,
  PasswordStrengthInfoComponent,
  EcnpjUploadDialogComponent
];

const MODULES = [PaymentFormModule, PeriodicityModule];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, ...MODULES],
  declarations: COMPONENTS,
  exports: [...COMPONENTS, ...MODULES]
})
export class ComponentsModule {}
