import { JsonEntity, SgvJson } from "@eceos/arch";

export class InstallmentPolicyFiscalData implements JsonEntity {

  constructor(
    public paymentMethod: string = ''
  ) { }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): InstallmentPolicyFiscalData {
    return SgvJson.from.simple(data, InstallmentPolicyFiscalData);
  }
}
