import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { FiscalSeriesSummary } from '../../fiscal/series/fiscal-series';
import { SaleActionEvent } from './sale-action-event';

export abstract class SaleAction implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public event: SaleActionEvent = null,
    public isActive = true
  ) {}

  abstract toJson(): any;

  static fromJson(json: any): SaleAction {
    if (json && json.type) {
      switch (json.type) {
        case 'generateAndTransmitNFeAction':
          return SaleGenerateAndTransmitNFeAction.fromJson(json);
        default:
          return null;
      }
    }

    return null;
  }
}

export class SaleGenerateAndTransmitNFeAction extends SaleAction {
  constructor(
    id = SgvId.gen(),
    event: SaleActionEvent = null,
    isActive = true,
    public serie: FiscalSeriesSummary = null
  ) {
    super(id, event, isActive);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'generateAndTransmitNFeAction',
      serie: this.serie.toJson()
    });
  }

  static fromJson(json: any): SaleGenerateAndTransmitNFeAction {
    return json
      ? SgvJson.from.simple(json, SaleGenerateAndTransmitNFeAction, {
          serie: FiscalSeriesSummary.fromJson(json.serie)
        })
      : null;
  }
}
