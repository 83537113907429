import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  CurrentInfoService,
  EnterpriseSummary,
  LegalEntityDocuments,
  MovideskService
} from '@eceos/domain';
import {
  CurrentUserService as CurrentManagerUserService,
  User as ManagerUser
} from '@eceos/manager-domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAIN_MENU, SubMenu } from '../../features/features-catalog';
import { LayoutBaseComponent } from '../base/layout-base.component';

interface LayoutUser {
  name: string;
  identifier: string;
  enterprise: EnterpriseSummary;
}

@Component({
  selector: 'app-layout',
  templateUrl: './layout-eceos.component.html',
  styleUrls: ['./layout-eceos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('menuAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('150ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [animate('150ms ease-in', style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class LayoutEceosComponent implements OnInit, OnDestroy {
  @Input() fillPrimary = false;

  @Input() pageTitle: string = null;

  @Input() showSearch = false;

  @Input() searchPlaceholder = 'Pesquisar';

  @Input() showMenu = true;

  @Input() showBack = false;

  @Output() searchChange = new EventEmitter<string>();

  @ViewChild(LayoutBaseComponent) layoutBase: LayoutBaseComponent;

  readonly mainMenu = MAIN_MENU.childs;

  readonly cnpjMask = LegalEntityDocuments.MASK;

  submenu: SubMenu = null;

  homePath = '/';

  user: LayoutUser;

  managerUser: Observable<ManagerUser>;

  constructor(
    public currentInfo: CurrentInfoService,
    public currentManagerUser: CurrentManagerUserService,
    private movideskService: MovideskService
  ) {
    this.managerUser = this.currentManagerUser.$user;
    this.currentInfo.$value
      .pipe(
        untilDestroyed(this),
        map((current) => {
          return {
            name: current.authenticated.name,
            identifier: current.principalStr,
            enterprise: current.enterprise
          };
        })
      )
      .subscribe((u) => (this.user = u));
  }

  get showHelp() {
    return this.movideskService.isEnabled;
  }

  onSideBackClickFn = () => this.onSideBackClick();

  ngOnInit() {}

  onSideBackClick() {
    if (this.submenu) {
      this.submenu = null;
    } else {
      this.layoutBase.closeMenu();
    }
  }

  onSubmenuClick(submenu: SubMenu) {
    this.submenu = submenu;
    this.layoutBase.resetMenu();
  }

  openHelp() {
    this.movideskService.openChat();
  }

  ngOnDestroy(): void {}
}
