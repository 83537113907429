<div class="content">
    <div class="info-row" *ngIf="passwordValidator.enableLowerCaseLetterRule">
        <div *ngIf="passwordValidator.containAtLeastOneLowerCaseLetter; then done else error" @flipY>
        </div>
        <ng-template #done>
            <mat-icon color="primary">done</mat-icon>
        </ng-template>
        <ng-template #error>
            <mat-icon class="error">error</mat-icon>
        </ng-template>
        <span>letra minúscula</span>
    </div>

    <div class="info-row" *ngIf="passwordValidator.enableUpperCaseLetterRule">
        <div *ngIf="passwordValidator.containAtLeastOneUpperCaseLetter; then done else error">
        </div>
        <ng-template #done>
            <mat-icon color="primary">done</mat-icon>
        </ng-template>
        <ng-template #error>
            <mat-icon class="error">error</mat-icon>
        </ng-template>
        <span>letra maiúscula</span>
    </div>

    <div class="info-row" *ngIf="passwordValidator.enableDigitRule">
        <div *ngIf="passwordValidator.containAtLeastOneDigit; then done else error">
        </div>
        <ng-template #done>
            <mat-icon color="primary">done</mat-icon>
        </ng-template>
        <ng-template #error>
            <mat-icon class="error">error</mat-icon>
        </ng-template>
        <span>número</span>
    </div>

    <div class="info-row" *ngIf="passwordValidator.enableSpecialCharRule">
        <div *ngIf="passwordValidator.containAtLeastOneSpecialChar; then done else error">
        </div>
        <ng-template #done>
            <mat-icon color="primary">done</mat-icon>
        </ng-template>
        <ng-template #error>
            <mat-icon class="error">error</mat-icon>
        </ng-template>
        <span>caracter especial</span>
    </div>

    <div class="info-row" *ngIf="passwordValidator.enableLengthRule">
        <div *ngIf="passwordValidator.containAtLeastMinChars; then done else error">
        </div>
        <ng-template #done>
            <mat-icon color="primary">done</mat-icon>
        </ng-template>
        <ng-template #error>
            <mat-icon class="error">error</mat-icon>
        </ng-template>
        <span>8 caracteres</span>
    </div>
</div>