import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { QueryFilterOp, QueryValueOp } from '@eceos/arch';
import { NfeItemsCube } from '@eceos/domain';
import { ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { Label, SingleDataSet } from 'ng2-charts';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-nfes-cfop-widget',
  templateUrl: './nfes-cfop-widget.component.html',
  styleUrls: ['./nfes-cfop-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NfesCfopWidgetComponent implements OnInit {
  data: SingleDataSet = [];

  labels: Label[] = [];

  options: ChartOptions = { responsive: true };

  constructor(private cube: NfeItemsCube, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.fetchData();
  }

  async refresh() {
    this.fetchData();
  }

  async fetchData() {
    const result = await lastValueFrom(
      this.cube.fetch({
        groups: [NfeItemsCube.DIMENSIONS.CFOP],
        filters: [
          NfeItemsCube.DIMENSIONS.MOVEMENT.YEAR_MONTH_DAY.asFilter(
            QueryFilterOp.GTE,
            moment().subtract(7, 'days').format('YYYY-MM-DD')
          )
        ],
        values: [NfeItemsCube.MEASURES.TOTAL.asValue(QueryValueOp.SUM)],
        sorts: ['values.total:DSC']
      })
    );

    const dataset = [];
    const labels = [];
    for (const { groups, values } of result) {
      labels.push(groups.cfop);
      dataset.push(values.total);
    }
    this.labels = labels;
    this.data = dataset;
    this.changeDetector.markForCheck();
  }
}
