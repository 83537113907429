import { SgvJson } from "@eceos/arch";
import { FiscalUnity } from '../fiscal';

export class NCM {
  constructor(
    public id ='',
    public name = '',
    public number ='',
    public defaultUnity: FiscalUnity = null
  ) { }

  readonly isActive = true;

  get nameWithNumber(){
    return this.number && this.name !== '' ? `${this.number} - ${this.name}` : '';
  }

  toJson() {
    return SgvJson.to.simple(this, {
      defaultUnity: this.defaultUnity? this.defaultUnity.toJson() : null
    });
  }

  static fromJson(json: any): NCM {
    return SgvJson.from.simple(json, NCM, {
      defaultUnity: FiscalUnity.fromJson(json.defaultUnity)
    });
  }
}
