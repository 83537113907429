import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { BcModalityST } from './bc-modality-st';
@Injectable({ providedIn: 'root' })
export class BcModalitiesStRepository extends ReadonlyRepositoryImpl<BcModalityST, BcModalityST>
  implements ReadonlyRepository<BcModalityST, BcModalityST> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/icms/bcModalitiesST'), BcModalityST, BcModalityST);
  }
}
