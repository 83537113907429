import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { User } from '../user';
import { UserRoleSummary } from './../roles/user-role';

export abstract class UserInvite implements DomainEntity {
  constructor(
    public readonly id: string,
    public userRole: UserRoleSummary,
    public inviter: User,
    public created: Date
  ) {}

  toJson() {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): UserInvite {
    if (json && json.type) {
      switch (json.type) {
        case 'accountant':
          return AccountantUserInvite.fromJson(json);
        case 'owner':
          return OwnerUserInvite.fromJson(json);
        case 'member':
          return MemberUserInvite.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class AccountantUserInvite extends UserInvite {
  constructor(
    id = SgvId.gen(),
    userRole: UserRoleSummary = null,
    inviter: User = null,
    created: Date = null
  ) {
    super(id, userRole, inviter, created);
  }

  static fromJson(json: any): AccountantUserInvite {
    return json
      ? SgvJson.from.simple(json, AccountantUserInvite, {
          userRole: UserRoleSummary.fromJson(json.userRole),
          inviter: User.fromJson(json.inviter)
        })
      : null;
  }
}

export class OwnerUserInvite extends UserInvite {
  constructor(
    id = SgvId.gen(),
    userRole: UserRoleSummary = null,
    inviter: User = null,
    created: Date = null
  ) {
    super(id, userRole, inviter, created);
  }

  static fromJson(json: any): OwnerUserInvite {
    return json
      ? SgvJson.from.simple(json, OwnerUserInvite, {
          userRole: UserRoleSummary.fromJson(json.userRole),
          inviter: User.fromJson(json.inviter)
        })
      : null;
  }
}

export class MemberUserInvite extends UserInvite {
  constructor(
    id = SgvId.gen(),
    userRole: UserRoleSummary = null,
    inviter: User = null,
    created: Date = null
  ) {
    super(id, userRole, inviter, created);
  }

  static fromJson(json: any): MemberUserInvite {
    return json
      ? SgvJson.from.simple(json, MemberUserInvite, {
          userRole: UserRoleSummary.fromJson(json.userRole),
          inviter: User.fromJson(json.inviter)
        })
      : null;
  }
}
