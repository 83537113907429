import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class CofinsCst implements DomainEntity {
  constructor(readonly id = SgvId.gen(), readonly name = '', readonly number = '') {}

  get nameWithNumber(): string {
    return this.number + ' - ' + this.name;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): CofinsCst {
    return json ? SgvJson.from.simple(json, CofinsCst) : null;
  }
}
