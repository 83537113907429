import { Injectable } from '@angular/core';
import { ApiService, RequestMetadata, InterceptorConfig, RequestMetadataUtils } from '@eceos/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Session } from './session';

@Injectable({ providedIn: 'root' })
export class SessionsRepository {
  private metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(private api: ApiService) {}

  current(metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }): Observable<Session> {
    return this.api.root
      .path('equeue')
      .path('sessions')
      .path('current')
      .getJson({ metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => Session.fromJson(r)));
  }

  openSession(
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Session> {
    return this.api.root
      .path('equeue')
      .path('sessions')
      .path('current')
      .postJson(null, { metadata: RequestMetadataUtils.merge(metadata, this.metadata) });
  }

  closeSession(
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<Session> {
    return this.api.root
      .path('equeue')
      .path('sessions')
      .path('current')
      .patchJson(
        { action: 'close' },
        { metadata: RequestMetadataUtils.merge(metadata, this.metadata) }
      );
  }
}
