<div *ngIf="showing">
    <div class="body">
        <div class="title">
            Aviso de suspensão de contrato!
        </div>
        <div>
            <p>Caro cliente, por motivo de inadimplência o seu acesso a este sistema será 
                suspenso em <strong>{{ remainingDays }} dia(s)</strong>. Para evitar o encerramento do seu contrato em 
                {{ inactivationDate | date }}, 
                envie um e-mail para <a href="mailto:financeiro@eceos.com.br">financeiro@eceos.com.br</a> ou 
                entre em contato através do 
                <a href="" (click)="openHelp()">Chat</a>, <a href="https://wa.me/558008782737">Whatsapp</a> ou ligue gratuitamente para <a href="tel:08008782737">08008782737</a> para regularização.</p>
        </div>
    </div>
    <div class="footer">
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
            Fechar
        </button>
    </div>
</div>