import { SgvJson } from "@eceos/arch";

export abstract class ExpirationData {
    constructor() { }

    abstract toJson(): any;

    static fromJson(json: any): ExpirationData {
        if (!(json || json.type)) return null;

        switch (json.type) {
            case 'perishable':
                return Perishable.fromJson(json);
            case 'notPerishable':
                return NotPerishable.fromJson(json);
            default:
                throw new Error('Expiration data type not mapped');
        }
    }
}

export class Perishable extends ExpirationData {
    constructor(
        public readonly date: Date = new Date(),
    ) {
        super();
    }

    toJson(): any {
        throw Error('Não é possível criar ou atualizar Perishable');
    }

    static fromJson(json: any): Perishable {
        return json ? SgvJson.from.simple(json, Perishable, {
            date: json.date ? new Date(json.date) : new Date()
        }) : null;
    }

}

export class NotPerishable extends ExpirationData {
    constructor() {
        super();
    }

    toJson(): any {
        throw Error('Não é possível criar ou atualizar NotPerishable');
    }

    static fromJson(json: any): NotPerishable {
        return json ? SgvJson.from.simple(json, NotPerishable) : null;
    }

}