<mat-card>
  <mat-card-header>
    <mat-card-title>Total de notas por CFOP</mat-card-title>
    <mat-card-subtitle>
      últimos 7 dias
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div style="display: block">
      <canvas
        baseChart
        [data]="data"
        [labels]="labels"
        [options]="options"
        [legend]="false"
        chartType="horizontalBar"
      >
      </canvas>
    </div>
  </mat-card-content>
</mat-card>
