import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { City } from './city';

export class ZipCode implements DomainEntity {
  constructor(
    public id: string = SgvId.gen(),
    public zipCode: string = '',
    public street: string = '',
    public complement: string = null,
    public district: string = '',
    public city: City = null
  ) {}

  get label(): string {
    let result = '';
    if (this.street) {
      result += this.street;
    }
    if (result !== '') {
      result += ' - ';
    }
    if (this.district) {
      result += this.district;
    }
    if (this.district && this.city) {
      result += ', ';
    }
    if (this.city) {
      result += this.city.nameWithUf;
    }
    return result;
  }

  toJson() {
    return SgvJson.to.simple(this, {
      city: this.city ? this.city.toJson() : null
    });
  }

  static fromJson(json: any): ZipCode {
    return json
      ? SgvJson.from.simple(json, ZipCode, {
          city: json.city ? City.fromJson(json.city) : null
        })
      : null;
  }
}
