import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { NFePaymentMethod } from './nfe-payment-method';

@Injectable({ providedIn: 'root' })
export class NFePaymentMethodsRepository extends ReadonlyRepositoryImpl<NFePaymentMethod, NFePaymentMethod> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/paymentMethods/nfes'), NFePaymentMethod, NFePaymentMethod);
  }
}
