import { DomainEntity, JsonEntity, SgvId, SgvJson } from '@eceos/arch';
import { CFOP } from '../cfops';
import { BaseNFe } from '../nfes/nfe';
import { NFeItem } from '../nfes/nfe-item';
import { IcmsStPassedOn } from '../nfes/tax/icms/icms-st-passed-on';
import { FiscalProductOperationMessage } from './messages';
import { FiscalTaxConfig } from './tax/fiscal-tax-config';

export class TaxCategorySummary implements DomainEntity {
  constructor(readonly id = SgvId.gen(), readonly name = '') {}
  toJson() {
    return SgvJson.to.simple(this);
  }
  static fromJson(json: any): TaxCategorySummary {
    return SgvJson.from.simple(json, TaxCategorySummary);
  }
}

export class TaxCategory implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public operations: FiscalProductOperation[] = [],
    public operationDefault = new FiscalProductOperationDefault(),
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  toJson() {
    return SgvJson.to.simple(this, {
      operations: SgvJson.to.array(this.operations),
      operationDefault: this.operationDefault.toJson()
    });
  }
  static fromJson(json: any): TaxCategory {
    return SgvJson.from.simple(json, TaxCategory, {
      operations: SgvJson.from.array(json.operations, FiscalProductOperation.fromJson),
      operationDefault: FiscalProductOperationDefault.fromJson(json.operationDefault)
    });
  }
}

export class FiscalProductOperationDefault implements JsonEntity {
  constructor(
    public input = new FiscalProductOperationDefaultNature(),
    public output = new FiscalProductOperationDefaultNature(),
    public transfer = new FiscalProductOperationDefaultNature()
  ) {}
  toJson() {
    return SgvJson.to.simple(this, {
      input: this.input.toJson(),
      output: this.output.toJson(),
      transfer: this.transfer.toJson()
    });
  }
  static fromJson(json: any): FiscalProductOperationDefault {
    return json
      ? new FiscalProductOperationDefault(
          FiscalProductOperationDefaultNature.fromJson(json.input),
          FiscalProductOperationDefaultNature.fromJson(json.output),
          FiscalProductOperationDefaultNature.fromJson(json.transfer)
        )
      : new FiscalProductOperationDefault();
  }
}
export class FiscalProductOperationDefaultNature implements JsonEntity {
  constructor(
    public insideState: CFOP = null,
    public outsideState: CFOP = null,
    public outsideCountry: CFOP = null
  ) {}
  toJson() {
    return SgvJson.to.simple(this, {
      insideState: this.insideState ? this.insideState.toJson() : null,
      outsideState: this.outsideState ? this.outsideState.toJson() : null,
      outsideCountry: this.outsideCountry ? this.outsideCountry.toJson() : null
    });
  }
  static fromJson(json: any): FiscalProductOperationDefaultNature {
    return json
      ? new FiscalProductOperationDefaultNature(
          CFOP.fromJson(json.insideState),
          CFOP.fromJson(json.outsideState),
          CFOP.fromJson(json.outsideCountry)
        )
      : new FiscalProductOperationDefaultNature();
  }
}

export class FiscalProductOperation implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public cfop: CFOP = null,
    public taxes: FiscalTaxConfig = null,
    public additionalMessages: FiscalProductOperationMessage[] = [],
    public active = true
  ) {}

  update(nfe: BaseNFe, nfeItem: NFeItem, icmsStPassedOn: IcmsStPassedOn) {
    if (nfeItem && nfeItem.tax) {
      nfeItem.tax.cfop = this.cfop;
      if (this.taxes) {
        this.taxes.update(nfe, nfeItem, icmsStPassedOn);
      }
    }
  }

  toJson() {
    return SgvJson.to.simple(this, {
      cfop: this.cfop ? this.cfop.toJson() : null,
      taxes: this.taxes ? this.taxes.toJson() : null,
      additionalMessages: SgvJson.to.array(this.additionalMessages)
    });
  }

  static fromJson(json: any): FiscalProductOperation {
    return json
      ? SgvJson.from.simple(json, FiscalProductOperation, {
          cfop: CFOP.fromJson(json.cfop),
          taxes: FiscalTaxConfig.fromJson(json.taxes),
          additionalMessages: SgvJson.from.array(
            json.additionalMessages,
            FiscalProductOperationMessage.fromJson
          )
        })
      : null;
  }
}
