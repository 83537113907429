import { Pipe, PipeTransform } from '@angular/core';
import { OperatableUnityLegacy } from '@eceos/domain';

@Pipe({
  name: 'acronym'
})
export class AcronymPipe implements PipeTransform {
  transform(operatableUnity: OperatableUnityLegacy, defaultValue: string = 'UNID'): string {
    return operatableUnity && operatableUnity.acronym ? operatableUnity.acronym : defaultValue;
  }
}
