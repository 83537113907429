import { SgvJson } from '@eceos/arch';

export class InterstateAliquot {
  constructor(readonly interstateAliquot: number = null, readonly internalAliquot: number = null) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): InterstateAliquot {
    return json ? SgvJson.from.simple(json, InterstateAliquot) : null;
  }

  static zero(): InterstateAliquot {
    return new InterstateAliquot(0, 0);
  }
}
