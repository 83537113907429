import { NFeItem } from '../nfe-item';
import { IPI } from '../tax/ipi/ipi';
import { IpiValueWithBcAliquot } from '../tax/ipi/ipi-value-with-bc-aliquot';

export class IPITotal {
  constructor(private items: () => NFeItem[]) {}

  get total(): number {
    return this.value;
  }

  get value(): number {
    return this.ipiValueWithBcAliquot()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  private ipi(): IPI[] {
    return this.items()
      .map(it => it.tax.taxes.ipi)
      .filter(it => it != null);
  }

  private ipiValueWithBcAliquot(): IpiValueWithBcAliquot[] {
    return (this.ipi() as any[])
      .filter(it => it.ipi !== undefined && it.ipi instanceof IpiValueWithBcAliquot)
      .map(it => it.ipi as IpiValueWithBcAliquot);
  }
}
