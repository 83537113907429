import { JsonEntity } from '@eceos/arch';

export abstract class SalesOrderActionRequest implements JsonEntity {
  abstract toJson(): any;
}

export class ApproveSalesOrderRequest extends SalesOrderActionRequest {
  toJson(): any {
    return { action: 'approve' };
  }
}
