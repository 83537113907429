import { Injectable } from '@angular/core';
import { RepositoryImpl, ApiService } from '@eceos/arch';
import { PersonSummary } from './person';

@Injectable({
  providedIn: 'root'
})
export class PeopleRepository extends RepositoryImpl<PersonSummary, PersonSummary>{

  constructor(api: ApiService) {
    super(api.root.path('people'), PersonSummary, PersonSummary);
  }
}
