import { SgvJson } from "@eceos/arch";
import { StockAmount } from "./stock-amount";

export class SimpleStockData {
    constructor(
        public amount: StockAmount = new StockAmount()
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this, {
            amount: this.amount ? this.amount.toJson() : null
        });
    }

    static fromJson(json: any): SimpleStockData {
        return json ? SgvJson.from.simple(json, SimpleStockData, {
            amount: json.amount ? StockAmount.fromJson(json.amount) : null
        }) : null;
    }
}