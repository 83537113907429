import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { InstallmentPolicy, InstallmentPolicySummary } from './installment-policy';
import { ParcelSimulator } from './parcel-simulator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InstallmentPoliciesRepository extends RepositoryImpl<
  InstallmentPolicySummary,
  InstallmentPolicy
> {
  constructor(api: ApiService) {
    super(
      api.root.path('financial/installmentPolicies'),
      InstallmentPolicySummary,
      InstallmentPolicy
    );
  }
  calculateParcelValue(
    installmentPolicy: InstallmentPolicy | InstallmentPolicySummary,
    parcelSimulator: ParcelSimulator
  ): Observable<number> {
    return this.api
      .path(installmentPolicy.id)
      .path('calculateParcelValue')
      .postJson(parcelSimulator.toJson())
      .pipe(map((v: any) => v.parceledValue as number));
  }
}
