import { IcmsUfReceiver } from './../tax/icms-uf-receiver/icms-uf-receiver';
import { NFeItem } from '../nfe-item';

export class ICMSUFReceiverTotal {
  constructor(private items: () => NFeItem[]) {}

  get total(): number {
    const total = [this.fcpUFReceiverValue, this.ufReceiverShareValue, this.ufEmitterShareValue]
      .filter(it => it != null)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
    return total;
  }

  get fcpUFReceiverValue(): number {
    return this.icmsUfReceiver()
      .filter(it => it.fcp && it.fcp.value && it.fcp.value.value)
      .map(it => it.fcp.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get ufReceiverShareValue(): number {
    return this.icmsUfReceiver()
      .filter(it => it.icmsUfReceiver && it.icmsUfReceiver.value && it.icmsUfReceiver.value.value)
      .map(it => it.icmsUfReceiver.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get ufEmitterShareValue(): number {
    return 0;
  }

  private icmsUfReceiver(): IcmsUfReceiver[] {
    return this.items()
      .map(it => it.tax.taxes.icmsUfReceiver)
      .filter(it => it != null);
  }
}
