import { Injectable, HostListener, Directive } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Directive()
export abstract class RefreshablePage implements ChangeablePage {
  abstract hasUnsavedState(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if (this.hasUnsavedState()) {
      $event.returnValue = true;
    }
  }
}

export interface ChangeablePage {
  hasUnsavedState(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ChangeAlertGuard implements CanDeactivate<ChangeablePage> {
  canDeactivate(page: ChangeablePage): Promise<boolean> {
    if (!page.hasUnsavedState) {
      console.error(
        `Componente ${page.constructor.name} não implementa a interface ChangeablePage`
      );
    }
    return new Promise<boolean>(resolve =>
      !page.hasUnsavedState ||
      !page.hasUnsavedState() ||
      window.confirm(
        'Suas alterações ainda não foram salvas e serão perdidas, deseja realmente sair?'
      )
        ? resolve(true)
        : resolve(false)
    ).then(v => {
      if (!v) {
        history.pushState(null, null, location.href);
      }
      return v;
    });
  }

}
