import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedPercent } from '../../calculated-percent';
import { CalculatedValue } from '../../calculated-value';
import { NFeItem } from '../../nfe-item';
import { IcmsStPassedOn } from './icms-st-passed-on';

export interface IcmsStWithRetainedValueHolder {
  icmsStRetained: IcmsStWithRetainedValue;

  setDefaultIcmsStRetained?(isEndConsumer: boolean): void;
}

export class IcmsStWithRetainedValue {
  constructor(
    public bcRetainedValue: CalculatedValue = new CalculatedValue(),
    public retainedValue: CalculatedValue = new CalculatedValue(),
    public endConsumerSupportedAliquot: CalculatedPercent = new CalculatedPercent(),
    public substituteValue: CalculatedValue = null
  ) {}

  isValid(): boolean {
    return (
      EceosValidatorsUtils.isValid(this.bcRetainedValue) &&
      EceosValidatorsUtils.isValid(this.retainedValue) &&
      EceosValidatorsUtils.isValid(this.endConsumerSupportedAliquot) &&
      EceosValidatorsUtils.isValidOptional(this.substituteValue)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      bcRetainedValue: this.bcRetainedValue ? this.bcRetainedValue.toJson() : null,
      retainedValue: this.retainedValue ? this.retainedValue.toJson() : null,
      endConsumerSupportedAliquot: this.endConsumerSupportedAliquot
        ? this.endConsumerSupportedAliquot.toJson()
        : null,
      substituteValue: this.substituteValue ? this.substituteValue.toJson() : null
    });
  }

  calcBcRetainedValue(icmsPassedOn: IcmsStPassedOn, isEndConsumer: boolean): number {
    if (this.bcRetainedValue && this.bcRetainedValue.value) {
      return this.bcRetainedValue.value;
    } else if (icmsPassedOn && icmsPassedOn.bcRetainedValue && !isEndConsumer) {
      return icmsPassedOn.bcRetainedValue;
    }
  }

  calcRetainedValue(item: NFeItem, icmsPassedOn: IcmsStPassedOn, isEndConsumer: boolean): number {
    return this.calcValue(
      item,
      this.retainedValue,
      () => (icmsPassedOn ? icmsPassedOn.icmsRetainedValue : null),
      isEndConsumer
    );
  }

  calcSubstituteValue(item: NFeItem, icmsPassedOn: IcmsStPassedOn, isEndConsumer: boolean): number {
    return this.calcValue(
      item,
      this.substituteValue,
      () => (icmsPassedOn ? icmsPassedOn.icmsSubstituteValue : null),
      isEndConsumer
    );
  }

  calcEndConsumerAliquot(icmsPassedOn: IcmsStPassedOn, isEndConsumer: boolean): number {
    if (this.endConsumerSupportedAliquot && this.endConsumerSupportedAliquot.value) {
      return this.endConsumerSupportedAliquot.value;
    } else if (!isEndConsumer) {
      return icmsPassedOn ? icmsPassedOn.endConsumerSupportedAliquot : null;
    }
    return null;
  }

  publishValues(
    nfeItem: NFeItem,
    icmsPassedOn: IcmsStPassedOn,
    isEndConsumer: boolean
  ): IcmsStWithRetainedValue {
    this.bcRetainedValue.value = this.calcBcRetainedValue(icmsPassedOn, isEndConsumer);
    this.retainedValue.value = this.calcRetainedValue(nfeItem, icmsPassedOn, isEndConsumer);
    this.endConsumerSupportedAliquot.value = this.calcEndConsumerAliquot(
      icmsPassedOn,
      isEndConsumer
    );

    const substituteValue = this.calcSubstituteValue(nfeItem, icmsPassedOn, isEndConsumer);
    if (substituteValue != null) {
      if (this.substituteValue) {
        this.substituteValue.value = substituteValue;
      } else {
        this.substituteValue = new CalculatedValue(substituteValue);
      }
    }

    return this;
  }

  private calcValue(
    item: NFeItem,
    calculated: CalculatedValue | CalculatedPercent,
    valueSupplier: () => number,
    isEndConsumer: boolean
  ): number {
    if (calculated && calculated.value) {
      return calculated.value;
    } else if (!isEndConsumer) {
      return valueSupplier() ? valueSupplier() * item.amount : null;
    }
  }

  static fromJson(json: any): IcmsStWithRetainedValue {
    return json
      ? SgvJson.from.simple(json, IcmsStWithRetainedValue, {
          bcRetainedValue: CalculatedValue.fromJson(json.bcRetainedValue),
          retainedValue: CalculatedValue.fromJson(json.retainedValue),
          endConsumerSupportedAliquot: CalculatedPercent.fromJson(json.endConsumerSupportedAliquot),
          substituteValue: CalculatedValue.fromJson(json.substituteValue)
        })
      : null;
  }
}
