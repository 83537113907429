import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class ChargingFlowSummary implements DomainEntity {
  constructor(readonly id: string = SgvId.gen(), name: string = '') {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ChargingFlowSummary {
    return json ? SgvJson.from.simple(json, ChargingFlowSummary) : null;
  }
}
