import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from './login.component';

export const routes: Routes = [
  { path: 'login', component: LoginPageComponent, data: { title: 'e-Ceos - Login' } }
];

export const LoginRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
