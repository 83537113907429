import { Injectable } from '@angular/core';
import { RepositoryImpl, ApiService } from '@eceos/arch';
import { Vendor, VendorSummary } from './vendor';

@Injectable({providedIn:'root'})
export class VendorsRepository extends RepositoryImpl<VendorSummary, Vendor> {
  constructor(api: ApiService) {
    super(api.root.path('vendors'), VendorSummary, Vendor);
  }
}
