import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { retryExceptOnClientError } from '@eceos/common-utils';
import { Observable } from 'rxjs';
import { map, retryWhen } from 'rxjs/operators';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class UsersRepository extends RepositoryImpl<User, User> {
  constructor(api: ApiService) {
    super(api.manager.path('users'), User, User);
  }

  getCurrent(): Observable<User> {
    return this.api
      .path('current')
      .getJson({ metadata: this.metadata })
      .pipe(
        retryWhen(retryExceptOnClientError()),
        map((json) => User.fromJson(json))
      );
  }

  updateCurrent(entity: User): Observable<User> {
    return this.api
      .path('current')
      .putJson(entity.toJson(), { metadata: this.metadata })
      .pipe(map((json) => User.fromJson(json)));
  }
}
