export enum IconSource {
  MATERIAL = 'MATERIAL',
  FONTAWESOME = 'FONTAWESOME'
}
export interface EntityFabDialOption {
  label: string;
  value: string;
  icon: string;
  iconSource?: IconSource;
  color?: string;
  backgroundColor?: string;
}
