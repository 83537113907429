import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { InterstateIcmsUfAliquot } from './interstate-icms-uf-aliquot';

@Injectable({ providedIn: 'root' })
export class InterstateICMSUFAliquotRepository
  extends ReadonlyRepositoryImpl<InterstateIcmsUfAliquot, InterstateIcmsUfAliquot>
  implements ReadonlyRepository<InterstateIcmsUfAliquot, InterstateIcmsUfAliquot> {
  constructor(api: ApiService) {
    super(
      api.root.path('/fiscal/ufReceiver/interstateAliquots'),
      InterstateIcmsUfAliquot,
      InterstateIcmsUfAliquot
    );
  }
}
