import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Name } from '../../name';

export class Lead implements DomainEntity {
  constructor(
    public id = SgvId.gen(),
    public name = new Name(),
    public phone = '',
    public email = '',
    public source = '',
    public creation = new Date(),
    public isActive = true,
    public tags: Map<string, string> = new Map()
  ) {}

  toJson() {
    return SgvJson.to.simple(this, {
      name: this.name.toJson(),
      tags: Array.from(this.tags.entries()).reduce(
        (main, [key, value]) => ({ ...main, [key]: value }),
        {}
      )
    });
  }

  static fromJson(json: any) {
    if (!json) return null;
    return SgvJson.from.simple(json, Lead, {
      name: Name.fromJson(json.name),
      tags: new Map(Object.entries(json.tags ?? {}))
    });
  }
}
