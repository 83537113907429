import { JsonEntity, SgvJson } from '@eceos/arch';
import { NumberMask } from '@eceos/common-utils';

export const REGEX_EXP_VALID_MAIL = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export abstract class Contact implements JsonEntity {
  constructor(public detail = '') {}

  abstract toJson(): any;

  static fromJson(json: any): Contact {
    if (json && json.type) {
      switch (json.type) {
        case 'EMAIL':
          return EmailContact.fromJson(json);
        case 'TELEFONE':
          return PhoneContact.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class EmailContact extends Contact {
  constructor(detail = '') {
    super(detail);
  }

  get isValid() {
    return this.detail && this.detail !== '' && REGEX_EXP_VALID_MAIL.test(this.detail);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'EMAIL'
    });
  }

  static fromJson(json: any): EmailContact {
    return json ? SgvJson.from.simple(json, EmailContact) : null;
  }
}

export class PhoneContact extends Contact {
  constructor(detail = '') {
    super(detail);
  }

  get formattedValue() {
    return PhoneContact.MASK.apply(this.detail);
  }

  get isValid() {
    return this.detail && this.detail.length >= 8;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'TELEFONE'
    });
  }

  static MASK = new NumberMask(['9999-9999', '99999-9999', '(99) 9999-9999', '(99) 99999-9999']);

  static fromJson(json: any): PhoneContact {
    return json ? SgvJson.from.simple(json, PhoneContact) : null;
  }
}
