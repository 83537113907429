import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class IcmsCst implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly name = '',
    readonly number = '',
    readonly part = false,
    readonly st = false
  ) { }

  get nameWithNumber(): string {
    return this.number + " - " + this.name;
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsCst {
    return SgvJson.from.simple(json, IcmsCst);
  }
}