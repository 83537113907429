import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { FormulaExecutor } from '../../../../core/formula-executor/formula-executor';
import { CalculatedValue } from '../../calculated-value';
import { NFeItem } from '../../nfe-item';
import { NFeFiscalTax } from '../nfe-item-tax';

export class IcmsUfReceiverWithFcpValue {
  constructor(
    public bcFormula = '',
    public bcValue: CalculatedValue = new CalculatedValue(),
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  calcBcValue(nfeItem: NFeItem): number {
    return this.bcFormula
      ? new FormulaExecutor(this.bcFormula, NFeFiscalTax.buildBcVariables(nfeItem)).execute()
      : null;
  }

  calcValue(): number {
    return NFeFiscalTax.calcValueBy(this.bcValue.value, this.aliquot);
  }

  isValid(): boolean {
    return (
      this.aliquot != null &&
      EceosValidatorsUtils.isValid(this.bcValue) &&
      EceosValidatorsUtils.isValid(this.value)
    );
  }

  publishValues(nfeItem: NFeItem): IcmsUfReceiverWithFcpValue {
    this.bcValue.value = this.calcBcValue(nfeItem);
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      bcValue: this.bcValue ? this.bcValue.toJson() : null,
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): IcmsUfReceiverWithFcpValue {
    return json
      ? SgvJson.from.simple(json, IcmsUfReceiverWithFcpValue, {
          bcValue: CalculatedValue.fromJson(json.bcValue),
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}
