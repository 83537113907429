import { Operatable } from './operatable';
import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService, RequestMetadata, InterceptorConfig } from '@eceos/arch';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OperatablesRepository extends ReadonlyRepositoryImpl<
Operatable,
Operatable
> {
  constructor(api: ApiService) {
    super(api.root.path('operatables'), Operatable, Operatable);
  }

  fileToBalance(balanceModel: string, metadata: RequestMetadata = { autoCatch: InterceptorConfig.INTERCEPT }): Observable<Blob> {
    return this.api.path('fileToBalance').path(balanceModel).getBlob({ metadata: metadata });
  }
}
