import { Injectable } from '@angular/core';
import {
  Actives,
  ApiService,
  Filter,
  PageData,
  RepositoryImpl,
  SgvJson,
  SortOrder,
  UrlSearchParamsBuilder
} from '@eceos/arch';
import { mapToNumber } from '@eceos/common-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enterprise, EnterpriseSummary } from '../enterprises/enterprise';
import { Partner, PartnerSummary } from './partner';
import { EnterprisesCountByPlanSummary, EnterprisesCountByWalletStatusSummary } from './summary';
import { EnterprisesLastMonthGrowthSummary } from './summary/enterprises-last-month-growth-summary';

@Injectable({ providedIn: 'root' })
export class PartnersRepository extends RepositoryImpl<PartnerSummary, Partner> {
  constructor(api: ApiService) {
    super(api.manager.path('partners'), PartnerSummary, Partner);
  }

  findCurrent(id: string): Observable<Partner> {
    return this.api
      .path('current')
      .path(id)
      .getJson()
      .pipe(map((r) => this.type.fromJson(r)));
  }

  listCurrent(
    pageData: PageData = new PageData(),
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = []
  ): Observable<Partner[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(filters)
      .sortOrders(sortOrders)
      .build();
    return this.api
      .path('current')
      .getJson<any[]>({ params: search })
      .pipe(map((json) => SgvJson.from.array(json, Partner.fromJson)));
  }

  countCurrent(query: string = '', filters: Filter[] = []): Observable<number> {
    const search = new UrlSearchParamsBuilder().query(query).filters(filters).build();
    return this.api.path('current').path('size').getText({ params: search }).pipe(mapToNumber());
  }

  listEnterprises(
    partnerId: string,
    pageData: PageData = new PageData(),
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives: Actives = Actives.ALL
  ): Observable<EnterpriseSummary[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(filters)
      .sortOrders(sortOrders)
      .summary(true)
      .actives(actives)
      .build();
    return this.api
      .path(partnerId)
      .path('enterprises')
      .getJson<any[]>({ params: search })
      .pipe(map((json) => SgvJson.from.array(json, EnterpriseSummary.fromJson)));
  }

  listEnterprisesFull(
    partnerId: string,
    pageData: PageData = new PageData(),
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives: Actives = Actives.ALL
  ): Observable<Enterprise[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(filters)
      .sortOrders(sortOrders)
      .summary(false)
      .actives(actives)
      .build();
    return this.api
      .path(partnerId)
      .path('enterprises')
      .getJson<any[]>({ params: search })
      .pipe(map((json) => SgvJson.from.array(json, Enterprise.fromJson)));
  }

  countEnterprises(
    partnerId: string,
    query: string = '',
    filters: Filter[] = [],
    actives: Actives = Actives.ALL
  ): Observable<number> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(filters)
      .actives(actives)
      .build();
    return this.api
      .path(partnerId)
      .path('enterprises')
      .path('size')
      .getText({ params: search })
      .pipe(mapToNumber());
  }

  getEnterpriseToken(partnerId: string, enterpriseId: string): Observable<string> {
    return this.api.path(partnerId).path('enterprises').path(enterpriseId).path('token').getText();
  }

  getEnterprisesLastMonthGrowthSummaryFrom(
    partnerId: string
  ): Observable<EnterprisesLastMonthGrowthSummary> {
    return this.api
      .path(partnerId)
      .path('enterprises')
      .path('summary')
      .path('lastMonthGrowth')
      .getJson()
      .pipe(map((json) => EnterprisesLastMonthGrowthSummary.fromJson(json)));
  }

  getEnterprisesCountByPlanSummaryFrom(
    partnerId: string
  ): Observable<EnterprisesCountByPlanSummary[]> {
    return this.api
      .path(partnerId)
      .path('enterprises')
      .path('summary')
      .path('countGroupedByPlan')
      .getJson<EnterprisesCountByPlanSummary[]>()
      .pipe(map((json) => SgvJson.from.array(json, EnterprisesCountByPlanSummary.fromJson)));
  }

  getEnterprisesCountByWalletStatusSummaryFrom(
    partnerId: string
  ): Observable<EnterprisesCountByWalletStatusSummary[]> {
    return this.api
      .path(partnerId)
      .path('enterprises')
      .path('summary')
      .path('countGroupedByWalletStatus')
      .getJson<EnterprisesCountByWalletStatusSummary[]>()
      .pipe(
        map((json) => SgvJson.from.array(json, EnterprisesCountByWalletStatusSummary.fromJson))
      );
  }
}
