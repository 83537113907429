import { JsonEntity, SgvJson } from '@eceos/arch';

export class Periodicity implements JsonEntity {
  static readonly MONTHLY = new Periodicity('MONTHLY', "Mensal");
  static readonly QUARTERLY = new Periodicity('QUARTERLY', "Trimestral");
  static readonly YEARLY = new Periodicity('YEARLY', "Anual");

  constructor(readonly id = '', readonly name = '') { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static values() {
    return [Periodicity.MONTHLY, Periodicity.QUARTERLY, Periodicity.YEARLY]
  }

  static fromJson(json: any): Periodicity {
    if (!json) return null;
    return this.values().find(p => json.id === p.id);
  }
}
