import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonPagesModule } from '@eceos/common-pages';
import { DefaultSharedModule } from '@eceos/default-shared';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { WidgetsModule } from './widgets/widgets.module';

const MODULES = [
  DefaultSharedModule,
  CommonPagesModule,
  LayoutModule,
  ComponentsModule,
  PipesModule,
  WidgetsModule
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  exports: MODULES
})
export class SharedModule {}
