import { Pipe, PipeTransform } from '@angular/core';
import {
  DeliveryCarrierResponsible,
  DeliveryDelivererResponsible,
  DeliveryOwnResponsible,
  DeliveryResponsible,
  DeliveryWithoutResponsible
} from '@eceos/domain';

@Pipe({
  name: 'deliveryResponsible'
})
export class DeliveryResponsiblePipe implements PipeTransform {
  private readonly labels = new Map<any, string>();

  constructor() {
    this.labels.set(DeliveryWithoutResponsible, 'Sem responsável');
    this.labels.set(DeliveryCarrierResponsible, 'Transportadora');
    this.labels.set(DeliveryDelivererResponsible, 'Entregador');
    this.labels.set(DeliveryOwnResponsible, 'Próprio');
  }

  transform(value: DeliveryResponsible): string {
    if (!value) return '';
    if (this.labels.has(value.constructor)) {
      return this.labels.get(value.constructor);
    } else {
      return value.constructor.name;
    }
  }
}
