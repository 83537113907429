import { JsonEntity, SgvId, SgvJson } from '@eceos/arch';
import { IcmsCst } from '@eceos/domain';

export class IcmsDesonerationCalc implements JsonEntity {
  constructor(readonly id: string = SgvId.gen(), readonly description: string = '') {}

  apply(cst: IcmsCst, bcValue: number, aliquot: number, bcReductionPercent: number = 0) {
    switch (this.id) {
      case 'INSIDE':
        return new IcmsDesonerationCalcInside(cst, bcValue, aliquot, bcReductionPercent).apply();
      case 'OUTSIDE':
        return new IcmsDesonerationCalcOutside(cst, bcValue, aliquot, bcReductionPercent).apply();;
      default:
        return null;
    }
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsDesonerationCalc {
    return SgvJson.from.simple(json, IcmsDesonerationCalc);
  }
}

export class IcmsDesonerationCalcInside {
  constructor(
    private cst: IcmsCst,
    private bcValue: number,
    private aliquot: number,
    private bcReductionPercent: number = 0
  ) {}

  apply(): number {
    if(this.cst == null || this.bcValue == null || this.aliquot == null) {
      return null;
    }
    switch (this.cst.id) {
      case 'CST_20':
      case 'CST_70':
      case 'CST_90':
        return this.withBcReduction(this.bcValue, this.aliquot, (this.bcReductionPercent ? this.bcReductionPercent : 0));
      case 'CST_30':
      case 'CST_40':
        return this.withoutTax(this. bcValue, this.aliquot);
      default:
        return null;
    }
  }

  /*
  * Valor do ICMS desonerado = ValorOperação * (1 - (Aliquota * (1 - ReduçãoBC))) / (1 - Aliquota) - ValorOperação
  * */
  withBcReduction(bcValue: number, aliquot: number, bcReductionPercent: number): number {
    const operationValue = this.getOperationValue(bcValue, aliquot);
    return operationValue * (1 - ((aliquot/100) * (1 - (bcReductionPercent/100)))) / (1 - (aliquot/100)) - operationValue;
  }

  /*
  * ValorOperação = BCICMS - (Aliquota * BCICMS)
  * */
  private getOperationValue(bcValue: number, aliquot: number) {
    return bcValue - (bcValue * (aliquot/100));
  }

  /*
  * Valor do ICMS desonerado = (BCICMS / (1 - Aliquota)) * Aliquota
  * */
  withoutTax(bcValue: number, aliquot: number) {
    return bcValue / (1 - (aliquot/100)) * (aliquot/100);
  }
}

export class IcmsDesonerationCalcOutside {
  constructor(
    private cst: IcmsCst,
    private bcValue: number,
    private aliquot: number,
    private bcReductionPercent: number = 0
  ) {}

  apply(): number {
    if(this.cst == null || this.bcValue == null || this.aliquot == null) {
      return null;
    }
    switch (this.cst.id) {
      case 'CST_20':
      case 'CST_70':
      case 'CST_90':
        return this.withBcReduction(this.bcValue, this.aliquot, (this.bcReductionPercent ? this.bcReductionPercent : 0));
      case 'CST_30':
      case 'CST_40':
        return this.withoutTax(this. bcValue, this.aliquot);
      default:
        return null;
    }
  }

  /*
  * Valor do ICMS desonerado = (ValorOperação * Aliquota) - (BCICMS * Aliquota)
  * */
  withBcReduction(bcValue: number, aliquot: number, bcReductionPercent: number): number {
    return this.getIcmsValue(this.getOperationValue(bcValue, bcReductionPercent), aliquot) - this.getIcmsValue(bcValue, aliquot)
  }

  /*
  * ValorICMS = BCICMS * Aliquota
  * */
  private getIcmsValue(bcValue: number, aliquot: number) {
    return bcValue * (aliquot/100);
  }

  /*
  * ValorOperação = BCICMS / (1 - ReduçãoBC)
  * */
  getOperationValue(bcValue: number, bcReductionPercent: number) {
    return bcValue / (1 - (bcReductionPercent/100));
  }

  /*
  * Valor do ICMS desonerado = ValorICMS
  * */
  withoutTax(bcValue: number, aliquot: number) {
    return this.getIcmsValue(bcValue, aliquot);
  }
}