import { SgvJson } from '@eceos/arch';
import { CalculatedValue } from '../../../nfes/calculated-value';
import {
  CofinsValueWithAliquot,
  CofinsValueWithAliquotPercent,
  CofinsValueWithAliquotValue
} from '../../../nfes/tax/cofins/cofins-value-with-aliquot';
import {
  CofinsValueWithBcAliquot,
  CofinsValueWithBcAliquotPercent,
  CofinsValueWithBcAliquotValue
} from '../../../nfes/tax/cofins/cofins-value-with-bc-aliquot';
import { NFeItem } from '../../../nfes/nfe-item';

export abstract class CofinsWithAliquot {
  constructor(public aliquot: number = null) {}

  abstract toAliquot(): CofinsValueWithAliquot;

  abstract toBcAliquot(nfeItem: NFeItem): CofinsValueWithBcAliquot;

  abstract toJson(): any;

  static fromJson(json: any): CofinsWithAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'percent':
          return CofinsWithAliquotPercent.fromJson(json);
        case 'value':
          return CofinsWithAliquotValue.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class CofinsWithAliquotPercent extends CofinsWithAliquot {
  constructor(aliquot: number = null, public bcFormula = '') {
    super(aliquot);
  }

  toAliquot(): CofinsValueWithAliquotPercent {
    return new CofinsValueWithAliquotPercent(this.aliquot);
  }

  toBcAliquot(nfeItem: NFeItem): CofinsValueWithBcAliquotPercent {
    return new CofinsValueWithBcAliquotPercent(
      this.aliquot,
      new CalculatedValue(),
      new CalculatedValue(),
      this.bcFormula
    ).publishValues(nfeItem);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'percent' });
  }

  static fromJson(json: any): CofinsWithAliquotPercent {
    return json ? SgvJson.from.simple(json, CofinsWithAliquotPercent) : null;
  }
}

export class CofinsWithAliquotValue extends CofinsWithAliquot {
  constructor(aliquot: number = null) {
    super(aliquot);
  }

  toAliquot(): CofinsValueWithAliquotValue {
    return new CofinsValueWithAliquotValue(this.aliquot).publishValues();
  }

  toBcAliquot(): CofinsValueWithBcAliquotValue {
    return new CofinsValueWithBcAliquotValue(this.aliquot).publishValues();
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'value' });
  }

  static fromJson(json: any): CofinsWithAliquotValue {
    return json ? SgvJson.from.simple(json, CofinsWithAliquotValue) : null;
  }
}
