import { JsonEntity, SgvId, SgvJson } from '@eceos/arch';

export class ProducerReferencedNFModel implements JsonEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    readonly name: string = '',
    readonly value: string = '',
    readonly description: string = ''
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ProducerReferencedNFModel {
    if (typeof json === 'string') {
      return new ProducerReferencedNFModel(json, json);
    } else {
      return SgvJson.from.simple(json, ProducerReferencedNFModel);
    }
  }
}
