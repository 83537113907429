import { SgvJson } from '@eceos/arch';
import { NFeVehicle } from './nfe-vehicle';

export abstract class NFeTransportMean {
  abstract toJson(): any;

  static fromJson(json: any) {
    if (json && json.type) {
      switch (json.type) {
        case 'vehicleTowing':
          return VehicleTowingTransportMean.fromJson(json);
        case 'ferry':
          return FerryTransportMean.fromJson(json);
        case 'wagon':
          return WagonTransportMean.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class VehicleTowingTransportMean extends NFeTransportMean {
  constructor(public vehicle: NFeVehicle = null,
    public towing: NFeVehicle[] = []) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      vehicle: this.vehicle.toJson(),
      towing: SgvJson.to.array(this.towing),
      type: 'vehicleTowing'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, VehicleTowingTransportMean, {
      vehicle: NFeVehicle.fromJson(json.vehicle),
      towing: SgvJson.from.array(json.towing, NFeVehicle.fromJson),
    });
  }
}

export class FerryTransportMean extends NFeTransportMean {

  constructor(public identifier: String = null) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'ferry'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, FerryTransportMean);
  }
}

export class WagonTransportMean extends NFeTransportMean {

  constructor(public identifier: String = null) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'wagon'
    });
  }

  static fromJson(json: any) {
    return SgvJson.from.simple(json, WagonTransportMean);
  }
}
