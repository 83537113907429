<fieldset>
  <section *ngIf="hasValueToPay">
    <mat-form-field class="paymentFormType">
      <mat-label>Tipo de Pagamento</mat-label>
      <mat-select
        [(ngModel)]="selectedPaymentFormType"
        (ngModelChange)="onSelectedPaymentFormTypeChange($event)"
        [disabled]="disabled"
      >
        <mat-option *ngFor="let paymentFormType of paymentFormTypes" [value]="paymentFormType">
          {{ paymentFormType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="form">
      <app-cash-payment-form
        *ngIf="isCashPayment"
        [formControl]="form"
        [totalToPay]="totalToPay"
        [financialDocuments]="financialDocuments"
        [paybackFinancialDocuments]="paybackFinancialDocuments"
      ></app-cash-payment-form>
      <app-deferred-payment-form
        *ngIf="isDeferredPayment"
        [formControl]="form"
        [totalToPay]="totalToPay"
        [financialDocuments]="financialDocuments"
        [paybackFinancialDocuments]="paybackFinancialDocuments"
      ></app-deferred-payment-form>
    </ng-container>
  </section>
</fieldset>
