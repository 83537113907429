import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserPasswordRecoveryRepository, UserPublicRepository } from '@eceos/manager-domain';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, lastValueFrom } from 'rxjs';
import { ForgotPasswordSuccessDialogComponent } from './forgot-password-success-dialog/forgot-password-success-dialog.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  signUpPath = "/signup"

  private emailSubject = new BehaviorSubject('');

  constructor(
    private location: Location,
    private dialog: MatDialog,
    private repository: UserPublicRepository,
    private userPasswordRepository: UserPasswordRecoveryRepository 
  ) { }

  ngOnInit(): void {
    this.verifyEmail();

  }

  verifyEmail() {
    this.emailSubject
      .pipe(debounceTime(1000), filter(v => !!v), distinctUntilChanged())
      .subscribe(async value => {
        const email = await lastValueFrom(this.repository.findEmail(value))
        if (!email) return;

        this.openDialog(email);
        this.userPasswordRepository.sendEmailToPasswordRecovery(email).subscribe();
      })
  }

  openDialog(email: string) {
    this.dialog.open(ForgotPasswordSuccessDialogComponent , { data: { email: email } } )
  }

  ngOnDestroy(): void {
    this.emailSubject.unsubscribe();
  }

  sendLink(email: string) {
    this.emailSubject.next(email);
  }

  isInvalidEmail(email: HTMLInputElement) {
    return email.value && !email.validity.valid;
  }

  onBtnGoBackClick() {
    this.location.back();
  }

}
