import { JsonEntity, SgvJson } from '@eceos/arch';

export abstract class Interest implements JsonEntity {
  constructor(public percentage: number = 0.0, public type: string = '') {}

  abstract toJson(): any;

  static fromJson(data: any): Interest {
    if (!data) return null;
    switch (data.type) {
      case 'simple':
        return SimpleInterest.fromJson(data);
      case 'compound':
        return CompoundInterest.fromJson(data);
    }
  }
}

export class SimpleInterest extends Interest {
  constructor() {
    super(0.0, 'simple');
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): SimpleInterest {
    return SgvJson.from.simple(data, SimpleInterest);
  }
}

export class CompoundInterest extends Interest {
  constructor() {
    super(0.0, 'compound');
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): CompoundInterest {
    return SgvJson.from.simple(data, CompoundInterest);
  }
}
