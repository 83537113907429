import { Pipe, PipeTransform } from '@angular/core';
import { NumberMask } from '@eceos/common-utils';

@Pipe({
  name: 'numberMask'
})
export class NumberMaskPipe implements PipeTransform {

  transform(value: string, mask: NumberMask): string {
    return mask.apply(value);
  }

}
