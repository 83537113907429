import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedValue } from '../../calculated-value';

export interface IcmsSnWithCreditValueHolder {
  creditSn: IcmsSnWithCreditValue;
}

export class IcmsSnWithCreditValue {
  constructor(
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  calcCreditSnValue(baseValue: number): number {
    return baseValue != null && this.aliquot != null ? (this.aliquot / 100) * baseValue : null;
  }

  isValid(): boolean {
    return this.aliquot != null && EceosValidatorsUtils.isValid(this.value);
  }

  publishValues(baseValue: number): IcmsSnWithCreditValue {
    const value = this.calcCreditSnValue(baseValue);
    if (value != null) {
      this.value.value = value;
    }
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): IcmsSnWithCreditValue {
    return json
      ? SgvJson.from.simple(json, IcmsSnWithCreditValue, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }

  static fromAliquot(aliquot: number, baseValue: number): IcmsSnWithCreditValue {
    return aliquot ? new IcmsSnWithCreditValue(aliquot).publishValues(baseValue) : null;
  }
}
