import { City } from './city';
import { SgvId, SgvJson, DomainEntity } from "@eceos/arch";
import { StreetType } from "./streetType";

export class ZipCode implements DomainEntity {
  constructor(
    public id: string = SgvId.gen(),
    public zipCode: string = '',
    public street: string = '',
    public complement:string= '',
    public district: string= '',
    public city:City = null
  ) {}

  toJson() {
    return SgvJson.to.simple(this,{
      city: this.city ? this.city.toJson() : null
    });
  }

  static fromJson(json: any): ZipCode {
    return SgvJson.from.simple(json, ZipCode, {
      city: json.city ? City.fromJson(json.city) : null
    });
  }

}
