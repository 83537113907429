import { JsonEntity, SgvJson } from '@eceos/arch';
import { NumberMask } from '@eceos/common-utils';

export abstract class Documents implements JsonEntity {
  constructor(public cnp: string) {}

  get isLegalEntity(): boolean {
    return this.constructor === LegalEntityDocuments;
  }

  get isNaturalPerson(): boolean {
    return this.constructor === NaturalPersonDocuments;
  }

  abstract get maskedCnp(): string;

  abstract toJson(): any;

  static fromJson(json: any): Documents {
    if (json && json.type) {
      switch (json.type) {
        case 'legalEntity':
          return LegalEntityDocuments.fromJson(json);
        case 'naturalPerson':
          return NaturalPersonDocuments.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class LegalEntityDocuments extends Documents {
  constructor(public cnpj = '', public corporateName = '', public ie = '', public im = '') {
    super(cnpj);
  }

  get maskedCnp(): string {
    return LegalEntityDocuments.MASK.apply(this.cnpj);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'legalEntity' });
  }

  static readonly MASK = new NumberMask('99.999.999/9999-99');

  static fromJson(json: any): LegalEntityDocuments {
    return json ? SgvJson.from.simple(json, LegalEntityDocuments) : null;
  }
}

export class NaturalPersonDocuments extends Documents {
  constructor(public cpf = '', public rg = '') {
    super(cpf);
  }

  get maskedCnp(): string {
    return NaturalPersonDocuments.MASK.apply(this.cpf);
  }

  toJson() {
    return SgvJson.to.simple(this, { type: 'naturalPerson' });
  }

  static readonly MASK = new NumberMask('999.999.999-99');

  static fromJson(json: any): NaturalPersonDocuments {
    return json ? SgvJson.from.simple(json, NaturalPersonDocuments) : null;
  }
}
