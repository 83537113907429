import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { SpouseDocuments } from '../core/documents';

export class Spouse implements DomainEntity {
  constructor(readonly id = SgvId.gen(), public name: string = '', public documents: SpouseDocuments = new SpouseDocuments()) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      documents: this.documents.toJson()
    });
  }

  static fromJson(data: any): Spouse {
    return SgvJson.from.simple(data, Spouse, {
      documents: SpouseDocuments.fromJson(data.documents)
    });
  }
}
