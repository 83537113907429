import { SgvJson } from '@eceos/arch';

export class IcmsStPassedOn {
  constructor(
    public bcRetainedValue: number = null,
    public icmsRetainedValue: number = null,
    public endConsumerSupportedAliquot: number = null,
    public icmsSubstituteValue: number = null
  ) {}

  toJson(): string {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): IcmsStPassedOn {
    return json ? SgvJson.from.simple(json, IcmsStPassedOn) : null;
  }
}
