import { FinancialDataMovementsCache } from './financial-data-movements-cache';
import { SgvJson, JsonEntity } from "@eceos/arch";
import { ChargingFlowSummary } from '../../financial';

export class FinancialData implements JsonEntity {

  constructor(
    public creditLimit: number = 0,
    public cache: FinancialDataMovementsCache = new FinancialDataMovementsCache(),
    public usedCreditLimit: number = 0,
    public chargingFlow: ChargingFlowSummary = null
  ) { }

  get availableCreditLimit(): number {
    return this.creditLimit - this.usedCreditLimit;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      cache: this.cache.toJson(),
      chargingFlow: this.chargingFlow ? this.chargingFlow.toJson() : null,
    });
  }

  static fromJson(data: any): FinancialData {
    return SgvJson.from.simple(data, FinancialData, {
      cache: data.cache ? FinancialDataMovementsCache.fromJson(data.cache) : null,
      chargingFlow: data.chargingFlow ? ChargingFlowSummary.fromJson(data.chargingFlow) : null,
    });
  }
}
