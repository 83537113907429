import { SgvId, SgvJson } from '@eceos/arch';

export class StockMovement {

  constructor(
    public readonly id = SgvId.gen(),
    public date: Date = new Date(),
    public amount: number = 0,
    public stockLocationName: string = '',
    public operation: string = '',
    public type: string = '',
    public details: string = ''
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): StockMovement {
    return json ? SgvJson.from.simple(json, StockMovement, {
      date: json.date ? new Date(json.date) : new Date()
    }) : null;
  }

}
