<fieldset>
  <legend>
    <div class="label" (click)="changeVisible()">
      <mat-icon *ngIf="!visible"> add </mat-icon>
      <mat-icon *ngIf="visible"> remove </mat-icon>
      {{ label }}
    </div>
  </legend>
  <div class="body" [class.opened]="visible">
    <ng-content></ng-content>
  </div>
</fieldset>
