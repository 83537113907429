import { SgvJson } from '@eceos/arch';
import { Discount } from './discounts/discount';
import { FixedValueDiscount } from './discounts/fixed-value-discount';
import { SimpleDiscount } from './discounts/simple-discount';
import { Rate } from './rate';

export class RateDiscount implements Rate {

  readonly type = 'discount';

  constructor(
    public discount: Discount = new FixedValueDiscount()
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      discount: this.discount.toJson()
    });
  }

  static fromJson(data: any): Discount {
    switch (data.type) {
      case 'fixed':
        return FixedValueDiscount.fromJson(data);
      case 'simple':
        return SimpleDiscount.fromJson(data);
    }
  }
}
