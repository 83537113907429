import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[cbContent]' })
export class CopyClipboardDirective {
  @Input('cbContent')
  public payload: string;

  @Input('cbSnackbar')
  public snackbar: boolean = true;

  @Output('cbOnSuccess')
  public copied: EventEmitter<string> = new EventEmitter<string>();

  constructor(private snackBar: MatSnackBar) {}

  @HostListener('click')
  public onClick(): void {
    if (!this.payload) return;

    this.payload = this.payload.replace(/null|undefined/g, 'vazio');

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window['clipboardData'];
      clipboard.setData('text', this.payload);
      e.preventDefault();
      this.copied.emit(this.payload);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  
    if (this.snackbar) this.snackBar.open('Valor copiado com sucesso', null, {duration: 500});
  }
}
