import { Component, OnInit, Inject } from '@angular/core';
import { ReadonlyRepository } from '@eceos/arch';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-data-selector-dialog',
  templateUrl: './data-selector-dialog.component.html',
  styleUrls: ['./data-selector-dialog.component.scss']
})
export class DataSelectorDialogComponent<T> implements OnInit {

  private _defaultData: DataSelectorDialogData<T> = {
    title: 'Selecione um registro',
    content: 'Informe um registro para secionar abaixo',
    repository: null,
    value: null,
    required: true
  };

  data: DataSelectorDialogData<T>;

  entity: T = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DataSelectorDialogData<T>,
    private dialogRef: MatDialogRef<DataSelectorDialogComponent<T>>
  ) {
    this.dialogRef.disableClose = true;
    this.data = (<any>Object).assign({}, this._defaultData, data);
    this.entity = this.data.value;
  }

  ngOnInit() {}

}

export interface DataSelectorDialogData<T> {
  title: string;
  content: string;
  repository: ReadonlyRepository<any, T>;
  value?: T;
  required?: boolean;
}
