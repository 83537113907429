import { SgvId, SgvJson } from '@eceos/arch';
import { NFePaymentMethod } from './nfe-payment-method';

export class NFePayment {
  readonly id = SgvId.gen();
  constructor(public method: NFePaymentMethod = null, public value: number = 0) {}

  get isValid(): boolean {
    return this.method?.isWithoutPayment || (this.method && this.value && this.value > 0);
  }

  toJson() {
    return SgvJson.to.simple(this, {
      method: this.method.toJson()
    });
  }

  static fromJson(json: any): NFePayment {
    return SgvJson.from.simple(json, NFePayment, {
      method: NFePaymentMethod.fromJson(json?.method)
    });
  }
}
