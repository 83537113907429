import { NFeItem } from '../nfe-item';
import { II } from '../tax/ii/ii';

export class IITotal {
  constructor(private items: () => NFeItem[]) {}

  get total(): number {
    return this.value;
  }

  get value(): number {
    return this.ii()
      .filter(it => it.value)
      .map(it => it.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  private ii(): II[] {
    return this.items()
      .map(it => it.tax.taxes.ii)
      .filter(it => it != null);
  }
}
