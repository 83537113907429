import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { FiscalSeries, FiscalSeriesSummary } from './fiscal-series';

@Injectable({ providedIn: 'root' })
export class FiscalSeriesRepository extends RepositoryImpl<FiscalSeriesSummary, FiscalSeries> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/series'), FiscalSeriesSummary, FiscalSeries, []);
  }
}
