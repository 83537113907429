import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';

export class StreetType implements DomainEntity {
  constructor(public id: string = SgvId.gen(), public name: string = '', public acronym: string = '') {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): StreetType {
    return SgvJson.from.simple(json, StreetType);
  }
}
