import { JsonEntity, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedValue } from '../../../calculated-value';
import { IcmsDesonerationCalc } from './icms-desoneration-calc';
import { IcmsDesonerationReason } from './icms-desoneration-reason';
export interface IcmsWithDesonerationValueHolder {
  desoneration: IcmsWithDesonerationValue;
}

export class IcmsWithDesonerationValue implements JsonEntity {
  constructor(
    public reason: IcmsDesonerationReason = null,
    public calc: IcmsDesonerationCalc = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  isValid(): boolean {
    return (
      this.reason != null && this.calc != null && EceosValidatorsUtils.isValidOptional(this.value)
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      reason: this.reason ? this.reason.toJson() : null,
      calc: this.calc ? this.calc.toJson() : null,
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): IcmsWithDesonerationValue {
    return json
      ? SgvJson.from.simple(json, IcmsWithDesonerationValue, {
          reason: IcmsDesonerationReason.fromJson(json.reason),
          calc: IcmsDesonerationCalc.fromJson(json.calc),
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}
