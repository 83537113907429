import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserConfirmationRepository } from '@eceos/manager-domain';

@Component({
  selector: 'app-confirm-email-card',
  templateUrl: './confirm-email-card.component.html',
  styleUrls: ['./confirm-email-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmEmailCardComponent implements OnInit, OnDestroy {
  @Input() email = '';

  @Input() onSendEmail: () => void;

  @Input() onLogin: () => void;

  private sendEmailListener = new EventEmitter<void>();

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private userConfirmationRepository: UserConfirmationRepository
  ) {
    this.sendEmailListener
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe(() => this.sendEmail());
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  onSendEmailClick() {
    if (this.onSendEmail) {
      this.onSendEmail();
    } else {
      this.sendEmailListener.next();
    }
  }

  onLoginClick() {
    if (this.onLogin) {
      this.onLogin();
    } else {
      this.router.navigate(['/']);
    }
  }

  private sendEmail() {
    this.userConfirmationRepository.sendMail().subscribe(
      () => this.showMessage('Email enviado com sucesso!'),
      err => this.showError(err, 'Ocorreu um erro ao enviar email!')
    );
  }

  private showMessage(message: string) {
    this.snackbar.open(message, null, { duration: 2000 });
  }

  private showError(error: Error, message: string) {
    console.error(error);
    this.snackbar.open(message, null, { duration: 2000 });
  }
}
