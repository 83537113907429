export interface MovideskUser {
  name: string;
  email: string;
  phoneNumber: string;
  codeReference: string;
  organizationCodeReference: string;
}

export interface ChatOptions {
  user: MovideskUser;
  startChat?: boolean;
}

export class Movidesk {
  constructor(public readonly key: string) {}

  buildChatLink(params: ChatOptions) {
    const user = params.user;
    const startChat = params.startChat ?? false;
    return (
      `https://chat.movidesk.com/ChatWidget/Login/${this.key}` +
      `?Name=${user.name}` +
      `&Email=${user.email}` +
      `&PhoneNumber=${user.phoneNumber}` +
      `&CodeReference=${user.codeReference}` +
      `&OrganizationCodeReference=${user.organizationCodeReference}` +
      `&StayConnected=false` +
      `&EmptySubject=false&StartChat=${startChat}`
    );
  }
}
