import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { Billing } from './billing';

@Injectable({ providedIn: 'root' })
export class BillingsRepository extends RepositoryImpl<Billing, Billing> {
  constructor(api: ApiService) {
    super(api.manager.path('billings'), Billing, Billing);
  }
}
