import { JsonEntity, SgvJson } from 'libs/arch/src/lib/domain/json-utils';

export class Ibpt implements JsonEntity {
  constructor(public token = "") {}
 
  toJson() : any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Ibpt {
    return SgvJson.from.simple(json, Ibpt);
  }
}
