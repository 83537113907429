import { JsonEntity, SgvId, SgvJson } from '@eceos/arch';

export class ReferencedECFModel implements JsonEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    readonly name: string = '',
    readonly value: string = '',
    readonly description: string = ''
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ReferencedECFModel {
    if (typeof json === 'string') {
      return new ReferencedECFModel(json, json);
    } else {
      return SgvJson.from.simple(json, ReferencedECFModel);
    }
  }
}
