import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnInit,
  Optional
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputNumberConfig, INPUT_NUMBER_CONFIG } from './input-number.config';
import { InputNumberHandler } from './input-number.handler';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[inputNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberDirective),
      multi: true
    }
  ]
})
export class InputNumberDirective implements ControlValueAccessor, OnInit, AfterViewInit, DoCheck {
  private inputElement: HTMLInputElement;

  private readonly configDefault: InputNumberConfig = {
    align: 'left',
    allowNegative: true,
    allowZero: true,
    decimalSeparator: ',',
    thousandSeparator: '',
    prefix: '',
    suffix: '',
    precision: 2,
    nullable: false,
    maxValue: null,
    minValue: null
  };

  @Input() options: Partial<InputNumberConfig> = {};

  handler: InputNumberHandler;

  keyValueDiffer: KeyValueDiffer<any, any>;

  config: InputNumberConfig;

  constructor(
    @Optional() @Inject(INPUT_NUMBER_CONFIG) config: InputNumberConfig,
    keyValueDiffers: KeyValueDiffers,
    elementRef: ElementRef
  ) {
    this.config = (<any>Object).assign({}, this.configDefault, config);
    this.keyValueDiffer = keyValueDiffers.find({}).create();
    this.inputElement = elementRef.nativeElement;
  }

  ngOnInit() {
    this.handler = new InputNumberHandler(
      this.inputElement,
      (<any>Object).assign({}, this.configDefault, this.config)
    );
  }

  ngAfterViewInit() {
    this.inputElement.style.textAlign =
      this.options && this.options.align ? this.options.align : this.configDefault.align;
  }

  ngDoCheck() {
    if (this.keyValueDiffer.diff(this.options)) {
      const newOptions = (<any>Object).assign({}, this.configDefault, this.config, this.options);
      this.inputElement.style.textAlign = newOptions.align;
      this.handler.updateOptions(newOptions);
    }
  }

  @HostListener('blur', ['$event'])
  handleBlur(event: any) {
    if (this.handler.onTouch) {
      this.handler.onTouch(event);
    }
  }

  @HostListener('cut', ['$event'])
  handleCut(event: any) {
    if (!this.isChromeAndroid()) {
      this.handler.handleCut(event);
    }
  }

  @HostListener('input', ['$event'])
  handleInput(event: any) {
    if (this.isChromeAndroid()) {
      this.handler.handleInput(event);
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: any) {
    if (!this.isChromeAndroid()) {
      this.handler.handleKeydown(event);
    }
  }

  @HostListener('keypress', ['$event'])
  handleKeypress(event: any) {
    if (!this.isChromeAndroid()) {
      this.handler.handleKeypress(event);
    }
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: any) {
    if (!this.isChromeAndroid()) {
      this.handler.handlePaste(event);
    }
  }

  isChromeAndroid(): boolean {
    return /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
  }

  registerOnChange(fn: (number: number) => void): void {
    this.handler.onChangeFunction = fn;
  }

  registerOnTouched(fn: (event: any) => void): void {
    this.handler.onTouch = fn;
  }

  setDisabledState(value: boolean): void {
    this.inputElement.disabled = value;
  }

  writeValue(value: number): void {
    this.handler.setValue(value);
  }
}
