import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { EnterpriseSummary } from './../enterprises/enterprise';
import { RegionCoverage } from './partner-region-coverage';

export class Partner implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public enterprise: EnterpriseSummary = null,
    public isDefault = false,
    public regions: RegionCoverage[] = []
  ) {}

  get regionsCount(): number {
    return this.regions?.length ?? 0;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      enterprise: this.enterprise.toJson(),
      regions: SgvJson.to.array(this.regions)
    });
  }

  static fromJson(json: any): Partner {
    return json
      ? SgvJson.from.simple(json, Partner, {
          enterprise: EnterpriseSummary.fromJson(json.enterprise),
          regions: SgvJson.from.array(json?.regions, RegionCoverage.fromJson)
        })
      : null;
  }
}

export class PartnerSummary implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public enterprise: EnterpriseSummary = null
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, { enterprise: this.enterprise.toJson() });
  }

  static fromJson(json: any): PartnerSummary {
    return json
      ? SgvJson.from.simple(json, PartnerSummary, {
          enterprise: EnterpriseSummary.fromJson(json.enterprise)
        })
      : null;
  }
}
