import { ReadonlyRepositoryImpl, ReadonlyRepository, ApiService } from '@eceos/arch';
import { CFOP } from './cfop';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CFOPsRepository extends ReadonlyRepositoryImpl<CFOP, CFOP>
  implements ReadonlyRepository<CFOP, CFOP> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/cfops'), CFOP, CFOP);
  }
}
