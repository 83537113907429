<ng-container [formGroup]="form" *ngIf="form">
  <fieldset>
    <legend>
      Formas de Pagamento
      <small>
        (Total: {{ totalPaid | currency: 'BRL' }})
        <span *ngIf="hasPendingPaid"> (Pendente: {{ pendingPaid | currency: 'BRL' }}) </span>
      </small>
    </legend>

    <app-payment-table
      [financialDocuments]="financialDocuments"
      [totalToPay]="totalToPay"
      (totalPaidChange)="clearChanges()"
      formControlName="paid"
    ></app-payment-table>
  </fieldset>
  <fieldset *ngIf="hasTotalChangeToPay">
    <legend>
      Troco
      <small>
        (Total: {{ totalChange | currency: 'BRL' }})
        <span *ngIf="hasPendingChange"> (Pendente: {{ pendingChange | currency: 'BRL' }}) </span>
      </small>
    </legend>
    <app-payment-table
      [financialDocuments]="paybackFinancialDocuments"
      [totalToPay]="totalChangeToPay"
      formControlName="change"
    ></app-payment-table>
  </fieldset>
</ng-container>
