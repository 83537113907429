<mat-card class="confirmEmail">
  <mat-card-title>Verifique seu e-mail</mat-card-title>
  <mat-card-content>
    <p>
      Enviamos um pedido de confirmação para <strong>{{ email }}</strong
      >.
      <br />
      Confirme que ele pertence a você para manter a sua conta segura.
    </p>
    <p>
      Não recebeu o e-mail?
      <a [routerLink]="" queryParamsHandling="preserve" (click)="onSendEmailClick()">
        Reenviar pedido de confirmação.
      </a>
    </p>
    <span>
      <p>
        Caso tenha algum problema com o pedido de confirmação entre em contato conosco através
        das nossas centrais.
      </p>
      <p class="contacts">
        <span>
          <a mat-button href="tel:0800 878 2737" target="_blank">
            <mat-icon> phone </mat-icon>
          </a>
          <a href="tel:0800 878 2737"> 0800 878 2737 </a>
        </span>
        <span>
          <a mat-button href="mailto:contato@eceos.com.br" target="_blank">
            <mat-icon> email </mat-icon>
          </a>
          <a href="mailto:contato@eceos.com.br"> contato@eceos.com.br </a>
        </span>
      </p>
    </span>
    <p>
      <strong>Voce não poderá acessar a sua conta até confirmar o seu e-mail.</strong>
    </p>
    <p>
      Já confirmou o e-mail?
      <a [routerLink]="" queryParamsHandling="preserve" (click)="onLoginClick()">Entrar</a>
    </p>
  </mat-card-content>
</mat-card>
