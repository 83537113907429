<fieldset *ngIf="form">
  <section [formGroup]="form">
    <mat-form-field class="modality">
      <mat-label>Modalidade do Frete</mat-label>
      <mat-select formControlName="modality" [compareWith]="compareById">
        <mat-option
          *ngFor="let modality of modalities$ | async; trackBy: trackById"
          [value]="modality"
        >
          {{ modality.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="!isWithoutFreight">
      <app-data-selector
        label="Transportadora"
        [repository]="carriersRepository"
        formControlName="carrier"
      ></app-data-selector>
      <mat-form-field>
        <mat-label>Valor do Frete</mat-label>
        <input matInput currencyMask formControlName="value" />
      </mat-form-field>
    </ng-container>
  </section>
</fieldset>
