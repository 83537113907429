import { Injectable } from '@angular/core';
import { ReadonlyRepositoryImpl, ApiService } from '@eceos/arch';
import { City } from './city';

@Injectable({providedIn:'root'})
export class CitiesRepository extends ReadonlyRepositoryImpl<City, City> {
  constructor(api: ApiService) {
    super(api.root.path('cities'), City, City);
  }
}
