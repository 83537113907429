import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleHistoryWidgetComponent } from './sale-history-widget/sale-history-widget.component';
import { DefaultSharedModule } from '@eceos/default-shared';
import { OperatablesRankWidgetComponent } from './operatables-rank-widget/operatables-rank-widget.component';
import { FinancialExpensesCategoriesWidgetComponent } from './financial-expenses-categories-widget/financial-expenses-categories-widget.component';
import { FinancialCashFlowComponent } from './financial-cash-flow/financial-cash-flow.component';
import { NfesCfopWidgetComponent } from './nfes-cfop-widget/nfes-cfop-widget.component';
import { NfesPaymentWidgetComponent } from './nfes-payment-widget/nfes-payment-widget.component';

const COMPONENTS = [
  SaleHistoryWidgetComponent,
  OperatablesRankWidgetComponent,
  FinancialExpensesCategoriesWidgetComponent,
  FinancialCashFlowComponent,
  NfesCfopWidgetComponent,
  NfesPaymentWidgetComponent
];

@NgModule({
  imports: [DefaultSharedModule, CommonModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class WidgetsModule {}
