import { SgvJson } from '@eceos/arch';

export class NFePaymentMethod {
  constructor(
    readonly value: string = '',
    readonly name: string = '',
    readonly description: string = '',
    readonly isWithoutPayment: boolean = false
  ) {}

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): NFePaymentMethod {
    return SgvJson.from.simple(json, NFePaymentMethod);
  }
}
