import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InactiveComponent } from './inactive.component';
import { InactiveRoutes } from './inactive.routes';

@NgModule({
  declarations: [InactiveComponent],
  imports: [CommonModule, SharedModule, InactiveRoutes]
})
export class InactiveModule {}
