import { DomainEntity, SgvId, SgvJson, YearMonth } from '@eceos/arch';
import { Uf } from '../../../core/address';
import { ProducerReferencedNFModel } from './producer-referenced-nf-model';
import { ReferencedECFModel } from './referenced-ecf-model';

export abstract class DocumentReference implements DomainEntity {
  constructor(readonly id = SgvId.gen()) {}

  abstract toJson(): any;

  static fromJson(json: any): DocumentReference {
    if (json && json.type) {
      switch (json.type) {
        case 'cte':
          return ReferenceToCTe.fromJson(json);
        case 'ecf':
          return ReferenceToECF.fromJson(json);
        case 'nf':
          return ReferenceToNF.fromJson(json);
        case 'nfp':
          return ReferenceToNFP.fromJson(json);
        case 'nfeAccessKey':
          return ReferenceToNFeAccessKey.fromJson(json);
        case 'nfeInternal':
          return ReferenceToNFeInternal.fromJson(json);
        case 'nfeThirdParty':
          return ReferenceToNFeThirdParty.fromJson(json);
      }
    }
    return null;
  }
}

export class ReferenceToCTe extends DocumentReference {
  constructor(readonly id = SgvId.gen(), public accessKey: string = '') {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'cte' });
  }

  static fromJson(json: any): ReferenceToCTe {
    return json ? SgvJson.from.simple(json, ReferenceToCTe) : null;
  }
}

export class ReferenceToECF extends DocumentReference {
  constructor(
    readonly id = SgvId.gen(),
    public model: ReferencedECFModel = null,
    public ecfNumber: string = '',
    public cooNumber: string = ''
  ) {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      model: this.model.toJson(),
      type: 'ecf'
    });
  }

  static fromJson(json: any): ReferenceToECF {
    return json
      ? SgvJson.from.simple(json, ReferenceToECF, {
          model: ReferencedECFModel.fromJson(json.model)
        })
      : null;
  }
}

export class ReferenceToNF extends DocumentReference {
  constructor(
    readonly id = SgvId.gen(),
    public series: string = '',
    public number: number = 0,
    public uf: Uf = null,
    public cnpj: string = '',
    public month: YearMonth = null
  ) {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      uf: this.uf.toJson(),
      month: this.month.toDefaultString(),
      type: 'nf'
    });
  }

  static fromJson(json: any): ReferenceToNF {
    return json
      ? SgvJson.from.simple(json, ReferenceToNF, {
          uf: Uf.fromJson(json.uf),
          month: YearMonth.from(json.month)
        })
      : null;
  }
}

export class ReferenceToNFP extends DocumentReference {
  constructor(
    readonly id = SgvId.gen(),
    public series: string = '',
    public number: number = 0,
    public uf: Uf = null,
    public cnp: string = '',
    public month: YearMonth = null,
    public stateRegistration: string = '',
    public model: ProducerReferencedNFModel = null
  ) {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      uf: this.uf.toJson(),
      model: this.model.toJson(),
      month: this.month.toDefaultString(),
      type: 'nfp'
    });
  }

  static fromJson(json: any): ReferenceToNFP {
    return json
      ? SgvJson.from.simple(json, ReferenceToNFP, {
          uf: Uf.fromJson(json.uf),
          month: YearMonth.from(json.month),
          model: ProducerReferencedNFModel.fromJson(json.model),
          cnp: json.cnp ? json.cnp.replace(/\D/g, '') : null
        })
      : null;
  }
}

export class ReferenceToNFeAccessKey extends DocumentReference {
  constructor(readonly id = SgvId.gen(), public accessKey: string = '') {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'nfeAccessKey' });
  }

  static fromJson(json: any): ReferenceToNFeAccessKey {
    return json ? SgvJson.from.simple(json, ReferenceToNFeAccessKey) : null;
  }
}

export class ReferenceToNFeInternal extends DocumentReference {
  constructor(readonly id = SgvId.gen(), public nfeId: string = '') {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'nfeInternal' });
  }

  static fromJson(json: any): ReferenceToNFeInternal {
    return json ? SgvJson.from.simple(json, ReferenceToNFeInternal) : null;
  }
}

export class ReferenceToNFeThirdParty extends DocumentReference {
  constructor(readonly id = SgvId.gen(), public nfeId: string = '') {
    super(id);
  }

  toJson(): any {
    return SgvJson.to.simple(this, { type: 'nfeThirdParty' });
  }

  static fromJson(json: any): ReferenceToNFeThirdParty {
    return json ? SgvJson.from.simple(json, ReferenceToNFeThirdParty) : null;
  }
}
