import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { IcmsCst } from './icms-cst';
@Injectable({ providedIn: 'root' })
export class IcmsCstsRepository extends ReadonlyRepositoryImpl<IcmsCst, IcmsCst>
  implements ReadonlyRepository<IcmsCst, IcmsCst> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/icms/csts'), IcmsCst, IcmsCst);
  }
}
