import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { Dimensions, ExpirationType, FiscalProductSummary, Pack, ProductCategorySummary } from '@eceos/domain';
import { OperatableUnityLegacy } from '../operatables';
import { ConversionRateUnity } from './conversion-rate-unity';
import { Image } from './image';
import { Stock } from './stock/stock';
import { ProductLot } from './product-lot';
import { ProductVariation } from './product-variation/product-variation';
import { VariationTypeSummary } from './product-variation/variation-type-summary';
import { Barcode } from '@eceos/common-utils';

export abstract class Product implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public description: string = '',
    public defaultUnity: OperatableUnityLegacy = null,
    public defaultSellValue: number = 0,
    public mainCategory: ProductCategorySummary = null,
    public packs: Pack[] = [],
    public dimensions: Dimensions = null,
    public conversionRateUnities: ConversionRateUnity[] = [],
    public images: Image[] = [],
    public fiscalProduct: FiscalProductSummary = null,
    public fiscalCode: string = '',
    public costPrice: number = 0,
    public readonly isActive = true
  ) { }

  abstract get isSimpleProduct(): boolean;

  abstract get isVariableProduct(): boolean;

  abstract get isProductByLots(): boolean;

  abstract toJson(): any;

  abstract get barcodeArray(): any;

  static fromJson(json: any): Product {
    if (!(json || json.type)) {
      return null;
    }

    switch (json.type) {
      case 'simpleProduct':
        return SimpleProduct.fromJson(json);
      case 'variableProduct':
        return VariableProduct.fromJson(json);
      case 'productByLots':
        return ProductByLots.fromJson(json);
      default:
        throw new Error('Product type not mapped');
    }
  }
}

export class SimpleProduct extends Product {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public description: string = '',
    public defaultUnity: OperatableUnityLegacy = null,
    public defaultSellValue: number = 0,
    public mainCategory: ProductCategorySummary = null,
    public packs: Pack[] = [],
    public dimensions: Dimensions = null,
    public conversionRateUnities: ConversionRateUnity[] = [],
    public images: Image[] = [],
    public fiscalProduct: FiscalProductSummary = null,
    public fiscalCode: string = '',
    public costPrice: number = 0,
    public readonly isActive = true,
    public barcode: string = Barcode.gen(),
    public stock: Stock = null
  ) {
    super(id, name, description, defaultUnity, defaultSellValue, mainCategory, packs, dimensions, conversionRateUnities, images, fiscalProduct, fiscalCode, costPrice, isActive);
  }

  get isSimpleProduct(): boolean {
    return true;
  }
  get isVariableProduct(): boolean {
    return false;
  }
  get isProductByLots(): boolean {
    return false;
  }
  get barcodeArray(){
    return this.barcode ? [this.barcode] : [];
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'simpleProduct',
      defaultUnity: this.defaultUnity ? this.defaultUnity.toJson() : null,
      mainCategory: this.mainCategory ? this.mainCategory.toJson() : null,
      packs: this.packs ? SgvJson.to.array(this.packs) : null,
      dimensions: this.dimensions ? this.dimensions.toJson() : null,
      conversionRateUnities: this.conversionRateUnities? SgvJson.to.array(this.conversionRateUnities) : null,
      images: this.images ? SgvJson.to.array(this.images) : null,
      fiscalProduct: this.fiscalProduct ? this.fiscalProduct.toJson() : null,
      stock: this.stock ? this.stock.toJson() : null
    });
  }

  static fromJson(json: any): SimpleProduct {
    return json
      ? SgvJson.from.simple(json, SimpleProduct, {
      defaultUnity: json.defaultUnity ? OperatableUnityLegacy.fromJson(json.defaultUnity) : null,
      mainCategory: json.mainCategory ? ProductCategorySummary.fromJson(json.mainCategory) : null,
      packs: json.packs ? SgvJson.from.array(json.packs, Pack.fromJson) : null,
      dimensions: json.dimensions ? Dimensions.fromJson(json.dimensions) : null,
      conversionRateUnities: json.conversionRateUnities ? SgvJson.from.array(json.conversionRateUnities, ConversionRateUnity.fromJson): null,
      images: json.images ? SgvJson.from.array(json.images, Image.fromJson) : null,
      fiscalProduct: json.fiscalProduct ? FiscalProductSummary.fromJson(json.fiscalProduct) : null,
      stock: json.stock ? Stock.fromJson(json.stock) : null
    })
    : null;
  }

}

export class VariableProduct extends Product {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public description: string = '',
    public defaultUnity: OperatableUnityLegacy = null,
    public defaultSellValue: number = 0,
    public mainCategory: ProductCategorySummary = null,
    public packs: Pack[] = [],
    public dimensions: Dimensions = null,
    public conversionRateUnities: ConversionRateUnity[] = [],
    public images: Image[] = [],
    public fiscalProduct: FiscalProductSummary = null,
    public fiscalCode: string = '',
    public costPrice: number = 0,
    public readonly isActive = true,
    public stock: Stock = null,
    public variationTypes: VariationTypeSummary[] = [],
    public variations: ProductVariation[] = [],
    public minimumStocksAmount: number[] = []
  ) {
    super(id, name, description, defaultUnity, defaultSellValue, mainCategory, packs, dimensions, conversionRateUnities, images, fiscalProduct, fiscalCode, costPrice, isActive);
  }

  get isSimpleProduct(): boolean {
    return false;
  }
  get isVariableProduct(): boolean {
    return true;
  }
  get isProductByLots(): boolean {
    return false;
  }
  get barcodeArray(){
    return this.variations ? this.variations.map(variation => variation.barcode) : [];
  }


  toJson(): any {
    throw Error('Não é possível criar ou atualizar Produto com Variação');
  }

  static fromJson(json: any): VariableProduct {
    return json 
      ? SgvJson.from.simple(json, VariableProduct, {
      defaultUnity: json.defaultUnity ? OperatableUnityLegacy.fromJson(json.defaultUnity) : null,
      mainCategory: json.mainCategory ? ProductCategorySummary.fromJson(json.mainCategory) : null,
      packs: json.packs ? SgvJson.from.array(json.packs, Pack.fromJson) : null,
      dimensions: json.dimensions ? Dimensions.fromJson(json.dimensions) : null,
      conversionRateUnities: json.conversionRateUnities ? SgvJson.from.array(json.conversionRateUnities, ConversionRateUnity.fromJson): null,
      images: json.images ? SgvJson.from.array(json.images, Image.fromJson) : null,
      fiscalProduct: json.fiscalProduct ? FiscalProductSummary.fromJson(json.fiscalProduct) : null,
      stock: json.stock ? Stock.fromJson(json.stock) : null,
      variationTypes: json.variationTypes ? SgvJson.from.array(json.variationTypes, VariationTypeSummary.fromJson) : null,
      variations: json.variations ? SgvJson.from.array(json.variations, ProductVariation.fromJson) : null
    }) : null;
  }
}

export class ProductByLots extends Product {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public description: string = '',
    public defaultUnity: OperatableUnityLegacy = null,
    public defaultSellValue: number = 0,
    public mainCategory: ProductCategorySummary = null,
    public packs: Pack[] = [],
    public dimensions: Dimensions = null,
    public conversionRateUnities: ConversionRateUnity[] = [],
    public images: Image[] = [],
    public fiscalProduct: FiscalProductSummary = null,
    public fiscalCode: string = '',
    public costPrice: number = 0,
    public readonly isActive = true,
    public stock: Stock = null,
    public lots: ProductLot[] = [],
    public expirationType: ExpirationType = null,
    public minimumStocksAmount: number[] = []
  ) {
    super(id, name, description, defaultUnity, defaultSellValue, mainCategory, packs, dimensions, conversionRateUnities, images, fiscalProduct, fiscalCode, costPrice, isActive);
  }

  get isSimpleProduct(): boolean {
    return false;
  }
  get isVariableProduct(): boolean {
    return false;
  }
  get isProductByLots(): boolean {
    return true;
  }
  get barcodeArray(){
    return this.lots ? this.lots.map(lot => lot.barcode) : [];
  }

  toJson(): any {
    throw Error('Não é possível criar ou atualizar Produto por Lote');
  }

  static fromJson(json: any): ProductByLots {
    return json 
    ? SgvJson.from.simple(json, ProductByLots, {
      defaultUnity: json.defaultUnity ? OperatableUnityLegacy.fromJson(json.defaultUnity) : null,
      mainCategory: json.mainCategory ? ProductCategorySummary.fromJson(json.mainCategory) : null,
      packs: json.packs ? SgvJson.from.array(json.packs, Pack.fromJson) : null,
      dimensions: json.dimensions ? Dimensions.fromJson(json.dimensions) : null,
      conversionRateUnities: json.conversionRateUnities ? SgvJson.from.array(json.conversionRateUnities, ConversionRateUnity.fromJson): null,
      images: json.images ? SgvJson.from.array(json.images, Image.fromJson) : null,
      fiscalProduct: json.fiscalProduct ? FiscalProductSummary.fromJson(json.fiscalProduct) : null,
      stock: json.stock ? Stock.fromJson(json.stock) : null,
      lots: json.lots ? SgvJson.from.array(json.lots, ProductLot.fromJson) : null,
      expirationType: json.expirationType ? ExpirationType.get(json.expirationType) : null,
    }) : null;
  }
  
}