import { SgvJson } from "@eceos/arch";
import { TimeInterval } from "../time-interval";
import { InterestArrears } from "./interest-arrears";

export class FixedValueInterestArrears extends InterestArrears {

  constructor(timeInterval: TimeInterval = TimeInterval.PER_MONTH) {
    super(0.0, timeInterval, "fixed");
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): FixedValueInterestArrears {
    return SgvJson.from.simple(data, FixedValueInterestArrears, {
      timeInterval: InterestArrears.parseInterval(data.timeInterval)
    });
  }
}
