import { Injectable } from "@angular/core";
import { ApiPath, ApiService, InterceptorConfig } from "@eceos/arch";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AuthorizedCnpFiscalSettingRepository {
    private api: ApiPath;

    constructor(api: ApiService) {
        this.api = api.root.path('fiscal/settings/nfes/cnpAccessXml')
    }

    get(): Observable<string[]> {
        return this.api.getJson({ metadata: { autoCatch: InterceptorConfig.NO_INTERCEPT } });
    }

    update(cnps: string[]): Observable<string> {
        return this.api.patchJson({ nfeCnpAccessXml: cnps })
    }
}