import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {
  DeferredPaymentForm,
  FinancialDocument,
  InstallmentPoliciesRepository,
  InstallmentPolicySummary
} from '@eceos/domain';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-deferred-payment-form',
  templateUrl: './deferred-payment-form.component.html',
  styleUrls: ['./deferred-payment-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeferredPaymentFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DeferredPaymentFormComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeferredPaymentFormComponent implements OnInit, ControlValueAccessor, Validator {
  private _entity: DeferredPaymentForm;

  private _totalToPay = 0;

  private _parcelValue = 0;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  private parcelValueCache: Map<string, number> = new Map();

  @Output() entityChange = new EventEmitter<DeferredPaymentForm>();

  @Input() financialDocuments: FinancialDocument[];

  @Input() paybackFinancialDocuments: FinancialDocument[];

  form: FormGroup;

  disabled = false;

  installmentPolicies$: Observable<InstallmentPolicySummary[]>;

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private installmentPoliciesRepository: InstallmentPoliciesRepository
  ) {
    this.installmentPolicies$ = this.installmentPoliciesRepository.list();
  }

  ngOnInit() {}

  get entity(): DeferredPaymentForm {
    return this._entity;
  }

  @Input()
  set entity(entity: DeferredPaymentForm) {
    if (this.entity !== entity) {
      this._entity = entity;
      this.createFormFromEntity();
    }
  }

  get totalToPay(): number {
    return this._totalToPay;
  }

  @Input()
  set totalToPay(totalToPay: number) {
    if (this._totalToPay !== totalToPay) {
      this._totalToPay = totalToPay;
      this.clearPayments();
    }
  }

  get parcelValue(): number {
    return this._parcelValue;
  }

  set parcelValue(parcelValue: number) {
    this._parcelValue = parcelValue;
    this.entity.installmentValue = parcelValue;
  }

  get totalPaid(): number {
    return this.entity.getTotalPaid();
  }

  get pendingPaid(): number {
    return this.entity.getPendingPaid(this.totalToPay);
  }

  get hasPendingPaid(): boolean {
    return this.entity.hasPendingPaid(this.totalToPay);
  }

  get numberOfInstallmentsControl(): AbstractControl {
    return this.form.get('numberOfInstallments');
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.form && this.form.invalid ? { invalid: true } : null;
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.form) {
      this.publishDisable();
    }
  }

  compareById(obj, anotherObj): boolean {
    return obj && anotherObj && obj.id === anotherObj.id;
  }

  trackById(i, entity) {
    return entity.id;
  }

  private createFormFromEntity() {
    this.form = null;
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
    if (this.entity) {
      this.form = this.fb.group({
        paid: [this.entity.paid],
        periodicity: [this.entity.periodicity, Validators.required],
        firstExpiration: [this.entity.firstExpiration, Validators.required],
        numberOfInstallments: [
          this.entity.numberOfInstallments,
          [
            Validators.required,
            Validators.min(1),
            this.entity.installmentPolicy &&
            this.entity.installmentPolicy.maximum != null &&
            this.entity.installmentPolicy.maximum > 0
              ? Validators.max(this.entity.installmentPolicy.maximum)
              : null
          ].filter(validator => validator != null)
        ],
        installmentPolicy: [this.entity.installmentPolicy, Validators.required]
      });
      this.addFormValueChange(
        v =>
          new DeferredPaymentForm(
            v.installmentPolicy,
            v.numberOfInstallments,
            v.firstExpiration,
            this.parcelValue,
            v.periodicity,
            v.paid
          )
      );
      this.calculateParcelValue();
    }
    this.changeDetector.markForCheck();
  }

  private addFormValueChange(mapper: (f: any) => DeferredPaymentForm) {
    this.form.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300), map(mapper))
      .subscribe(value => {
        this.entityChange.emit((this._entity = value));
        this.calculateParcelValue();
        this.changeDetector.markForCheck();
      });
  }

  private calculateParcelValue() {
    if (this.entity && this.entity.installmentPolicy) {
      const parcelSimulator = this.entity.getParcelSimulator(this.totalToPay);
      const parcelValueRequest: ParcelValueRequest = {
        installmentPolicyId: this.entity.installmentPolicy.id,
        parceledValue: parcelSimulator.parceledValue,
        numberOfInstallments: parcelSimulator.numberOfInstallments,
        firstExpiration: parcelSimulator.firstExpiration.getTime(),
        periodicity: {
          recurrences: parcelSimulator.periodicity.recurrences,
          type: parcelSimulator.periodicity.constructor.name
        }
      };

      const cacheKey = JSON.stringify(parcelValueRequest);

      if (this.parcelValueCache.has(cacheKey)) {
        this.parcelValue = this.parcelValueCache.get(cacheKey);
      } else {
        this.installmentPoliciesRepository
          .calculateParcelValue(this.entity.installmentPolicy, parcelSimulator)
          .pipe(distinctUntilChanged(), debounceTime(300), shareReplay(1))
          .subscribe(
            value => {
              this.parcelValueCache.set(cacheKey, (this.parcelValue = value));
            },
            err => {
              console.error(err);
              this.numberOfInstallmentsControl.setValue(null, { emitEvent: false });
              this.parcelValueCache.set(cacheKey, (this.parcelValue = 0));
            },
            () => this.changeDetector.markForCheck()
          );
      }
    }
  }

  private publishDisable() {
    if (this.disabled && this.form.enabled) {
      this.form.disable({ emitEvent: false });
    } else if (!this.disabled && this.form.disabled) {
      this.form.enable({ emitEvent: false });
    }
  }

  private clearPayments() {
    if (this.form) {
      this.form.patchValue({
        paid: [],
        numberOfInstallments: null
      });
    }
  }
}

interface ParcelValueRequest {
  installmentPolicyId: string;
  parceledValue: number;
  numberOfInstallments: number;
  firstExpiration: number;
  periodicity: {
    recurrences: number;
    type: string;
  };
}
