import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@eceos/default-shared';
import { LayoutEceosModule } from './eceos/layout-eceos.module';
import { LayoutManagerModule } from './manager/layout-manager.module';

const MODULES = [LayoutEceosModule, LayoutManagerModule];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, ...MODULES],
  declarations: [],
  exports: MODULES
})
export class LayoutModule {}
