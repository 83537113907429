import { SgvId, SgvJson } from '@eceos/arch';
import { ProductSummary } from '../../product-summary';
import { StockLocationSummary } from '../stock-location-summary';

export class StockAdjustment {

  constructor(
    public readonly id = SgvId.gen(),
    public justification: string = '',
    public adjustment: number = 0,
    public date: Date = new Date(),
    public stockLocation: StockLocationSummary = null,
    public product: ProductSummary = null,
    public state: string = ''
  ) { }

  toJson(): any {
    throw Error('Não é possível criar ou atualizar StockAjustment');
  }

  static fromJson(json: any): StockAdjustment {
    return json ? SgvJson.from.simple(json, StockAdjustment, {
      date: json.date ? new Date(json.date) : new Date(),
      stockLocation: json.stockLocation ? StockLocationSummary.fromJson(json.stockLocation) : null,
      product: json.product ? ProductSummary.fromJson(json.product) : null,
    }) : null;
  }

}