import { Component, Input, ViewEncapsulation } from '@angular/core';

type Type = 'text' | 'password';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'password-visibility-toggle',
  templateUrl: './password-visibility-toggle.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PasswordVisibilityToggleComponent {
  @Input() isVisible: boolean;

  constructor() {}

  get type(): Type {
    return this.isVisible ? 'text' : 'password';
  }
}
