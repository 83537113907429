import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class UserPassword implements DomainEntity {
    constructor(
        public readonly id = SgvId.gen(),
        public password = ''
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this);
    }
    static fromJson(json: any): UserPassword {
        return json
            ? SgvJson.from.simple(json, UserPassword)
            : null;
    }
}