import { PeriodicityComponent } from './periodicity.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultSharedModule } from '@eceos/default-shared';

@NgModule({
  imports: [CommonModule, DefaultSharedModule],
  declarations: [PeriodicityComponent],
  exports: [PeriodicityComponent]
})
export class PeriodicityModule {}
