import { SgvJson } from "@eceos/arch";
import { ExpirationData } from "./expiration-data/expiration-data";
import { Stock } from "./stock/stock";

export class ProductLot {
    constructor(
        public readonly id: string = '',
        public readonly number: string = '',
        public readonly expirationData: ExpirationData = null,
        public readonly barcode: string = '',
        public readonly stock: Stock = null,
    ) { }

    toJson(): any {
        throw Error('Não é possível criar ou atualizar ProductLot');
    }

    static fromJson(json: any): ProductLot {
        return json 
        ? SgvJson.from.simple(json, ProductLot, {
            expirationData: json.expirationData ? ExpirationData.fromJson(json.expirationData) : null,
            stock: json.stock ? Stock.fromJson(json.stock) : null,
        }) : null;
    }
}