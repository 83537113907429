import { JsonEntity, SgvId, SgvJson } from '@eceos/arch';
import { TaxCategorySummary } from '../fiscal';
import { NCM } from '../ncm/ncm';

export class FiscalProduct implements JsonEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name = '',
    public ncm: NCM = null,
    public taxCategory: TaxCategorySummary = null,
    public cest = '',
    public pharmaco = false,
    public fuel = false,
    public importDeclaration = false,
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  toJson() {
    return SgvJson.to.simple(this, {
      ncm: this.ncm ? this.ncm.toJson() : null,
      taxCategory: this.taxCategory ? this.taxCategory.toJson() : null
    });
  }

  static fromJson(json: any): FiscalProduct {
    return SgvJson.from.simple(json, FiscalProduct, {
      ncm: json.ncm ? NCM.fromJson(json.ncm) : null,
      taxCategory: json.taxCategory ? TaxCategorySummary.fromJson(json.taxCategory) : null
    });
  }
}

export class FiscalProductSummary implements JsonEntity {
  constructor(
    readonly id = SgvId.gen(),
    public name?: string,
    public ncm?: NCM
  ) { }

  get nameWithNumber(){
    return this.ncm.number && this.name !== '' ? `${this.ncm.number} - ${this.name}` : '';
  }

  toJson() {
    return SgvJson.to.simple(this, {
      ncm: this.ncm ? this.ncm.toJson() : null
    });
  }

  static fromJson(json: any): FiscalProductSummary {
    return SgvJson.from.simple(json, FiscalProductSummary, {
      ncm: json.ncm ? NCM.fromJson(json.ncm) : null
    });
  }
}
