import { SgvJson } from '@eceos/arch';
import { CalculatedValue } from '../../../nfes/calculated-value';
import { NFeItem } from '../../../nfes/nfe-item';
import { BcModality } from '../../../nfes/tax/icms/bc-modalities/bc-modality';
import { IcmsWithBcValue } from '../../../nfes/tax/icms/icms-with-bc-value';

export class IcmsWithBc {
  constructor(
    public bcModality: BcModality = null,
    public bcFormula: string = null,
    public aliquot: number = null,
    public bcReductionPercent: number = null
  ) {}

  toValue(nfeItem: NFeItem): IcmsWithBcValue {
    return new IcmsWithBcValue(
      this.bcModality,
      this.bcFormula,
      new CalculatedValue(),
      this.aliquot,
      new CalculatedValue(),
      this.bcReductionPercent
    ).publishValues(nfeItem);
  }

  toJson() {
    return SgvJson.to.simple(this, {
      bcModality: this.bcModality ? this.bcModality.toJson() : null
    });
  }

  static fromJson(json: any) {
    return json
      ? SgvJson.from.simple(json, IcmsWithBc, {
          bcModality: BcModality.fromJson(json.bcModality)
        })
      : null;
  }
}
