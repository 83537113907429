import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@eceos/arch';
import { Category } from './category';

@Injectable({ providedIn: 'root' })
export class CategoriesRepository extends RepositoryImpl<Category, Category> {
  constructor(api: ApiService) {
    super(api.root.path('equeue').path('categories'), Category, Category);
  }
}
