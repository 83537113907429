import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { EntityFabDialOption, IconSource } from './entity-fab-dial-option';

export type LabelPosition = 'left' | 'right' | 'top' | 'bottom';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'entity-fab-dial',
  templateUrl: './entity-fab-dial.component.html',
  styleUrls: ['./entity-fab-dial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFabDialComponent implements OnInit {
  readonly MATERIAL = IconSource.MATERIAL;
  readonly FONT_AWESOME = IconSource.FONTAWESOME;

  _fabOpen = false;

  @Output() optionSelect = new EventEmitter<EntityFabDialOption>();

  @Output() fabOpenChange = new EventEmitter<boolean>();

  @Input() showLabels = true;

  @Input() labelsPosition: LabelPosition = 'left';

  @Input() options: EntityFabDialOption[] = [];

  @Input() set fabOpen(value: boolean) {
    this._fabOpen = value;
  }

  get fabOpen(): boolean {
    return this._fabOpen;
  }
  constructor() {}

  ngOnInit() {}

  optionClick(option: EntityFabDialOption) {
    this.optionSelect.emit(option);
  }

  onOpenChange(opened: boolean) {
    this.fabOpenChange.emit(opened);
  }
}
