import { SgvId, SgvJson } from '@eceos/arch';
import {
  CuritibaSpecialTaxationRegime,
  ElotechSpecialTaxationRegime,
  Govbr203SpecialTaxationRegime,
  GovbrSpecialTaxationRegime,
  SpecialTaxationRegime
} from './special-taxation-regime';

export abstract class NfseFiscalDocumentSetting {
  readonly id: string = SgvId.gen();

  abstract get specialTaxationRegime(): SpecialTaxationRegime;

  abstract toJson(): any;

  static fromJson(json: any): NfseFiscalDocumentSetting {
    if (!json) return;
    switch (json.type) {
      case 'curitibaFiscalDocument':
        return CuritibaFiscalDocumentSetting.fromJson(json);
      case 'elotechFiscalDocument':
        return ElotechFiscalDocumentSetting.fromJson(json);
      case 'govbr203FiscalDocument':
        return Govbr203FiscalDocumentSetting.fromJson(json);
      case 'govbrFiscalDocument':
        return GovbrFiscalDocumentSetting.fromJson(json);
    }
  }
}

export class CuritibaFiscalDocumentSetting extends NfseFiscalDocumentSetting {
  constructor(
    public specialTaxationRegime: CuritibaSpecialTaxationRegime = null,
    public simpleNational: boolean = false,
    public culturalPromoter: boolean = false,
    public defaultConditionedDiscount: boolean = false
  ) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'curitibaFiscalDocument',
      specialTaxationRegime: this.specialTaxationRegime?.toJson()
    });
  }

  static fromJson(json: any): CuritibaFiscalDocumentSetting {
    return SgvJson.from.simple(json, CuritibaFiscalDocumentSetting, {
      specialTaxationRegime: CuritibaSpecialTaxationRegime.fromJson(json?.specialTaxationRegime)
    });
  }
}

export class ElotechFiscalDocumentSetting extends NfseFiscalDocumentSetting {
  constructor(
    public specialTaxationRegime: ElotechSpecialTaxationRegime = null,
    public taxIncentive: boolean = false,
    public password: string = '',
    public defaultConditionedDiscount: boolean = false
  ) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'elotechFiscalDocument',
      specialTaxationRegime: this.specialTaxationRegime?.toJson()
    });
  }

  static fromJson(json: any): ElotechFiscalDocumentSetting {
    return SgvJson.from.simple(json, ElotechFiscalDocumentSetting, {
      specialTaxationRegime: ElotechSpecialTaxationRegime.fromJson(json?.specialTaxationRegime)
    });
  }
}

export class GovbrFiscalDocumentSetting extends NfseFiscalDocumentSetting {
  constructor(
    public specialTaxationRegime: GovbrSpecialTaxationRegime = null,
    public simpleNational: boolean = false,
    public culturalPromoter: boolean = false,
    public defaultConditionedDiscount: boolean = false
  ) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'govbrFiscalDocument',
      specialTaxationRegime: this.specialTaxationRegime?.toJson()
    });
  }

  static fromJson(json: any): GovbrFiscalDocumentSetting {
    return SgvJson.from.simple(json, GovbrFiscalDocumentSetting, {
      specialTaxationRegime: GovbrSpecialTaxationRegime.fromJson(json?.specialTaxationRegime)
    });
  }
}

export class Govbr203FiscalDocumentSetting extends NfseFiscalDocumentSetting {
  constructor(
    public specialTaxationRegime: Govbr203SpecialTaxationRegime = null,
    public taxIncentive: boolean = false,
    public simpleNational: boolean = false,
    public defaultConditionedDiscount: boolean = false
  ) {
    super();
  }

  toJson() {
    return SgvJson.to.simple(this, {
      type: 'govbr203FiscalDocument',
      specialTaxationRegime: this.specialTaxationRegime?.toJson()
    });
  }

  static fromJson(json: any): Govbr203FiscalDocumentSetting {
    return SgvJson.from.simple(json, Govbr203FiscalDocumentSetting, {
      specialTaxationRegime: Govbr203SpecialTaxationRegime.fromJson(json?.specialTaxationRegime)
    });
  }
}
