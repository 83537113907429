import { NFeItem } from '../nfe-item';
import { ICMS } from '../tax/icms/icms';
import { IcmsSnWithCreditValue } from '../tax/icms/icms-sn-with-credit-value';
import { IcmsStWithBcValue } from '../tax/icms/icms-st-with-bc-value';
import { IcmsStWithDestinationUfValue } from '../tax/icms/icms-st-with-destination-value';
import { IcmsStWithRetainedValue } from '../tax/icms/icms-st-with-retained-value';
import { IcmsWithBcValue } from '../tax/icms/icms-with-bc-value';
import { IcmsWithDeferralValue } from '../tax/icms/icms-with-deferral-value';
import { IcmsWithFcpStRetainedValue } from '../tax/icms/icms-with-fcp-st-retained-value';
import { IcmsWithFcpStValue } from '../tax/icms/icms-with-fcp-st-value';
import { IcmsWithFcpValue } from '../tax/icms/icms-with-fcp-value';
import { AddsValue } from './adds-value';
import { IcmsWithDesonerationValue, IcmsWithDesonerationWithoutTaxValue } from '..';

export class ICMSTotal {
  constructor(private items: () => NFeItem[], private addsValue: () => AddsValue) {}

  get total(): number {
    const total = [this.addsValue().totalIcms ? this.value : 0, this.stValue]
      .filter(it => it != null)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
    return total - (this.addsValue().totalIcmsDesoneration ? this.desonerationValue : 0);
  }

  get bcValue(): number {
    return this.icmsWithBcValue()
      .filter(it => it.bcValue && it.bcValue.value)
      .map(it => it.bcValue.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get value(): number {
    return this.icmsWithBcValue()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get desonerationValue(): number {
    return this.icmsWithDesoneration()
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0)
      + 
      this.icmsWithDesonerationWithoutTax()
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get stBcValue(): number {
    return this.icmsStWithBcValue()
      .filter(it => it.bcValue && it.bcValue.value)
      .map(it => it.bcValue.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get stValue(): number {
    return this.icmsStWithBcValue()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get fcpValue(): number {
    return this.icmsWithFcpValue()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get fcpStValue(): number {
    return this.icmsWithFcpStValue()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get fcpStRetainedValue(): number {
    return this.icmsWithFcpStRetainedValue()
      .map(it => it.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get stRetainedValue(): number {
    return this.icmsStWithRetainedValue()
      .filter(it => it.retainedValue && it.retainedValue.value)
      .map(it => it.retainedValue.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get stSubstituteValue(): number {
    return this.icmsStWithRetainedValue()
      .filter(it => it.substituteValue && it.substituteValue.value)
      .map(it => it.substituteValue.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get deferralValue(): number {
    return this.icmsWithDeferralValue()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get stDestinationUfValue(): number {
    return this.icmsStWithDestinationUfValue()
      .map(it => it.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  get snCreditValue(): number {
    return this.icmsSnWithCreditValue()
      .filter(it => it.value && it.value.value)
      .map(it => it.value.value)
      .reduce((vAnt, vAt) => vAnt + vAt, 0.0);
  }

  private icms(): ICMS[] {
    return this.items()
      .map(it => it.tax.taxes.icms)
      .filter(it => it != null);
  }

  private icmsWithBcValue(): IcmsWithBcValue[] {
    return (this.icms() as any[])
      .filter(it => it.icms !== undefined && it.icms instanceof IcmsWithBcValue)
      .map(it => it.icms as IcmsWithBcValue);
  }

  private icmsWithDesoneration(): IcmsWithDesonerationValue[] {
    return (this.icms() as any[])
      .filter(
        it => it.desoneration !== undefined && it.desoneration instanceof IcmsWithDesonerationValue
      )
      .map(it => it.desoneration as IcmsWithDesonerationValue);
  }

  private icmsWithDesonerationWithoutTax(): IcmsWithDesonerationWithoutTaxValue[] {
    return (this.icms() as any[])
      .filter(
        it => it.desoneration !== undefined && it.desoneration instanceof IcmsWithDesonerationWithoutTaxValue
      )
      .map(it => it.desoneration as IcmsWithDesonerationWithoutTaxValue);
  }

  private icmsStWithBcValue(): IcmsStWithBcValue[] {
    return (this.icms() as any[])
      .filter(it => it.icmsSt !== undefined && it.icmsSt instanceof IcmsStWithBcValue)
      .map(it => it.icmsSt as IcmsStWithBcValue);
  }

  private icmsWithFcpValue(): IcmsWithFcpValue[] {
    return (this.icms() as any[])
      .filter(it => it.fcp !== undefined && it.fcp instanceof IcmsWithFcpValue)
      .map(it => it.fcp as IcmsWithFcpValue);
  }

  private icmsWithFcpStValue(): IcmsWithFcpStValue[] {
    return (this.icms() as any[])
      .filter(it => it.fcpSt !== undefined && it.fcpSt instanceof IcmsWithFcpStValue)
      .map(it => it.fcpSt as IcmsWithFcpStValue);
  }

  private icmsWithFcpStRetainedValue(): IcmsWithFcpStRetainedValue[] {
    return (this.icms() as any[])
      .filter(
        it =>
          it.fcpStRetained !== undefined && it.fcpStRetained instanceof IcmsWithFcpStRetainedValue
      )
      .map(it => it.fcpStRetained as IcmsWithFcpStRetainedValue);
  }

  private icmsStWithRetainedValue(): IcmsStWithRetainedValue[] {
    return (this.icms() as any[])
      .filter(
        it =>
          it.icmsStRetained !== undefined && it.icmsStRetained instanceof IcmsStWithRetainedValue
      )
      .map(it => it.icmsStRetained as IcmsStWithRetainedValue);
  }

  private icmsWithDeferralValue(): IcmsWithDeferralValue[] {
    return (this.icms() as any[])
      .filter(it => it.deferral !== undefined && it.deferral instanceof IcmsWithDeferralValue)
      .map(it => it.deferral as IcmsWithDeferralValue);
  }

  private icmsStWithDestinationUfValue(): IcmsStWithDestinationUfValue[] {
    return (this.icms() as any[])
      .filter(
        it =>
          it.icmsStDestinationUf !== undefined &&
          it.icmsStDestinationUf instanceof IcmsStWithDestinationUfValue
      )
      .map(it => it.icmsStDestinationUf as IcmsStWithDestinationUfValue);
  }

  private icmsSnWithCreditValue(): IcmsSnWithCreditValue[] {
    return (this.icms() as any[])
      .filter(it => it.creditSn !== undefined && it.creditSn instanceof IcmsSnWithCreditValue)
      .map(it => it.creditSn as IcmsSnWithCreditValue);
  }

}
