import { SgvJson } from '@eceos/arch';
import { NFeInvoice } from './nfe-invoice';
import { NFeDuplicate } from './nfe-duplicate';

export class NFeCharging {
  constructor(
    public invoice: NFeInvoice = new NFeInvoice(),
    public duplicates: NFeDuplicate[] = []
  ) {}

  get duplicatesTotalValue(): number {
    return this.duplicates
      ? this.duplicates
          .map(duplicate => duplicate.value)
          .reduce((value, otherValue) => value + otherValue, 0)
      : 0;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      invoice: this.invoice.toJson(),
      duplicates: SgvJson.to.array(this.duplicates)
    });
  }

  static fromJson(json: any): NFeCharging {
    return json
      ? SgvJson.from.simple(json, NFeCharging, {
          invoice: NFeInvoice.fromJson(json.invoice),
          duplicates: SgvJson.from.array(json.duplicates, NFeDuplicate.fromJson)
        })
      : null;
  }
}
