import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiPath, ApiService, InterceptorConfig, RequestMetadata } from '@eceos/arch';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnterprisesService {
  private path: ApiPath;
  constructor(api: ApiService, private sanitizer: DomSanitizer) {
    this.path = api.root.path('enterprises');
  }

  getInactive(): Observable<Date> {
    return this.path.path('current').path('inactive').getJson().pipe(map((r) => { 
            return r ? new Date(String(r)) : null;
    }));
  }

}
