import { SgvId, SgvJson } from '@eceos/arch';

export class II {
  constructor(
    readonly id: string = SgvId.gen(),
    public bcValue: number = null,
    public customCharge: number = null,
    public value: number = null,
    public iof: number = null
  ) {}

  isValid(): boolean {
    return (
      this.bcValue != null && this.customCharge != null && this.value != null && this.iof != null
    );
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): II {
    return SgvJson.from.simple(json, II);
  }
}
