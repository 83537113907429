import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class DefaultMatPaginatorIntl extends MatPaginatorIntl {
    itemsPerPageLabel = "Items por página";
    firstPageLabel = "Primeira página";
    lastPageLabel = "Última página";
    nextPageLabel = "Próxima página";
    previousPageLabel = "Página anterior";

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' até ' + endIndex + ' de ' + length;
    };

}