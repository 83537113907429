import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrentEcnpjService, CURRENT_ENTERPRISE, Ecnpj, PermissionService } from '@eceos/domain';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom, take } from 'rxjs';
import { EcnpjUploadDialogComponent } from '../../../shared/components/ecnpj-upload-dialog/ecnpj-upload-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-certificate-expiration',
  templateUrl: './certificate-expiration.component.html',
  styleUrls: ['./certificate-expiration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificateExpirationComponent implements OnInit {

  ecnpj: Ecnpj = null;
  almostExpired = false;
  isExpired = false;
  canAccess = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private ecnpjService: CurrentEcnpjService,
    private dialog: MatDialog,
    private permissionService: PermissionService
  ) { }

  async ngOnInit() {
    this.canAccess = await this.canAccessEcnpjConfig();
    if (this.canAccess) {
      this.loadEcnpj();
    }
  }

  private async canAccessEcnpjConfig() {
    await lastValueFrom(this.permissionService.$permissions.pipe(untilDestroyed(this), take(1)));
    return this.permissionService.canAccess(CURRENT_ENTERPRISE);
  }

  private async loadEcnpj() {
    this.ecnpj = await lastValueFrom(this.ecnpjService.getInfo());
    this.updateExpiredStatus();
    this.changeDetector.markForCheck();
  }

  private updateExpiredStatus() {
    this.almostExpired = this.ecnpj?.isAlmostExpired;
    this.isExpired = this.ecnpj?.isExpired;
  }

  openUploadDialog() {
    this.dialog
      .open(EcnpjUploadDialogComponent)
      .afterClosed().subscribe((update) => {
        if (update) {
          this.loadEcnpj();
        }
      });
  }

}
