import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MailConfirmationComponent } from './mail-confirmation.component';

export const routes: Routes = [
  { path: 'mail/confirmation/:token', component: MailConfirmationComponent }
];

export const MailConfirmationRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
