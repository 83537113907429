import { SgvJson } from "libs/arch/src/lib/domain/json-utils";
import { SgvId } from "libs/arch/src/lib/domain/sgv-id";

export class NFeVolume {

    constructor(
        public id: string = null,
        public volumeQuantity: number = null,
        public volumeSpecies: string = '',
        public volumeTrademark: string = '',
        public volumeNumbering: string = '',
        public netWeight: number = null,
        public grossWeight: number = null,
        public seals: string[] = []
    ) {
        this.id = id ?? SgvId.gen();
        this.volumeQuantity = volumeQuantity;
        this.volumeSpecies = volumeSpecies;
        this.volumeTrademark = volumeTrademark;
        this.volumeNumbering = volumeNumbering;
        this.netWeight = netWeight;
        this.grossWeight = grossWeight;
        this.seals = seals;
    }

    toJson(): NFeVolume {
        return SgvJson.to.simple(this, {
            id: this.id,
            volumeQuantity: this.volumeQuantity,
            volumeSpecies: this.volumeSpecies,
            volumeTrademark: this.volumeTrademark,
            volumeNumbering: this.volumeNumbering,
            netWeight: this.netWeight,
            grossWeight: this.grossWeight,
            seals: this.seals
        });
    }

    static fromJson(json: any): NFeVolume {
        if(json === null){
            return;
        }

        return SgvJson.from.simple(json, NFeVolume);
    }
}