import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { ProductOrigin } from './product-origin';
@Injectable({ providedIn: 'root' })
export class ProductOriginsRepository extends ReadonlyRepositoryImpl<ProductOrigin, ProductOrigin>
  implements ReadonlyRepository<ProductOrigin, ProductOrigin> {
  constructor(api: ApiService) {
    super(api.root.path('fiscal/icms/productOrigins'), ProductOrigin, ProductOrigin);
  }
}
