import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepositoryImpl } from '@eceos/arch';
import { Periodicity } from './periodicity';

@Injectable({ providedIn: 'root' })
export class PeriodicitiesRepository extends ReadonlyRepositoryImpl<Periodicity, Periodicity> {
  constructor(api: ApiService) {
    super(api.manager.path('periodicities'), Periodicity, Periodicity);
  }
}
