import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class Bank implements DomainEntity {
  readonly id = SgvId.gen();
  constructor(
    public name = '',
    public number = 0,
    public allowBankSlip = false,
  ) { }

  readonly isActive = true;

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Bank {
    return SgvJson.from.simple(json, Bank);
  }
}
