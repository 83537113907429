import { DomainEntity, SgvId, SgvJson } from "@eceos/arch";

export class ProductCategorySummary implements DomainEntity{
    constructor(
        public readonly id: string = SgvId.gen(),
        public readonly name: string = '',
        public readonly fullName: string = '',
        public readonly isActive: boolean = true
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any): ProductCategorySummary {
        return json ? SgvJson.from.simple(json, ProductCategorySummary) : null;
    }
}