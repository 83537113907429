import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedValue } from '../../calculated-value';

export interface IcmsWithDeferralValueHolder {
  deferral: IcmsWithDeferralValue;
}

export class IcmsWithDeferralValue {
  constructor(
    public percent: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  isValid(): boolean {
    return EceosValidatorsUtils.isValidOptional(this.value);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): IcmsWithDeferralValue {
    return json
      ? SgvJson.from.simple(json, IcmsWithDeferralValue, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }

  static fromPercent(percent: number): IcmsWithDeferralValue {
    return percent ? new IcmsWithDeferralValue(percent, new CalculatedValue()) : null;
  }
}
