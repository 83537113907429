import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  CashPaymentForm,
  DeferredPaymentForm,
  FinancialDocument,
  FinancialDocumentsRepository,
  PaymentForm
} from '@eceos/domain';
import { forkJoin, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PaymentFormComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentFormComponent implements OnInit, ControlValueAccessor, Validator {
  private _entity: PaymentForm;

  private _totalToPay = 0;

  private registerOnChangeSubscription: Subscription = null;

  private onTouchedListener: any = null;

  @Output() entityChange = new EventEmitter<PaymentForm>();

  form: FormControl;

  disabled = false;

  readonly paymentFormTypes: PaymentFormType[] = [
    { value: null, label: 'Sem pagamento' },
    { value: CashPaymentForm, label: 'Pagamento à vista' },
    { value: DeferredPaymentForm, label: 'Pagamento à prazo' }
  ];

  selectedPaymentFormType: PaymentFormType = this.paymentFormTypes[0];

  financialDocuments: FinancialDocument[] = [];

  paybackFinancialDocuments: FinancialDocument[] = [];

  constructor(
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private financialDocumentsRepository: FinancialDocumentsRepository
  ) {
    forkJoin([
      this.financialDocumentsRepository.listIncome(),
      this.financialDocumentsRepository.allowsPayback()
    ])
      .pipe(take(1))
      .subscribe(
        ([financialDocuments, paybackFinancilDocuments]) => {
          this.financialDocuments = financialDocuments;
          this.paybackFinancialDocuments = paybackFinancilDocuments;
        },
        err => console.error(err),
        () => this.changeDetector.markForCheck()
      );
  }

  ngOnInit() {}

  get entity(): PaymentForm {
    return this._entity;
  }

  @Input()
  set entity(entity: PaymentForm) {
    if (this.entity !== entity) {
      this._entity = entity;
      if (entity) {
        this.selectedPaymentFormType = this.paymentFormTypes.find(
          type => entity.constructor === type.value
        );
      } else {
        this.selectedPaymentFormType = this.paymentFormTypes[0];
      }
      this.createFormFromEntity();
    }
  }

  get totalToPay(): number {
    return this._totalToPay;
  }

  @Input()
  set totalToPay(totalToPay: number) {
    if (this._totalToPay !== totalToPay) {
      this._totalToPay = totalToPay;
      this.clearPayment();
    }
  }

  get hasValueToPay(): boolean {
    return this.totalToPay && this.totalToPay > 0;
  }

  get isCashPayment(): boolean {
    return this.entity instanceof CashPaymentForm;
  }

  get isDeferredPayment(): boolean {
    return this.entity instanceof DeferredPaymentForm;
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.form && this.form.invalid ? { invalid: true } : null;
  }

  writeValue(obj: any): void {
    this.entity = obj;
  }

  registerOnChange(fn: any): void {
    if (this.registerOnChangeSubscription) {
      this.registerOnChangeSubscription.unsubscribe();
    }
    this.registerOnChangeSubscription = this.entityChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListener = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.form) {
      this.publishDisable();
    }
  }

  onSelectedPaymentFormTypeChange(paymentFormType: PaymentFormType) {
    this.entity = paymentFormType.value ? new paymentFormType.value() : null;
    if (this.form) {
      this.form.updateValueAndValidity();
    }
  }

  private createFormFromEntity() {
    this.form = null;
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  
    this.form = this.fb.control(this.entity);
    this.addFormValueChange();
    
    this.changeDetector.markForCheck();
  }

  private addFormValueChange() {
    this.form.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value => {
      this.entityChange.emit((this._entity = value));
      this.changeDetector.markForCheck();
    });
  }

  private publishDisable() {
    if (this.disabled && this.form.enabled) {
      this.form.disable();
    } else if (!this.disabled && this.form.disabled) {
      this.form.enable();
    }
  }

  private clearPayment() {
    if (this.entity) {
      this.entityChange.emit((this.entity = null));
    }
  }
}

interface PaymentFormType {
  readonly value: new () => PaymentForm;
  readonly label: string;
}
