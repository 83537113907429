import { SgvId, SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { PisCst } from './csts/pis-cst';
import { PisValueWithAliquotHolder, PisValueWithAliquotValue } from './pis-value-with-aliquot';
import {
  PisValueWithBcAliquot,
  PisValueWithBcAliquotHolder,
  PisValueWithBcAliquotPercent
} from './pis-value-with-bc-aliquot';

export abstract class PIS {
  constructor(readonly id: string = SgvId.gen(), public cst: PisCst = null) {}

  abstract get value(): number;

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): PIS {
    if (json && json.type) {
      switch (json.type) {
        case 'AliquotPis':
          return AliquotPIS.fromJson(json);
        case 'NonTaxablePis':
          return NonTaxablePIS.fromJson(json);
        case 'OtherPis':
          return OtherPIS.fromJson(json);
        case 'QuantityPis':
          return QuantityPIS.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class AliquotPIS extends PIS implements PisValueWithBcAliquotHolder {
  constructor(
    id = SgvId.gen(),
    cst: PisCst = null,
    public pis: PisValueWithBcAliquot = new PisValueWithBcAliquotPercent()
  ) {
    super(id, cst);
  }

  get value(): number {
    return this.pis.value.value;
  }

  isValid(): boolean {
    return this.cst != null && EceosValidatorsUtils.isValid(this.pis);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'AliquotPis',
      cst: this.cst ? this.cst.toJson() : null,
      pis: this.pis ? this.pis.toJson() : null
    });
  }
  static fromJson(json: any): AliquotPIS {
    return json
      ? SgvJson.from.simple(json, AliquotPIS, {
          cst: PisCst.fromJson(json.cst),
          pis: PisValueWithBcAliquot.fromJson(json.pis)
        })
      : null;
  }
}

export class NonTaxablePIS extends PIS {
  constructor(id = SgvId.gen(), cst: PisCst = null) {
    super(id, cst);
  }

  get value(): number {
    return null;
  }

  isValid(): boolean {
    return this.cst != null;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'NonTaxablePis',
      cst: this.cst ? this.cst.toJson() : null
    });
  }
  static fromJson(json: any): NonTaxablePIS {
    return json
      ? SgvJson.from.simple(json, NonTaxablePIS, { cst: PisCst.fromJson(json.cst) })
      : null;
  }
}

export class OtherPIS extends PIS implements PisValueWithBcAliquotHolder {
  constructor(id = SgvId.gen(), cst: PisCst = null, public pis: PisValueWithBcAliquot = null) {
    super(id, cst);
  }

  get value(): number {
    return this.pis.value.value;
  }

  isValid(): boolean {
    return this.cst != null && EceosValidatorsUtils.isValid(this.pis);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'OtherPis',
      cst: this.cst ? this.cst.toJson() : null,
      pis: this.pis ? this.pis.toJson() : null
    });
  }
  static fromJson(json: any): OtherPIS {
    return json
      ? SgvJson.from.simple(json, OtherPIS, {
          cst: PisCst.fromJson(json.cst),
          pis: PisValueWithBcAliquot.fromJson(json.pis)
        })
      : null;
  }
}

export class QuantityPIS extends PIS implements PisValueWithAliquotHolder {
  constructor(
    id = SgvId.gen(),
    cst: PisCst = null,
    public pis: PisValueWithAliquotValue = new PisValueWithAliquotValue()
  ) {
    super(id, cst);
  }

  get value(): number {
    return this.pis.value.value;
  }

  isValid(): boolean {
    return this.cst != null && EceosValidatorsUtils.isValid(this.pis);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'QuantityPis',
      cst: this.cst ? this.cst.toJson() : null,
      pis: this.pis ? this.pis.toJson() : null
    });
  }
  static fromJson(json: any): QuantityPIS {
    return json
      ? SgvJson.from.simple(json, QuantityPIS, {
          cst: PisCst.fromJson(json.cst),
          pis: PisValueWithAliquotValue.fromJson(json.pis)
        })
      : null;
  }
}
