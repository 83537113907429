import { Injectable } from "@angular/core"
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from "@eceos/arch"
import { Balance } from "./balance"

@Injectable({ providedIn: 'root' })
export class BalancesRepository extends ReadonlyRepositoryImpl<Balance, Balance>
    implements ReadonlyRepository<Balance, Balance> {

    constructor(api: ApiService) {
        super(api.root.path('balancesFile'), Balance, Balance)
    }
}