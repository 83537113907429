import { DomainEntity, SgvId, SgvJson } from '@eceos/arch';
import { NFeFiscalTax } from '@eceos/domain';

export class BcModality implements DomainEntity {
  constructor(
    readonly id = SgvId.gen(),
    readonly name = '',
    readonly number = ''
  ) { }

  get nameWithNumber(): string {
    return this.number + " - " + this.name;
  }

  apply(bcValue: number, aliquot: number, icmsValue: number = 0) {
      return NFeFiscalTax.calcValueBy(bcValue, aliquot);
  }

  toJson() {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): BcModality {
    return SgvJson.from.simple(json, BcModality);
  }
}