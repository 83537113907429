import { SgvJson } from "@eceos/arch";
import { TimeInterval } from '../time-interval';
import { InterestArrears } from "./interest-arrears";

export class CompoundInterestArrears extends InterestArrears {

  constructor(timeInterval?: TimeInterval) {
    super(0.0, timeInterval, "compound");
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): CompoundInterestArrears {
    return SgvJson.from.simple(data, CompoundInterestArrears, {
      timeInterval: InterestArrears.parseInterval(data.timeInterval)
    });
  }
}
