import { FiscalUnity } from './../../fiscal/unities/fiscal-unity';
import { DomainEntity, SgvId, SgvJson, JsonEntity } from '@eceos/arch';

export abstract class OperatableUnitySummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly acronym = '',
    public readonly fiscalUnity: FiscalUnity = null,
    public readonly conversionRate = 0
  ) { }

  abstract get isSimpleUnity(): boolean;

  abstract get isWeightableUnity(): boolean;

  abstract get isHourlyUnity(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): OperatableUnitySummary {
    if (!json) return null;

    switch (json.type) {
      case 'simple':
        return SimpleOperatableUnitySummary.fromJson(json);
      case 'weightable':
        return WeightableOperatableUnitySummary.fromJson(json);
      case 'hourly':
        return HourlyOperatableUnitySummary.fromJson(json);
      default:
        throw new Error('Operatable Unity type not mapped');
    }
  }
}

export class SimpleOperatableUnitySummary extends OperatableUnitySummary {
  constructor(
    public id: string = SgvId.gen(),
    public name: string = '',
    public acronym: string = ''
  ) {
    super(id, name, acronym);
  }

  get isSimpleUnity(): boolean {
    return true;
  }

  get isWeightableUnity(): boolean {
    return false;
  }

  get isHourlyUnity(): boolean {
    return false;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'simple',
    });
  }

  static fromJson(json: any): SimpleOperatableUnitySummary {
    return json 
      ? SgvJson.from.simple(json, SimpleOperatableUnitySummary, {
    }) : null;
  }
}

export class WeightableOperatableUnitySummary extends OperatableUnitySummary {
  constructor(
    public id: string = SgvId.gen(),
    public name: string = '',
    public acronym: string = ''
  ) {
    super(id, name, acronym);
  }

  get isSimpleUnity(): boolean {
    return false;
  }
  get isWeightableUnity(): boolean {
    return true;
  }
  get isHourlyUnity(): boolean {
    return false;
  }
  
  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'weightable'
    });
  }

  static fromJson(json: any): WeightableOperatableUnitySummary {
    return json ? SgvJson.from.simple(json, WeightableOperatableUnitySummary, {
    }) : null;
  }
}

export class HourlyOperatableUnitySummary extends OperatableUnitySummary {
  constructor(
    public id: string = SgvId.gen(),
    public name: string = '',
    public acronym: string = ''
  ) {
    super(id, name, acronym );
  }

  get isSimpleUnity(): boolean {
    return false;
  }
  get isWeightableUnity(): boolean {
    return false;
  }
  get isHourlyUnity(): boolean {
    return true;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'hourly'
    });
  }

  static fromJson(json: any): HourlyOperatableUnitySummary {
    return json ? SgvJson.from.simple(json, HourlyOperatableUnitySummary, {
    }) : null;
  }
}