export class BlobHandler {
  constructor(private readonly blob: Blob) {}

  open() {
    const url = window.URL.createObjectURL(this.blob);
    window.open(url, '_blank');

    this.revokeUrlAfter(url, 10000);
  }

  private revokeUrlAfter(url: string, timeout = 1000) {
    setTimeout(() => window.URL.revokeObjectURL(url), timeout);
  }
}
