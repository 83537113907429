import { Injectable } from '@angular/core';
import { ApiService, ReadonlyRepository, ReadonlyRepositoryImpl } from '@eceos/arch';
import { OperatableUnityLegacy } from './operatable-unity-legacy';
@Injectable({ providedIn: 'root' })
export class OperatableUnitiesRepositoryLegacy extends ReadonlyRepositoryImpl<OperatableUnityLegacy, OperatableUnityLegacy>
  implements ReadonlyRepository<OperatableUnityLegacy, OperatableUnityLegacy> {
  
    constructor(api: ApiService) {
      super(api.root.path('operatable/unities'), OperatableUnityLegacy, OperatableUnityLegacy);
    }
  
}
