import { SgvJson } from '@eceos/arch';
import { EceosValidatorsUtils } from '@eceos/common-utils';
import { CalculatedValue } from '../../calculated-value';

export interface CofinsValueWithAliquotHolder {
  cofins: CofinsValueWithAliquot;
}

export abstract class CofinsValueWithAliquot {
  constructor(
    public aliquot: number = null,
    public value: CalculatedValue = new CalculatedValue()
  ) {}

  abstract isValid(): boolean;

  abstract toJson(): any;

  static fromJson(json: any): CofinsValueWithAliquot {
    if (json && json.type) {
      switch (json.type) {
        case 'percent':
          return CofinsValueWithAliquotPercent.fromJson(json);
        case 'value':
          return CofinsValueWithAliquotValue.fromJson(json);
        default:
          return null;
      }
    }
    return null;
  }
}

export class CofinsValueWithAliquotPercent extends CofinsValueWithAliquot {
  constructor(aliquot: number = null, value = new CalculatedValue()) {
    super(aliquot, value);
  }

  isValid(): boolean {
    return this.aliquot != null && EceosValidatorsUtils.isValid(this.value);
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'percent',
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): CofinsValueWithAliquotPercent {
    return json
      ? SgvJson.from.simple(json, CofinsValueWithAliquotPercent, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}

export class CofinsValueWithAliquotValue extends CofinsValueWithAliquot {
  constructor(
    aliquot: number = null,
    value = new CalculatedValue(),
    public productQuantity: number = null
  ) {
    super(aliquot, value);
  }

  calcValue(): number {
    const value = this.productQuantity * this.aliquot;
    return isNaN(value) ? null : value;
  }

  isValid(): boolean {
    return (
      this.aliquot != null &&
      EceosValidatorsUtils.isValid(this.value) &&
      this.productQuantity != null
    );
  }

  publishValues(): CofinsValueWithAliquotValue {
    this.value.value = this.calcValue();
    return this;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'value',
      value: this.value ? this.value.toJson() : null
    });
  }

  static fromJson(json: any): CofinsValueWithAliquotValue {
    return json
      ? SgvJson.from.simple(json, CofinsValueWithAliquotValue, {
          value: CalculatedValue.fromJson(json.value)
        })
      : null;
  }
}
