import { SgvJson } from "@eceos/arch";
import { SimpleStockData } from "./simple-stock-data";
import { StockLocationSummary } from "./stock-location-summary";

export class StockLocationData {
    constructor(
        public location: StockLocationSummary = null,
        public stockData: SimpleStockData = new SimpleStockData()
    ) { }

    toJson(): any {
        return SgvJson.to.simple(this, {
            location: this.location ? this.location.toJson() : null,
            stockData: this.stockData ? this.stockData.toJson() : null
        });
    }

    static fromJson(json: any): StockLocationData {
        return json 
            ? SgvJson.from.simple(json, StockLocationData, {
            location: json.location ? StockLocationSummary.fromJson(json.location) : null,
            stockData: json.stockData ? SimpleStockData.fromJson(json.stockData) : null
        }) 
        : null;
    }
    
}