import { Injectable } from '@angular/core';
import {
  ApiPath,
  ApiService,
  InterceptorConfig,
  ReadonlyRepositoryImpl,
  RequestMetadata
} from '@eceos/arch';
import { Observable } from 'rxjs';
import { Lead } from './lead';
import { LeadOperation } from './lead-operation';

@Injectable({ providedIn: 'root' })
export class LeadsRepository extends ReadonlyRepositoryImpl<Lead, Lead> {
  protected metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO };

  constructor(api: ApiService) {
    super(api.manager.path('leads'), Lead, Lead);
  }

  sendOperation(operation: LeadOperation): Observable<void> {
    return this.api.path('current').postJson(operation.toJson());
  }
}
